import { DataRequest, MultiMessage, LoginInfo, LoginData } from "./communication.base";
import { delay, Log, parseData, sleep } from "./common";
import JSZip from "jszip";
import { Context } from "../appcontext";

export enum NclMessageType {
  nmsgOK = 0,
  nmsgRealize = 1,
  nmsgData = 2,
  nmsgClose = 3,
  nmsgTerminate = 4,
  nmsgMulti = 5,
  // nmsgMultiWithOutData = 6, Not used on the client
  nmsgOutData = 7,
  nmsgNoop = 8,
  nmsgPostback = 9,

  nmsgCheckUnexpected = Number.MIN_SAFE_INTEGER,
}

export enum ConnectionState {
  Unknown = 0,
  Connected = 1,
  Close = 2,
  Loging = 3,
  Logged = 4,
  Logouting = 5,
  LoggedOut = 6,
}

export interface NclMessage {
  messageType: NclMessageType;
  realizerUID?: string;
  realizeCounter?: number;
  useCompress?: boolean;
  json?: string;
  compressData?: any;
}

enum TPostbackKind {
  pkNone, // Už neposílat postback informace
  pkContinue, // Pokračovat v zasílání postback informací
  pkStop, // Ukončit zpracování vlákna
  pkProgress, // Posílají se informace o průběhu
  pkQuestion, // Posílá se otázka
  pkAnswer, // Posílá se odpověď na otázku
}

enum WSClientRequestType
{
    loginInfo,
    login,
    realize,
    closeSession,
    wait2FResult
}

export interface PostbackMessage {
  PostbackKind: TPostbackKind;
  ProgressTitle: string;
  ProgressPercentage: string;
}

export class NclMessageHelper {
  public static CreateTerminateMsg(realizerUID: string): NclMessage {
    return { messageType: NclMessageType.nmsgTerminate, realizerUID: realizerUID };
  }

  public static CreateLoginMsg(user: string, password: string, sessionId: string): NclMessage {
    return { messageType: NclMessageType.nmsgRealize, json: JSON.stringify({RequestType: WSClientRequestType.login, UserName:user, Password: password, OS: Context.DeviceInfo.OSInfo, Browser:Context.DeviceInfo.BrowserInfo, SessionId: sessionId}) };
  }

  public static CreateWait2FResultMsg(wsid: string): NclMessage {
    return { messageType: NclMessageType.nmsgRealize, json: JSON.stringify({RequestType: WSClientRequestType.wait2FResult, WSID:wsid}) };
  }

  public static CreateCloseSessionMsg(user: string, password: string, sessionId: string): NclMessage {
    return { messageType: NclMessageType.nmsgRealize, json: JSON.stringify({RequestType: WSClientRequestType.closeSession, UserName:user, Password: password, SessionId: sessionId}) };
  }

  public static CreateLoginInfoMsg(user: string): NclMessage {
    return { messageType: NclMessageType.nmsgRealize, json: JSON.stringify({RequestType: WSClientRequestType.loginInfo, UserName:user})};
  }

  public static CreateNoopMsg(inactivitySec: number): NclMessage {
    if (inactivitySec !== undefined) return { messageType: NclMessageType.nmsgNoop, json: JSON.stringify({ InactivitySec: inactivitySec }) };
    else return { messageType: NclMessageType.nmsgNoop };
  }

  public static CreateRealizeMsg(isReconnecting:boolean): NclMessage {
    let obj = {ScreenSize: Context.DeviceInfo.ScreenWidth,
      TransformColumnsCount: Context.DeviceInfo.TransformColumnsCount,
      Culture: Context.DeviceInfo.CurrentCulture,
      PDFSupport: Context.DeviceInfo.IsPDFSupport,
      IndepententFormatMode: Context.DeviceInfo.IndependentFormatMode,
      DCPort: Context.DeviceInfo.DCPort,
      InplaceEditBehavior: Context.DeviceInfo.InplaceEditBehavior,
      UseServerVirtualKeyboard: Context.DeviceInfo.UseServerVirtualKeyboard,
      IsTestMode: Context.DeviceInfo.IsTestMode,
      OS: Context.DeviceInfo.OSInfo, 
      Browser:Context.DeviceInfo.BrowserInfo,
      DeviceInfo: Context.DeviceInfo.DeviceInfo};

    if(isReconnecting !== true){
      obj = Object.assign(obj, {
        K2PK: Context.DeviceInfo.K2PK,
        RID: Context.DeviceInfo.RID,
        ClassName: Context.DeviceInfo.StartClassName,
        FrgtId: Context.DeviceInfo.FrgtId,
        ActiveDC: Context.DeviceInfo.ActiveDC,
        EditMode: Context.DeviceInfo.EditMode,
        SelectionID: Context.DeviceInfo.SelectionID,
        UseMainLayout: Context.DeviceInfo.UseMainLayout,
        Script: Context.DeviceInfo.Script,
        ScriptParams: Context.DeviceInfo.ScriptParams,
        
      });
    }
    return {
      messageType: NclMessageType.nmsgRealize,
      json: JSON.stringify(obj)
    };
  }

  public static CreateRealizeAppMsg(reconnectData: LoginData): NclMessage {
    if (reconnectData) {
      return {
        messageType: NclMessageType.nmsgRealize,
        json: JSON.stringify({RequestType: WSClientRequestType.realize, Server:reconnectData.AS3Server, Pipe:reconnectData.AS3Pipe, ReconnectId: reconnectData.ReconnectUID}),
      };
    }
    return { messageType: NclMessageType.nmsgRealize, json: JSON.stringify({RequestType: WSClientRequestType.realize})};
  }

  public static CreateUpdateMsg(realizerUID: string, realizeCounter: number, request: DataRequest): NclMessage {
    return { messageType: NclMessageType.nmsgData, realizerUID: realizerUID, json: JSON.stringify(request), realizeCounter: realizeCounter };
  }

  public static Create(msg: MultiMessage): NclMessage {
    return { messageType: msg.Operation, realizerUID: msg.RealizerUID, json: msg.JSon, realizeCounter: msg.RealizeCounter };
  }

  public static CreatePostbackMsg(stop: boolean): NclMessage {
    return { messageType: NclMessageType.nmsgPostback, json: stop ? `{PostbackKind:${TPostbackKind.pkStop}}` : `{PostbackKind:${TPostbackKind.pkContinue}}` };
  }

  public static unzipMessage(msg: NclMessage): Promise<boolean> {
    if (msg.useCompress === true) {
      const promise: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
        if (!msg.compressData) reject("Invalid message data: " + JSON.stringify(msg));
        let zip = new JSZip();
        zip.loadAsync(msg.compressData, { base64: true }).then((zip: JSZip) => {
          zip
            .file("message")
            .async("text")
            .then((data: string) => {
              msg.json = data.replace(/\0/g, "");
              resolve(true);
            });
        });
      });

      return promise;
    } else {
      return Promise.resolve<boolean>(true);
    }
  }
}

export interface ReceiveCallBack {
  resolve: (data: any) => void;
  reject: (reason: any) => void;
}

export class K2CommunicationError extends Error {
  private _closeEvent: CloseEvent;
  private _message: string;
  private _detailMessage: string;

  constructor(closeEvent?: CloseEvent, message?: string) {
    super(K2CommunicationError.getDetailMessage(closeEvent, message));
    this._closeEvent = closeEvent;
    this._message = message;
  }

  private static getDetailMessage(closeEvent?: CloseEvent, message?: string): string {
    let result: string = "";
    if (message) {
      result = message + "\n";
    }

    if (closeEvent) {
      result += "Code: " + closeEvent.code + " Reason: " + closeEvent.reason;
    }

    return result;
  }

  get closeEvent(): CloseEvent {
    return this._closeEvent;
  }
}

export interface ReceiveData {
  message: NclMessage;
  error: K2CommunicationError;
}

/**
 * Třída pro komunikaci s WS pomoci WebSocketu
 */
export class BaseConnection {
  private receiveCallbacksQueue: Array<ReceiveCallBack>; //fronta callbacku
  private receiveMessagesQueue: Array<NclMessage>; //fronta příchozích zpráv
  private wsUrl: string; // url WebSocketu
  private socket: WebSocket;
  private closeEvent: CloseEvent;
  protected checkMessCount: number;
  private lastActivity: Date;
  protected lastMessage: Blob;

  public constructor(url: string, https: boolean) {
    this.internalSetWSUrl(https ? `wss://${url}/ws` : `ws://${url}/ws`);
    this.clear();
  }

  public get isConnected(): boolean {
    return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
  }

  public get LastActivity(): Date {
    return this.lastActivity;
  }

  public async connect(): Promise<void> {
    return this.disconnect().then(
      () => {
        this.clear();
        this.socket = new WebSocket(this.wsUrl);
        return this.initListeners();
      },
      () => {
        Log.warn(`Couldn't be disconnect socket:${this.wsUrl}`);
      }
    );
  }

  public async disconnect(code?: number, reason?: string): Promise<CloseEvent> {
    if (!this.isConnected) {
      return Promise.resolve(this.closeEvent);
    }

    let __this = this;
    return new Promise<CloseEvent>((resolve, reject) => {
      const callbacks = {
        resolve: (dummy: any) => {
          __this.receiveCallbacksQueue.push(callbacks);
        },

        reject: resolve,
      };

      __this.receiveCallbacksQueue.push(callbacks);
      __this.socket.close(code, reason);
    });
  }

  public async receive(): Promise<ReceiveData> {
    if (this.receiveMessagesQueue.length !== 0) {
      return Promise.resolve<ReceiveData>({ message: this.receiveMessagesQueue.shift(), error: null });
    }

    if (!this.isConnected) {
      return Promise.reject({
        message: null,
        error: new K2CommunicationError(this.closeEvent, `Not connected. ws:${this.wsUrl}`),
      });
    }

    const promise: Promise<ReceiveData> = new Promise<ReceiveData>((resolve, reject) => {
      this.receiveCallbacksQueue.push({ resolve: resolve, reject: reject });
    });

    return promise;
  }

  protected internalSetWSUrl(value: string) {
    this.wsUrl = value;
  }

  protected isSendMessageAllowed(message: NclMessage): boolean {
    return this.checkMessCount === 0;
  }

  public send(message: NclMessage): boolean {
    if (message.messageType === NclMessageType.nmsgCheckUnexpected && this.receiveMessagesQueue.length > 0) return true;

    if (!this.isSendMessageAllowed(message)) return false;

    if (!this.isConnected) {
      Log.error("Socket isn't connected.", new K2CommunicationError(this.closeEvent, "Socket isn't connected."));
      return false;
    }

    let msg = JSON.stringify(message);
    return this.sendText(msg);
  }

  public sendText(message: string): boolean {
    if (!this.isConnected) {
      Log.error("Socket isn't connected.", new K2CommunicationError(this.closeEvent, "Socket isn't connected."));
      return false;
    }

    this.lastActivity = new Date();
    this.socket.send(message);
    this.checkMessCount++;
    return true;
  }

  private processMessage(data: string) {
    let obj: NclMessage = null;
    if (data) {
      obj = parseData(data);
      if (obj != null) {
        if (obj.messageType === NclMessageType.nmsgPostback) {
          NclMessageHelper.unzipMessage(obj)
            .then((e) => {
              let pm: PostbackMessage = parseData(obj.json);

              if (Context.getApplication().canStopPostback(pm)) {
                this.send(NclMessageHelper.CreatePostbackMsg(true));
              } else {
                this.send(NclMessageHelper.CreatePostbackMsg(false));
              }
            })
            .catch((reason) => {
              console.log(reason);
            });
          return;
        }
        if (this.receiveCallbacksQueue.length !== 0) {
          this.lastMessage = undefined;
          this.receiveCallbacksQueue.shift().resolve({ message: obj, error: null });
          return;
        }
        if (this.checkMessCount != 0) {
          //checkMessCount !=0 means that message received from server without client request. Terminate - server ended klient session.. Or an error has occurred.
          if (obj.messageType === NclMessageType.nmsgTerminate) {
            this.receiveMessagesQueue.push(obj);
            Context.getApplication().sendMessage({ messageType: NclMessageType.nmsgCheckUnexpected } as NclMessage);
            return;
          }
          throw new Error("Send/Receive message mishmash:" + data);
        } else {
          this.receiveMessagesQueue.push(obj);
        }
        return;
      }
    }
    throw new Error("Unknown receive data:" + data);
  }

  private blobToString(blob: Blob, callback: any) {
    var f = new FileReader();
    f.onload = function (e) {
      callback(e.target.result);
    };
    f.readAsText(blob);
  }

  private initListeners(): Promise<void> {
    let _socket = this.socket;
    return new Promise((resolve, reject) => {
      const handleMessage = async (ev: MessageEvent) => {
        this.lastMessage = ev.data;
        this.lastActivity = new Date();
        this.checkMessCount--;
        if (typeof ev.data === "string") {
          this.processMessage(ev.data);
        } else {
          this.blobToString(await ev.data, (json: string) => {
            this.processMessage(json);
          });
        }
      };

      const handleOpen = (ev: Event): any => {
        this.lastActivity = new Date();
        _socket.onmessage = handleMessage;
        _socket.onclose = async (ev: CloseEvent) => {
          Log.error(`Websocket ${this.wsUrl}, error code: ${ev.code} reason ${ev.reason}`, null);
          this.closeEvent = ev;
          while (this.receiveCallbacksQueue.length !== 0) {
            this.receiveCallbacksQueue.shift().reject(this.closeEvent);
              return;
          }

          if (ev.code !== 1000 || !this.processClosedConnection()) {
            reject(`Websocket ${this.wsUrl}, error code: ${ev.code} reason ${ev.reason}`);
          }
        };
        resolve();
      };

      _socket.onopen = handleOpen;
      _socket.onerror = (e) => {
        Log.error(`Websocket error`, null);
        reject(`Websocket error`);
      };
    });
  }

  protected processClosedConnection(): Boolean {
    return false;
  }

  private clear() {
    this.closeEvent = null;
    this.socket = null;
    this.receiveCallbacksQueue = new Array<ReceiveCallBack>();
    this.receiveMessagesQueue = new Array<NclMessage>();
    this.checkMessCount = 0;
  }
}

/**
 * Třída pro komunikaci s WS pomoci WebSocketu rozšířená o funkčnosti UI
 */
export class Connection extends BaseConnection {
  private baseUrl: string; // url pro přístup k obrázkům

  public constructor(url: string, https: boolean) {
    super(url, https);
    this.baseUrl = https ? `https://${url}` : `http://${url}`;
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  private modifyUrl(value: string): string {
    let server = Context.DeviceInfo.getQueryVariable("AS3SERVER", undefined);
    let pipe = Context.DeviceInfo.getQueryVariable("AS3PIPE", undefined);
    if (server && pipe) {
      if (value.indexOf("?") > 0) {
        value = value + `&AS3SERVER=${server}&AS3PIPE=${pipe}`;
      } else {
        value = value + `?AS3SERVER=${server}&AS3PIPE=${pipe}`;
      }
    }
    return value;
  }

  protected internalSetWSUrl(value: string) {
    super.internalSetWSUrl(this.modifyUrl(value));
  }

  protected isSendMessageAllowed(message: NclMessage): boolean {
    return (
      super.isSendMessageAllowed(message) || //jen pokud se zrovna neceka na odpoved
      (this.checkMessCount >= 0 && message && message.messageType === NclMessageType.nmsgPostback)
    ); // jediny postback chodi v prubehu request  -  response, ale taky musi byt parovy
  }

  public getLoginInfo(user: string): Promise<LoginInfo> {
    return new Promise<LoginInfo>(async (resolve, reject) => {
      this.connect()
        .then(() => {
          if (this.send(NclMessageHelper.CreateLoginInfoMsg(user))) {
            return this.receive().then((data) => {
              if (data.message) {
                if (data.message.messageType === undefined || data.message.messageType === NclMessageType.nmsgOK) {
                  resolve(parseData(data.message.json) as LoginInfo);
                } else {
                  reject(data.message.json);
                }
              } else {
                if (data.error) {
                  Log.error("LoginInfo", data.error);
                  reject(data.error.message);
                }

                reject("LoginInfo Failed");
              }
              }).catch((reason)=>{reject(this.getReason(reason));});
          } else {
            reject("Request GetLoginInfo not sended.");
          }
        })
        .catch((reason) => {
          reject(this.getReason(reason));
        });
    });
  }

  private getReason(reason: any): any{
    if (reason instanceof Event) {
      if (this.lastMessage) return this.lastMessage;
      else return "Připojení bylo neočekávaně ukončeno.";
    } 
    return reason;
  }

  public closeSession(user: string, password: string, sessionId:string): Promise<boolean> {
    if (this.send(NclMessageHelper.CreateCloseSessionMsg(user, password, sessionId))) {
      return this.receive()
        .then((data) => {
          if (data.message) {
            if (data.message.messageType === undefined || (data.message.messageType === NclMessageType.nmsgOK && !data.message.json)) {
              return Promise.resolve<boolean>(true);
            } else {
              return Promise.reject(data.message.json);
            }
          } else {
            if (data.error) {
              Log.error("Close session", data.error);
              return Promise.reject(data.error.message);
            }

            return Promise.reject("Close session failed");
          }
        })
        .catch((reason) => {
          return Promise.reject(this.getReason(reason));
        });
    } else {
      return Promise.reject("Request CloseSession not sended.");
    }
  }

  public login(user: string, password: string, sessionId:string): Promise<LoginData> {
    return this.loginOrWait2FResult(NclMessageHelper.CreateLoginMsg(user, password, sessionId));
  }

  public wait2FResult(wsid:string): Promise<LoginData> {
    if(this.isConnected){
      return this.loginOrWait2FResult(NclMessageHelper.CreateWait2FResultMsg(wsid));
    }else{
      return new Promise<LoginData>((resolve,reject)=>{
        this.connect().then(async()=>{
            resolve(this.wait2FResult(wsid));
          }).catch(async(reason)=>{
            await delay(1000);
            resolve(this.wait2FResult(wsid));
          });
      });
    }
  }

  private loginOrWait2FResult(msg:NclMessage): Promise<LoginData> {
    if (this.send(msg)) {
      return this.receive()
        .then((data) => {
          if (data.message) {
            if (data.message.messageType === undefined || data.message.messageType === NclMessageType.nmsgOK) {
              return Promise.resolve(JSON.parse(data.message.json) as LoginData);
            } else {
              return Promise.reject(data.message.json);
            }
          } else {
            if (data.error) {
              Log.error("Login", data.error);
              return Promise.reject(data.error.message);
            }

            return Promise.reject("Login Failed");
          }
        })
        .catch((reason) => {
          return  Promise.reject(this.getReason(reason));
        });
    } else {
      return Promise.reject("Request Login not sended.");
    }
  }

  protected processClosedConnection(): Boolean {
    //iis send only this code in te case that app closed
    if (Context.getApplication().appViewRealizer != null) {
      Context.getApplication().terminate();
      return true;
    }

    return false;
  }
}
