import React, { useRef, useState, useEffect } from "react";
import { Context } from "../../appcontext";
import { FrgtPanelBaseData, SplitterPanelOrientation, UpdateSplitterPanel } from "../../common/communication.base";
import { NclPanel, NclSplitterPanel, NclView } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { GenerateControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import css from "./SplitterPanel.scss";

const TOUCH_MARGIN = 20;
const ALLOWED_SWIPE_AREA = 200;

export const SplitterContext = React.createContext(null);

const K2SplitterPanel = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclSplitterPanel, UpdateSplitterPanel, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclSplitterPanel
  );
  const [ratio, setRatio] = useState(control.Ncl.FrgtData.InitialRatio / 100);
  const [mobile, setMobile] = useState(window.innerWidth > Context.DeviceInfo.ResponsiveBreakpoints[0] ? false : true);
  const [dragging, setDragging] = useState(false);
  const timer = useRef(0);
  const currentTarget = useRef<HTMLDivElement>();
  const rect = useRef<DOMRect>();

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dragging]);

  useEffect(() => {
    if (!data.Visible) return;

    setRatio(getRatio());
  }, [data.Ratio]);

  const handleResize = () => {
    if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      setRatio(Math.round(ratio));
      setMobile(true);
    } else {
      setRatio(ratio);
      setMobile(false);
    }
  };

  function getRatio() {
    let ratio = 0;

    if (window.innerWidth > Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      ratio = data.Ratio;
    } else {
      if (control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight || control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoBottom) {
        ratio = Math.ceil(data.Ratio);
      } else {
        ratio = Math.floor(data.Ratio);
      }
    }

    return ratio;
  }

  const isSplitterVertical = () => {
    if (control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoLeft || control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight)
      return true;
    else return false;
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setDragging(true);
    rect.current = e.currentTarget.parentElement.getBoundingClientRect();
  };

  const handleContextMenu = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) => {
    if (dragging) {
      e.stopPropagation();
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.currentTarget.children[1] === undefined) return;

    currentTarget.current = e.currentTarget;
    rect.current = e.currentTarget.getBoundingClientRect();
    const splitter = e.currentTarget.children[1].getBoundingClientRect();
    const clientX = e.touches[0].clientX;
    const clientY = e.touches[0].clientY;

    // Vymezeni mista, kde je mozne vyvolat resize
    if (
      (isSplitterVertical() && clientX > splitter.left - TOUCH_MARGIN && clientX < splitter.right + TOUCH_MARGIN) ||
      (isSplitterVertical() === false && clientY > splitter.top - TOUCH_MARGIN && clientY < splitter.bottom + TOUCH_MARGIN)
    ) {
      e.stopPropagation();

      // Simulace long touche
      timer.current = window.setTimeout(() => {
        if (isSplitterVertical()) {
          dragStart(currentTarget.current.children[1] as HTMLDivElement, "width");
        } else if (isSplitterVertical() === false) {
          dragStart(currentTarget.current.children[1] as HTMLDivElement, "height");
        }
      }, 500);
    }
  };

  const dragStart = (splitter: HTMLDivElement, dimension: string) => {
    setDragging(true);
    splitter.style.cssText = `${dimension}: ${control.VCX.sizeMap(control.VCX.SplitterControl.Size * 2)}px;`;
  };

  const dragCancel = (splitter: HTMLDivElement, dimension: string) => {
    clearTimeout(timer.current);

    setDragging(false);
    splitter.style.cssText = `${dimension}: ${null}`;
  };

  const handleMouseTouchMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (!dragging) return;
    e.stopPropagation();

    // Podminka zajisti preruseni resizu na dotykovach zarizenich, pokud uzivatel zacne vyvolavat long touch, ale rychle swipne pryc z oblasti okolo splitteru
    if (
      window.TouchEvent &&
      e.nativeEvent instanceof TouchEvent &&
      isSplitterVertical() &&
      !(
        e.nativeEvent.touches[0].clientX > e.currentTarget.children[1].getBoundingClientRect().left - ALLOWED_SWIPE_AREA &&
        (e as React.TouchEvent).touches[0].clientX < e.currentTarget.children[1].getBoundingClientRect().right + ALLOWED_SWIPE_AREA
      )
    ) {
      dragCancel(e.currentTarget.children[1] as HTMLDivElement, "width");
      return;
    } else if (
      window.TouchEvent &&
      e.nativeEvent instanceof TouchEvent &&
      isSplitterVertical() === false &&
      !(
        e.nativeEvent.touches[0].clientY > e.currentTarget.children[1].getBoundingClientRect().top - ALLOWED_SWIPE_AREA &&
        (e as React.TouchEvent).touches[0].clientY < e.currentTarget.children[1].getBoundingClientRect().bottom + ALLOWED_SWIPE_AREA
      )
    ) {
      dragCancel(e.currentTarget.children[1] as HTMLDivElement, "height");
      return;
    }

    let clientX: number;
    let clientY: number;

    if (e.nativeEvent instanceof MouseEvent) {
      clientX = (e as React.MouseEvent<HTMLDivElement>).clientX;
      clientY = (e as React.MouseEvent<HTMLDivElement>).clientY;
    } else {
      clientX = (e as React.TouchEvent<HTMLDivElement>).touches[0].clientX;
      clientY = (e as React.TouchEvent<HTMLDivElement>).touches[0].clientY;
    }

    if (isSplitterVertical()) {
      const xPos = clientX - rect.current.left;
      const ratio = xPos / e.currentTarget.offsetWidth;
      setRatio(ratio);
    } else {
      const yPos = clientY - rect.current.top;
      const ratio = yPos / e.currentTarget.offsetHeight;
      setRatio(ratio);
    }
  };

  const handleMouseUpTouchEnd = () => {
    if (timer.current) {
      if (isSplitterVertical()) {
        dragCancel(currentTarget.current.children[1] as HTMLDivElement, "width");
      } else {
        dragCancel(currentTarget.current.children[1] as HTMLDivElement, "height");
      }
    }

    setDragging(false);
    control.setRatio(ratio);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleDoubleClick = () => {
    control.toggleCollapsed();
  };

  const handleClick = () => {
    if (!isSplitterVertical()) return;

    if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
      setRatio(ratio === 0 ? 1 : 0);
    }
  };

  const isCollapsed = (first: boolean) => {
    if (data.Visible === false) return true;

    if (data.Collapsed) {
      if (
        (first &&
          (control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight ||
            control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoBottom)) ||
        (!first &&
          (control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoLeft || control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoTop))
      ) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  const isOnlyFirstVisible = () => {
    if (data.FirstVisible === false && data.SecondVisible === true) return true;
    else return false;
  };

  const isOnlySecondVisible = () => {
    if (data.FirstVisible === true && data.SecondVisible === false) return true;
    else return false;
  };

  const setStyle = (first: boolean) => {
    let style: React.CSSProperties = {};

    if (data.Collapsed) {
      if (
        (first &&
          (control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoRight ||
            control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoBottom)) ||
        (!first &&
          (control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoLeft || control.Ncl.FrgtData.Orientation === SplitterPanelOrientation.spoTop))
      ) {
        style = { flexGrow: 1 };
      } else {
        style = { display: "none" };
      }
    } else {
      style = { flexGrow: 1 };
      if (isSplitterVertical()) {
        if (first) {
          if (control.Children.get(0) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = {
              ...style,
              width: ratio * 100 + "%",
              display: isOnlyFirstVisible() ? "none" : "flex",
            };
          }

          if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
            style = { ...style, transition: "width 0.5s ease-in-out 0s", padding: `${control.VCX.sizeMap(3)}px 0px` };
          }
        } else {
          if (control.Children.get(1) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = {
              ...style,
              width: (1 - ratio) * 100 + "%",
              display: isOnlySecondVisible() ? "none" : "flex",
            };
          }

          if (window.innerWidth < Context.DeviceInfo.ResponsiveBreakpoints[0]) {
            style = { ...style, transition: "width 0.5s ease-in-out 0s", padding: `${control.VCX.sizeMap(3)}px 0px` };
          }
        }
      } else {
        if (first) {
          if (control.Children.get(0) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = {
              ...style,
              height: ratio * 100 + "%",
              display: isOnlyFirstVisible() ? "none" : "flex",
              minHeight:
                control.Children.get(0) instanceof NclPanel && (control.Children.get(0).MetaData.FrgtData as FrgtPanelBaseData).Scroll
                  ? control.Children.get(0).VCX.LabelControl.getHeight(control.Children.get(0).Size)
                  : control.Children.get(0).ComputedMinHeightWithMargin,
            };
          }
        } else {
          if (control.Children.get(1) === undefined) {
            style = { ...style, flexGrow: 0 };
          } else {
            style = {
              ...style,
              height: (1 - ratio) * 100 + "%",
              display: isOnlySecondVisible() ? "none" : "flex",
              minHeight:
                control.Children.get(1) instanceof NclPanel && (control.Children.get(1).MetaData.FrgtData as FrgtPanelBaseData).Scroll
                  ? control.Children.get(1).VCX.LabelControl.getHeight(control.Children.get(1).Size)
                  : control.Children.get(1).ComputedMinHeightWithMargin,
            };
          }
        }
      }
      style = { overflow: "auto", ...style };
    }

    return style;
  };

  const updateRatio = () => {
    setRatio(ratio === 1 ? 0 : 1);
  };

  const getSplitterClassName = () => {
    let className = css.splitter;

    if (isSplitterVertical()) {
      className += ` ${css.splitter_vertical}`;
    } else {
      className += ` ${css.splitter_horizontal}`;
    }

    return className;
  };

  const getSplitterHandleClassName = () => {
    let className = css.splitter_handle;

    if (isSplitterVertical()) {
      className += ` ${css.splitter_handle_vertical}`;
    } else {
      className += ` ${css.splitter_handle_horizontal}`;
    }

    if (!data.Collapsed && (isOnlyFirstVisible() || isOnlySecondVisible())) {
      className += ` ${css.splitter_handle_hide}`;
    }

    if (control.Parent instanceof NclPanel && control.Parent.Parent instanceof NclView) {
      className += ` ${css.splitter_handle_main_panel}`;
    }

    return className;
  };

  return (
    <SplitterContext.Provider value={{ ratio: ratio, updateRatio: updateRatio }}>
      <div
        ref={element}
        style={StyleHelper(control, props.style)}
        className={getSplitterClassName()}
        onTouchStartCapture={handleTouchStart}
        onTouchMoveCapture={handleMouseTouchMove}
        onTouchEnd={handleMouseUpTouchEnd}
        onMouseMove={handleMouseTouchMove}
        onMouseUp={handleMouseUpTouchEnd}
        onMouseLeave={handleMouseLeave}
        onContextMenuCapture={handleContextMenu}
      >
        <div style={setStyle(true)}>{!isCollapsed(true) ? GenerateControl(control.Children.get(0), { alignSelf: "stretch", flex: "1 1 auto" }) : null}</div>
        <div className={getSplitterHandleClassName()} onMouseDown={handleMouseDown} onDoubleClick={handleDoubleClick} onClick={handleClick}>
          {isSplitterVertical() && mobile ? (
            <K2Img vcx={control.VCX} glyphId={ratio === 0 ? "wui*triangleright" : "wui*triangleleft"} height={15} width={15} strokeColor="white" />
          ) : (
            <div className={`${css.splitter_handle_dots}${isSplitterVertical() ? "" : ` ${css.splitter_handle_dots_horizontal}`}`}></div>
          )}
        </div>
        <div
          style={setStyle(false)}
          className={`${control.Parent instanceof NclPanel && control.Parent.Parent instanceof NclView ? css.splitter_mobile_main_panel : ""}`}
        >
          {!isCollapsed(false) ? GenerateControl(control.Children.get(1), { alignSelf: "stretch", flex: "1 1 auto" }) : null}
        </div>
      </div>
    </SplitterContext.Provider>
  );
};

export default K2SplitterPanel;
