import * as React from "react";
import FullCalendar, {
  CalendarApi,
  DatesSetArg,
  DayCellContentArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
  MountArg,
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { EventResizeDoneArg } from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import allLocales from "@fullcalendar/core/locales-all";
import { Hint } from "../Hint/K2Hint";
import { getLocale } from "./CalendarFunctions";

interface CalendarProps {
  lEvents: EventInput[];
  hintBackgroundColor: string;
  hintBorderColor: string;
  deleteButton?: JSX.Element;
  initialStartDate: string;
  initialView: string;
  activeStartDate: string;
  activeEndDate: string;
  locale: number;
  handleDatesRender: (arg: DatesSetArg) => void;
  handleSettingButonClick: (El: HTMLElement) => void;
  handleEventClick: (arg: EventClickArg) => void;
  handleDrop: (arg: EventDropArg) => void;
  handleAppointmentResize: (arg: EventResizeDoneArg) => void;
  contextMenuCallback: (args: MountArg<DayCellContentArg>) => void;
  getApi?: (ref: React.RefObject<FullCalendar>) => void;
  showDatepicker?: (left: number, top: number) => void;
}

export class K2CalendarCommon extends React.Component<CalendarProps> {
  private calendarComponentRef = React.createRef<FullCalendar>();
  private calendarApi: CalendarApi;
  private initialActiveStartDate: string;
  private initialActiveEndDate: string;

  constructor(props: CalendarProps) {
    super(props);
  }

  componentDidMount(): void {
    this.calendarApi = this.calendarComponentRef.current.getApi();

    if (!this.props.getApi) return;
    this.props.getApi(this.calendarComponentRef);
  }

  componentDidUpdate(): void {
    document.body.style.removeProperty("pointer-events");

    if (!this.initialActiveStartDate && !this.initialActiveEndDate) {
      this.initialActiveStartDate = this.props.activeStartDate;
      this.initialActiveEndDate = this.props.activeEndDate;
    }

    if (this.initialActiveStartDate !== this.props.activeStartDate && this.initialActiveEndDate !== this.props.activeEndDate) return;
  }

  getInitialView = () => {
    if (this.props.initialView === "day") {
      return "timeGridDay";
    } else if (this.props.initialView === "week") {
      return "timeGridWeek";
    } else {
      return "dayGridMonth";
    }
  };

  render(): JSX.Element {
    return (
      <div style={{ display: "block", height: "100%", width: "100%" }} onWheel={this.handleWheel}>
        <FullCalendar
          initialView={this.getInitialView()}
          plugins={[interactionPlugin, dayGridPlugin, bootstrapPlugin, timeGridPlugin]}
          ref={this.calendarComponentRef}
          height="100%"
          navLinks={true}
          editable={true}
          dayMaxEventRows={true}
          datesSet={this.props.handleDatesRender}
          headerToolbar={{
            left: "prev,next,SettingButton,today",
            center: "title",
            right: this.props.showDatepicker ? "datePickerButton,dayGridMonth,timeGridWeek,timeGridDay" : "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          locales={allLocales}
          locale={getLocale(this.props.locale)}
          firstDay={1}
          dayHeaderFormat={{
            weekday: "long",
          }}
          views={{
            week: {
              dayHeaderFormat: {
                weekday: "short",
                month: "numeric",
                day: "numeric",
              },
            },
          }}
          customButtons={{
            prev: {
              click: () => {
                if (this.calendarApi.view.type == "dayGrid") {
                  this.calendarApi.changeView("dayGridMonth");
                }

                this.calendarApi.prev();
              },
            },
            next: {
              click: () => {
                if (this.calendarApi.view.type == "dayGrid") {
                  this.calendarApi.changeView("dayGridMonth");
                }

                this.calendarApi.next();
              },
            },
            today: {
              text: "🏠",
              click: () => {
                if (this.calendarApi.view.type == "dayGrid") {
                  this.calendarApi.changeView("dayGridMonth");
                }

                this.calendarApi.today();
              },
            },
            SettingButton: {
              text: "📅",
              click: (ev: MouseEvent, el: HTMLElement) => {
                this.props.handleSettingButonClick(el);
              },
            },
            // datePickerButton: {
            // text: "Vyberte datum...",
            //   click: (ev: MouseEvent) => {
            //     if (ev.currentTarget) {
            //       const rect = (ev.currentTarget as HTMLButtonElement).getBoundingClientRect();
            //       this.props.showDatepicker(rect.left, rect.top);
            //     }
            //   },
            // },
          }}
          eventClick={this.props.handleEventClick}
          events={this.props.lEvents}
          eventDrop={(arg) => this.handleDrop(arg)}
          eventResize={(arg) => this.handleResize(arg)}
          dayCellDidMount={(args) => {
            args.el.addEventListener("contextmenu", () => this.props.contextMenuCallback(args));
          }}
          eventContent={(args) => this.getEventContent(args)}
          eventOrder="priority"
        />
      </div>
    );
  }

  handleDrop = (arg: EventDropArg) => {
    this.disableInteraction();
    this.props.handleDrop(arg);
  };

  handleResize = (arg: EventResizeDoneArg) => {
    this.disableInteraction();
    this.props.handleAppointmentResize(arg);
  };

  disableInteraction = () => {
    document.body.style.pointerEvents = "none";
  };

  getEventContent = (args: EventContentArg): JSX.Element => {
    let DeleteButtonContent: JSX.Element = null;

    if (this.props.deleteButton && args.event.extendedProps.AllowDelete) {
      DeleteButtonContent = (
        <div className="fc-button-delete-wrapper">
          <button className="fc-button-delete-event">{this.props.deleteButton}</button>
        </div>
      );
    }

    return (
      <Hint
        text={args.event.extendedProps.Hint === "" ? args.event.title : args.event.extendedProps.Hint}
        backgroundColor={this.props.hintBackgroundColor}
        borderColor={this.props.hintBorderColor}
      >
        <div className="fc-event-content">
          {args.event.extendedProps.StateGlyphId && args.event.extendedProps.StateIconJSX}
          <div className="fc-event-info">
            {args.event.allDay ? (
              args.event.title
            ) : (
              <div className="fc-event-info-not-all-day">
                <div className="fc-circle" style={{ borderColor: args.event.backgroundColor, backgroundColor: args.event.backgroundColor }}></div>
                <div className="fc-time-and-event">
                  <span className="fc-event-time">{args.event.start.toLocaleTimeString("cs-CZ", { hour: "numeric", minute: "2-digit" })}</span>
                  <span className="fc-event-name">{args.event.title}</span>
                </div>
              </div>
            )}
          </div>
          {args.isEnd ? DeleteButtonContent : ""}
        </div>
      </Hint>
    );
  };

  handleWheel = (e: React.WheelEvent): void => {
    if (!(this.calendarApi.view.type == "dayGrid" || this.calendarApi.view.type == "dayGridMonth")) return;

    const { activeStart, activeEnd } = this.calendarApi.view;
    const offset = 7;

    if (e.deltaY > 0) {
      this.calendarApi.changeView("dayGrid", {
        start: new Date(activeStart.setDate(activeStart.getDate() + offset)),
        end: new Date(activeEnd.setDate(activeEnd.getDate() + offset)),
      });
    } else {
      this.calendarApi.changeView("dayGrid", {
        start: new Date(activeStart.setDate(activeStart.getDate() - offset)),
        end: new Date(activeEnd.setDate(activeEnd.getDate() - offset)),
      });
    }
  };
}
