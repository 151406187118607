import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  DashboardData,
  DynamicFilterChoice,
  DynamicFilterPart,
  FilterPart,
  Part,
  PartState,
  ReportPart,
  SelectedMember,
  TARContainerOrientation,
  TARGraphSerieType,
  TARLegendPosition,
  TARMemberState,
  TARPageSetup,
  TARPresentationType,
  TilePart,
  TMouseButton,
  TPartState,
  UpdateDashboard,
  VisibleMember,
} from "../../common/communication.base";
import { NclDashboard } from "../../common/components.ncl";
import { WithContextPlacementProps, StyleHelper } from "../k2hoc";
import { Chart as DefaultChart, Bar, Line, Pie } from "react-chartjs-2";
import {
  ChartOptions,
  Chart as ChartJS,
  defaults,
  ScaleOptionsByType,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  Legend,
  LineElement,
  Tooltip,
  ArcElement,
  ChartArea,
} from "chart.js";
import K2Img from "../Image/K2Img";
import { Context } from "../../appcontext";
import { VCXContext } from "../../context";
import K2Action from "../Action/K2Action";
import css from "./Dashboard.scss";
import { useServerState } from "../hooks";

ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, ArcElement, Legend, Tooltip);
defaults.color = "black";

const DashboardContext = createContext<{
  control: NclDashboard;
  dataVersion: number;
  partClick: (
    part: Part,
    members: SelectedMember[],
    shiftKey: boolean,
    ctrlKey: boolean,
    datasetIndex: number,
    index: number,
    mouse: TMouseButton
  ) => Promise<void>;
}>(null);

const K2Dashboard = (props: WithContextPlacementProps): JSX.Element => {
  const [control, data, element] = useServerState<NclDashboard, UpdateDashboard, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclDashboard
  );
  const context = useContext(VCXContext);

  useEffect(() => {
    defaults.color = "black";
    defaults.font.family = context.vcx.DashboardControl.GraphFont.FontName;
    defaults.font.size = (context.vcx.DashboardControl.GraphFont.FontSize / 3) * 4;

    if (context.vcx.DashboardControl.GraphFont.IsItalic) {
      defaults.font.style = "italic";
    }

    if (context.vcx.DashboardControl.GraphFont.IsBold) {
      defaults.font.weight = "bold";
    }
  }, []);

  const getAlignment = (aligment: number, align: string) => {
    let className: string;

    switch (aligment) {
      case 0:
        className = css[`db_containers_${align}_start`];
        break;
      case 1:
        className = css[`db_containers_${align}_center`];
        break;
      case 2:
        className = css[`db_containers_${align}_end`];
        break;
      default:
        className = css[`db_containers_${align}_normal`];
        break;
    }

    return className;
  };

  const handlePartClick = async (
    part: Part,
    members: SelectedMember[],
    shiftKey: boolean,
    ctrlKey: boolean,
    datasetIndex: number,
    index: number,
    mouse: TMouseButton
  ) => {
    if (part) {
      if (!control.isPartsUpdateCompleted()) return;

      const ids = members?.map((member) => (member ? member.Identifier : null));
      control.partClick(part.PartIdentifier, ids ? ids : [], shiftKey, ctrlKey, datasetIndex, index, mouse);
    }
  };

  const handleIsPartChanged = (id: string): boolean => {
    if (control) {
      return control.isChangedParts(id);
    }

    return false;
  };

  const handleGetPartState = (id: string): PartState => {
    if (control) {
      return control.getPartState(id);
    }

    return undefined;
  };

  const toggleMember = (partId: string, memberId: string) => {
    control.toggleMember(partId, memberId);
  };

  const dashboardData = control.DashboardData;

  if (!dashboardData || Object.keys(dashboardData).length === 0) {
    if (data?.Error)
      return (
        <div ref={element} style={StyleHelper(control, props.style)} className={css.db}>
          {control.Header?.LQuickButton && (
            <K2Action
              controlUID={control.Header?.LQuickButton.MetaData.ControlUID}
              vrUID={control.getRealizerUID()}
              style={{ flex: "none", width: "auto", paddingRight: control.VCX.cssSizeMap(4, "px") }}
            />
          )}
          <div className={css.db_no_data}>{data.Error}</div>
        </div>
      );

    return null;
  }

  let container: JSX.Element;

  if (dashboardData.PageSetup === TARPageSetup.psFiltersAboveContainersBelow) {
    container = (
      <FiltersAboveContainersBelow data={dashboardData} isChangedParts={handleIsPartChanged} getPartState={handleGetPartState} toggleMember={toggleMember} />
    );
  } else {
    container = (
      <ContainersHorizontallyOrVertically
        data={dashboardData}
        isChangedParts={handleIsPartChanged}
        getPartState={handleGetPartState}
        toggleMember={toggleMember}
      />
    );
  }

  let menuPartsCount = 0;

  dashboardData.Containers.map((container) => {
    const isMenu = container.Parts.some((part) => "Description" in part);
    if (isMenu) {
      if (container.Parts.length > menuPartsCount) {
        menuPartsCount = container.Parts.length;
      }
    }
  });

  const getClassName = () => {
    let className = css.db_body;

    if (dashboardData.PageSetup === TARPageSetup.psContainersHorizontally) {
      className += ` ${css.db_containers_horizontal}`;
    } else {
      className += ` ${css.db_containers_vertical}`;
    }

    className += ` ${getAlignment(dashboardData.HorizontalAlignment, "justify")} ${getAlignment(dashboardData.VerticalAlignment, "items")}`;

    return className;
  };

  return (
    <DashboardContext.Provider value={{ control: control, dataVersion: (data as UpdateDashboard)?.DataVersion, partClick: handlePartClick }}>
      <div ref={element} style={StyleHelper(control, props.style)} className={css.db}>
        <div className={css.db_header}>
          {control.Header?.LQuickButton && (
            <K2Action
              controlUID={control.Header?.LQuickButton.MetaData.ControlUID}
              vrUID={control.getRealizerUID()}
              style={{ flex: "none", width: "auto", paddingRight: control.VCX.cssSizeMap(4, "px") }}
            />
          )}
          <p className={css.db_title}>{dashboardData.Title}</p>
        </div>
        <div className={getClassName()} style={{ minWidth: menuPartsCount > 0 ? `${menuPartsCount * 340}px` : null }}>
          {container}
        </div>
      </div>
    </DashboardContext.Provider>
  );
};

export default K2Dashboard;

interface PartBaseProps {
  isChangedParts: (id: string) => boolean;
  getPartState: (id: string) => PartState;
  toggleMember?: (partId: string, memberId: string) => void;
}

interface ContainerProps extends PartBaseProps {
  data: DashboardData;
}

const FiltersAboveContainersBelow = (props: ContainerProps) => {
  return (
    <>
      <div style={{ flex: `0 1 ${props.data.Containers[0].ContainerWidth}%` }} className={`${css.db_layout} ${css.db_containers_horizontal}`}>
        {props.data.Containers[0].Parts.map((part) => {
          return (
            <K2Part
              key={part.PartIdentifier}
              part={part}
              getPartState={props.getPartState}
              isChangedParts={props.isChangedParts}
              toggleMember={props.toggleMember}
            />
          );
        })}
      </div>
      <div className={css.db_wrap}>
        {props.data.Containers.map((container, index) => {
          if (index === 0) return null;

          return (
            <div
              key={index}
              style={{ minHeight: container.Orientation === TARContainerOrientation.coVertical ? "auto" : null, flex: `0 1 ${container.ContainerWidth}%` }}
              className={`${css.db_layout}${
                container.Orientation === TARContainerOrientation.coHorizontal ? ` ${css.db_containers_horizontal}` : ` ${css.db_containers_vertical}`
              }`}
            >
              {container.Parts.map((part) => {
                return (
                  <K2Part
                    key={part.PartIdentifier}
                    part={part}
                    getPartState={props.getPartState}
                    isChangedParts={props.isChangedParts}
                    toggleMember={props.toggleMember}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </>
  );
};

const ContainersHorizontallyOrVertically = (props: ContainerProps) => {
  return (
    <>
      {props.data.Containers.map((container, index) => {
        return (
          <div
            key={index}
            style={{
              flex: `0 1 ${container.Parts.length > 0 && "Description" in container.Parts[0] ? "auto" : `${container.ContainerWidth}%`}`,
              minHeight: container.Orientation === TARContainerOrientation.coVertical ? "auto" : null,
            }}
            className={`${css.db_layout}${
              container.Orientation === TARContainerOrientation.coHorizontal ? ` ${css.db_containers_horizontal}` : ` ${css.db_containers_vertical}`
            }`}
          >
            {container.Parts.map((part) => {
              return (
                <K2Part
                  key={part.PartIdentifier}
                  part={part}
                  getPartState={props.getPartState}
                  isChangedParts={props.isChangedParts}
                  toggleMember={props.toggleMember}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};

interface PartProps<T extends Part> extends PartBaseProps {
  part: T;
}

const K2Part = (props: PartProps<Part>) => {
  const context = useContext(DashboardContext);
  const vcx = useContext(VCXContext);
  const firstSelectedMember = useRef<VisibleMember>();
  const [zoomed, setZoomed] = useState(false);

  const handleZoom = () => {
    setZoomed(!zoomed);
  };

  const setFirstSelectedMember = (member: VisibleMember) => {
    firstSelectedMember.current = member;
  };

  if ("Description" in props.part) {
    const menuPart = props.part as TilePart;

    return (
      <div
        onClick={(e) => context.partClick(props.part, null, e.shiftKey, e.ctrlKey, null, null, null)}
        style={{ backgroundColor: "#" + menuPart.BackgroundColor }}
        className={css.db_tile}
      >
        <div className={css.db_tile_top}>
          <K2Img glyphId={menuPart.IconPath ? `wui*ARTile_${menuPart.IconPath}` : "ui*element.k2olap"} vcx={vcx.vcx} height={50} width={50} />
          <p className={css.dg_tile_title}>{menuPart.TileTitle}</p>
        </div>
        <div style={{ backgroundColor: menuPart.KPIText === "" ? null : "rgb(0 0 0 / 10%)" }} className={css.db_tile_bottom}>
          <p className={css.dg_tile_kpi}>{menuPart.KPIText}</p>
          {menuPart.KPIStateIcon && (
            <K2Img
              glyphId={menuPart.KPIStateIcon.includes("false") ? `${menuPart.KPIStateIcon}_invert` : `${menuPart.KPIStateIcon}`}
              vcx={vcx.vcx}
              height={30}
              width={30}
            />
          )}
        </div>
      </div>
    );
  } else if ("VisibleMembers" in props.part || "DynamicFilterChoices" in props.part) {
    let content: JSX.Element;
    let part: FilterPart | DynamicFilterPart;
    let partType: string;

    if ("DynamicFilterChoices" in props.part) {
      part = props.part as DynamicFilterPart;
      partType = part.DynamicFilterPartType;

      switch (part.DynamicFilterPartType) {
        case "SingleSelectSlicerDynamicFilter":
          content = (
            <MultiSelectSlicerFilter
              key={part.PartIdentifier}
              filterPart={part}
              setFirstSelectedMember={setFirstSelectedMember}
              firstSelectedMember={firstSelectedMember.current}
              isDynamicPart={true}
            />
          );
          break;
        case "SingleSelectComboDynamicFilter":
          content = <Select filterPart={part} toggleMember={props.toggleMember} isDynamicPart={true} />;
          break;
      }
    } else {
      part = props.part as FilterPart;
      partType = part.FilterPartType;

      switch (part.FilterPartType) {
        case "MultiSelectSlicerFilter":
          content = (
            <MultiSelectSlicerFilter
              key={part.PartIdentifier + Date.now()} // vynucuje re-render kvuli klientske akci (kdyz drzim CTRL)
              filterPart={part}
              setFirstSelectedMember={setFirstSelectedMember}
              firstSelectedMember={firstSelectedMember.current}
              isDynamicPart={false}
            />
          );
          break;
        case "MultiSelectTreeFilter":
        case "MultiSelectComboFilter":
        case "SingleSelectComboFilter":
          content = <Select filterPart={part} toggleMember={props.toggleMember} isDynamicPart={false} />;
          break;
        default:
          break;
      }
    }

    return (
      <div
        style={{ flex: `0 1 ${part.PartWidth}%` }}
        className={`${css.db_layout} ${css.db_part}${
          partType === "MultiSelectSlicerFilter" || partType === "SingleSelectSlicerDynamicFilter"
            ? ` ${css.db_containers_vertical}`
            : ` ${css.db_containers_horizontal}`
        }`}
      >
        <div className={css.db_filter_title}>
          {partType === "SingleSelectSlicerDynamicFilter" ? (part as DynamicFilterPart).DynamicFilterTitle : (part as FilterPart).PartTitle}
        </div>
        <div className={css.db_filters}>{content}</div>
      </div>
    );
  } else {
    const chart = (
      <Chart part={props.part as ReportPart} isChangedParts={props.isChangedParts} getPartState={props.getPartState} handleZoom={handleZoom} zoomed={zoomed} />
    );
    return (
      <>
        {chart}
        {zoomed && ReactDOM.createPortal(<div className={css.db_backdrop}>{chart}</div>, document.body)}
      </>
    );
  }
};

interface K2ChartProps extends PartProps<ReportPart> {
  zoomed: boolean;
  handleZoom: () => void;
}

const Chart = (props: K2ChartProps) => {
  const context = useContext(DashboardContext);
  const vcx = useContext(VCXContext);
  const menu = useRef<HTMLDivElement>();
  let menuContent: { name: string; type: TARPresentationType }[];
  let content: JSX.Element;
  const [openMenu, setOpenMenu] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [chartType, setChartType] = useState(props.part.DefaultPresentation);

  useEffect(() => {
    if (menu.current && window.innerWidth < menu.current.offsetLeft + menu.current.offsetWidth) {
      setX(window.innerWidth - menu.current.offsetWidth);
    }
  }, [openMenu]);

  const getLegendPosition = () => {
    const { glpBottom, glpLeft, glpRight, glpTop } = TARLegendPosition;
    let legendPosition: "bottom" | "center" | "left" | "right" | "top" | "chartArea";

    switch (props.part.LegendPosition) {
      case glpRight:
        legendPosition = "right";
        break;
      case glpLeft:
        legendPosition = "left";
        break;
      case glpBottom:
        legendPosition = "bottom";
        break;
      case glpTop:
        legendPosition = "top";
        break;
      default:
        legendPosition = null;
        break;
    }

    return legendPosition;
  };

  menuContent = [
    { name: "Výchozí nastavení zobrazení", type: props.part.DefaultPresentation },
    { name: "Graf spojnicový", type: TARPresentationType.ptLineChart },
    { name: "Graf sloupcový", type: TARPresentationType.ptBarChart },
    { name: "Graf výsečový", type: TARPresentationType.ptPieChart },
    { name: "Graf skládaný sloupcový", type: TARPresentationType.ptStackedChart },
    { name: "Graf plošný", type: TARPresentationType.ptAreaChart },
    { name: "Tabulka", type: TARPresentationType.ptGrid },
  ];

  const options: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: getLegendPosition(),
        title: {
          display: true,
          text: props.part.LegendTitle,
        },
      },
      tooltip: {
        backgroundColor: "white",
        borderColor: "#ccc",
        borderWidth: 1,
        cornerRadius: 0,
        bodyColor: "black",
        titleColor: "black",
      },
    },
    interaction: {
      intersect: true,
      mode: "nearest",
    },
    scales: {
      y: {
        grid: {
          borderDash: [10, 5],
        },
      },
      x: {
        ticks: {
          maxRotation: 0,
        },
      },
    },
    onClick: (e: unknown, data: { datasetIndex: number; index: number }[]) => {
      if (data.length > 0) {
        context.partClick(props.part, [], false, false, data[0].datasetIndex, data[0].index, TMouseButton.mbLeft);
      }
    },
  };

  const handleClickZoom = () => {
    props.handleZoom();
  };

  const handleOpenMenu = (e: React.MouseEvent) => {
    setOpenMenu(true);
    setX(e.pageX);
    setY(e.pageY);
  };

  const getChartType = (state: PartState, type: TARPresentationType) => {
    if (state) {
      switch (state.State) {
        case TPartState.psShowPart:
          switch (type) {
            case TARPresentationType.ptChartBySeries:
              return <MixedChart part={props.part} options={options} />;
            case TARPresentationType.ptGrid:
              return <Grid part={props.part} />;
            case TARPresentationType.ptBarChart:
              return <BarChart part={props.part} options={options} />;
            case TARPresentationType.ptLineChart:
              return <LineChart part={props.part} options={options} isAreaChart={false} />;
            case TARPresentationType.ptStackedChart:
              return <BarChart part={props.part} options={options} />;
            case TARPresentationType.ptPieChart:
              return <PieChart part={props.part} options={options} />;
            case TARPresentationType.ptAreaChart:
              return <LineChart part={props.part} options={options} isAreaChart={true} />;
            default:
              break;
          }
          break;
        case TPartState.psShowProgress:
          return <p>{"Načítám"}</p>;
        default:
          // return <p>{`Stav: ${state} `}</p>;
          break;
      }
    }
    return <p>{`Stav: ${state} ${type}`}</p>;
  };

  let state: PartState;

  if (props.getPartState && props.part) {
    state = props.getPartState(props.part.PartIdentifier);
  }

  content = useMemo(() => {
    return getChartType(state, chartType);
  }, [context.dataVersion, chartType]);

  return (
    <div
      style={{ flex: props.zoomed ? "1 1 auto" : `0 1 ${props.part.PartWidth}%` }}
      className={`${css.db_layout} ${css.db_part} ${css.db_layout_chart} ${css.db_containers_vertical}`}
    >
      <div className={css.db_chart_header}>
        <p className={css.db_chart_header_title}>{props.part.PartTitle}</p>
        <div className={css.db_chart_header_controls}>
          <div onClick={handleClickZoom} className="button">
            <K2Img
              glyphId={props.zoomed ? "wui*zoom.out" : "wui*zoom.in"}
              vcx={vcx.vcx}
              width={20}
              height={20}
              strokeColor={vcx.vcx.getColor(vcx.vcx.Data.ColorMap.BaseColorBck1)}
            />
          </div>
          <div onClick={handleOpenMenu} className="button">
            <K2Img glyphId={"wui*options"} vcx={vcx.vcx} width={20} height={20} strokeColor={vcx.vcx.getColor(vcx.vcx.Data.ColorMap.BaseColorBck1)} />
          </div>
        </div>
      </div>
      {props.part.Series?.length > 0 ? <div className={css.db_chart}>{content}</div> : <div className={css.db_no_data}>Žádná data k zobrazení</div>}
      {openMenu &&
        ReactDOM.createPortal(
          <div className={css.db_backdrop + " " + css.db_menu_backdrop} onClick={() => setOpenMenu(false)}>
            <div ref={menu} style={{ top: y, left: x }} className={css.db_menu}>
              {menuContent.map((menuItem) => (
                <button key={menuItem.name} className={css.db_menu_button} onClick={() => setChartType(menuItem.type)}>
                  {menuItem.name}
                </button>
              ))}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

interface ChartProps {
  part: ReportPart;
  options: ChartOptions;
}

const PieChart = (props: ChartProps) => {
  const ref = useRef<ChartJS<"pie">>();

  useEffect(() => {
    return () => ref.current.destroy();
  }, []);

  return (
    <Pie
      type={Pie}
      ref={ref}
      options={{
        ...(props.options as ChartOptions<"pie">),
        scales: {
          y: {
            display: false,
          },
        },
      }}
      data={{
        labels: props.part.Series[0].DataPoints.map((point) => point.Independent),
        datasets: props.part.Series?.map((serie) => {
          return {
            label: serie.Title,
            data: serie.DataPoints.map((point) => point.Dependent),
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
          };
        }),
      }}
    />
  );
};

const BarChart = (props: ChartProps) => {
  const ref = useRef<ChartJS<"bar">>();

  useEffect(() => {
    return () => ref.current.destroy();
  }, []);

  return (
    <Bar
      type={Bar}
      ref={ref}
      options={{
        ...(props.options as ChartOptions<"bar">),
        scales: {
          x: {
            ...(props.options.scales.x as ScaleOptionsByType<"linear">),
            stacked: props.part.DefaultPresentation === TARPresentationType.ptStackedChart ? true : false,
            grid: {
              display: false,
            },
          },
          y: {
            ...(props.options.scales.y as ScaleOptionsByType<"linear">),
            stacked: props.part.DefaultPresentation === TARPresentationType.ptStackedChart ? true : false,
          },
        },
      }}
      data={{
        labels: props.part.Series[0].DataPoints.map((point) => point.Independent),
        datasets: props.part.Series?.map((serie) => {
          return {
            label: serie.Title,
            data: serie.DataPoints.map((point) => point.Dependent),
            backgroundColor: "#" + serie.Color,
          };
        }),
      }}
    />
  );
};

const LineChart = (props: ChartProps & { isAreaChart: boolean }) => {
  const ref = useRef<ChartJS<"line">>();
  let timeout: number;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    timeout = window.setTimeout(() => {
      setLoaded(true);
    }, 0);

    return () => {
      window.clearInterval(timeout);
      ref.current.destroy();
    };
  }, []);

  const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea, color: string) => {
    if (!ctx) return;

    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

    gradient.addColorStop(0, "white");
    gradient.addColorStop(1, color);

    return gradient;
  };

  return (
    <Line
      type={Line}
      ref={ref}
      options={{
        ...(props.options as ChartOptions<"line">),
        scales: {
          ...props.options.scales,
          x: {
            ...(props.options.scales.x as ScaleOptionsByType<"linear">),
            grid: {
              display: false,
            },
          },
        },
      }}
      data={{
        labels: props.part.Series[0].DataPoints.map((point) => point.Independent),
        datasets: props.part.Series?.map((serie) => {
          return {
            label: serie.Title,
            data: serie.DataPoints.map((point) => point.Dependent),
            backgroundColor: props.isAreaChart ? createGradient(ref.current?.ctx, ref.current?.chartArea, "#" + serie.Color + "80") : "#" + serie.Color,
            borderColor: "#" + serie.Color,
            fill: props.isAreaChart ? "start" : null,
            // order: this.props.part.Series.length - index,
          };
        }),
      }}
    />
  );
};

const MixedChart = (props: ChartProps) => {
  const ref = useRef<ChartJS>();

  useEffect(() => {
    return () => ref.current.destroy();
  }, []);

  return (
    <Bar
      type={Bar}
      ref={ref}
      options={{
        ...props.options,
        scales: {
          x: {
            ...props.options.scales.x,
            grid: {
              display: false,
            },
          },
          y: { ...props.options.scales.y },
        },
      }}
      data={{
        labels: props.part.Series[0].DataPoints.map((point) => point.Independent),
        datasets: props.part.Series?.map((serie) => {
          return {
            label: serie.Title,
            data: serie.DataPoints.map((point) => point.Dependent),
            backgroundColor: "#" + serie.Color,
            borderColor: "#" + serie.Color,
            type: serie.GraphSerieType === TARGraphSerieType.gstLineSerie ? "line" : null,
            order: serie.GraphSerieType === TARGraphSerieType.gstLineSerie ? -1 : 0,
          };
        }),
      }}
    />
  );
};

const Grid = (props: Partial<ChartProps>) => {
  const context = useContext(VCXContext);
  const dashboardContext = useContext(DashboardContext);
  const titles = props.part.Series[0].DataPoints.map((point) => point.Independent);

  const handleClick = (datasetIndex: number, pointIndex: number) => {
    dashboardContext.partClick(props.part, [], false, false, datasetIndex, pointIndex, TMouseButton.mbLeft);
  };

  return (
    <div className={css.dg_grid}>
      <table className={css.db_table}>
        <thead>
          <tr>
            <th className={css.db_table_header}></th>
            {titles.map((title, index) => (
              <th key={index} className={css.db_table_header}>
                {title}
              </th>
            ))}
            {props.part.SeriesRowSum.length > 0 && <th className={css.db_table_header}>Celkem</th>}
          </tr>
        </thead>
        <tbody>
          {props.part.Series.map((serie, index) => (
            <tr key={index} className={css.db_table_row}>
              <td className={css.db_table_cell}>{serie.Title}</td>
              {serie.DataPoints.map((point, pointIndex) => (
                <td
                  key={pointIndex}
                  style={{
                    color: point.FORE_COLOR > -1 ? context.vcx.getColor(point.FORE_COLOR) : null,
                    backgroundColor: point.BACK_COLOR > -1 ? context.vcx.getColor(point.BACK_COLOR) : null,
                  }}
                  className={css.db_table_cell}
                  onClick={() => handleClick(index, pointIndex)}
                >
                  {point.FomattedValue === "" ? point.Dependent : point.FomattedValue}
                </td>
              ))}
              {props.part.SeriesRowSum.length > 0 && (
                <td className={css.db_table_cell}>{props.part.SeriesRowSum[index]?.FomattedValue ? props.part.SeriesRowSum[index]?.FomattedValue : ""}</td>
              )}
            </tr>
          ))}
          {props.part.SeriesColumnSum.length > 0 && (
            <tr className={css.db_table_row}>
              <td>Celkem</td>
              {props.part.SeriesColumnSum.map((colSum, index) => (
                <td key={index} className={css.db_table_cell}>
                  {colSum.FomattedValue}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

interface MultiSelectSlicerFilterProps extends Omit<FilterProps, "filterPart"> {
  filterPart: FilterPart | DynamicFilterPart;
  isDynamicPart: boolean;
}

const MultiSelectSlicerFilter = (props: MultiSelectSlicerFilterProps) => {
  const context = useContext(DashboardContext);
  let selectedMembers: VisibleMember[] = [];

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key !== "Control") return;

    context.partClick(props.filterPart, selectedMembers, e.shiftKey, e.ctrlKey, null, null, null);
  };

  useEffect(() => {
    if (!props.isDynamicPart) {
      selectedMembers = (props.filterPart as FilterPart).VisibleMembers.filter((val) => val.Selected === true);
    }
  }, []);

  const handleClick = (e: React.MouseEvent, member: VisibleMember | DynamicFilterChoice) => {
    if (!props.isDynamicPart) {
      const part = props.filterPart as FilterPart;
      const visibleMember = member as VisibleMember;

      if (!(context.control as NclDashboard).isPartsUpdateCompleted()) return;

      if (!e.ctrlKey && !e.shiftKey) {
        if (Context.DeviceInfo.IsTouchDevice) {
          if (visibleMember.Selected) {
            selectedMembers = selectedMembers.filter((selectedMember) => selectedMember.Identifier !== visibleMember.Identifier);
          } else {
            selectedMembers.push(visibleMember);
          }
        } else {
          selectedMembers = [];
          selectedMembers.push(visibleMember);
          props.setFirstSelectedMember(visibleMember);
        }
      } else if (e.shiftKey) {
        if (!props.firstSelectedMember) return;

        selectedMembers = [];
        let first = part.VisibleMembers.findIndex((val) => val.Identifier === props.firstSelectedMember.Identifier);
        let last = part.VisibleMembers.findIndex((val) => val.Identifier === visibleMember.Identifier);

        if (first > last) {
          const temp = first;
          first = last;
          last = temp;
        }

        selectedMembers = part.VisibleMembers.filter((val, i) => {
          if (i >= first && i <= last) {
            return val;
          }
        });
      } else if (e.ctrlKey) {
        if (selectedMembers.includes(visibleMember)) {
          selectedMembers = selectedMembers.filter((member) => member.Identifier !== visibleMember.Identifier);
        } else {
          selectedMembers.push(visibleMember);
        }
      }

      if (e.ctrlKey) return;

      context.partClick(props.filterPart, selectedMembers, e.shiftKey, e.ctrlKey, null, null, null);
    } else {
      const visibleMember = member as DynamicFilterChoice;
      (context.control as NclDashboard).dynamicFilterPartClick(props.filterPart.PartIdentifier, visibleMember.DynamicFilterChoiceItemID);
    }
  };

  if (!props.isDynamicPart) {
    const part = props.filterPart as FilterPart;

    return (
      <>
        {part.VisibleMembers?.map((member) => {
          return (
            <MultiSelectSlicerFilterButton
              key={member.Identifier}
              handleKeyUp={handleKeyUp}
              handleClick={handleClick}
              member={member}
              type={part.FilterPartType}
            />
          );
        })}
      </>
    );
  } else {
    const part = props.filterPart as DynamicFilterPart;

    return (
      <>
        {part.DynamicFilterChoices?.map((member) => {
          return (
            <MultiSelectSlicerFilterButton
              key={member.DynamicFilterChoiceItemDescription}
              handleKeyUp={handleKeyUp}
              handleClick={handleClick}
              member={member}
              type={part.DynamicFilterPartType}
              selected={part.DynamicFilterSelectedChoiceIndex}
            />
          );
        })}
      </>
    );
  }
};

interface MultiSelectSlicerFilterButtonProps {
  member: VisibleMember | DynamicFilterChoice;
  type: string;
  selected?: number;
  handleKeyUp: (e: React.KeyboardEvent) => void;
  handleClick: (e: React.MouseEvent, member: VisibleMember | DynamicFilterChoice) => void;
}

const MultiSelectSlicerFilterButton = (props: MultiSelectSlicerFilterButtonProps) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected((props.member as VisibleMember).Selected);
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    if (props.type === "MultiSelectSlicerFilter") {
      if (!e.ctrlKey) {
        props.handleClick(e, props.member as VisibleMember);
        return;
      }

      if (selected) {
        setSelected(false);
      } else {
        setSelected(true);
      }

      props.handleClick(e, props.member as VisibleMember);
    } else {
      props.handleClick(e, props.member as DynamicFilterChoice);
    }
  };

  if (props.type === "MultiSelectSlicerFilter") {
    const member = props.member as VisibleMember;

    return (
      <button
        key={member.Identifier}
        className={`${css.db_filter_button}${selected ? ` ${css.db_filter_button_selected}` : ""}`}
        onClick={handleClick}
        onKeyUp={(e) => props.handleKeyUp(e)}
      >
        {member.Caption}
      </button>
    );
  } else {
    const member = props.member as DynamicFilterChoice;

    return (
      <button
        key={member.DynamicFilterChoiceItemDescription}
        className={`${css.db_filter_button}${props.selected === member.DynamicFilterChoiceItemID ? ` ${css.db_filter_button_selected}` : ""}`}
        onClick={handleClick}
      >
        {member.DynamicFilterChoiceItemDescription}
      </button>
    );
  }
};

interface FlatMember {
  memberId: string;
  memberState: TARMemberState;
  parentId: string;
  caption: string;
}

const MultiSelectTreeFilter = (props: FilterProps) => {
  const context = useContext(DashboardContext);
  const prevDataVersion = useRef<number>(null);
  const [showPage, setShowPage] = useState(1);
  const [updatedMembers, setUpdatedMembers] = useState<FlatMember[]>([]);

  const actualFlattenTree = (treeMembers: VisibleMember[], flatMembers: FlatMember[]) => {
    treeMembers.map((member) => {
      flatMembers.push({ memberId: member.Identifier, memberState: member.MemberState, parentId: member.Parent?.Identifier, caption: member.Caption });

      if (member.Children) {
        actualFlattenTree(member.Children, flatMembers);
      }
    });
  };

  /**
   * Converts nested array of objects to flat array.
   * @param treeMembers Array of nested objects.
   * @returns New flat array.
   */
  const flattenTree = (treeMembers: VisibleMember[]) => {
    const flatMembers: FlatMember[] = [];
    actualFlattenTree(treeMembers, flatMembers);

    return flatMembers;
  };

  /**
   * Updates current member state with data from server.
   * @param flatMembers Flatten members.
   * @returns New array of members with updated state.
   */
  const synchronizeTreeState = (flatMembers: FlatMember[], updatedMembers: FlatMember[]) => {
    const flatMembersCopy: FlatMember[] = flatMembers.map((flatMember) => ({ ...flatMember }));

    updatedMembers.map((member) => {
      flatMembersCopy.find((flatMember) => {
        if (flatMember.memberId === member.memberId) {
          flatMember.memberState = member.memberState;

          return true;
        }
      });
    });

    return flatMembersCopy;
  };

  useEffect(() => {
    if (context.dataVersion !== prevDataVersion.current) {
      const flatMembers = flattenTree(props.filterPart.VisibleMembers);
      const synchronizedMembers = synchronizeTreeState(flatMembers, updatedMembers);
      setUpdatedMembers(synchronizedMembers);
      prevDataVersion.current = context.dataVersion;
      props.setTreeFilterMembers(synchronizedMembers);
    }
  }, [context.dataVersion]);

  useEffect(() => {
    if (props.checkAll === MultiSelectComboOperation.UncheckAll) {
      const members = updatedMembers.map((member) => ({ ...member }));
      members.map((member) => (member.memberState = 0));
      setUpdatedMembers(members);
      props.setSelectedMembers(MultiSelectComboOperation.Default, null);
      props.setTreeFilterMembers(members);
    }
  }, [props.checkAll]);

  const updateMembersInputState = (currentMember: VisibleMember, memberState: number) => {
    const members = updatedMembers.map((member) => ({ ...member }));
    const member = members.find((member) => member.memberId === currentMember.Identifier);

    if (memberState === TARMemberState.msClear) {
      member.memberState = TARMemberState.msChecked;
    } else if (memberState === TARMemberState.msChecked) {
      if (currentMember.Children) {
        const children = members.filter((newMember) => newMember.parentId === member.memberId);
        const some = children.some((child) => child.memberState === TARMemberState.msChecked || child.memberState === TARMemberState.msGray);

        if (some) {
          member.memberState = TARMemberState.msGray;
        } else {
          member.memberState = TARMemberState.msClear;
        }
      } else {
        member.memberState = TARMemberState.msClear;
      }
    } else {
      member.memberState = TARMemberState.msChecked;
    }

    const parent = members.find((item) => item.memberId === member.parentId);

    if (parent) {
      updateParentsInputState(parent, members);
    }

    props.setTreeFilterMembers(members);
    setUpdatedMembers(members);
  };

  const updateParentsInputState = (parent: FlatMember, members: FlatMember[]) => {
    const children = members.filter((member) => member.parentId === parent.memberId);
    const some = children.some((child) => child.memberState === TARMemberState.msChecked || child.memberState === TARMemberState.msGray);

    if (some) {
      members.find((member) => {
        if (member.memberId === parent.memberId) {
          if (member.memberState === TARMemberState.msChecked) {
            member.memberState = TARMemberState.msChecked;
          } else {
            member.memberState = TARMemberState.msGray;
          }

          return true;
        }
      });
    } else {
      members.find((member) => {
        if (member.memberId === parent.memberId) {
          if (parent.memberState !== TARMemberState.msChecked) {
            member.memberState = TARMemberState.msClear;
          }

          return true;
        }
      });
    }

    if (parent.parentId) {
      const newParent = members.find((member) => parent.parentId === member.memberId);
      updateParentsInputState(newParent, members);
    }
  };

  const renderRow = (member: VisibleMember, depth: number): JSX.Element => {
    let children = null;

    if (member.Children?.length > 0) {
      children = member.Children.map((member: any) => {
        const newDepth = depth + 1;

        return renderRow(member, newDepth);
      });
    }

    const flatMember = updatedMembers.find((flatMember) => flatMember.memberId === member.Identifier);

    if (!flatMember) return null;

    return (
      <MultiSelectTreeFilterMember
        key={member.Identifier}
        member={member}
        depth={depth}
        partId={props.filterPart.PartIdentifier}
        expandedMembers={props.expandedMembers}
        toggleMember={props.toggleMember}
        setExpandedMembers={props.setExpandedMembers}
        part={props.filterPart}
        updateMembers={updateMembersInputState}
        memberState={flatMember?.memberState}
      >
        <>{children}</>
      </MultiSelectTreeFilterMember>
    );
  };

  if (updatedMembers.length === 0) return null;

  return (
    <>
      {/* {false && (
        <>
          <div style={{ flex: "0 0 auto" }}>
            <form>
              <input></input>
              <button>Hledej</button>
            </form>
          </div>
          <div style={{ flex: "0 0 auto" }}>
            <div style={{ background: showPage === 1 ? "white" : null }} onClick={() => setShowPage(1)}>
              Všechny prvky
            </div>
            <div style={{ background: showPage === 2 ? "white" : null }} onClick={() => setShowPage(2)}>
              Nalezené prvky
            </div>
          </div>
        </>
      )} */}
      {showPage === 1 && (
        <div className={css.dg_tree_page}>
          <table>
            <tbody>{props.filterPart.VisibleMembers.map((member) => renderRow(member, 0))}</tbody>
          </table>
        </div>
      )}
      {showPage === 2 && (
        <div className={css.dg_tree_page}>
          <table>
            <thead>
              <tr>
                <th>Jméno</th>
                <th>Cesta</th>
              </tr>
            </thead>
          </table>
        </div>
      )}
    </>
  );
};

interface MultiSelectTreeFilterMemberProps {
  member: VisibleMember;
  depth: number;
  children: JSX.Element;
  partId: string;
  expandedMembers: VisibleMember[];
  part: FilterPart;
  memberState: TARMemberState;
  toggleMember: (partId: string, memberId: string) => void;
  setExpandedMembers: (expanding: boolean, member: VisibleMember) => void;
  updateMembers: (members: VisibleMember, memberState: number) => void;
}

const MultiSelectTreeFilterMember = (props: MultiSelectTreeFilterMemberProps) => {
  const [expanded, setExpanded] = useState(false);
  const context = useContext(DashboardContext);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    (context.control as NclDashboard).ExpandedMembers.map((expandedMember) => {
      if (expandedMember.Identifier === props.member.Identifier) {
        setExpanded(true);
      }
    });

    if (props.memberState === TARMemberState.msClear) {
      inputRef.current.checked = false;
      inputRef.current.indeterminate = false;
    } else if (props.memberState === TARMemberState.msChecked) {
      inputRef.current.indeterminate = false;
      inputRef.current.checked = true;
    } else {
      inputRef.current.checked = false;
      inputRef.current.indeterminate = true;
    }
  }, [props.memberState]);

  const expandOrCollapse = (member: VisibleMember) => {
    if (expanded) {
      props.setExpandedMembers(expanded, member);
      setExpanded(false);
    } else {
      props.setExpandedMembers(expanded, member);
      if (!member.Children) {
        props.toggleMember(props.partId, member.Identifier);
      }
      setExpanded(true);
    }
  };

  const updateSelect = () => {
    props.updateMembers(props.member, props.memberState);
  };

  return (
    <>
      <tr>
        <td>
          <div className={css.dg_member}>
            <span style={{ paddingLeft: `${15 * props.depth}px` }}></span>
            <div className={css.dg_tree_member_toggle} onClick={() => expandOrCollapse(props.member)}>
              {props.member.Children?.length !== 0
                ? context.control.ExpandedMembers.find((expandedMember) => expandedMember.Identifier === props.member.Identifier)
                  ? "-"
                  : "+"
                : ""}
            </div>
            <div className={css.dg_member_checkbox}>
              <input type="checkbox" onChange={updateSelect} ref={inputRef}></input>
            </div>
            <p onDoubleClick={() => expandOrCollapse(props.member)}>{props.member.Caption}</p>
          </div>
        </td>
      </tr>
      {expanded && props.children}
    </>
  );
};

interface FilterProps {
  filterPart: FilterPart;
  checkAll?: MultiSelectComboOperation;
  firstSelectedMember?: VisibleMember;
  expandedMembers?: VisibleMember[];
  setSelectedMembers?: (operation: MultiSelectComboOperation, member: VisibleMember) => void;
  closeModal?: (e: React.MouseEvent) => void;
  setFirstSelectedMember?: (member: VisibleMember) => void;
  toggleMember?: (partId: string, memberId: string) => void;
  setExpandedMembers?: (expanding: boolean, member: VisibleMember) => void;
  setTreeFilterMembers?: (members: FlatMember[]) => void;
}

const MultiSelectComboFilter = (props: FilterProps) => {
  return (
    <table>
      <tbody>
        {props.filterPart.VisibleMembers.map((member) => {
          return <MultiSelectComboFilterRow key={member.Identifier} member={member} setSelectedMembers={props.setSelectedMembers} checkAll={props.checkAll} />;
        })}
      </tbody>
    </table>
  );
};

interface MultiSelectComboFilterRowProps {
  member: VisibleMember;
  checkAll: MultiSelectComboOperation;
  setSelectedMembers: (operation: MultiSelectComboOperation, member: VisibleMember) => void;
}

const MultiSelectComboFilterRow = (props: MultiSelectComboFilterRowProps) => {
  const [selected, setSelected] = useState(props.member.Selected); // jen pro nastaveni defaultni hodnoty, jinak se jedna o anti-pattern, viz https://reactjs.org/docs/react-component.html#constructor

  const handleClick = (member: VisibleMember, checked: boolean) => {
    setSelected(checked);

    if (!checked) {
      props.setSelectedMembers(MultiSelectComboOperation.Uncheck, member);
    } else {
      props.setSelectedMembers(MultiSelectComboOperation.Check, member);
    }
  };

  useEffect(() => {
    if (props.checkAll === MultiSelectComboOperation.CheckAll) {
      setSelected(true);
    } else if (props.checkAll === MultiSelectComboOperation.UncheckAll) {
      setSelected(false);
    }
  }, [props.checkAll]);

  return (
    <tr>
      <td>
        <div className={css.dg_member}>
          <span className={css.dg_member_checkbox}>
            <input id={props.member.Identifier} type="checkbox" onChange={(e) => handleClick(props.member, e.currentTarget.checked)} checked={selected}></input>
          </span>
          <label htmlFor={props.member.Identifier}>{props.member.Caption}</label>
        </div>
      </td>
    </tr>
  );
};

interface SingleSelectComboFilterProps extends Omit<FilterProps, "filterPart"> {
  filterPart: FilterPart | DynamicFilterPart;
  isDynamicPart: boolean;
}

const SingleSelectComboFilter = (props: SingleSelectComboFilterProps) => {
  let content: JSX.Element[];

  if (!props.isDynamicPart) {
    content = (props.filterPart as FilterPart).VisibleMembers.map((member) => {
      return (
        <SingleSelectComboFilterRow
          member={member}
          key={member.Identifier}
          closeModal={props.closeModal}
          setSelectedMembers={props.setSelectedMembers}
          isDynamicPart={false}
        />
      );
    });
  } else {
    content = (props.filterPart as DynamicFilterPart).DynamicFilterChoices.map((member) => {
      return (
        <SingleSelectComboFilterRow
          member={member}
          key={member.DynamicFilterChoiceItemDescription}
          closeModal={props.closeModal}
          setSelectedMembers={props.setSelectedMembers}
          isDynamicPart={true}
          selected={member.DynamicFilterChoiceItemID === (props.filterPart as DynamicFilterPart).DynamicFilterSelectedChoiceIndex}
        />
      );
    });
  }

  return <>{content}</>;
};

interface RowProps {
  isDynamicPart: boolean;
  member: VisibleMember | DynamicFilterChoice;
  selected?: boolean;
  closeModal: (e: React.MouseEvent) => void;
  setSelectedMembers: (operation: MultiSelectComboOperation, member: VisibleMember) => void;
}

const SingleSelectComboFilterRow = (props: RowProps) => {
  let selected: boolean;

  if (!props.isDynamicPart) {
    selected = (props.member as VisibleMember).Selected;
  } else {
    selected = props.selected;
  }

  const handleMemberClick = (e: React.MouseEvent, member: VisibleMember) => {
    props.setSelectedMembers(MultiSelectComboOperation.Check, member);
    props.closeModal(e);
  };

  return (
    <div
      className={`${css.db_single_select_combo}${selected ? ` ${css.db_single_select_combo_selected}` : ""}`}
      onClick={(e) => handleMemberClick(e, props.member as any)}
    >
      {props.isDynamicPart ? (props.member as DynamicFilterChoice).DynamicFilterChoiceItemDescription : (props.member as VisibleMember).Caption}
    </div>
  );
};

interface SelectProps {
  filterPart: FilterPart | DynamicFilterPart;
  isDynamicPart: boolean;
  toggleMember?: (partId: string, memberId: string) => void;
}

enum MultiSelectComboOperation {
  Check,
  Uncheck,
  CheckAll,
  UncheckAll,
  Default,
}

const Select = (props: SelectProps) => {
  const context = useContext(DashboardContext);
  const vcx = useContext(VCXContext);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0, width: 0 });
  const [showModal, setShowModal] = useState(false);
  const [checkAll, setCheckAll] = useState(MultiSelectComboOperation.Default);
  const [selectText, setSelectText] = useState("");
  const selectedMembers = useRef<VisibleMember[]>([]);
  const expandedMembers = useRef<VisibleMember[]>([]);
  const treeFilterMembers = useRef<{ memberId: string; state: number; caption: string }[]>([]);
  let partType: string;

  useEffect(() => {
    let text = "";

    if (!props.isDynamicPart) {
      (props.filterPart as FilterPart).VisibleMembers.map((member) => {
        if (member.Selected) {
          text += member.Caption + ", ";
        }
      });

      text = text.slice(0, -2);
    } else {
      text = (props.filterPart as DynamicFilterPart).DynamicFilterChoices.find(
        (item) => item.DynamicFilterChoiceItemID === (props.filterPart as DynamicFilterPart).DynamicFilterSelectedChoiceIndex
      ).DynamicFilterChoiceItemDescription;
    }

    setSelectText(text);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (partType !== "MultiSelectTreeFilter") {
      if (!props.isDynamicPart) {
        selectedMembers.current = (props.filterPart as FilterPart).VisibleMembers.filter((member) => {
          if (member.Selected) return member;
        });
      } else {
        selectedMembers.current = (props.filterPart as DynamicFilterPart).DynamicFilterChoices.filter((member) => {
          if (member.DynamicFilterChoiceItemID === (props.filterPart as DynamicFilterPart).DynamicFilterSelectedChoiceIndex) return member;
        }) as any;
      }
    }

    const rect = e.currentTarget.getBoundingClientRect();
    setModalPosition({ top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight, left: e.currentTarget.offsetLeft, width: rect.width });
    setShowModal(true);
  };

  const closeModal = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (partType === "MultiSelectTreeFilter") {
      (context.control as NclDashboard).updateSelectedMembersInTreeFilter(props.filterPart.PartIdentifier, treeFilterMembers.current);
    } else if (partType === "SingleSelectComboDynamicFilter") {
      const choices = selectedMembers.current as unknown[];

      (context.control as NclDashboard).dynamicFilterPartClick(
        props.filterPart.PartIdentifier,
        (choices as DynamicFilterChoice[])[0].DynamicFilterChoiceItemID
      );
    } else {
      context.partClick(props.filterPart, selectedMembers.current, false, false, null, null, null);
    }

    let text = "";

    if (partType === "MultiSelectTreeFilter") {
      treeFilterMembers.current.map((member) => {
        if (member.state === TARMemberState.msChecked) text += member.caption + ", ";
      });
    } else {
      if (!props.isDynamicPart) {
        selectedMembers.current.map((member) => {
          text += member.Caption + ", ";
        });
      } else {
        const choices = selectedMembers.current as unknown[];

        (choices as DynamicFilterChoice[]).map((item) => {
          text += item.DynamicFilterChoiceItemDescription + ", ";
        });
      }
    }

    text = text.slice(0, -2);

    setShowModal(false);
    setSelectText(text);
  };

  const setSelectedMembers = (operation: MultiSelectComboOperation, updatedMember: VisibleMember) => {
    switch (operation) {
      case MultiSelectComboOperation.Check:
        if (partType === "SingleSelectComboFilter" || partType === "SingleSelectComboDynamicFilter") {
          selectedMembers.current = [];
        }

        selectedMembers.current.push(updatedMember);
        break;
      case MultiSelectComboOperation.Uncheck:
        selectedMembers.current = selectedMembers.current.filter((member) => member.Identifier !== updatedMember.Identifier);
        break;
      case MultiSelectComboOperation.CheckAll:
        selectedMembers.current = (props.filterPart as FilterPart).VisibleMembers;
        setCheckAll(MultiSelectComboOperation.CheckAll);
        break;
      case MultiSelectComboOperation.UncheckAll:
        selectedMembers.current = [];
        setCheckAll(MultiSelectComboOperation.UncheckAll);
        break;
      default:
        setCheckAll(MultiSelectComboOperation.Default);
        break;
    }
  };

  const setExpandedMembers = (expanded: boolean, member: VisibleMember) => {
    if (expanded) {
      expandedMembers.current = expandedMembers.current.filter((expandedMember) => expandedMember.Identifier !== member.Identifier);
    } else {
      expandedMembers.current.push(member);
    }

    (context.control as NclDashboard).ExpandedMembers = expandedMembers.current;
  };

  const setTreeFilterMembers = (members: FlatMember[]) => {
    const checkedOrGray = members.map((member) => {
      if (member.memberState === TARMemberState.msChecked || member.memberState === TARMemberState.msGray) {
        return { memberId: member.memberId, state: member.memberState, caption: member.caption };
      }
    });

    treeFilterMembers.current = checkedOrGray.filter((member) => member != null);
  };

  let filter: JSX.Element;

  if (!props.isDynamicPart) {
    const part = props.filterPart as FilterPart;
    partType = part.FilterPartType;

    if (part.FilterPartType === "MultiSelectComboFilter") {
      filter = <MultiSelectComboFilter filterPart={part} setSelectedMembers={setSelectedMembers} checkAll={checkAll} />;
    } else if (part.FilterPartType === "SingleSelectComboFilter") {
      filter = <SingleSelectComboFilter filterPart={part} closeModal={closeModal} setSelectedMembers={setSelectedMembers} isDynamicPart={false} />;
    } else {
      filter = (
        <MultiSelectTreeFilter
          filterPart={part}
          expandedMembers={expandedMembers.current}
          toggleMember={props.toggleMember}
          setExpandedMembers={setExpandedMembers}
          setTreeFilterMembers={setTreeFilterMembers}
          checkAll={checkAll}
          setSelectedMembers={setSelectedMembers}
        />
      );
    }
  } else {
    const part = props.filterPart as DynamicFilterPart;
    partType = part.DynamicFilterPartType;

    filter = <SingleSelectComboFilter filterPart={part} closeModal={closeModal} setSelectedMembers={setSelectedMembers} isDynamicPart={true} />;
  }

  return (
    <div onClick={handleClick} className={css.db_select} tabIndex={1}>
      <p className={css.dg_select_text}>{selectText}</p>
      <K2Img
        glyphId={partType === "MultiSelectTreeFilter" ? "wui*filter" : "wui*lookupsimple"}
        vcx={vcx.vcx}
        width={20}
        height={20}
        strokeColor={vcx.vcx.getColor(vcx.vcx.Data.ColorMap.BaseColorBck1)}
        style={{ flex: "0 0 auto" }}
      />
      {showModal && (
        <Modal
          closeModal={closeModal}
          showCheckCheckbox={partType === "MultiSelectComboFilter" || partType === "MultiSelectTreeFilter"}
          showUncheckCheckbox={partType === "MultiSelectComboFilter"}
          coords={{ top: modalPosition.top, left: modalPosition.left, width: modalPosition.width }}
          setSelectedMembers={setSelectedMembers}
        >
          {filter}
        </Modal>
      )}
    </div>
  );
};

interface ModalProps {
  children: JSX.Element;
  coords: { left: number; top: number; width: number };
  showCheckCheckbox: boolean;
  showUncheckCheckbox: boolean;
  setSelectedMembers: (operation: MultiSelectComboOperation, member: VisibleMember) => void;
  closeModal: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

const Modal = (props: ModalProps) => {
  const checkOrUncheckAll = (checked: boolean) => {
    if (checked) {
      props.setSelectedMembers(MultiSelectComboOperation.CheckAll, null);
    } else {
      props.setSelectedMembers(MultiSelectComboOperation.UncheckAll, null);
    }
  };

  return (
    <div className={css.db_backdrop} onClick={(e) => props.closeModal(e)}>
      <div
        style={{ top: props.coords.top + "px", left: props.coords.left + "px", width: props.coords.width + "px" }}
        className={css.db_select_modal}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={css.dg_modal_filter}>{props.children}</div>
        <div className={css.db_modal_controls}>
          {props.showCheckCheckbox && <Checkbox checked={false} checkOrUncheckAll={checkOrUncheckAll} />}
          {props.showUncheckCheckbox && <Checkbox checked={true} checkOrUncheckAll={checkOrUncheckAll} />}
          <button className={css.dg_modal_button} onClick={(e) => props.closeModal(e)}>
            Zavřít
          </button>
        </div>
      </div>
    </div>
  );
};

interface CheckboxProps {
  checked: boolean;
  checkOrUncheckAll: (checked: boolean) => void;
}

const Checkbox = (props: CheckboxProps) => (
  <div onClick={() => props.checkOrUncheckAll(props.checked)} style={{ flex: "0 0 auto" }}>
    <K2Img glyphId={props.checked ? "wui*checkbox" : "wui*uncheckbox"} vcx={null} width={15} height={15} />
  </div>
);
