import { useEffect, useRef, useState } from "react";
import { UpdateControl } from "../common/communication.base";
import { NclControlBase } from "../common/components.ncl";
import { AcquireControl } from "./k2hoc";
import { writeToCSS } from "./VCX/VCXHelper";

export const useServerState = <T extends NclControlBase, U extends UpdateControl, V extends HTMLElement>(
  controlUID: string,
  vrUID: string,
  checkType: (ctrl: NclControlBase) => boolean,
  setAsActive?: (isActive: boolean) => void
) => {
  const [control] = useState(() => AcquireControl(controlUID, vrUID, checkType) as T);
  const [data, setData] = useState(control.init({ updateState: updateState, updateVCX: updateVCX, setAsActive: setAsActive }) as U);
  const [vcxVersion, setVCXVersion] = useState(-1);
  const element = useRef<V>(null);

  useEffect(() => {
    if (control.VCX !== control.Parent?.VCX && element.current) {
      writeToCSS(control.VCX, element.current);
    }

    return () => {
      control.willUnMount(true);
    };
  }, []);

  function updateState(state: U) {
    setData(state);
  }

  function updateVCX(vcxVersion: number) {
    setVCXVersion(vcxVersion);

    if (element.current) {
      writeToCSS(control.VCX, element.current);
    }
  }

  return [control, data, element, vcxVersion] as const;
};

export const useAnimationEnd = (show: boolean) => {
  const [render, setRender] = useState(false);

  const handleAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  return [render, handleAnimationEnd] as const;
};
