import { List } from "immutable";
import * as React from "react";
import { UpdateControl, UpdateHeadered } from "../../common/communication.base";
import { NclEmptyViewDialog } from "../../common/components.ncl";
import { AcquireControl, K2ComponentState, WithContextPlacementProps } from "../k2hoc";
import { RealizerQueueItem } from "../View/ViewRealizerReact";

interface EmptyViewProps extends WithContextPlacementProps{
  realizersQueue: List<RealizerQueueItem>;
  updateModalList: () => void;
}

export class K2EmptyView extends React.PureComponent<EmptyViewProps, K2ComponentState<UpdateHeadered>> {
  static displayName = `K2EmptyView`;
  private control: NclEmptyViewDialog<any, any>;

  constructor(props: EmptyViewProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclEmptyViewDialog;
    }) as NclEmptyViewDialog<any, any>;
    this.state = { data: this.control.init(this) as UpdateHeadered, vcxVersion: -1 };
  }

  componentDidUpdate(prevProps: Readonly<EmptyViewProps>, prevState: Readonly<K2ComponentState<UpdateHeadered>>, snapshot?: any): void {
    if(prevProps.realizersQueue !== this.props.realizersQueue){
      this.props.realizersQueue.map((r) => {
        if (!r.isOpen) {
          this.props.updateModalList();
        }
      });
    }
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount() {}

  updateState(state: UpdateControl) {
    this.setState(
      (prevState: K2ComponentState<UpdateHeadered>) => {
        return { data: state as UpdateHeadered };
      },
      () => {
        this.control.afterViewUpdated();
      }
    );
  }

  updateVCX(vcxVersion: number) {
    //
  }

  close(): Promise<void> {
    return Promise.resolve();
  }

  show(): Promise<void> {
    return Promise.resolve();
  }

  render(): JSX.Element {
    return null;
  }
}
