import React, { useEffect, useRef, useState } from "react";
import { getAttributes } from "../../common/common";
import { Align, MFMeasure, MultiFormatterValuesPair, UpdateDataGrid } from "../../common/communication.base";
import { NclDataGrid, NclSplitterPanel, UFOverAlign } from "../../common/components.ncl";
import { K2Header } from "../Expander/K2Expander";
import { AcquireControl, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { K2MFList, K2MFText } from "../MultiFormatText/K2MultiFormatText";
import { writeToCSS } from "../VCX/VCXHelper";
import K2DataGridContent from "./K2DataGridContent";
import K2DataGridFooter from "./K2DataGridFooter";

const K2DataGrid = (props: WithContextPlacementProps) => {
  const control = useRef(AcquireControl(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclDataGrid) as NclDataGrid);
  const [data, setData] = useState(
    control.current.init({
      updateState: updateState,
      updateVCX: updateVCX,
      getOverRect: getOverRect,
      setAsActive: setAsActive,
    }) as UpdateDataGrid
  );
  const [vcxVersion, setVCXVersion] = useState(-1);
  const element = useRef<HTMLDivElement>();

  useEffect(() => {
    return () => {
      control.current.willUnMount(true);
    };
  }, []);

  function updateState(state: UpdateDataGrid) {
    setData(state);

    if (control.current.VCX !== control.current.Parent.VCX) {
      writeToCSS(control.current.VCX, element.current);
    }
  }

  function updateVCX(vcxVersion: number) {
    setVCXVersion(vcxVersion);
    writeToCSS(control.current.VCX, element.current);
  }

  function getOverRect() {
    if (control.current && control.current.Content && control.current.Content.DataGrid && control.current.Content.DataGrid.Listener) {
      let listener: UFOverAlign = control.current.Content.DataGrid.Listener;

      if (listener.getOverRect) {
        return listener.getOverRect();
      }
    }

    return null;
  }

  function setAsActive(isActive: boolean) {
    if (element.current && isActive) {
      element.current.focus();
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    control.current.setActiveControlRequested();
    e.stopPropagation();
  };
  const style: React.CSSProperties = {
    ...props.style,
    minHeight: control.current.ComputedMinHeightWithMargin + "px",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div ref={element} data-k2-test-id={control.current.Ncl.Name} style={StyleHelper(control.current, style)} onFocus={handleFocus} {...getAttributes(data)}>
      {control.current.isShowHeader() && (
        <K2Header
          controlUID={control.current.Header.MetaData.ControlUID}
          vrUID={props.vrUID}
          title={data.Title}
          titleSuffix={data.TitleSuffix}
          titleSuffixCommandEnabled={data.TitleSuffixCommandEnabled}
        />
      )}
      {control.current.InstantFilterPanel && (
        <K2MFList vrUID={control.current.getRealizerUID()} controlUID={control.current.InstantFilterPanel.MetaData.ControlUID} />
      )}
      <K2DataGridContent controlUID={control.current.Content.MetaData.ControlUID} vrUID={props.vrUID} setActive={setAsActive} />
      {control.current.showFooter() && <K2DataGridFooter controlUID={control.current.Footer.MetaData.ControlUID} vrUID={control.current.getRealizerUID()} />}
    </div>
  );
};

export default K2DataGrid;
