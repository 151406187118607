import { List } from "immutable";
import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { Context } from "../../appcontext";
import { ClientActionNames, CSUpdateControl, FrameStyle, RectInDockSizeMode } from "../../common/communication.base";
import { ClientNclCommandItem, NclCommandItem, NclFloaterAccessor, NclMenuView, NclOpenDialog, NclViewBase } from "../../common/components.ncl";
import { ViewRealizer, ViewRealizerManager } from "../../viewrealizer";
import { K2Header } from "../Expander/K2Expander";
import { AcquireControl, WithVCXProps } from "../k2hoc";
import { RealizerQueueItem } from "../View/ViewRealizerReact";
import css from "./MobileModal.scss";

interface SimpleModalWindowProps extends WithVCXProps {
  realizerUID: string; // realizer for show
  controlUID: string;
  isOverlayBck?: boolean;
  realizersQueue: List<RealizerQueueItem>;
  headerTitle: string;
  updateModalList: () => void;
}

let mobileVH: number;

const K2SimpleModalWindow = (props: SimpleModalWindowProps) => {
  const control = useRef(AcquireControl(props.controlUID, props.realizerUID, (ctrl) => ctrl instanceof NclViewBase) as NclViewBase<any, any>);
  const vr = useRef<ViewRealizer>(ViewRealizerManager.getViewRealizer(props.realizerUID));
  const _closeCommand = useRef<NclCommandItem>();
  const root = useRef<HTMLDivElement>();
  const [actions] = useState(() => initClientActions());

  function getCloseCommand() {
    if (!_closeCommand.current) {
      if (vr.current) {
        _closeCommand.current = vr.current.createClientControl((context) => {
          return new ClientNclCommandItem("", "wui*close", this, handleClose, control.current.Header, true, context, ClientActionNames.CLOSE);
        }) as NclCommandItem;
      }
    }
    return _closeCommand.current;
  }

  function initClientActions() {
    if (!control.current.isShowHeader() && control.current.Header && props.vcx.Data.Placement?.MaximizeAll != 1 && !control.current.isNotificationBox) {
      control.current.Header.updateState({ Visible: true } as CSUpdateControl);

      if (control.current.getRectInDock().FrameStyle >= FrameStyle.frsNone) {
        control.current.getRectInDock().FrameStyle = FrameStyle.frsTitle;
      }

      control.current.Header.RQuickButtons.push(getCloseCommand());
    }
  }

  if (!mobileVH) {
    mobileVH = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${mobileVH}px`);
  }

  const handleClose = (ctrl: ClientNclCommandItem) => {
    control.current.closeRequest();
  };

  const handleAnimationtionEnd = () => {
    props.realizersQueue.map((r) => {
      if (!r.isOpen) {
        props.updateModalList();
      }
    });
  };

  useEffect(() => {
    if (!isAnimationDisabled()) return;

    props.realizersQueue.map((r) => {
      if (!r.isOpen) {
        props.updateModalList();
      }
    });
  }, [props.realizersQueue]);

  const isAnimationDisabled = (): boolean => {
    return Context.DeviceInfo.IsAnimationDisabled;
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (control.current.getRectInDock().EffectiveMouseClickClose) {
      if (e.target === root.current) {
        //nevim jestli pro to není lepší způsob, prozatím takto
        control.current.closeRequest();
        if (e.type === "contextmenu") {
          e.preventDefault();
        }
      }
    }
  };

  function handleClientAction(ctrl: ClientNclCommandItem) {
    if (ctrl.Name === ClientActionNames.CLOSE) {
      control.current.closeRequest();
    } else if (ctrl.Name === ClientActionNames.OKCOMMAND) {
      if (control.current.Parent instanceof NclFloaterAccessor) {
        control.current.Parent.hide();
      }
    }
  }

  let rect = control.current.getRectInDock();
  let isModalOrPercentSize = !vr.current.isModal() || (rect && rect.SizeMode === RectInDockSizeMode.ridsmPercent && (rect.Height > 0 || rect.Width > 0));
  let animationDisabled = isAnimationDisabled();
  let className = css.modal_mobile;
  const closingRealizer = props.realizersQueue.find((r) => {
    if (
      (control.current.MetaData.ControlUID === r.controlUID && r.isOpen === false) || // zavira se realizer
      (r.controlUID == null && props.realizerUID === r.realizerUID && r.isOpen === false) // zavira se jen control na realizeru (napr. floater)
    ) {
      return true;
    }
  });

  if (control.current.isMaximized() || control.current.isNotificationBox) {
    className += ` ${css.maximized}`;
  }

  if (control.current instanceof NclMenuView) {
    className += ` ${css.modal_mobile_menu_modal}`;
  } else if (control.current.isNotificationBox) {
    className += ` ${css.modal_mobile_notification}`;
  } else if (control.current instanceof NclOpenDialog) {
    className += ` ${css.modal_mobile_open_dialog}`;
  } else if (!isModalOrPercentSize) {
    if (vr.current.isDialog()) {
      className += ` ${css.modal_mobile_dialog}`;
    } else {
      className += ` ${css.modal_mobile_modal}`;
    }
  } else {
    className += ` ${css.modal_mobile_local_modal}`;
  }

  if (control.current.isMaximized()) {
    className += ` ${css.maximized}`;
  }

  if (!animationDisabled) {
    if (closingRealizer) {
      className += ` ${css.animation_close}`;
    } else {
      className += ` ${css.animation_open}`;
    }
  }

  return (
    <div
      ref={root}
      onClick={control.current.isNotificationBox ? handleOutsideClick : undefined}
      onContextMenu={control.current.isNotificationBox ? handleOutsideClick : undefined}
      style={{
        zIndex: vr.current.getDepth() * 5,
        backgroundColor: props.isOverlayBck ? control.current.VCX.getRGBColor(control.current.VCX.Data.ColorMap.AlphaColor).toHTML(0.2) : null,
        flexDirection: vr.current.isDialog() ? (Context.DeviceInfo.IsAnimationDisabled ? "column-reverse" : "column") : "row",
      }}
      className={css.modal_mobile_backdrop}
    >
      <div
        onAnimationEnd={!animationDisabled ? handleAnimationtionEnd : undefined}
        className={className}
        style={{
          touchAction: "pinch-zoom !important",
          height: vr.current.isDialog() ? control.current.calcFullHeight() + "px" : null,
        }} // doplněn touch-action, aby bylo možné zoomovat modální okno(např. sestava pro podpis). Pro draggable doplní React automaticky touch-action: none, což bylo nutné přerazit.
      >
        {control.current.isShowHeader() && (
          <K2Header
            controlUID={control.current.Header.MetaData.ControlUID}
            vrUID={control.current.getRealizerUID()}
            title={props.headerTitle}
            className={"handle_" + control.current.MetaData.ControlUID}
            onClientAction={handleClientAction}
          />
        )}
        {props.children}
      </div>
    </div>
  );
};

export default K2SimpleModalWindow;
