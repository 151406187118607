import * as React from "react";
import { useContext } from "react";
import { IconPosition } from "../../common/communication.base";
import { NclPage } from "../../common/components.ncl";
import { VCXContext } from "../../context";
import K2Img from "../Image/K2Img";
import K2Scroll from "../Scroll/K2Scroll";
import { SplitterContext } from "../SplitterPanel/K2SplitterPanel";
import K2Tab from "./K2Tab";
import K2TabContent from "./K2TabContent";
import { TabControlProps } from "./K2TabControl";
import css from "./ListDetailTabControl.scss";

const K2ListDetailTabControl = (props: TabControlProps) => {
  const vcxContext = useContext(VCXContext);
  const splitterContext = useContext(SplitterContext);

  const getClassName = (page: NclPage) => {
    let className = css.tc_list_detail_button;

    if ([IconPosition.ipLeft, IconPosition.ipRight].includes(props.control.Ncl.FrgtData.TabIconPosition)) {
      className += ` ${css.tc_icon_left_right}`;
    }

    if (props.currentPage === page.Ncl.PageUID) {
      className += ` ${css.tc_current_page}`;
    }

    return className;
  };

  return (
    <div className={css.tc_list_detail}>
      <div className={css.tc_list_detail_tabs}>
        <K2Scroll orientation={props.control.getOrientation()} control={props.control}>
          {props.control.visiblePages().map((page: NclPage) => {
            return (
              <K2Tab
                key={page.MetaData.ControlUID}
                controlUID={page.MetaData.ControlUID}
                vrUID={props.control.getRealizerUID()}
                className={getClassName(page)}
                onClick={props.setShowMobileMenu}
              />
            );
          })}
        </K2Scroll>
        <div className={css.tc_toggle_preview_button} onClick={() => splitterContext?.updateRatio()}>
          <K2Img
            glyphId={splitterContext?.ratio === 1 ? "wui*left" : "wui*right"}
            vcx={props.control.VCX}
            width={props.control.VCX.sizeMap(20)}
            height={props.control.VCX.sizeMap(20)}
            strokeColor={props.control.VCX.getColor(props.control.VCX.Data.ColorMap.BaseColorBck1)}
          />
        </div>
      </div>
      <K2TabContent control={props.control} currentPage={props.currentPage} />
    </div>
  );
};

export default K2ListDetailTabControl;
