import React from "react";
import { Context } from "../../appcontext";
import { UpdateInput, UpdateControl, CSUpdateInput, TNclInputType, TUFDisplayValueAs, TAcceptMode, HorizontalAlignment } from "../../common/communication.base";
import { UFOverAlign, NclInput, EditCheckValueResult, InputSelectionText, ClientNclInput } from "../../common/components.ncl";
import { K2ComponentState, WithContextPlacementProps, AcquireControl, StyleHelper } from "../k2hoc";
import K2Img from "../Image/K2Img";
import { K2InnerInput } from "./K2InnerInput";
import { K2InnerSimpleInput } from "./K2InnerSimpleInput";
import K2LabelInput from "./K2LabelInput";
import css from "./Input.scss";
import K2ToolBar from "../ToolBar/K2ToolBar";
import { ColumnBaseHelper, InputProps } from "./utils";
import { writeToCSS } from "../VCX/VCXHelper";
import { getTextDecoration } from "../k2hoc";

interface InputState extends K2ComponentState<UpdateInput> {
  Focused: boolean;
  revealPassword: boolean;
}

enum TextPosition {
  beforeCursor,
  afterCursor,
  selected,
}

export class K2Input extends React.PureComponent<InputProps, InputState> implements UFOverAlign {
  static displayName = `K2Input`;
  private element: K2InnerInput | K2InnerSimpleInput;
  private timer: any;
  private rootEl: HTMLElement;
  private control: NclInput;
  private requestSelectionStart: number;

  constructor(props: InputProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInput;
    }) as NclInput;
    this.state = { data: this.control.init(this) as UpdateInput, Focused: false, vcxVersion: -1, revealPassword: false };
  }

  getOverRect(): DOMRect {
    let result = undefined;
    if (this.element) {
      result = this.element.getOverRect();
    }

    if (!result) {
      if (this.rootEl) {
        result = this.rootEl.getBoundingClientRect();
      }
    }

    return result;
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateInput>) => {
      return { data: state as UpdateInput };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
    writeToCSS(this.control.VCX, this.rootEl);
  }

  setAsActive(isActive: boolean, backspace = false) {
    if (this.element && isActive) {
      this.element.focus();
    }

    if (backspace) {
      this.change(this.getText().slice(0, -1));
    }
  }

  componentDidMount(): void {
    if (this.control.VCX !== this.control.Parent?.VCX) {
      writeToCSS(this.control.VCX, this.rootEl);
    }
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InputState) {
    if (this.state.data != prevState.data) {
      if (this.element) {
        if (this.requestSelectionStart >= 0) {
          this.element.updateState(this.state.data, { Value: this.getText(), Position: this.requestSelectionStart });
        } else {
          this.element.updateState(this.state.data, { Value: this.getText() });
        }
      }
    }
  }

  public getSelectionStart(): number {
    if (this.element) return this.element.selectionStart;
    return -1;
  }

  public getSelectionEnd(): number {
    if (this.element) return this.element.selectionEnd;
    return -1;
  }

  public setText(value: string, position: number) {
    this.requestSelectionStart = position;
    this.control.updateState({ Text: value } as CSUpdateInput);
  }

  render() {
    return (
      <div
        data-k2-errors-count={this.state.data.ErrorsCount > 0 ? this.state.data.ErrorsCount : undefined}
        style={StyleHelper(this.control, this.props.style)}
        ref={(ref) => {
          this.rootEl = ref;
        }}
        className={css.in_input_base}
      >
        <K2LabelInput
          titleVisible={this.control.isVisibleTitle()}
          title={this.state.data.Title}
          errorsCount={this.state.data.ErrorsCount}
          warningsCount={this.state.data.WarningsCount}
          focused={this.state.Focused}
          inEditMode={this.control.InEditMode}
          modified={this.state.data.Modified}
          readOnly={this.state.data.ReadOnly}
          vcx={this.control.VCX}
          frgtData={this.control.Ncl.FrgtData}
          id={this.control.Ncl.Name}
        >
          <div className={css.in_input_prefix_suffix} onContextMenu={this.handleContextMenu}>
            {this.getPrefixSufix(true)}
            {this.getInputTag()}
            {this.getPrefixSufix(false)}
          </div>
          {this.control?.ToolBar?.Actions?.length > 0 && (
            <K2ToolBar
              key={this.control.ToolBar.MetaData.ControlUID}
              controlUID={this.control.ToolBar.MetaData.ControlUID}
              vrUID={this.props.vrUID}
              className={css.in_toolbar}
            />
          )}
          {this.control.Ncl.FrgtData.IsPassword && (
            <button
              type="button"
              className="button"
              style={{ border: "none", backgroundColor: "transparent", minWidth: "min-content" }}
              onClick={this.revealPassword}
            >
              <K2Img
                width={this.control.VCX.MinRowHeight}
                height={this.control.VCX.MinRowHeight}
                glyphId={this.state.revealPassword ? "wui*eye.no" : "wui*eye"}
                vcx={this.control.VCX}
                strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
              />
            </button>
          )}
        </K2LabelInput>
      </div>
    );
  }

  private handleContextMenu = (e: React.MouseEvent) => {
    if (Context.DeviceInfo.IndependentFormatMode) return;

    e.preventDefault();
    this.showContextMenu();
  };

  protected getText(): string {
    let newText = this.state.data.Text;
    if (this.state.data.PasteText && this.state.data.PasteText.length > 0) {
      if (this.element) {
        if (this.element.selectionStart >= 0 && this.element.selectionStart < newText.length) {
          if (this.element.selectionEnd >= 0 && this.element.selectionEnd < newText.length) {
            newText = newText.substring(0, this.element.selectionStart) + this.state.data.PasteText + newText.substring(this.element.selectionEnd);
          } else {
            newText = newText.substring(0, this.element.selectionStart) + this.state.data.PasteText + newText.substring(this.element.selectionStart);
          }
        } else {
          newText = newText + this.state.data.PasteText;
        }
      } else {
        newText = newText + this.state.data.PasteText;
      }
    }

    return newText;
  }

  private getHorizontalAlignCss(): string {
    switch (this.state.data.HorizontalAlignment) {
      case HorizontalAlignment.fhaLeft:
        return css.in_text_left;
      case HorizontalAlignment.fhaRight:
        return css.in_text_right;
      case HorizontalAlignment.fhaCenter:
        return css.in_text_center;
      default:
        return css.in_text_left;
    }
  }

  getStyle = (): React.CSSProperties => {
    return {
      backgroundColor: ColumnBaseHelper.getBackgroundColor(this.state.data.FormattingStyle, false, false, this.control.VCX),
      color: ColumnBaseHelper.getForegroundColor(this.state.data.FormattingStyle, false, false, this.control.VCX),
      fontWeight: ColumnBaseHelper.IsBold(this.state.data.FormattingStyle) ? ("bold" as "bold") : ("normal" as "normal"),
      fontStyle: ColumnBaseHelper.IsItalic(this.state.data.FormattingStyle) ? ("italic" as "italic") : ("normal" as "normal"),
      textDecoration: getTextDecoration(
        ColumnBaseHelper.IsStrike(this.state.data.FormattingStyle),
        ColumnBaseHelper.IsUnderline(this.state.data.FormattingStyle)
      ),
    };
  };

  private getPrefixSufix(isPrefix: boolean = true): JSX.Element {
    let glyphId: string = "";
    let text: string = "";
    let valAs: TUFDisplayValueAs;
    if (isPrefix) {
      glyphId = this.state.data.PrefixGlyphId;
      text = this.state.data.PrefixText;
      valAs = this.control.Ncl.PrefixDisplayAs;
    } else {
      glyphId = this.state.data.SuffixGlyphId;
      text = this.state.data.SuffixText;
      valAs = this.control.Ncl.SuffixDisplayAs;
    }
    switch (valAs) {
      case TUFDisplayValueAs.dvaImage:
        return (
          <K2Img
            glyphId={glyphId}
            vcx={this.control.VCX}
            strokeColor={this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1)}
            width={this.control.VCX.InputControl.getEditHeight(1)}
            height={this.control.VCX.InputControl.getEditHeight(1)}
          />
        );
      case TUFDisplayValueAs.dvaText:
        return (
          text && (
            <span style={this.getStyle()} className={css.in_prefix_suffix}>
              {text}
            </span>
          )
        );
      default:
        return null;
    }
  }

  private getTextFromPosition(option: TextPosition): string {
    if (
      this.element &&
      this.element.Value &&
      this.element.selectionStart &&
      this.element.selectionStart > 0 &&
      this.element.selectionStart < this.element.Value.length
    ) {
      switch (option) {
        case TextPosition.beforeCursor:
          return this.element.Value[this.element.selectionStart - 1];
        case TextPosition.afterCursor:
          return this.element.Value[this.element.selectionStart];
        case TextPosition.selected:
          if (this.element.selectionEnd && this.element.selectionStart < this.element.selectionEnd) {
            return this.element.Value.slice(this.element.selectionStart, this.element.selectionEnd);
          }
        default:
          break;
      }
    }

    return "";
  }

  private revealPassword = () => {
    this.setState({ revealPassword: !this.state.revealPassword });
  };

  private refCallBack = (element: K2InnerInput | K2InnerSimpleInput) => {
    if (element) {
      this.element = element;
    }
  };

  private getInputTag(): JSX.Element {
    if (Context.DeviceInfo.IndependentFormatMode) {
      return (
        <K2InnerSimpleInput
          controlUID={this.control.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          change={this.change}
          onDrop={this.handleDrop}
          onPaste={this.handlePaste}
          showContextMenu={() => {
            this.showContextMenu();
          }}
          ref={this.refCallBack}
          InputTagPlaceholder={this.state.data.Watermark}
          className={this.getHorizontalAlignCss()}
          style={this.getStyle()}
          revealPassword={this.state.revealPassword}
          blockSoftKeyboard={this.props.blockSoftKeyboard}
        />
      );
    } else {
      return (
        <K2InnerInput
          controlUID={this.control.MetaData.ControlUID}
          vrUID={this.control.getRealizerUID()}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          change={this.change}
          onDrop={this.handleDrop}
          onKeyDown={this.handleKeyDown}
          onPaste={this.handlePaste}
          ref={this.refCallBack}
          InputTagPlaceholder={this.state.data.Watermark}
          className={this.getHorizontalAlignCss()}
          style={this.getStyle()}
          revealPassword={this.state.revealPassword}
          blockSoftKeyboard={this.props.blockSoftKeyboard}
        />
      );
    }
  }

  private change = (value: string) => {
    this.control.change(value, false);
    switch (this.state.data.AcceptMode) {
      case TAcceptMode.amImmediate:
        this.control.accept();
        break;
      case TAcceptMode.amDelayed:
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          this.control.accept();
        }, 1000);
        break;
    }
  };

  private handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if ((this.control.State as UpdateInput).ReadOnly) return;
    if (!(this.control instanceof ClientNclInput)) this.control.setActiveControlRequested();
    this.setState({ Focused: true });
    e.stopPropagation();
  };

  private handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ Focused: false });
  };

  private showContextMenu() {
    this.control.contextMenu();
  }

  public handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>) => {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (this.control.isDateOrTime()) {
        let value: string = this.getTextFromPosition(TextPosition.selected);
        if (!value) {
          value = this.getTextFromPosition(e.keyCode === 8 ? TextPosition.beforeCursor : TextPosition.afterCursor);
        }
        if (value && value.indexOf(Context.getApplication().getLocalInputInfo(this.control).separator) >= 0) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }
    }

    if (e.key.length === 1) {
      if (this.checkInput(e.key) > EditCheckValueResult.default) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }
  };

  private handlePaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.checkInput(e.clipboardData.getData("text").trim()) > EditCheckValueResult.default) {
      e.preventDefault();
      return;
    }
  };

  private handleDrop = (e: React.DragEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let data = e.dataTransfer.getData("text").trim();
    let value = this.checkInput(data, true);
    switch (value) {
      case EditCheckValueResult.block:
        e.preventDefault();
        break;
      case EditCheckValueResult.default:
        this.element.updateState(null, { Value: data });
        e.preventDefault();
      default:
        this.element.focus();
        break;
    }
  };

  private checkInput(key: string, replaceAll?: boolean): EditCheckValueResult {
    if ((this.control.State as UpdateInput).ReadOnly) return;
    if (!this.element) return EditCheckValueResult.default;
    if (this.element.selectionStart && this.element.selectionStart < 0 && !replaceAll) return EditCheckValueResult.default;
    let selection: InputSelectionText = {
      start: this.element.selectionStart !== null && this.element.selectionStart >= 0 ? this.element.selectionStart : this.element.Value.length,
      end: this.element.selectionEnd !== null && this.element.selectionEnd >= 0 ? this.element.selectionEnd : this.element.Value.length,
    };
    let result = this.control.checkInput(this.element.Value, key, selection, replaceAll);
    switch (result.Result) {
      case EditCheckValueResult.block:
        //beep???
        break;
      case EditCheckValueResult.newValue:
        this.element.updateState(null, { Value: result.Value as string, Position: result.ToPosition });
        break;
      default:
        break;
    }

    return result.Result;
  }
}
