import * as React from "react";
import { Align, CSUFNclControlMetadata, FrgtPanelBaseData, UFUpdateControl, UpdateControl, UpdateExpander } from "../../common/communication.base";
import { NclBaseContainer, NclExpander, NclPanel, NclPage, NclTabControl, NclView, NclSplitterPanel } from "../../common/components.ncl";
import { GenerateControl } from "../K2GenerateControl";
import { K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "../k2hoc";
import { UpdateContext } from "../../context";
import css from "./FlowPanel.scss";
import { withVCXInCSS, WithVCXinCSSProps } from "../VCX/VCXHelper";

export function getBackroundCss(isInPreview: boolean, isInEditMode: boolean): string {
  if (isInPreview) {
    return css.fp_background_preview;
  } else {
    if (isInEditMode) {
      return css.fp_background_edit;
    }
  }
  return css.fp_background;
}

class _FlowPanel extends React.Component<WithVCXinCSSProps> {
  static displayName = `K2FlowPanel`;
  private control: NclBaseContainer;
  static contextType = UpdateContext;

  constructor(props: WithVCXinCSSProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      let ctn = ctrl as NclBaseContainer;
      if (ctn && ctn.Children) return true;
      return false;
    }) as NclBaseContainer;
    this.state = { data: this.control.init(this) as UFUpdateControl, maxHeight: 0, vcxVersion: -1 };
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UFUpdateControl>) => {
      return { data: state as UFUpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  private getGridTemplateRows = () => {
    let template: string = "";

    this.control.Parts.map((row) => {
      const isColumnTopOrBottomAligned = row.some((column) => {
        if (column.MetaData.Bounds.Align === Align.Top || column.MetaData.Bounds.Align === Align.Bottom) {
          return true;
        }
      });

      const isAnyColumnVisible = row.some((column) => column.State.Visible === true);

      if (!isAnyColumnVisible) return;

      if (isColumnTopOrBottomAligned) {
        template += "min-content ";
      } else {
        if (row[0] instanceof NclExpander && (row[0].State as UpdateExpander).Collapsed) {
          template += "min-content ";
        } else {
          template += "auto ";
        }
      }
    });

    return template;
  };

  render() {
    let frgtData = this.control.MetaData.FrgtData as FrgtPanelBaseData;
    let isScroll = frgtData && frgtData.Scroll;
    let clsName: string = this.props.className && this.props.className != undefined ? this.props.className + " " : "";
    clsName += ` ${getBackroundCss(this.control.isInPreview(), this.control.InEditMode)}`;
    if (this.control.Parent instanceof NclView) {
      clsName += ` ${css.fp_preview}`;
    } else {
      if (this.control.Parent instanceof NclPage && (this.control.Parent.Parent as NclTabControl).Btns != null) {
        clsName += ` ${css.fp_main_content}`;
      }
    }

    return (
      <div
        style={StyleHelper(
          this.control,
          Object.assign(
            {},
            {
              flex: "1 1 auto",
              overflow: isScroll ? "auto" : "hidden",
              display: this.control.State.Visible ? "grid" : "none",
              gridTemplateColumns: `repeat(${this.control.MetaData.Bounds.InteriorBandsCount}, 1fr)`,
              gridTemplateRows: this.getGridTemplateRows(),
              gap: `${this.control.VCX.Data.MarginY * 2}px ${this.control.VCX.Data.MarginX * 2}px`,
            },
            this.props.style
          )
        )}
        className={"fp " + clsName}
      >
        {this.control.Parts.map((controls) => {
          let columnStart = 1;

          return controls.map((control) => {
            const columnEnd = columnStart + control.MetaData.Bounds.BandsCount;

            const style = {
              gridColumn: `${columnStart} / ${columnEnd}`,
            };

            columnStart = columnEnd;

            if (control instanceof NclPanel && [Align.Client, Align.Left, Align.Right].includes(control.MetaData.Bounds.Align)) {
              // pokud ma panel zarovnani Client apod. je nutne jej vyjmout z kontextu (pomoci absolutni pozice), aby jeho vysku nezvetsoval vnitrni content
              return (
                <div
                  key={control.MetaData.ControlUID}
                  style={{
                    ...style,
                    position: control.State.Visible ? "relative" : "absolute", // pokud je Panel neviditelny (suppressed), vyjmu z kontextu tento div, aby neovlivnoval vykreslovani radku v gridu (grid-template-rows)
                    minHeight: control.ComputedMinHeightWithMargin + "px",
                    width: "100%",
                  }}
                >
                  {GenerateControl(control, {
                    position: "absolute",
                    height: "100%",
                  })}
                </div>
              );
            }

            return GenerateControl(control, { ...style, minHeight: control instanceof NclPanel ? control.ComputedMinHeightWithMargin + "px" : null });
          });
        })}
      </div>
    );
  }
}

export const K2FlowPanel = withVCXInCSS(_FlowPanel);
