import { List } from "immutable";
import React from "react";
import { NclViewBase, UFNclControlBase } from "../../common/components.ncl";
import { ViewRealizerManager } from "../../viewrealizer";
import { K2View } from "../View/K2View";
import { RealizerQueueItem, ViewRealizerReact } from "../View/ViewRealizerReact";

interface ModalProps {
  realizerUID: string;
  realizersQueue: List<RealizerQueueItem>;
  isActive: boolean;
  updateModalList: () => void;
}

const K2Modal = (props: ModalProps) => {
  return (
    <>
      {props.realizersQueue?.map((item: RealizerQueueItem) => {
        let vr = ViewRealizerManager.getViewRealizer(item.realizerUID);

        if (vr != null) {
          let priorRealizerUID: string = "";
          let content = null;
          let key = "Modal_" + vr.getRealizerUID();
          if (!item.controlUID) {
            priorRealizerUID = vr.getPriorRealizer() ? vr.getPriorRealizer().getRealizerUID() : "";
            key += `_${priorRealizerUID}_${props.realizerUID}`;
            content = (
              <ViewRealizerReact
                VRUID={vr.getRealizerUID()}
                key={key}
                overlayBck={props.isActive}
                updateModalList={props.updateModalList}
                realizersQueue={props.realizersQueue}
                skip={true}
              />
            );
          } else {
            let ctrl = vr.getControlByUID(item.controlUID) as UFNclControlBase;
            if (ctrl && ctrl instanceof NclViewBase) {
              key += "_" + ctrl.MetaData.ControlUID;
              content = (
                <K2View
                  controlUID={ctrl.MetaData.ControlUID}
                  vrUID={vr.getRealizerUID()}
                  overlayBck={props.isActive}
                  key={key}
                  realizersQueue={props.realizersQueue}
                  updateModalList={props.updateModalList}
                ></K2View>
              );
            } else {
              throw new Error("Modal control not found for show.");
            }
          }

          return content;
        }
      })}
    </>
  );
};

export default K2Modal;
