import React from "react";
import { UpdateControl } from "../../common/communication.base";
import { NclHeader, NclToolBar } from "../../common/components.ncl";
import K2Action from "../Action/K2Action";
import ActionSeparator from "../ActionSeparator/ActionSeparator";
import { useServerState } from "../hooks";
import { GenerateControl } from "../K2GenerateControl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import css from "./ToolBar.scss";

const K2ToolBar = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclToolBar, UpdateControl, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclToolBar
  );

  let className = css.tb;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div ref={element} style={StyleHelper(control, props.style)} className={className}>
      <div className={control.MenuBtn && control.Parent instanceof NclHeader ? css.tb_mobile_hide_actions : null}>
        {control.Actions.map((ctrl) => GenerateControl(ctrl, { width: "auto" }))}
      </div>
      {control.MenuBtn && (
        <>
          <ActionSeparator width={control.VCX.sizeMap(1)} color={control.VCX.getColor(control.VCX.Data.ColorMap.ContentFrame1)} />
          <K2Action
            style={{ width: "auto" }}
            controlUID={control.MenuBtn.MetaData.ControlUID}
            vrUID={control.getRealizerUID()}
            color={control.HasMainMenu ? control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1) : null}
          />
        </>
      )}
    </div>
  );
};

export default K2ToolBar;
