import * as React from "react";
import { useContext } from "react";
import { NclPage } from "../../common/components.ncl";
import { VCXContext } from "../../context";
import K2Scroll from "../Scroll/K2Scroll";
import K2Tab from "./K2Tab";
import K2TabContent from "./K2TabContent";
import { TabControlProps } from "./K2TabControl";
import css from "./TabControlBase.scss";

const K2TabControlBase = (props: TabControlProps) => {
  const vcxContext = useContext(VCXContext);

  const getClassName = (page: NclPage) => {
    let className = css.tc_base_button;

    if (props.currentPage === page.Ncl.PageUID) {
      className += ` ${css.tc_current_page}`;
    }

    return className;
  };

  return (
    <div className={css.tc_base}>
      <div className={css.tc_base_tabs}>
        <K2Scroll orientation={props.control.getOrientation()} control={props.control}>
          {props.control.visiblePages().map((page: NclPage) => {
            return (
              <K2Tab
                key={page.MetaData.ControlUID}
                controlUID={page.MetaData.ControlUID}
                vrUID={props.control.getRealizerUID()}
                onClick={props.setShowMobileMenu}
                className={getClassName(page)}
              />
            );
          })}
        </K2Scroll>
      </div>
      <K2TabContent control={props.control} currentPage={props.currentPage} />
    </div>
  );
};

export default K2TabControlBase;
