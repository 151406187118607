import React from "react";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import HighchartsReact from "highcharts-react-official";
import Exporting from "highcharts/modules/exporting";
import draggable from "highcharts/modules/draggable-points";
import patternFill from "highcharts/modules/pattern-fill";
import ResizeObserver from "resize-observer-polyfill";
import debounce from "lodash-es/debounce";
import { ColorProps } from "./GanttFunctions";

highchartsGantt(Highcharts);
Exporting(Highcharts);
draggable(Highcharts);
patternFill(Highcharts);

export interface GOptions extends Highcharts.Options {
  xAxis?: GXAxisOptions | Array<GXAxisOptions>;
}

interface GXAxisOptions extends Highcharts.XAxisOptions {
  dateTimeLabelFormats?: GAxisDateTimeLabelFormatsOptions;
}

interface GAxisDateTimeLabelFormatsOptions extends Highcharts.AxisDateTimeLabelFormatsOptions {
  hour?: GAxisDateTimeLabelFormatsOptionsObject;
  day?: GAxisDateTimeLabelFormatsOptionsObject;
  week?: GAxisDateTimeLabelFormatsOptionsObject;
  month?: GAxisDateTimeLabelFormatsOptionsObject;
}

interface GAxisDateTimeLabelFormatsOptionsObject extends Highcharts.AxisDateTimeLabelFormatsOptionsObject {
  list?: string[];
}

interface GanttCommonProps {
  dataitems: Highcharts.GanttPointOptionsObject[];
  seriesdataitems: Array<Highcharts.SeriesOptionsType>; // Highcharts.SeriesOptions[];// Highcharts.SeriesGanttOptions[];
  categories: string[];
  yAxisOptions: Highcharts.YAxisOptions;
  inEditMode: boolean;
  byOperations: boolean;
  byResources: boolean;
  headerAtBottom: boolean;
  ownColors: boolean;
  hideNavigator: boolean;
  hideXAxis: boolean;
  hideYAxis: boolean;
  hideDateIndicator: boolean;
  hideRangeSelectorDates: boolean;
  //showDateTime: boolean;
  twoRowXAxis: boolean;
  timeAxisPrecision: number;
  draggableStartEnd: boolean;
  pointSelectAllowed: boolean;
  draggableX: boolean;
  draggableY: boolean;
  colors: ColorProps;
  xAxisOptions: {
    minRangeDate: number;
    maxRangeDate: number;
    rangeDateFrom: number;
    rangeDateTo: number;
    plotBands: {
      from: number;
      to: number;
      color: string;
      zIndex: number;
    }[];
  };
  zoom: number;
  handleClick: (e: Highcharts.PointClickEventObject) => void;
  handleGanttContextMenu: (e: Highcharts.PointerEventObject) => void;
  handleRangeSelect: (e: Highcharts.AxisSetExtremesEventObject) => void;
  handleDrop: (e: Highcharts.PointDropEventObject) => void;
  handleSelect: (e: Highcharts.PointInteractionEventObject) => void;
  handleUnselect: (e: Highcharts.PointInteractionEventObject) => void;
  getApi?: (
    ref: React.RefObject<{
      chart: Highcharts.Chart;
      container: React.RefObject<HTMLDivElement>;
    }>
  ) => void;
}

interface GanttCommonState {
  chartOptions: GOptions;
}

export class K2GanttCommon extends React.Component<GanttCommonProps, GanttCommonState> {
  private gantt = React.createRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement> }>();
  private ro: ResizeObserver;
  private div: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  private ganttHeight: number;
  private ganttOptions: GOptions;

  constructor(props: GanttCommonProps) {
    super(props);

    Highcharts.setOptions({
      lang: {
        months: ["Leden", "Únor", "Březen", "Duben", "Květěn", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
        shortMonths: ["Led", "Únor", "Bře", "Dub", "Kvě", "Čer", "Čec", "Srp", "Zář", "Říj", "Lis", "Pro"],
        weekdays: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
        shortWeekdays: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
        rangeSelectorFrom: "Od",
        rangeSelectorTo: "Do",
        downloadCSV: "Stáhnout CSV",
        downloadJPEG: "Stáhnout JPEG",
        downloadPDF: "Stáhnout PDF",
        downloadPNG: "Stáhnout PNG",
        downloadSVG: "Stáhnout SVG",
        downloadXLS: "Stáhnout XLS",
        viewFullscreen: "Zobrazit na celou obrazovku",
        exitFullscreen: "Opustit celou obrazovku",
        printChart: "Vytisknout",
      },
    });

    this.ganttOptions = {
      chart: {
        backgroundColor: this.getBgColor(),
        spacing: [21, 16, 20, 0], // 21 = odsazeni shora, 16 = odsazeni pro scrollbar
        style: {
          fontFamily: "inherit",
        },
        animation: false,
        zoomType: this.props.byOperations ? "x" : "xy",
        events: {
          //click: this.props.handleGanttContextMenu,
          //click: (e) => {
          //  this.props.handleGanttContextMenu(e);
          //},
        },
      },
      xAxis: [
        {
          min: this.props.xAxisOptions.minRangeDate == 0 ? null : this.props.xAxisOptions.minRangeDate * 1000,
          max: this.props.xAxisOptions.maxRangeDate == 0 ? null : this.props.xAxisOptions.maxRangeDate * 1000,
          events: {
            setExtremes: (e) => {
              if (e.trigger === "navigator" || e.trigger === "rangeSelectorInput") {
                //console.log(e.min, e.max);
                this.props.handleRangeSelect(e);
              }
            },
          },
          visible: !this.props.hideXAxis,
          opposite: !this.props.headerAtBottom ? true : false,
          plotBands: this.props.xAxisOptions.plotBands,
          currentDateIndicator: this.props.hideDateIndicator
            ? false
            : {
                width: 2,
                dashStyle: "Solid",
                color: "red",
                label: {
                  format: "%Y-%m-%d",
                },
              },
          tickInterval: this.props.timeAxisPrecision,
          //tickColor: this.props.colors.ContentFrame1,
          minorTickInterval: this.props.timeAxisPrecision, //undefined, //"auto",
          minorGridLineColor: this.props.colors.ContentFrame1,
          type: "datetime",
          dateTimeLabelFormats: {
            //day: {
            //  list: [`${this.props.showDateTime ? "%d.%m." : ""} %H:%M`, "%a, %e. %b", "%d.%m.", "%d"],
            //},
            day: {
              list: ["%E %e.%m.", "%E %e.%m.", "%E"],
            },
            week: {
              list: ["%W. týden %Y", "%W. t."],
            },
            month: {
              list: ["%m %Y", "%m %Y", "%m %Y"],
            },
          },
          grid: {
            cellHeight: 27 * (this.props.zoom / 100),
            borderColor: this.props.colors.ContentFrame1,
          },
          labels: {
            padding: 5,
            style: {
              fontSize: "11px",
              color: this.props.colors.DataBrowseColorFrg,
            },
            align: "center",
          },
        },
        {
          visible: !this.props.hideXAxis && this.props.twoRowXAxis,
          opposite: !this.props.headerAtBottom ? true : false,
          plotBands: this.props.xAxisOptions.plotBands,
          type: "datetime",
          grid: {
            cellHeight: 27 * (this.props.zoom / 100),
            borderColor: this.props.colors.ContentFrame1,
          },
          dateTimeLabelFormats: {
            day: {
              list: ["%E %e.%m.", "%E %e.%m.", "%E"],
            },
            week: {
              list: ["%W. týden %Y"],
            },
            month: {
              list: ["%m %Y", "%m %Y", "%m %Y"],
            },
          },
          labels: {
            style: {
              fontSize: "11px",
              color: this.props.colors.DataBrowseColorFrg,
            },
            align: "center",
          },
        },
      ],
      navigator: {
        enabled: !this.props.hideNavigator,
        adaptToUpdatedData: false,
        series: {
          type: "gantt",
          pointPadding: 0.25,
          pointWidth: null,
        },
        yAxis: {
          reversed: true,
          categories: [],
        },
        xAxis: {
          gridLineColor: this.props.colors.ContentFrame1,
          labels: {
            style: {
              color: this.props.colors.DataBrowseColorFrg,
            },
          },
        },
        outlineColor: this.props.colors.ContentFrame1,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        gantt: {
          //grouping: true, nechci
          borderRadius: 0,
          borderWidth: 1,
          pointWidth: null,
          dataLabels: {
            format: "{point.description}",
          },
          allowPointSelect: this.props.pointSelectAllowed,
          dragDrop: {
            draggableStart: this.props.draggableStartEnd && this.props.inEditMode,
            draggableEnd: this.props.draggableStartEnd && this.props.inEditMode,
            dragPrecisionX: this.props.timeAxisPrecision, //1000 * 60 * 60 * 24,
            draggableX: this.props.inEditMode && this.props.draggableX,
            draggableY: this.props.inEditMode && this.props.draggableY,
            groupBy: "groupById",
          },
          point: {
            events: {
              //select: this.props.handleSelect,
              //unselect: this.props.handleUnselect,
              click: this.props.handleClick,
              drop: this.props.handleDrop,
            },
          },
        },
      },
      tooltip: {
        enabled: true,
        pointFormatter: function () {
          const point = this as Highcharts.GanttPointOptionsObject;
          if (point.custom.htmlTooltip) return point.custom.htmlTooltip;

          // prettier-ignore
          return `
            <b>${this.name}</b><br>Začátek: ${new Date(this.x).toLocaleDateString/*toLocaleString*/('cs-CZ', { timeZone: 'UTC' })}<br>Konec: ${new Date(this.x2).toLocaleDateString('cs-CZ', { timeZone: 'UTC' })}<br>${point.custom.workTime} ${point.custom.workTimeUnit}<br>${100*(point.completed as number)} %
          `;
        },
      },
      scrollbar: {
        enabled: false,
      },
      rangeSelector: {
        inputEnabled: !this.props.hideRangeSelectorDates,
        //inputBoxHeight: 0,
        //inputBoxWidth: 0,
        enabled: !this.props.hideNavigator,
        verticalAlign: "bottom",
        buttonPosition: { align: "right" },
        dropdown: "never",
        buttons: [
          {
            type: "all",
            text: "reset",
          },
          /*
          {
            type: "month",
            count: 1,
            text: "1m",
          },
          {
            type: "month",
            count: 3,
            text: "3m",
          },
          {
            type: "month",
            count: 6,
            text: "6m",
          },
          {
            type: "ytd",
            text: "YTD",
          },
          {
            type: "year",
            count: 1,
            text: "1r",
          },
          {
            type: "all",
            text: "Max",
          },*/
        ],
      },
      exporting: {
        enabled: false,
      },
      series: this.props.seriesdataitems,
      yAxis: {
        ...this.props.yAxisOptions,
        visible: !this.props.hideYAxis,
        gridLineColor: this.props.colors.ContentFrame1,
        grid: {
          ...this.props.yAxisOptions.grid,
          borderColor: this.props.colors.ContentFrame1,
        },
      },
    };

    this.state = { chartOptions: this.ganttOptions };
  }

  getBgColor = () => {
    return !this.props.inEditMode ? this.props.colors.DataBrowseColorBck : this.props.colors.DataChangeColorBck;
  };

  componentDidUpdate(prevProps: GanttCommonProps) {
    //this.gantt.current.chart.zoomOut();
    let newOptions: GanttCommonState = { chartOptions: {} };

    if (prevProps.seriesdataitems !== this.props.seriesdataitems || prevProps.yAxisOptions !== this.props.yAxisOptions) {
      newOptions.chartOptions = { series: this.props.seriesdataitems, yAxis: this.props.yAxisOptions };
      this.setState(newOptions);
    }

    if (prevProps.xAxisOptions !== this.props.xAxisOptions) {
      const newAxis = (this.ganttOptions.xAxis as GXAxisOptions[]).map((axis) => {
        return {
          //...axis,
          //min: this.props.xAxisOptions.rangeDateFrom * 1000,
          //max: this.props.xAxisOptions.rangeDateTo * 1000,
          plotBands: this.props.xAxisOptions.plotBands,
        };
      });

      newOptions.chartOptions = { ...newOptions.chartOptions, xAxis: newAxis };
      this.setState(newOptions);
    }

    if (this.props.inEditMode !== prevProps.inEditMode) {
      newOptions.chartOptions = {
        ...newOptions.chartOptions,
        chart: {
          backgroundColor: this.getBgColor(),
        },
        plotOptions: {
          gantt: {
            dragDrop: {
              draggableX: this.props.inEditMode && this.props.draggableX,
              draggableY: this.props.inEditMode && this.props.draggableY,
            },
          },
        },
      };
      this.setState(newOptions);
    }
  }

  debounce = debounce((ganttHeight: number, containerHeight: number) => {
    if (this.ganttHeight == null) this.ganttHeight = ganttHeight;

    this.setState(
      {
        chartOptions: {
          chart: {
            height: containerHeight < this.ganttHeight ? containerHeight : null,
            scrollablePlotArea: {
              minHeight: containerHeight < this.ganttHeight ? this.ganttHeight : 1,
            },
          },
        },
      },
      () => this.gantt.current.chart.reflow()
    );
  }, 250);

  componentDidMount() {
    if (this.props.getApi) {
      this.props.getApi(this.gantt);
    }

    this.ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      let ganttHeight: number = this.gantt.current.chart.chartHeight;
      let containerHeight: number = entries[0].contentRect.height;

      this.debounce(ganttHeight, containerHeight);
    });

    this.ro.observe(this.div.current);
  }

  componentWillUnmount() {
    this.ro.unobserve(this.div.current);
  }

  render() {
    return (
      <div
        className="gantt-wrapper"
        ref={this.div}
        style={{
          height: "100%",
          width: "100%",
          display: "block",
          backgroundColor: this.getBgColor(),
        }}
      >
        <HighchartsReact ref={this.gantt} highcharts={Highcharts} options={this.state.chartOptions} constructorType={"ganttChart"} />
      </div>
    );
  }
}
