import * as React from "react";
import K2Tab from "./K2Tab";
import { NclBaseTabControl, NclVRTabControl } from "../../common/components.ncl";
import { CSNclTabControlMetadata, UpdateTabControl } from "../../common/communication.base";
import { VCXContext } from "../../context";
import K2Img from "../Image/K2Img";
import css from "./ResponsiveTabControl.scss";

interface ResponsiveTabControlProps {
  control: NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>;
  currentPage: string;
  showMobileMenu: boolean;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleAnimationEnd: () => void;
}

const K2ResponsiveTabControl = (props: ResponsiveTabControlProps) => {
  const vcxContext = React.useContext(VCXContext);

  const visiblePages = () => {
    const pages = props.control.visiblePages();

    if (props.control instanceof NclVRTabControl) {
      return pages.filter((page) => page.Ncl.IsDynamic);
    }

    return pages;
  };

  const pages = visiblePages();

  return (
    <div
      onClick={() => props.setShowMobileMenu(false)}
      className={`${css.tc_backdrop} ${props.showMobileMenu ? css.tc_responsive_backdrop_open : css.tc_responsive_backdrop_close}`}
    >
      <div className={css.tc_close}>
        <K2Img glyphId="wui*close" width={30} height={30} vcx={vcxContext.vcx} />
      </div>
      <div className={css.tc_responsive_content}>
        <div className={css.tc_responsive_buttons}>
          {pages.map((page, i) => (
            <div
              style={{
                animationDelay: props.showMobileMenu ? `${i / 20}s` : `${(pages.size - i) / 20}s`,
                flex: "0 0 auto",
              }}
              className={props.showMobileMenu ? css.tc_responsive_button_up : css.tc_responsive_button_down}
              onAnimationEnd={pages.size - 1 === i ? props.handleAnimationEnd : null}
              key={page.MetaData.ControlUID}
            >
              <K2Tab
                controlUID={page.MetaData.ControlUID}
                vrUID={props.control.getRealizerUID()}
                className={`${css.tc_responsive_button} ${props.currentPage === page.Ncl.PageUID ? css.tc_current_page : ""}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default K2ResponsiveTabControl;
