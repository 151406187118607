import Quagga, { QuaggaJSResultObject } from "@ericblade/quagga2";
import React, { useEffect } from "react";
import { TReaderCodeType } from "../../common/communication.base";
import { NclCodeReaderDialog } from "../../common/components.ncl";

function initDecoder(ct: TReaderCodeType) {
  let result: Array<string> = [];
  if ((ct & TReaderCodeType.rct2OF0) === TReaderCodeType.rct2OF0) {
    result.push("2of5_reader");
  }
  if ((ct & TReaderCodeType.rctCODABAR) === TReaderCodeType.rctCODABAR) {
    result.push("codabar_reader");
  }
  if ((ct & TReaderCodeType.rctCODE128) === TReaderCodeType.rctCODE128) {
    result.push("code_128_reader");
  }
  if ((ct & TReaderCodeType.rctCODE32) === TReaderCodeType.rctCODE32) {
    result.push("code_32_reader");
  }
  if ((ct & TReaderCodeType.rctCODE39) === TReaderCodeType.rctCODE39) {
    result.push("code_39_reader");
  }
  if ((ct & TReaderCodeType.rctCODE39VIN) === TReaderCodeType.rctCODE39VIN) {
    result.push("code_39_vin_reader");
  }
  if ((ct & TReaderCodeType.rctCODE93) === TReaderCodeType.rctCODE93) {
    result.push("code_93_reader");
  }
  if ((ct & TReaderCodeType.rctEAN) === TReaderCodeType.rctEAN) {
    result.push("ean_reader");
  }
  if ((ct & TReaderCodeType.rctEAN8) === TReaderCodeType.rctEAN8) {
    result.push("ean_8_reader");
  }
  if ((ct & TReaderCodeType.rctI2OF5) === TReaderCodeType.rctI2OF5) {
    result.push("i2of5_reader");
  }
  if ((ct & TReaderCodeType.rctUPC_A) === TReaderCodeType.rctUPC_A) {
    result.push("upc_reader");
  }
  if ((ct & TReaderCodeType.rctUPC_E) === TReaderCodeType.rctUPC_E) {
    result.push("upc_e_reader");
  }
  if (result.length > 0) {
    Quagga.setReaders(result);
  } else {
    alert(`Unknown reader type: ${ct}`);
  }
}

const K2BarCodeReader = (props: { control: NclCodeReaderDialog }) => {
  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          constraints: {
            width: 900,
            height: 1920,
          },
          area: {
            // defines rectangle of the detection/localization area
            top: "0%", // top offset
            right: "0%", // right offset
            left: "0%", // left offset
            bottom: "0%", // bottom offset
          },
        },
        locator: {
          halfSample: true,
          patchSize: "medium",
        },
        decoder: {
          debug: {
            drawBoundingBox: false,
            showFrequency: false,
            drawScanline: false,
            showPattern: false,
          },
          multiple: false,
        },
      },
      (err) => {
        if (err) {
          console.log(err, "error msg");
        }
        initDecoder(props.control.Ncl.FrgtData.CodeTypes);
        Quagga.start();
        return () => {
          Quagga.stop();
        };
      }
    );

    Quagga.onProcessed(processed);
    Quagga.onDetected(detected);

    return () => {
      Quagga.offDetected(detected);
      Quagga.offProcessed(processed);
      Quagga.stop();
    };
  }, []);

  const getSize = (drawingCanvas: HTMLCanvasElement, qualifiedName: string) => {
    const attr = drawingCanvas.getAttribute(qualifiedName);
    let result = 0;

    if (attr != null) {
      result = parseInt(attr);
    }

    return result;
  };

  const processed = (result: QuaggaJSResultObject) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, getSize(drawingCanvas, "width"), getSize(drawingCanvas, "height"));
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(
          result.box,
          {
            x: 0,
            y: 1,
          },
          drawingCtx,
          {
            color: "#00F",
            lineWidth: 2,
          }
        );
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(
          result.line,
          {
            x: "x",
            y: "y",
          },
          drawingCtx,
          {
            color: "red",
            lineWidth: 3,
          }
        );
      }
    }
  };

  const detected = (data: QuaggaJSResultObject) => {
    if (data && data.codeResult && data.codeResult.code) {
      Quagga.offDetected(detected);
      Quagga.stop();
      props.control.setResult(data.codeResult.code);
    }
  };

  return <div id="interactive" className="viewport" style={{ position: "relative" }} />;
};

export default K2BarCodeReader;
