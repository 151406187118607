import { List } from "immutable";
import React from "react";
import { FileItem } from "../../common/communication.base";
import { VisualContext } from "../../common/visualContext";
import K2FileListItem from "./K2FileListItem";
import css from "./OpenDialog.scss";

interface FileListProps {
  items: List<FileItem>;
  allowMultipleSelect: boolean;
  vcx: VisualContext;
  handleRemoveFile(index: number): void;
}

const K2FileList = (props: FileListProps) => {
  const handleRemoveFile = (index: number) => {
    props.handleRemoveFile(index);
  };

  const handleRemoveAllFiles = () => {
    props.handleRemoveFile(0);
  };

  let suffix: string = "ý";
  let suffix2: string = "";

  if (props.allowMultipleSelect) {
    suffix = "é";
    suffix2 = "y";
  }

  return (
    <div className={css.od_file_list}>
      <div className={css.od_file_list_header}>
        Vybran{suffix} soubor{suffix2} - {props.items.count()}
        {props.items.count() > 1 && (
          <button onClick={handleRemoveAllFiles} className={css.od_file_list_button}>
            Smazat vše
          </button>
        )}
      </div>
      {props.items.size > 0 && (
        <div className={css.od_file_list_items}>
          {props.items.map((item, index) => (
            <K2FileListItem item={item} handleRemoveFile={handleRemoveFile} vcx={props.vcx} key={"FileListItem_" + index}></K2FileListItem>
          ))}
        </div>
      )}
    </div>
  );
};

export default K2FileList;
