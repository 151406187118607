import React from "react";
import { ApplicationBase } from "../app";
import ReactDOM from "react-dom";
import LoginView from "./views/LoginView";
import { NclDockControl } from "../common/components.ncl";
import { K2PageHolder } from "./PageHolder/K2PageHolder";
import {
  TClientFileActionType,
  CSClientData,
  cClientUrlType,
  CSClientUrl,
  cClienSoundType,
  cClientFileType,
  CSClientSound,
  CSClientFile,
} from "../common/communication.base";
import { MediaManager, MediaData } from "../common/mediaManager";
import { Context } from "../appcontext";
import { ViewRealizerManager } from "../viewrealizer";
import K2ErrorBoundary from "./K2ErrorBoundary";
import { Log, repairUrl } from "../common/common";
import { UpdateContext, VCXContext } from "../context";
import { showClientDialog } from "./ClientDialog/K2ClientDialog";
import { withVCXInCSS, WithVCXinCSSProps } from "./VCX/VCXHelper";

interface AppProps extends WithVCXinCSSProps {
  container: HTMLElement;
}

interface AppState {
  renderVersion?: number;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {};
  }

  update = () => {
    this.setState({ renderVersion: Date.now() }); // po expand/collapse expanderu je nutne provest re-render kvuli zmene vysek
  };

  componentDidMount(): void {
    if (this.props.onVCXChanged) this.props.onVCXChanged(Context.getApplication()?.appViewRealizer?.VCX, this.props.container);
  }

  render() {
    return (
      <UpdateContext.Provider value={{ update: this.update, renderVersion: this.state.renderVersion }}>
        <K2PageHolder controlUID={this.props.controlUID} vrUID={this.props.vrUID} />
      </UpdateContext.Provider>
    );
  }
}

const App = withVCXInCSS(_App);

export class K2App extends ApplicationBase {
  static readonly mainContentId = "maincontent";
  private dockControl: NclDockControl;
  private player: AudioPlayer;

  protected internalRender(): Promise<void> {
    super.internalRender();
    return new Promise((resolve, reject) => {
      let container = document.getElementById(K2App.mainContentId);
      if (container) {
        this.player = new AudioPlayer();
        this.dockControl = new NclDockControl(this.appViewRealizer.getDock(), null, null, null, this.appViewRealizer.getRealizerUID());

        ReactDOM.render(
          <>
            <VCXContext.Provider value={{ vcx: this.appViewRealizer.VCX }}>
              <K2ErrorBoundary>
                <App controlUID={this.dockControl.MetaData.ControlUID} vrUID={this.appViewRealizer.getRealizerUID()} container={container} />
              </K2ErrorBoundary>
            </VCXContext.Provider>
          </>,
          container,
          async () => {
            await this.dockControl.dockViewRealizer(this.appViewRealizer.getRealizerUID());
            resolve();
          }
        );
      } else {
        reject();
      }
    });
  }

  public getMainDockControl(): NclDockControl {
    return this.dockControl;
  }

  protected internalClear() {
    this.player = null;
    this.dockControl = null;
    ReactDOM.render(null, document.getElementById(K2App.mainContentId));
  }

  protected processClientData(list: Array<CSClientData>) {
    if (list && list.length > 0) {
      let data: CSClientData;
      for (let i = 0; i < list.length; i++) {
        data = list[i];
        if (data.__type === cClientUrlType) {
          this.openUrl((data as CSClientUrl).Url);
        }
        if (data.__type === cClienSoundType) {
          this.play(data as CSClientSound);
        }
        if (data.__type === cClientFileType) {
          this.processFileData(data as CSClientFile);
        }
      }
    }
  }

  private processFileData(data: CSClientFile) {
    if (data.ErrorMessage) {
      if (data.Action != TClientFileActionType.catUnknown) {
        alert(data.ErrorMessage);
      } else {
        MediaManager.setErrorMessge(data.FileName, data.ErrorMessage, data.UseCache);
      }
      return;
    }
    if (!data.Url) alert(`Url for file ${data.FileName} is empty.`);

    if (data.Action === TClientFileActionType.catUnknown) {
      MediaManager.download(data.Url, data.FileName); // unknown action only downloaded data from server and hold it for future used.
    } else {
      if (data.UseCache) {
        MediaManager.get(data.Url, data.FileName).then((result) => {
          this.processFile(result, data.Action);
        });
      } else {
        this.processFile(data, data.Action);
      }
    }
  }

  private internalPrint(data: CSClientFile | MediaData) {
    let printerElement = "___PRINTER__FRAME___";
    var iframe = document.getElementById(printerElement) as HTMLIFrameElement;
    let exist = true;
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.id = printerElement;
      exist = false;
    }

    iframe.style.display = "none";

    if (data instanceof MediaData) {
      iframe.src = URL.createObjectURL(data.blob);
    } else if (data.Url) {
      iframe.src = repairUrl(data.Url);
    } else {
      throw Error("Unknown data type for open");
    }

    if (exist == false) document.body.appendChild(iframe);
    iframe.contentWindow.focus();
    setTimeout(() => {
      iframe.contentWindow.print(); // Print.
    }, 350);
  }

  private processFile(data: CSClientFile | MediaData, type: TClientFileActionType) {
    switch (type) {
      case TClientFileActionType.catPrint:
        this.internalPrint(data);
        break;
      case TClientFileActionType.catOpen:
        if (data instanceof MediaData) {
          var win = this.openWindow();
          win.document.write(
            '<iframe src="' +
              URL.createObjectURL(data.blob) +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
          );
        } else if (data.Url) {
          let url = data.Url;
          if (data.UrlPattern) {
            url = data.UrlPattern.replace(data.FileName, data.Url);
          }
          this.openUrl(url);
        } else {
          throw Error("Unknown data type for open");
        }
        break;
      case TClientFileActionType.catDownload:
        alert("Not implemented.");
        break;
      case TClientFileActionType.catDeviceConnector:
        if (!(data instanceof MediaData)) {
          Context.DeviceConnector.setFile(data);
        }
        break;
      default:
        break;
    }
  }

  private play(data: CSClientSound) {
    if (!data.Url) {
      Log.warn(`Can't play file, url for file ${data.FileName} is empty.`);
      return;
    }
    if (data.ErrorMessage) {
      if (data.UseCache) {
        MediaManager.setErrorMessge(data.FileName, data.ErrorMessage, data.UseCache);
      } else {
        alert(data.ErrorMessage);
      }
    }

    if (data.UseCache) {
      MediaManager.get(data.Url, data.FileName).then((result) => {
        this.player.play(result);
      });
    } else {
      this.player.play(data.Url);
    }
  }

  private openUrl(url: string) {
    if (url) {
      url = repairUrl(url);
      if (url.match(/^\w*:/i)) {
        if (!url.match(/^https?:\/\//i)) {
          if (Context.DeviceInfo.IsAndroidDevice) {
            if (new Date().getTime() - this.lastRequestStartTime > 4500) {
              // When time between user interact and open url is greather than 4,5s,  must open client window with button for fire url open
              showClientDialog("Akce je připravená k dokončení.", () => {
                this.openWindow(url, "__blank");
              });
              return;
            }
          }
        }
        this.openWindow(url, "__blank"); // For open intent or others activity protocol must use __blank
        return;
      } else if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }

      this.openWindow(url, "");
    }
  }

  public openWindow(url?: string, target?: string, features?: string): Window {
    let old = this.hideReloadAlert;
    this.hideReloadAlert = true;
    let win = window.open(url, target, features);
    this.hideReloadAlert = old;
    return win;
  }

  protected loginForm(): void {
    ReactDOM.render(
      <LoginView
        headerImg="img/login_header_logo.jpg"
        footerImg="img/logo_k2.svg"
        ver="K2 ori"
        ToSLink="https://www.k2.cz/"
        privacyPolicyLink="#"
        licenseHolder="K2 software s.r.o."
      />,
      document.getElementById(K2App.mainContentId)
    );
  }

  protected reconnectForm(reconnectFce: () => void): void {
    ReactDOM.render(
      <LoginView
        reconnect={{ reconnectFce: reconnectFce }}
        headerImg="img/login_header_logo.jpg"
        footerImg="img/logo_k2.svg"
        ver="K2 ori"
        ToSLink="https://www.k2.cz/"
        privacyPolicyLink="#"
        licenseHolder="K2 software s.r.o."
      />,
      document.getElementById(K2App.mainContentId)
    );
  }
}

class AudioPlayer {
  private element: HTMLAudioElement;
  private queue: Array<string | MediaData>;
  private isPlaying: boolean = false;
  private current: string | MediaData;

  public constructor() {
    this.element = new Audio();
    this.element.onended = this.handleEnded;
    this.queue = [];
  }

  private handleEnded = (e: Event) => {
    this.isPlaying = false;
    if (this.queue.length > 0) {
      this.internalPlay();
    }
  };

  play(data: string | MediaData) {
    this.queue.push(data);
    this.internalPlay();
  }

  internalPlay() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.current = this.queue.pop();
      if (this.current instanceof MediaData) {
        this.element.src = this.current.base64Data;
      } else {
        this.element.src = this.current.toString();
      }
      this.element.play();
    }
  }
}

export function setRequestedActiveControl(e: React.FocusEvent<any>, vrUID: string) {
  let vr = ViewRealizerManager.getViewRealizer(vrUID);
  if (vr) {
    if (e.relatedTarget && e.relatedTarget instanceof HTMLElement) {
      let id = e.relatedTarget.getAttribute("data-k2-test-id");
      if (!id || id === "") {
        id = e.relatedTarget.id;
      }
      let ctrl = vr.findControlByName(e.relatedTarget.id);
      if (ctrl) {
        vr.RequestActiveControlUID = ctrl.MetaData.ControlUID;
      }
    }
  }
}
