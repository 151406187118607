import * as React from "react";
import { ElementHtmlAttributes, getAttributes } from "../../common/common";
import { Align, DataActionDecorate, IconPosition, UpdateCommandItem, UpdateControl } from "../../common/communication.base";
import { ClientNclButton, NclButton, NclImage, NclVirtualKeyboardDialog } from "../../common/components.ncl";
import { DecorateResult } from "../../common/visualContext";
import { withVCXInCSS, WithVCXinCSSProps, writePropertyToCSS } from "../VCX/VCXHelper";
import { K2ComponentState, WithContextPlacementProps, StyleHelper, AcquireControl } from "./../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "./../Text/K2TruncateText";
import css from "./Button.scss";
import ReactResizeDetector from "react-resize-detector";
import SubMenuIndicator from "../SubMenuIndicator/K2SubMenuIndicator";

export type BeforeExecuteProc = (control: NclButton) => void;

interface K2ButtonProps extends WithVCXinCSSProps {
  beforeExecute?: BeforeExecuteProc;
  id?: string;
  fillSvg?: boolean; //only for client button in virtual keyboard
}

interface K2ButtonState extends K2ComponentState<UpdateCommandItem> {
  accessKey: string;
}

class _Button extends React.PureComponent<K2ButtonProps, K2ButtonState> {
  static displayName = "K2Button";
  private control: NclButton;
  private element: HTMLDivElement;
  private focusedByTabKey = false;

  constructor(props: K2ButtonProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => ctrl instanceof NclButton) as NclButton;
    this.state = { data: this.control.init(this) as UpdateCommandItem, vcxVersion: -1, accessKey: null };
  }

  setAsActive(isActive: boolean) {
    if (this.element && isActive) {
      this.element.focus({ preventScroll: true });
    }
  }

  updateState(state: UpdateControl) {
    this.setState(() => {
      return { data: state as UpdateCommandItem };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentDidMount(): void {
    if (this.element && this.props.onVCXChanged && this.control.VCX != this.control.Parent?.VCX) {
      this.props.onVCXChanged(this.control.VCX, this.element);
    }
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidUpdate(prevProps: Readonly<K2ButtonProps>, prevState: Readonly<K2ComponentState<UpdateCommandItem>>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  private getContentCss(): string {
    let result: string = css.btn_content;

    if (this.control.MetaData.Bounds.Align === Align.Left || this.control.MetaData.Bounds.Align === Align.Right) result += " " + css.btn_padding;

    if (this.control.Ncl.FrgtData.FillWidth) result += " " + css.btn_content_fill;

    if (!this.control.State.Enabled) result += " " + css.btn_content_disabled;

    return result;
  }

  render() {
    const addAttributes: ElementHtmlAttributes = getAttributes(this.state.data);

    let finId: string = this.control.MetaData.Name;
    if (this.props.id != "" && this.props.id != undefined) {
      finId = this.props.id + finId;
    }

    const actDec = this.getActualDecorate();

    let btnBaseCss = css.btn_base;
    if (this.control.Parent instanceof NclVirtualKeyboardDialog) btnBaseCss += " " + css.btn_VK_padding;
    return (
      <div style={StyleHelper(this.control, { ...this.props.style })}>
        <div className={btnBaseCss}>
          <div
            tabIndex={this.state.data.TabStop ? 0 : -1}
            data-k2-test-id={finId}
            ref={(ref) => (this.element = ref)}
            className={`button ${this.getContentCss()}`}
            onClick={this.handleClick}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            accessKey={this.state.accessKey}
            {...addAttributes}
          >
            {this.getContent(actDec)}
            {this.state.data.SubMenuIndicatorVisible && <SubMenuIndicator strokeColor={actDec.foregroundColor} />}
          </div>
        </div>
      </div>
    );
  }

  private getActualDecorate(): DecorateResult {
    let result: DecorateResult;
    if (this.state.data.Enabled === true) {
      result = this.control.VCX.ColorMap.getColorsForActionDecorate(
        this.state.data.Decorate ? this.state.data.Decorate : this.control.Ncl.FrgtData.Decorate,
        this.state.data.Enabled
      );

      if (this.control.Ncl.BackgroundColorCalc >= 0) {
        result.backColor = this.control.VCX.getColor(this.control.Ncl.BackgroundColorCalc);
      }

      if (this.control.Ncl.ForegroundColorCalc >= 0) {
        result.foregroundColor = this.control.VCX.getColor(this.control.Ncl.ForegroundColorCalc);
      }
    } else {
      result = this.control.VCX.ColorMap.getColorsForActionDecorate(DataActionDecorate.dadeContentSuppress, this.state.data.Enabled);
    }

    if (this.element) {
      writePropertyToCSS(result.backColor, "btn_background_color", this.element, "");
      writePropertyToCSS(result.foregroundColor, "btn_color", this.element, "");
      if (result.bold == true) writePropertyToCSS("bold", "btn_style", this.element, "");
    }
    return result;
  }

  setAccessKey = (key: string) => {
    this.setState({ accessKey: key });
  };

  private getContent(actDec: DecorateResult): JSX.Element {
    if (!this.state.data.GlyphId || !this.control.Ncl.FrgtData.ShowIcon) {
      return this.control.Ncl.FrgtData.ShowCaption ? (
        <div className={css.btn_caption_center} key={this.control.Ncl.ControlUID + "_txt"}>
          <K2TruncateText className={css.btn_label_font} setAccessKey={this.setAccessKey}>
            {this.state.data.Title}
          </K2TruncateText>
        </div>
      ) : null;
    } else {
      if (!this.control.Ncl.FrgtData.ShowCaption) {
        return (
          <K2Img
            glyphId={this.state.data.GlyphId}
            //backgroundColor={actDec.backColor}
            strokeColor={actDec.foregroundColor}
            vcx={this.control.VCX}
            nonSvgHeight={64}
          />
        );
      }

      let cssCaption = css.btn_caption_center;
      if (this.control.MetaData.Bounds.Align === Align.Left || this.control.MetaData.Bounds.Align === Align.Right) {
        cssCaption += " " + css.btn_caption_LorR;
      }

      switch (this.control.Ncl.FrgtData.IconPosition) {
        case IconPosition.ipBottom:
          return (
            <div className={css.btn_icon_top}>
              <div className={css.btn_caption_stretch}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={this.setAccessKey}>
                  {this.state.data.Title}
                </K2TruncateText>
              </div>
              <K2Img
                glyphId={this.state.data.GlyphId}
                backgroundColor={actDec.backColor}
                strokeColor={actDec.foregroundColor}
                vcx={this.control.VCX}
                nonSvgHeight={64}
              />
            </div>
          );
        case IconPosition.ipTop:
          return (
            <div className={css.btn_icon_top}>
              <K2Img
                glyphId={this.state.data.GlyphId}
                backgroundColor={actDec.backColor}
                strokeColor={actDec.foregroundColor}
                vcx={this.control.VCX}
                nonSvgHeight={64}
              />
              <div className={css.btn_caption_stretch}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={this.setAccessKey}>
                  {this.state.data.Title}
                </K2TruncateText>
              </div>
            </div>
          );
        case IconPosition.ipLeft:
          return (
            <>
              <ReactResizeDetector handleHeight>
                {({ width, height }: { width: number; height: number }) => {
                  return (
                    <K2Img
                      glyphId={this.state.data.GlyphId}
                      backgroundColor={actDec.backColor}
                      strokeColor={actDec.foregroundColor}
                      vcx={this.control.VCX}
                      width={height}
                      nonSvgHeight={64}
                    />
                  );
                }}
              </ReactResizeDetector>
              <div className={cssCaption}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={this.setAccessKey}>
                  {this.state.data.Title}
                </K2TruncateText>
              </div>
            </>
          );
        case IconPosition.ipRight:
          return (
            <>
              <div className={cssCaption}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={this.setAccessKey}>
                  {this.state.data.Title}
                </K2TruncateText>
              </div>
              <ReactResizeDetector handleHeight>
                {({ width, height }: { width: number; height: number }) => {
                  return (
                    <K2Img
                      glyphId={this.state.data.GlyphId}
                      backgroundColor={actDec.backColor}
                      strokeColor={actDec.foregroundColor}
                      vcx={this.control.VCX}
                      width={height}
                      nonSvgHeight={64}
                    />
                  );
                }}
              </ReactResizeDetector>
            </>
          );
        case IconPosition.ipCenter:
          return (
            <>
              <K2Img
                glyphId={this.state.data.GlyphId}
                strokeColor={actDec.foregroundColor}
                vcx={this.control.VCX}
                style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "100%", height: "100%" }}
                nonSvgHeight={64}
              />
              <div className={css.btn_caption_center}>
                <K2TruncateText className={css.btn_label_font} setAccessKey={this.setAccessKey}>
                  {this.state.data.Title}
                </K2TruncateText>
              </div>
            </>
          );
      }
    }
  }

  private execute() {
    if (this.props.beforeExecute) {
      this.props.beforeExecute.call(this, this.control);
    }

    this.control.executeCommand(null);
  }

  private handleClick = () => {
    if (this.control.Ncl.FrgtData.TabStop && this.focusedByTabKey) {
      this.focusedByTabKey = false;
      this.control.setActiveControlRequested();
    }

    this.execute();
  };

  handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      this.focusedByTabKey = true;
    }
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      if (this.control instanceof ClientNclButton) return;

      this.execute();

      e.stopPropagation();
      e.preventDefault();
    } else if (e.key === "ArrowRight") {
      (this.element.parentElement.parentElement.nextElementSibling?.firstElementChild?.firstElementChild as HTMLDivElement)?.focus();
    } else if (e.key === "ArrowLeft") {
      (this.element.parentElement.parentElement.previousElementSibling?.firstElementChild?.firstElementChild as HTMLDivElement)?.focus();
    }
  };
}

export const K2Button = withVCXInCSS(_Button);
