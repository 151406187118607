import React from "react";
import { Context } from "../../appcontext";
import { getAttributes, setCustomAttributes } from "../../common/common";
import { UpdateInput, TNclInputType, TInputMultiLineMode, cJSonFunctionExecuteShortcut } from "../../common/communication.base";
import { NclInput, EditCheckValueResult, NclLocatorPanel, NclQuickFilter } from "../../common/components.ncl";
import { AcquireControl, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import css from "./Input.scss";
import { InnerInputProps, InnerInputState, InnerInputStateBase, InputHtmlAttributes, InputProps } from "./utils";

interface InnerSimpleInputProps extends InnerInputProps, InputProps {
  showContextMenu?: () => void;
}

interface InnerInputBaseState {
  Value?: string;
  Position?: number;
}

interface InnerSimpleInputState extends InnerInputStateBase {}

export class K2InnerSimpleInput extends React.PureComponent<InnerSimpleInputProps, InnerSimpleInputState> {
  static displayName = `InnerSimpleInput`;
  private element: HTMLInputElement | HTMLTextAreaElement;
  private control: NclInput;
  private contextMenuTimer: any;
  private possibleShowContextMenu: boolean = false;
  private disableFocus: boolean; // zakazuje automaticky focus na input po zavolani metody setSelectionRange, stava se jen na iOS

  constructor(props: InnerSimpleInputProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInput;
    }) as NclInput;

    this.state = { data: this.control.State as UpdateInput, Value: this.control.State.Text, vcxVersion: -1 };

    if (this.control.isDateOrTime() && this.control.ToolBar) {
      this.control.ToolBar.removeAction("_ExecuteLookupCOMMAND");
    }
  }

  updateState(state?: UpdateInput, valueState?: InnerInputBaseState) {
    this.setState((prevstate: InnerInputState) => {
      let newState = {};
      if (state) {
        newState = { data: state };
      }

      if (valueState) {
        newState = Object.assign({}, newState, valueState);
      }
      return newState;
    });
  }

  getOverRect(): DOMRect {
    if (this.element) {
      return this.element.getBoundingClientRect();
    }

    return null;
  }

  public get selectionStart(): number {
    return this.element ? this.element.selectionStart : -1;
  }

  public set selectionStart(value: number) {
    if (this.element) {
      this.element.selectionStart = value;
    }
  }

  public get selectionEnd(): number {
    return this.element ? this.element.selectionEnd : -1;
  }

  public set selectionEnd(value: number) {
    if (this.element) {
      this.element.selectionEnd = value;
    }
  }

  public get Value(): string {
    return this.state.Value;
  }

  focus() {
    if (!this.element) return;

    if (Context.DeviceInfo.ShowKeyboardOnFocus === "1") {
      this.element.focus();
    } else {
      this.element.readOnly = true;
      this.element.focus();

      if (Context.DeviceInfo.BrowserInfo !== "Firefox") {
        this.element.readOnly = false;
      }
    }
  }

  blur() {
    if (this.element) {
      this.element.blur();
    }
  }

  private getInputType(): string {
    if (this.isSearchControl()) return "search";

    switch (this.control.State.InputType) {
      case TNclInputType.nitDateTime:
        return "datetime-local"; // datetime je deprecated
      case TNclInputType.nitDate:
        return "date";
      case TNclInputType.nitTime:
        return "time";
      case TNclInputType.nitNumeric:
        return "number";
      default:
        break;
    }

    return "text";
  }

  private getInputMode(): "numeric" | "decimal" | "text" {
    if (this.control.State.InputType === TNclInputType.nitNumeric) {
      if (this.control.getDecimalsCountByFormat() > 0) {
        return "decimal";
      } else {
        return "numeric";
      }
    }

    return null; // Pokud se vrati typ inputmode = 'text', tak Enter na SW klavesnici se chova jako novy radek a ne jako potvrzeni, a neodesle se tak pozadavek na server; napr. odeslani hodnoty u sloupcoveho filtru.
  }

  private getValue(value: string) {
    if (value == undefined) return "";

    if (this.control.State.InputType === TNclInputType.nitTime) {
      return this.control.getTimeByFormat(this.state.data.Format, value);
    } else {
      return value;
    }
  }

  private getStep(): string {
    if (this.control.State.InputType == TNclInputType.nitNumeric) {
      return (1 / Math.pow(10, 4)).toString();
    }

    return "1";
  }

  componentDidUpdate(prevProps: WithContextPlacementProps, prevState: InnerInputStateBase) {
    if (this.element && this.state.Position != prevState.Position && this.state.Position >= 0) {
      setTimeout(() => {
        this.disableFocus = true;
        this.element.setSelectionRange(this.state.Position, this.state.Position);
        this.disableFocus = false;
      }, 10);
    }
  }

  private refCallBack = (ref: HTMLInputElement | HTMLTextAreaElement) => {
    if (ref) {
      setCustomAttributes(ref, this.control.Ncl.Attributes);
      this.element = ref;
      if ((Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS) && Context.DeviceInfo.IsTouchDevice && this.element instanceof HTMLInputElement) {
        this.element.defaultValue = "";
      }
    }
  };

  componentDidMount() {
    if (this.props.blockSoftKeyboard) {
      this.element.focus();
    }
  }

  render(): JSX.Element {
    // iOS/iPadOS bug - https://github.com/facebook/react/issues/8938
    if (
      (Context.DeviceInfo.IsIOS || Context.DeviceInfo.IsIPadOS) &&
      Context.DeviceInfo.IsTouchDevice &&
      this.element instanceof HTMLInputElement &&
      this.control.isDateOrTime()
    ) {
      const target = this.element;
      setTimeout(() => {
        target.defaultValue = "";
      }, 100);
    }
    let value = this.getValue(this.state.Value);
    let inputAttributes: InputHtmlAttributes = {
      type: this.getInputType(),
      id: this.control.Ncl.Name,
      title: this.state.data.Hint + ": " + value,
      ...getAttributes(this.state.data),
    };

    if (this.isSearchControl()) {
      inputAttributes.tabIndex = -1;
    }
    let inputClass: string = css.in_input + " " + this.props.className;

    inputAttributes.autoComplete = "off";
    inputAttributes.placeholder = this.props.InputTagPlaceholder;
    if (this.state.data.MaxLength) {
      inputAttributes.maxLength = this.state.data.MaxLength;
    }

    if (this.control.Ncl.FrgtData.IsPassword) {
      inputAttributes.type = this.props.revealPassword ? "text" : "password";
      inputAttributes.title = undefined;
    }
    if (this.control.State.InputType === TNclInputType.nitString && this.state.data.MultiLineMode) {
      return (
        <textarea
          className={inputClass}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onContextMenu={this.handleContextMenu}
          ref={this.refCallBack}
          {...inputAttributes}
          value={value}
          rows={this.state.data.MultiLineMode == TInputMultiLineMode.imlmMultiLineSimple ? this.control.Ncl.FrgtData.Size : 0}
          onKeyDown={this.handleKeyDown}
        />
      );
    } else {
      return (
        <>
          <input
            className={inputClass}
            ref={this.refCallBack}
            inputMode={this.getInputMode()}
            step={this.getStep()}
            value={value}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onPaste={this.props.onPaste}
            onTouchEnd={this.handleTouchEnd}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            //onTouchCancel={this.handleTouchCancel}
            onContextMenu={this.handleContextMenu}
            onKeyDown={this.handleKeyDown}
            {...inputAttributes}
          />
        </>
      );
    }
  }
  private isSearchControl(): boolean {
    return this.control.Parent instanceof NclQuickFilter || this.control.Parent instanceof NclLocatorPanel;
  }

  private handleKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.key === "Delete" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "z" ||
      e.key === "y" ||
      e.key === "-" ||
      e.keyCode === 110 || // numpad decimal
      e.key.match(/^[0-9]$/) ||
      e.key === "Backspace"
    ) {
      e.stopPropagation();
    }
  };

  private handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.change) {
      let value = e.target.value;
      switch (this.control.State.InputType) {
        case TNclInputType.nitNumeric:
          let accept = this.control.checkInput(this.state.Value, value, { start: 0, end: 0 }, true);
          if (!value || (accept && accept.Result === EditCheckValueResult.newValue)) {
            if (this.state.Value != value) {
              this.props.change(value);
              this.setState({ Value: value });
            }
          }

          break;
        case TNclInputType.nitDateTime:
        case TNclInputType.nitDate:
        case TNclInputType.nitTime:
          if (this.state.Value != value && (value !== "" || (value === "" && !this.control.isEmpty()))) {
            this.props.change(value);
            this.control.accept();
          }
          break;
        case TNclInputType.nitString:
          if (this.state.Value != value) {
            this.props.change(value);
            this.setState({ Value: value });

            // Pokud je input typu MultiLineMode, vykresluje se jako <textarea>.
            // Na mobilech nelze poznat klavesu Enter, takze akci vyvolavam, pokud hodnota konci zalomenim radku.
            if (!this.isSingleLine() && this.endsWithEnter(value)) {
              this.control.appendFunction({ Name: cJSonFunctionExecuteShortcut, Args: ["ENTER"] }, true);
            }
          }
          break;
      }
    }
  };

  private isSingleLine = () => {
    if (this.state.data.MultiLineMode === TInputMultiLineMode.imlmSingleLine) {
      return true;
    }

    return false;
  };

  private endsWithEnter = (value: string) => {
    if (value.match(/\n$/)) {
      return true;
    }

    return false;
  };

  private handleTouchStart = (e: React.TouchEvent) => {
    if (Context.DeviceInfo.BrowserInfo === "Firefox") {
      this.element.readOnly = false;
    }

    this.possibleShowContextMenu = true;
    this.contextMenuTimer = setTimeout(() => {
      if (this.possibleShowContextMenu) {
        this.possibleShowContextMenu = false;
        if (this.props.showContextMenu) {
          this.props.showContextMenu();
        }
      }
    }, 610);
  };

  private handleTouchEnd = (e: React.TouchEvent) => {
    if (this.possibleShowContextMenu === false) {
      e.preventDefault();
    }
    this.possibleShowContextMenu = false;
    clearTimeout(this.contextMenuTimer);
  };

  private handleTouchMove = (e: React.TouchEvent) => {
    this.possibleShowContextMenu = false;
    clearTimeout(this.contextMenuTimer);
  };

  private handleTouchCancel = (e: React.TouchEvent) => {
    this.possibleShowContextMenu = false;
    clearTimeout(this.contextMenuTimer);
  };

  private handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); //on android show context menu with copy/paste/...
  };

  private handleFocus = (e: React.FocusEvent<any>) => {
    if (this.disableFocus) return;

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    if (!(this.control.Parent instanceof NclLocatorPanel)) {
      this.element.select();
    }

    if (this.props.blockSoftKeyboard === true) {
      this.element.readOnly = true;
      setTimeout(() => {
        this.element.inputMode = this.getInputMode();
        this.element.readOnly = false;
      }, 0);
    }
  };

  private handleBlur = (e: React.FocusEvent<any>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    this.control.accept();
  };
}
