import React from "react";
import { useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { SignData, UpdateSignInput } from "../../common/communication.base";
import { NclSignInput } from "../../common/components.ncl";
import { setRequestedActiveControl } from "../app";
import { useServerState } from "../hooks";
import K2Img from "../Image/K2Img";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./Signature.scss";

const K2SignInput = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclSignInput, UpdateSignInput, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclSignInput
  );
  const [clearButtonSwitch, setClearButtonSwitch] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ro = useRef<ResizeObserver>();
  const isPainting = useRef(false);
  const signData = useRef<SignData>();
  const prevPos = useRef({ offsetX: 0, offsetY: 0 });
  const prevWidth = useRef(1);
  const canvas = useRef<HTMLCanvasElement>();
  const ctx = useRef<CanvasRenderingContext2D>();
  const dotSwitch = useRef(false);
  const rect = useRef<DOMRect>();

  useEffect(() => {
    ro.current = new ResizeObserver((entries: ResizeObserverEntry[], observer: ResizeObserver) => {
      if ((entries[0].contentRect.width / 3) * 2 > entries[0].contentRect.height) {
        setHeight(entries[0].contentRect.height);
        setWidth(entries[0].contentRect.width);
      } else {
        setHeight((entries[0].contentRect.width / 3) * 2);
        setWidth(entries[0].contentRect.width);
      }

      loadImgToCanvas();
    });

    ro.current.observe(element.current);
  }, []);

  useEffect(() => {
    if (data.SignData && ctx.current && data.Visible) {
      loadImgToCanvas();
    }
  }, [data.SignData, data.Visible]);

  const refCallback = (canvass: HTMLCanvasElement) => {
    if (canvass) {
      canvas.current = canvass;
      ctx.current = canvass.getContext("2d");

      if (data.SignData && data.SignData !== "") {
        loadImgToCanvas();
      }
    }
  };

  const handleTouchStart = (touchStartEvent: React.TouchEvent<HTMLCanvasElement>) => {
    touchStartEvent.preventDefault();
    rect.current = element.current.getBoundingClientRect();
    const offsetX = touchStartEvent.touches[0].clientX - rect.current.left;
    const offsetY = touchStartEvent.touches[0].clientY - rect.current.top;
    isPainting.current = true;
    prevPos.current = { offsetX, offsetY };
    prevWidth.current = 1;
    dotSwitch.current = true;
  };

  const handleTouchMove = (touchMoveEvent: React.TouchEvent<HTMLCanvasElement>) => {
    touchMoveEvent.preventDefault();

    if (isPainting.current) {
      dotSwitch.current = false;
      const offsetX = touchMoveEvent.touches[0].clientX - rect.current.left;
      const offsetY = touchMoveEvent.touches[0].clientY - rect.current.top;
      initPaint(offsetX, offsetY);
    }
  };

  const handleTouchEnd = (touchEndEvent: React.TouchEvent<HTMLCanvasElement>) => {
    touchEndEvent.preventDefault();

    if (isPainting.current) {
      stopPaiting();

      if (dotSwitch.current) {
        const offsetX = touchEndEvent.changedTouches[0].clientX - rect.current.left;
        const offsetY = touchEndEvent.changedTouches[0].clientY - rect.current.top;
        initPaint(offsetX, offsetY);
      }
    }
  };

  const onMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    rect.current = element.current.getBoundingClientRect();
    const { offsetX, offsetY } = e.nativeEvent;
    isPainting.current = true;
    prevPos.current = { offsetX, offsetY };
    prevWidth.current = 1;
    dotSwitch.current = true;
    document.addEventListener("mouseup", stopPaiting); // Při opuštění canvasu při stisknuté myši a následném uvolnění myši mimo canvas je zastaveno vykreslování stopy.
  };

  const onMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (isPainting.current) {
      dotSwitch.current = false;
      const { offsetX, offsetY } = e.nativeEvent;
      initPaint(offsetX, offsetY);
    }
  };

  const onMouseUp = (e: React.MouseEvent) => {
    if (isPainting.current) {
      stopPaiting();

      if (dotSwitch.current) {
        const { offsetX, offsetY } = e.nativeEvent;
        initPaint(offsetX, offsetY);
      }
    }

    document.removeEventListener("mouseup", stopPaiting);
  };

  const handleBlur = (e: React.FocusEvent) => {
    setRequestedActiveControl(e, control.getRealizerUID());
    control.change(canvas.current.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ""), true);
  };

  const stopPaiting = () => {
    isPainting.current = false;
    control.change(canvas.current.toDataURL().replace(/^data:image\/(png|jpg);base64,/, ""), false);
    signData.current = canvas.current.toDataURL().replace(/^data:image\/(png|jpg);base64,/, "");
  };

  const initPaint = (offsetX: number, offsetY: number) => {
    const offSetData = { offsetX, offsetY };
    paint(prevPos.current, offSetData);
  };

  const paint = (prevPoss: { offsetX: number; offsetY: number }, currPos: { offsetX: number; offsetY: number }) => {
    setClearButtonSwitch(true);
    const { offsetX, offsetY } = currPos;
    const { offsetX: x, offsetY: y } = prevPoss;

    if (x === offsetX && y === offsetY) {
      //tečka
      ctx.current.beginPath();
      ctx.current.arc(x, y, Math.round(width / 300), 0, 2 * Math.PI, true);
      ctx.current.fillStyle = control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1);
      ctx.current.fill();
    } else {
      //čára
      let lineWidth: number;
      const a = Math.abs(x - offsetX);
      const b = Math.abs(y - offsetY);
      const pathLength = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

      lineWidth = 4 / (pathLength / 5) > prevWidth.current ? prevWidth.current + 0.3 : prevWidth.current - 0.3; //Výpočet šířky tahu dle vzdálenosti posledních bodů cesty

      lineWidth = Math.min(lineWidth, 5);
      lineWidth = Math.max(lineWidth, 2);
      prevWidth.current = lineWidth;

      lineWidth = Math.round(lineWidth * (width / 300));

      ctx.current.lineWidth = lineWidth;
      ctx.current.lineJoin = "round";
      ctx.current.lineCap = "round";

      ctx.current.beginPath();
      ctx.current.moveTo(x, y);
      ctx.current.lineTo(offsetX, offsetY);
      ctx.current.strokeStyle = control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1);
      ctx.current.stroke();
      prevPos.current = { offsetX, offsetY };
    }
  };

  const getSignLine = () => {
    if (height > 300 && width > 400) {
      return (
        <div className={css.sign_line} style={{ top: Math.round((height / 3) * 2.4) }}>
          <K2TruncateText>Zde se podepište</K2TruncateText>
        </div>
      );
    }
  };

  const getClearButton = () => {
    if (clearButtonSwitch) {
      return (
        <div className={css.sign_clear} onMouseDown={clearCanvas} style={{ top: rect.current.top + 10, left: width - 65 }}>
          <K2Img height={48} width={48} glyphId={"wui*clear"} vcx={control.VCX} strokeColor={control.VCX.getColor(control.VCX.Data.ColorMap.BaseColorBck1)} />
        </div>
      );
    } else return null;
  };

  const clearCanvas = () => {
    setClearButtonSwitch(false);
    control.change("", false);
    signData.current = "";
    ctx.current.clearRect(0, 0, width, height);
  };

  const loadImgToCanvas = () => {
    if (!signData.current) return;

    ctx.current.clearRect(0, 0, width, height);
    const image = new Image();
    image.onload = () => {
      ctx.current.drawImage(image, 0, 0, width - 4, height - 21);
    };
    image.src = "data:image/png;base64," + signData.current;
  };

  return (
    <div style={StyleHelper(control, props.style)} ref={element}>
      <div className={css.sign}>
        <canvas
          className={css.sign_canvas}
          tabIndex={0}
          onBlur={handleBlur}
          ref={refCallback}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          height={height - 10}
          width={width}
        />
        {getSignLine()}
        {getClearButton()}
      </div>
    </div>
  );
};

export default K2SignInput;
