import React from "react";
import { CSColumn, CSDataItem } from "../../common/communication.base";
import { K2Column } from "./K2Column";
import { DGHeaderProps, FixedColumnOffset } from "./utils";

interface RowProps extends DGHeaderProps {
  data: CSDataItem;
  fixedColumnsCount: number;
  rowHeightMultiplier: number;
  getFixedColumnOffset: FixedColumnOffset;
  rowNdx: number;
  rowHeight: number;
  execute: (commandNumber: number) => void;
}

interface RowState {
  isSelected: boolean;
  inEditMode: boolean;
}

export class K2Row extends React.Component<RowProps, RowState> {
  static displayName = `K2Row`;

  private columns: Map<number, K2Column>;
  private content: HTMLTableRowElement;

  constructor(props: RowProps) {
    super(props);
    this.state = { isSelected: false, inEditMode: false };
    this.columns = new Map<number, K2Column>();
  }

  getOverRect(columnNdx: number): DOMRect {
    if (columnNdx !== undefined) {
      if (this.columns && this.columns.has(columnNdx)) {
        let column = this.columns.get(columnNdx);
        if (column) return column.getOverRect();
      }
      return null;
    }
    return this.content ? this.content.getBoundingClientRect() : undefined;
  }

  setAsActive(isActive: boolean) {
    //if(this.firstColumn) this.firstColumn.setAsActive();
    if (this.content && isActive) {
      this.content.focus();
    }
  }

  updateInEditMode(value: boolean) {
    this.setState({ inEditMode: value });
  }

  scrollTo(arg: boolean) {
    if (this.content) this.content.scrollIntoView(arg);
  }

  updateSelected(selected: boolean, oldColumn: number, newColumn: number) {
    this.setState({ isSelected: selected });
    let column: K2Column;
    if (oldColumn >= 0) {
      column = this.columns.get(oldColumn);
      if (column) {
        column.updateSelected(false);
      }
    }
    if (selected) {
      if (newColumn >= 0) {
        this.columns.forEach((column) => column.updateSelected(false));
        column = this.columns.get(newColumn);
        if (column) {
          column.updateSelected(true);
        }
      }
    }
  }

  componentDidUpdate(prevProps: RowProps, prevState: RowState) {
    if (this.state.isSelected === true && this.props.data !== prevProps.data) {
      this.setState({ isSelected: false });
    }
  }

  render() {
    let columns: any = null;
    if (this.props.data && this.props.data.Columns) {
      columns = this.props.data.Columns.map((value: CSColumn, index: number) => {
        if (index < this.props.columnsProportion.length) {
          return (
            <K2Column
              {...this.props}
              canEdit={this.state.inEditMode === true ? (value.CanEdit ? true : false) : undefined}
              isSelectedRow={this.state.isSelected}
              fixed={index < this.props.fixedColumnsCount}
              columnNdx={index}
              ref={(ref) => {
                if (ref && ref instanceof K2Column) this.columns.set(index, ref);
              }}
              key={"column_" + index}
              value={value}
              columnProportion={this.props.columnsProportion[index]}
              vcx={this.props.vcx}
              columnOffset={this.props.getFixedColumnOffset(this.props.columnsWidths, index, index < this.props.fixedColumnsCount)}
              rowHeightMultiplier={this.props.rowHeightMultiplier}
              execute={this.props.execute}
            />
          );
        } else {
          return null;
        }
      });
    }
    return (
      <tr
        data-k2-test-id={this.props.data.Key}
        tabIndex={1}
        //   className={this.getClassName()}
        style={{ height: this.props.rowHeight + "px" }}
        ref={(ref) => {
          this.content = ref;
        }}
      >
        {columns}
      </tr>
    );
  }
} //getexterior
