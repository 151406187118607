import React from "react";
import { getAttributes, setCustomAttributes } from "../../common/common";
import { UpdateInput, TNclInputType, TInputMultiLineMode } from "../../common/communication.base";
import { NclInput, NclLocatorPanel } from "../../common/components.ncl";
import { setRequestedActiveControl } from "../app";
import { AcquireControl } from "../k2hoc";
import css from "./Input.scss";
import { InnerInputBaseState, InnerInputProps, InnerInputStateBase, InputHtmlAttributes } from "./utils";

interface InnerInputState extends InnerInputStateBase {}

export class K2InnerInput extends React.PureComponent<InnerInputProps, InnerInputState> {
  static displayName = `InnerInput`;
  private element: HTMLInputElement | HTMLTextAreaElement;
  private control: NclInput;

  constructor(props: InnerInputProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInput;
    }) as NclInput;

    this.state = { data: this.control.State as UpdateInput, Value: this.control.State.Text, vcxVersion: -1 };
  }

  public get selectionStart(): number {
    return this.element ? this.element.selectionStart : -1;
  }

  public set selectionStart(value: number) {
    if (this.element) this.element.selectionStart = value;
  }

  public get selectionEnd(): number {
    return this.element ? this.element.selectionEnd : -1;
  }

  public set selectionEnd(value: number) {
    if (this.element) this.element.selectionStart = value;
  }

  getOverRect(): DOMRect {
    if (this.element) {
      return this.element.getBoundingClientRect();
    }

    return null;
  }

  public get Value(): string {
    return this.state.Value;
  }

  updateState(state?: UpdateInput, valueState?: InnerInputBaseState, callback?: () => void) {
    this.setState((prevstate: InnerInputState) => {
      let newState = {};
      if (state) {
        newState = { data: state };
      }

      if (valueState) {
        newState = Object.assign({}, newState, valueState);
      }
      return newState;
    }, callback);
  }

  focus() {
    if (this.element) {
      this.element.focus();
    }
  }

  blur() {
    if (this.element) {
      this.element.blur();
    }
  }

  private needKeyStopPropagation = (e: React.KeyboardEvent<HTMLElement>) => {
    // zachytávám použitou(již aplikovanou) klávesu, aby dále neprobublávala DOM stromem
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 37 && !e.altKey) {
      //ctrl+shift+left
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 39 && !e.altKey) {
      //ctrl+shift+right
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 67 && !(e.altKey || e.shiftKey)) {
      //ctrl+c
      e.stopPropagation();
    }
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 45 && !(e.altKey || e.shiftKey)) {
      //ctrl+insert
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 86 && !(e.altKey || e.shiftKey)) {
      //ctrl+v
      e.stopPropagation();
    }
    if (e.shiftKey && e.keyCode === 45 && !(e.ctrlKey || e.shiftKey)) {
      //shift+insert
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 88 && !(e.altKey || e.shiftKey)) {
      //ctrl+x
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 65 && !(e.altKey || e.shiftKey)) {
      //ctrl+a
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 90 && !(e.altKey || e.shiftKey)) {
      //ctrl+z
      e.stopPropagation();
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 89 && !(e.altKey || e.shiftKey)) {
      //ctrl+y
      e.stopPropagation();
    }

    if (e.keyCode === 8 && !(e.altKey || e.shiftKey || e.ctrlKey)) {
      //Del
      e.stopPropagation();
    }

    if (e.keyCode === 46 && !(e.altKey || e.shiftKey || e.ctrlKey)) {
      //Del
      e.stopPropagation();
    }

    if (!this.control.State.ReadOnly && e.nativeEvent.code.includes("Numpad") && e.nativeEvent.code !== "NumpadEnter") {
      e.stopPropagation();
    }
  };

  private refCallBack = (element: HTMLInputElement | HTMLTextAreaElement) => {
    if (element) {
      setCustomAttributes(element, this.control.Ncl.Attributes);
      this.element = element;
    }
  };

  componentDidMount() {
    if (this.props.blockSoftKeyboard) {
      this.element.focus();
    }
  }

  componentDidUpdate(prevProps: InnerInputProps, prevState: InnerInputStateBase) {
    if (this.element && this.state.Position != prevState.Position && this.state.Position >= 0) {
      setTimeout(() => {
        this.element.setSelectionRange(this.state.Position, this.state.Position);
      }, 10);
    }
  }

  render(): JSX.Element {
    let value: string = this.state.Value;
    let inputAttributes: InputHtmlAttributes = {
      type: "text",
      id: this.control.Ncl.Name,
      title: this.state.data.Hint + ": " + value,
      ...getAttributes(this.state.data),
    };
    let inputClass: string = css.in_input + " " + this.props.className;

    inputAttributes.autoComplete = "off";
    inputAttributes.placeholder = this.props.InputTagPlaceholder;
    if (this.state.data.MaxLength) {
      inputAttributes.maxLength = this.state.data.MaxLength;
    }

    if (this.control.Ncl.FrgtData.IsPassword) {
      inputAttributes.type = this.props.revealPassword ? "text" : "password";
      inputAttributes.title = undefined;
    }

    if (this.control.State.InputType === TNclInputType.nitString && this.state.data.MultiLineMode) {
      return (
        <textarea
          className={inputClass}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          ref={this.refCallBack}
          {...inputAttributes}
          value={value}
          rows={this.state.data.MultiLineMode == TInputMultiLineMode.imlmMultiLineSimple ? this.control.Ncl.FrgtData.Size : 0}
        />
      );
    } else {
      return (
        <input
          className={inputClass}
          value={value}
          ref={this.refCallBack}
          onKeyDown={this.handleKeyDown}
          onPaste={this.props.onPaste}
          onDrop={this.props.onDrop}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          {...inputAttributes}
        />
      );
    }
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.change) {
      let value = e.target.value;
      if (this.state.Value != value) {
        this.setState({ Value: value });
        this.props.change(value);
      }
    }
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }

    this.needKeyStopPropagation(e); // catched used key in input, that i can't use in other parts of application

    if (!e.isPropagationStopped()) {
      if (((e.ctrlKey || e.metaKey) && e.keyCode === 38) || ((e.ctrlKey || e.metaKey) && e.keyCode === 40)) {
        //ctrl+UpArrow//ctrl+DownArrow
        this.control.nextPriorValue(e.keyCode === 40 ? true : false, this.state.Value);
        e.preventDefault();
      }

      if ((e.shiftKey && e.key === "Enter") || (e.currentTarget.tagName === "TEXTAREA" && (e.key === "ArrowUp" || e.key === "ArrowDown"))) {
        e.stopPropagation();
      } else if (e.keyCode === 13) {
        //ENTER
        this.control.change(this.state.Value, false); //set value for send, but send key Enter
      }
    }
  };

  private handleFocus = (e: React.FocusEvent<any>) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
    if (!(this.control.Parent instanceof NclLocatorPanel)) {
      this.element.select();
    }
  };

  private handleBlur = (e: React.FocusEvent<any>) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
    setRequestedActiveControl(e, this.control.getRealizerUID());
    this.control.change(this.state.Value, true);
  };
}
