import React from "react";
import { UpdateTabControl, UpdateControl } from "../../common/communication.base";
import { NclMultiContent } from "../../common/components.ncl";
import { UpdateContext } from "../../context";
import { GenerateControl } from "../K2GenerateControl";
import { WithContextPlacementProps, K2ComponentState, AcquireControl, StyleHelper } from "../k2hoc";

export class K2MultiContent extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateTabControl>> {
  static displayName = `K2MultiContent`;
  private control: NclMultiContent;
  static contextType = UpdateContext;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclMultiContent;
    }) as NclMultiContent;
    this.state = { data: this.control.init(this) as UpdateTabControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateTabControl>) => {
      return { data: state as UpdateTabControl };
    });

    this.context.update();
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        {this.control.Pages.map((control) => {
          if (control.Content.MetaData.ControlUID == this.state.data.CurrentPage) {
            return GenerateControl(control.Content, { flex: "1 1 auto" });
          }
        })}
      </div>
    );
  }
}
