import * as React from "react";
import { VisualContext } from "./common/visualContext";

export const UpdateContext = React.createContext(null);

export interface IVCXContext {
  vcx: VisualContext;
}

export const VCXContext = React.createContext({} as IVCXContext);
