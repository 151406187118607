import {
  CSViewRealizerStructure,
  UpdateBase,
  CSUpdateData,
  DataRequest,
  cJSonFunctionUpdateData,
  ControlDataRequest,
  Updatable,
  VisualContextMetadata,
  RectInDock,
  cJSonFunctionAcceptData,
  UFUpdateControl,
  FrameStyle,
  cJSonFunctionExecuteShortcut,
  cJSonFunctionCloseView,
  UpdateHeadered,
  CSUpdateControl,
  CSUFUpdateControl,
  CSUpdateVRTabControl,
  cJSonFunctionAutoUpdate,
} from "./common/communication.base";

import { UFNclControlBase, NclContainer, NclControlBase, NclViewBase, NclDockControl, NclVRTabControl, NclLocatorPanel } from "./common/components.ncl";
import { Log } from "./common/common";
import { Context } from "./appcontext";
import { NclMessageHelper, NclMessage } from "./common/communication";
import { VisualContext } from "./common/visualContext";
import { stringify } from "querystring";

export interface ViewRealizerControlListener {
  getViewRealizer(): ViewRealizer;
  showModal(realizerUID: string, controlUID?: string): Promise<void>;
  closeModal(realizerUID: string, controlUID?: string): Promise<void>;

  isSupportModal(): boolean;
  reRealize(): Promise<void>;
  setAsInActive(value: boolean): void;
}

export enum RealizerOperations {
  None = 0,
  Update,
  Accept,
}
export type ViewRealizerAttachDetachControlFce = (control: NclControlBase, attach: boolean) => any;

export type InjectDataAction = (control: UFNclControlBase) => any;

type UnRegisterViewRealizer = (uid: string) => any;
export interface VRContext {
  viewRealizer: ViewRealizer;
  attachDettachFce: ViewRealizerAttachDetachControlFce;
}

type CreateClientControlInContext = (context: VRContext) => NclControlBase;

interface NextRealizerData {
  realizerUID: string;
  realizeCounter: number;
  data: UpdateBase;
}

export class ViewRealizer {
  protected elements: Map<string, NclControlBase>;
  private priorRealizer: ViewRealizer;
  private closeProc: UnRegisterViewRealizer;
  protected root: UFNclControlBase;
  private realizeCounter: number;
  private realizerUID: string;
  private dock: string;
  private modal: boolean;
  private showAsForm: boolean;
  private isVCXAsInActive: boolean;
  private vcx: VisualContext;
  private listener: ViewRealizerControlListener = null;
  private activeControlUID: string;
  private requestActiveControlUID: string;
  private rectInDock: RectInDock;
  private anchoredControl: NclControlBase;
  private inEditMode: boolean = true;
  private changedControls: Array<string>;
  private hotKeys: Array<string>;
  private dockControl: NclDockControl;
  private contextDependencyElements: Array<string>;
  private nextRealizerData: Array<NextRealizerData>;
  private callAutoUpdateCounter: number = 0;
  private autoCloseTime: number;
  private autoCloseTimeStart: number;
  private dialogForm: boolean;
  private unmountedDocks: Array<string> = [];
  private locators: NclLocatorPanel[] = [];
  public calcFullHeight = false;
  private depth: number = 0;

  public init(priorRealizer: ViewRealizer, realizeCounter: number, close: UnRegisterViewRealizer) {
    this.priorRealizer = priorRealizer;
    this.realizeCounter = realizeCounter;
    this.closeProc = close;
    this.changedControls = new Array<string>();
  }

  public constructor() {}

  /**
   * If hot key exist in hotkey list, then call to server and return true, otherwise return false
   * @param hotKey HotKey
   */
  public processHotKey(hotKey: string): boolean {
    if (hotKey === "ESC" && Context.getApplication().appViewRealizer === this) return;
    if (hotKey != "") {
      if (this.root instanceof NclViewBase && this.root.isForbiddenHotKey(hotKey)) return false;
      //if(this.hotKeys && this.hotKeys.indexOf(hotKey) >= 0){
      let act = this.getActualActiveControl();
      if (!act) {
        act = this.root;
      }
      if (act) {
        act.appendFunction({ Name: cJSonFunctionExecuteShortcut, Args: [hotKey] });
        this.sendRequest(RealizerOperations.None);
      }
      return true;
      //}
    }
    return false;
  }

  public isActive() {
    return this.root && this.root.State.Visible === true && this.root.State.Enabled !== false && this.listener;
  }

  public getControlByUID(uid: string): NclControlBase {
    if (this.elements != null && this.elements.has(uid)) {
      return this.elements.get(uid);
    }

    return null;
  }

  public findControlByName(name: string): NclControlBase {
    if (this.elements != null && name && name.length > 0) {
      let result: NclControlBase = null;
      this.elements.forEach((ctrl) => {
        if (ctrl.MetaData.Name === name) {
          result = ctrl;
          return;
        }
      });
      return result;
    }

    return null;
  }

  public createClientControl(createInContextFce: CreateClientControlInContext): NclControlBase {
    return createInContextFce({ viewRealizer: this, attachDettachFce: this.attachDetachControl });
  }

  public getAutoCloseTime(): number {
    return this.autoCloseTime;
  }

  public getAutoCloseTimeRemaining(): number {
    if (this.autoCloseTime > 0 && this.autoCloseTimeStart > 0) {
      return this.autoCloseTimeStart + this.autoCloseTime - Date.now();
    }
    return undefined;
  }

  public getRectInDock(): RectInDock {
    return this.rectInDock;
  }

  public setIsVCXAsInActive(value: boolean) {
    if (this.isVCXAsInActive !== value) {
      this.isVCXAsInActive = value;
      if (this.listener) {
        this.listener.setAsInActive(value);
      }
    }
  }

  public callAutoUpdate(ctrl: NclViewBase<any, any>) {
    if (this.callAutoUpdateCounter > 0) return;
    this.callAutoUpdateCounter++;
    ctrl.appendFunction({ Name: cJSonFunctionAutoUpdate }, true);
  }

  public getRealizeCounter(): number {
    return this.realizeCounter;
  }

  /**
   * Vrací identifikátor realizeru
   */
  public getRealizerUID(): string {
    return this.realizerUID;
  }

  public getRoot(): UFNclControlBase {
    return this.root;
  }

  public getAnchoredControl(): NclControlBase {
    return this.anchoredControl;
  }

  public get VCX(): VisualContext {
    if (this.vcx) {
      return this.vcx;
    } else {
      return VisualContext.Default;
    }
  }

  public get ActiveControlUID(): string {
    return this.activeControlUID;
  }

  public set ActiveControlUID(value: string) {
    if (value) {
      if (!this.root) return;

      if (this.activeControlUID !== value) {
        if (this.elements.has(this.activeControlUID)) {
          let ctrl = this.elements.get(this.activeControlUID);
          if ((ctrl as UFNclControlBase).setAsActiveControl) {
            (ctrl as UFNclControlBase).setAsActiveControl(false);
          }
        }
      }
      if (this.elements.has(value)) {
        let ctrl = this.elements.get(value);
        this.activeControlUID = value;
        if ((ctrl as UFNclControlBase).setAsActiveControl) {
          (ctrl as UFNclControlBase).setAsActiveControl(true);
          Context.getApplication().setActiveRealizerUID(this);
        }
      } else {
        this.activeControlUID = "";
        Log.warn("SetActiveControl - Not exist control with uid:" + value);
      }
    }
  }

  public set RequestActiveControlUID(value: string) {
    this.requestActiveControlUID = value;
    Context.getApplication().setActiveRealizerUID(this);
    if (this.contextDependencyElements && this.contextDependencyElements.length > 0) {
      this.sendRequest(RealizerOperations.Update);
    }
  }

  public get RequestActiveControlUID(): string {
    return this.requestActiveControlUID;
  }

  public get InEditMode(): boolean {
    return this.inEditMode;
  }

  public getHeaderTitle() {
    if (this.root && this.root.State instanceof UpdateHeadered) {
      return this.root.State.Title;
    }

    return "";
  }

  public notifyAsActive(active: boolean) {
    if (this.root instanceof NclViewBase) {
      if (!active) {
        this.root.stopAutoUpdate();
      } else {
        this.root.startAutoUpdate();
      }
    }
  }

  public addLocatorPanel(locator: NclLocatorPanel) {
    this.locators.push(locator);
  }

  public removeLocatorPanel(locator: NclLocatorPanel) {
    let ndx = this.locators.indexOf(locator);
    if (ndx >= 0) this.locators.splice(ndx, 1);
  }

  public get LastVisibleLocator(): NclLocatorPanel {
    for (let i = 0; i < this.locators.length; i++) {
      const item = this.locators[i];
      if (item && item.State.Visible === true) {
        return item;
      }
    }

    return undefined;
  }

  /**
   * Zpracovává data přijaté ze serveru.
   * @param data
   */
  public async processData(realizeCounter: number, data: UpdateBase, callback: () => void = null) {
    if (this.priorRealizer && this.priorRealizer.root && this.priorRealizer.root.State.Visible === false) {
      this.priorRealizer.pushNextRealizerData({ realizerUID: this.realizerUID, realizeCounter: realizeCounter, data: data });
      if (callback) callback();
    }
    if (this.realizeCounter != realizeCounter) {
      // s rozdílným realizerCounter lze spustit jen rerealize
      let structure = data as CSViewRealizerStructure;
      if (structure.Structure != null) {
        await this.realize(structure);
        await this.listener.reRealize();
        this.realizeCounter = realizeCounter;
        if (callback) callback();
        return;
      }
      Log.warn("Different realizerCounter. Message:" + data);
    } else {
      if (data != null) {
        let structure = data as CSViewRealizerStructure;
        if (structure.Structure != null) {
          await this.realize(structure);
          if (Context.getApplication().appViewRealizer) {
            await Context.getApplication().appViewRealizer.tryMount(structure.Dock, structure.RealizerUID);
          }
          if (callback) callback();
          return;
        }
        let upd = data as CSUpdateData;
        if (upd.VCX != null) {
          this.setVCX(upd.VCX, true);
        } else if (upd.VCXSetToDef) {
          this.setVCX(null, true);
        } else if (upd.IsVCXAsInActive != undefined) {
          this.setIsVCXAsInActive(upd.IsVCXAsInActive);
        }

        if (upd.CompanyColor) {
          Context.getApplication().setCompanyColor(upd.CompanyColor);
        }

        if (upd.CompanyID) {
          Context.getApplication().setCompanyID(upd.CompanyID);
        }

        if (upd.HotKeys) {
          this.hotKeys = upd.HotKeys;
        }

        let promises: Array<Promise<void>> = [];
        if (upd.Data != null) {
          let rootView: NclViewBase<any, any> = null;
          let item: CSUFUpdateControl = null;
          let ctrl: Updatable = null;
          let i: number = null;
          let docks: Array<string> = [];
          for (let index = 0; index < upd.Data.length; index++) {
            item = upd.Data[index];
            ctrl = this.elements.get(item.ControlUID);
            if (ctrl != null) {
              if (ctrl instanceof NclViewBase && item.Visible) {
                rootView = ctrl;
              }
              if (ctrl instanceof NclVRTabControl && (item as CSUpdateVRTabControl).AddedTabs) {
                (item as CSUpdateVRTabControl).AddedTabs.map((value: any) => {
                  docks.push(value[1]);
                });
              }
              if ((i = this.changedControls.indexOf(item.ControlUID)) >= 0) {
                this.changedControls.splice(i, 1);
              }
              ctrl.updateState(item);
            } else {
              Log.warn("Unknown control: " + item.ControlUID + " data:" + JSON.stringify(item));
            }
          }

          while (docks.length > 0) {
            let value = docks.pop();
            let dock = this.getControlByUID(value);
            if (dock instanceof NclDockControl && !dock.isDocked()) {
              promises.push(dock.dockViewRealizer());
              value = undefined;
            }
            if (value) {
              this.unmountedDocks.push(value);
            }
          }

          if (rootView) {
            if (this.autoCloseTime > 0) {
              promises.push(
                new Promise((resolve, reject) => {
                  rootView
                    .showView()
                    .then(() => {
                      this.autoCloseTimeStart = Date.now();
                      resolve();
                    })
                    .catch(reject);
                })
              );
            } else {
              promises.push(rootView.showView());
            }
          }

          if (this.nextRealizerData && this.nextRealizerData.length > 0) {
            let vr: ViewRealizer;
            this.nextRealizerData.forEach((item) => {
              vr = ViewRealizerManager.getViewRealizer(item.realizerUID);
              if (vr) {
                promises.push(
                  new Promise((resolve, reject) => {
                    vr.processData(item.realizeCounter, item.data, resolve);
                  })
                );
              }
            });
          }
          this.changedControls.map((item) => {
            // contrlos wwhit changed state by user, state not confirm on server and control must back changes
            let ctrl = this.getControlByUID(item);
            if (ctrl) {
              ctrl.resetToState();
            }
          });
        }
        if (promises.length > 0) {
          Promise.all(promises).then(() => {
            this.__setActiveCtrl(upd.ActiveControl, callback);
          });
        } else {
          this.__setActiveCtrl(upd.ActiveControl, callback);
        }
      }
    }

    if (this.callAutoUpdateCounter > 0) {
      this.callAutoUpdateCounter--;
    }
  }

  private tryMount(dockUID: string, realizerUID: string): Promise<void> {
    if (this.unmountedDocks.length > 0) {
      let dock: NclDockControl;
      let index: number = -1;
      this.unmountedDocks.map((value, i) => {
        if (value === dockUID) {
          dock = this.getControlByUID(value) as NclDockControl;
          index = i;
          return;
        }
      });
      if (dock && index > -1) {
        this.unmountedDocks.splice(index, 1);
        return dock.dockViewRealizer(realizerUID);
      }
    }
    return Promise.resolve();
  }

  private pushNextRealizerData(data: NextRealizerData) {
    if (!this.nextRealizerData) {
      this.nextRealizerData = new Array<NextRealizerData>();
    }

    this.nextRealizerData.push(data);
  }

  private __setActiveCtrl(uid: string, callback: () => void) {
    if (uid) {
      this.ActiveControlUID = uid;
    }
    this.nextRealizerData = null;
    if (callback) callback();
  }

  public getDepth(): number {
    return this.depth;
  }

  private calcDepth(): number {
    let result: number = 1;
    let prior: ViewRealizer = this.getPriorRealizer();
    if (this.isModal()) {
      if (prior) return 10 * Context.getApplication().ModalCount + prior.getDepth();
      else return 10 * Context.getApplication().ModalCount;
    }
    if (prior != null) {
      result += prior.getDepth();
    }

    return result;
  }

  /**
   * Vrací název dock elementu
   */
  public getDock(): string {
    return this.dock;
  }

  public getDockControl(): NclDockControl {
    return this.dockControl;
  }

  public setDockControl(ctrl: NclDockControl) {
    if (this.dockControl && ctrl) {
      throw new Error("Dock cotnrol mishmash.");
    }
    this.dockControl = ctrl;
  }

  public sendRequest(operation: RealizerOperations, control?: NclControlBase) {
    if (operation & RealizerOperations.Accept) {
      this.root.appendFunction({ Name: cJSonFunctionAcceptData });
    }

    if (operation & RealizerOperations.Update) {
      this.root.appendFunction({ Name: cJSonFunctionUpdateData });
    }

    let request = NclMessageHelper.CreateUpdateMsg(this.getRealizerUID(), this.realizeCounter, this.getDataRequest());

    Context.getApplication().sendMessage(request, control);
  }

  public addToChangeList(controlUID: string) {
    if (this.changedControls.indexOf(controlUID) < 0) this.changedControls.push(controlUID);
  }

  /**
   * Vrací zda je reailzer zobrazen modálně.
   */
  public isModal(): boolean {
    return this.modal;
  }

  /**
   * Vrací zda se jedná o dialog okno.
   */
  public isDialog(): boolean {
    return this.dialogForm;
  }

  public isForm(): boolean {
    return this.showAsForm;
  }

  /**
   * Vrací prior realizer
   */
  public getPriorRealizer(): ViewRealizer {
    return this.priorRealizer;
  }

  /**
   * Vyvolá požadavek na zavření realizeru.
   */
  public closeRequest() {
    this.root.appendFunction({ Name: cJSonFunctionCloseView });
    this.sendRequest(RealizerOperations.None);
  }

  /**
   * Vyvolá close view. Vyvolá jí vždy server.
   */
  public async close(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (this.root instanceof NclViewBase) {
        await this.root.closeView();
        await this.internalClose();
        resolve();
      } else {
        await this.internalClose();
        resolve();
      }
    });
  }

  private async internalClose(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (this.showAsModal()) {
        await Context.getApplication().closeModal(this.realizerUID);
      } else if (this.showAsLocalModal()) {
        await ViewRealizer.getNearestListener(this.getPriorRealizer()).closeModal(this.getRealizerUID());
      } else {
        if (this.dockControl) {
          await this.dockControl.undockViewRealizer();
        }
      }

      if (this.closeProc != null) {
        //Must call in the end of close because any parts of application could be find VR in VM manager.
        this.closeProc(this.getRealizerUID());
      }
      resolve();
    });
  }

  public getActualActiveControl(): NclControlBase {
    if (this.elements !== null) {
      let act = this.RequestActiveControlUID ? this.RequestActiveControlUID : this.ActiveControlUID;
      if (act && this.elements.has(act)) return this.elements.get(act);
    }

    return null;
  }

  public afterMount(listener: ViewRealizerControlListener) {
    this.sendRequest(RealizerOperations.Update);
    this.listener = listener;
  }

  public willUnMount() {
    this.listener = null;
    if (this.root) {
      this.root.willUnMount(false);
      this.root = null;
    }
    if (this.elements.size > 0) this.elements.clear();
  }

  public canSendRequest(): boolean {
    return this.root.State.Enabled !== false || !this.listener;
  }

  public registerContextDependency(uid: string) {
    if (!this.contextDependencyElements) {
      this.contextDependencyElements = [];
    }

    if (this.contextDependencyElements.indexOf(uid) < 0) {
      this.contextDependencyElements.push(uid);
    }
  }

  public unRegisterContextDependency(uid: string) {
    let i: number = -1;
    if (this.contextDependencyElements && (i = this.contextDependencyElements.indexOf(uid)) >= 0) {
      this.contextDependencyElements = this.contextDependencyElements.slice(i, 1);
    }
  }

  /**
   * Získání dat z komponent.
   */
  private getDataRequest(): DataRequest {
    let request: DataRequest = new DataRequest(this.realizerUID);
    Context.getApplication().addActualScreenSize(request);
    request.Data = new Array<ControlDataRequest>();
    this.root.collectData(request.Data);

    if (this.requestActiveControlUID) {
      request.ActiveControl = this.requestActiveControlUID;
    }
    this.requestActiveControlUID = undefined;
    return request;
  }

  private setData(data: CSViewRealizerStructure) {
    if (data != null) {
      this.realizerUID = data.RealizerUID;
      this.dock = data.Dock;
      this.modal = data.Modal;
      this.showAsForm = data.ShowAsForm;
      this.rectInDock = data.RectInDock;
      this.inEditMode = data.InEditMode;
      this.autoCloseTime = data.AutoCloseTime;
      this.dialogForm = data.DialogForm;
      if (data.HotKeys) {
        this.hotKeys = data.HotKeys;
      }
    } else {
      this.realizerUID = null;
      this.dock = null;
      this.modal = false;
      this.realizerUID = null;
      this.anchoredControl = null;
      this.hotKeys = null;
    }
  }

  private setVCX(vcx: VisualContextMetadata, forceRefresh: boolean) {
    if (!vcx) {
      this.vcx = null;
    } else {
      this.vcx = VisualContext.create(vcx, 100);
    }

    this.root.setVCX(this.VCX, forceRefresh);
  }

  /**
   * Na základě přijaté struktury necha buildrem sestavit DOM a mapu komponent (name -> komponenta).
   * Sestavenou část DOMu zapojí do existujícího domu, podle požadavků (Modal, Local modal, Normal).
   * Projde všechny komponenty a získá z nich požadavky na update který pošle na server.
   * @param data
   */
  private realize(data: CSViewRealizerStructure): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      this.setData(data);
      this.elements = new Map<string, NclControlBase>();
      this.root = NclContainer.createControl(data.Structure, null, this, this.attachDetachControl);

      if (this.rectInDock) {
        if (this.rectInDock.AnchoredControlName !== "") {
          this.elements.forEach((value) => {
            if (value.MetaData.Name === this.rectInDock.AnchoredControlName) {
              this.anchoredControl = value;
              return;
            }
          });
        }
      }
      this.setVCX(data.VCX, false);
      await this.render();
      resolve();
    });
  }

  private attachDetachControl(control: NclControlBase, attach: boolean) {
    if (control) {
      if (attach) {
        this.elements.set(control.MetaData.ControlUID, control);
      } else {
        if (this.elements.has(control.MetaData.ControlUID)) {
          this.elements.delete(control.MetaData.ControlUID);
        }
      }
    }
  }

  public showAsModal(): boolean {
    return this.isModal() || (this.priorRealizer && this.priorRealizer.isModal());
  }

  public showAsLocalModal(): boolean {
    return this.dock == null || this.dock === "" || (this.priorRealizer && this.priorRealizer.dock === this.dock);
  }

  private render(): Promise<void> {
    if (this.listener || Context.getApplication().appViewRealizer == this) return Promise.resolve();

    return new Promise<void>(async (resolve, reject) => {
      if (Context.getApplication().appViewRealizer == null) {
        await Context.getApplication().render(this);
      } else if (this.showAsModal()) {
        await Context.getApplication().showModal(this.realizerUID);
      } else if (this.showAsLocalModal()) {
        //dock to same dock element as parent
        await ViewRealizer.getNearestListener(this).showModal(this.realizerUID);
      }
      this.depth = this.calcDepth();
      // Other realizers docked to DockControl and will be shown after render this dock control.
      resolve();
    });
  }

  public static getNearestListener(vr: ViewRealizer): ViewRealizerControlListener {
    while (vr != null) {
      if (vr.listener != null && vr.listener.isSupportModal()) {
        return vr.listener;
      }
      vr = vr.priorRealizer;
    }

    vr = Context.getApplication().appViewRealizer;
    if (vr != null) {
      return vr.listener;
    }

    throw new Error("Not exist modal anchor.");
  }
}

export class ViewRealizerManager {
  private static instance: ViewRealizerManager;
  private viewRealizers: Map<string, ViewRealizer>;

  private constructor() {
    this.viewRealizers = new Map<string, ViewRealizer>();
  }

  private static getInstance(): ViewRealizerManager {
    if (ViewRealizerManager.instance == null) {
      ViewRealizerManager.instance = new ViewRealizerManager();
    }

    return ViewRealizerManager.instance;
  }

  public static clear() {
    ViewRealizerManager.instance = new ViewRealizerManager();
  }

  public static getViewRealizer(uid: string): ViewRealizer {
    let vr: ViewRealizer = null;

    vr = ViewRealizerManager.getInstance().viewRealizers.get(uid);
    return vr;
  }

  public static getViewRealizerByDock(dock: string): ViewRealizer {
    let result: ViewRealizer = null;
    ViewRealizerManager.getInstance().viewRealizers.forEach((value: ViewRealizer, key: string, map: Map<string, ViewRealizer>) => {
      if (value.getDock() === dock) {
        result = value;
        return;
      }
    });

    return result;
  }

  public static createViewRealizer(structure: CSViewRealizerStructure, realizeCounter: number): ViewRealizer {
    let realizers = ViewRealizerManager.getInstance().viewRealizers;
    if (realizers.has(structure.RealizerUID)) {
      throw new Error("Realizer is already exist: " + structure.RealizerUID);
    }

    let priorRealizer: ViewRealizer = null;
    if (structure.PriorRealizerUID != null && structure.PriorRealizerUID != "") {
      if (realizers.has(structure.PriorRealizerUID)) {
        priorRealizer = realizers.get(structure.PriorRealizerUID);
      } else {
        if (structure.DialogForm === true) {
          structure.PriorRealizerUID = Context.getApplication().appViewRealizer?.getRealizerUID();
          priorRealizer = Context.getApplication().appViewRealizer;
          console.log(`Not exist PriorRealizer: ${structure.PriorRealizerUID}, for dialog form change prior to main app vr.`);
        } else {
          throw new Error("Not exist PriorRealizer: " + structure.PriorRealizerUID);
        }
      }
    }

    let vr = new ViewRealizer();
    vr.init(priorRealizer, realizeCounter, (uid: string) => {
      ViewRealizerManager.unRegisterViewRealizer(uid);
    });
    realizers.set(structure.RealizerUID, vr);
    return vr;
  }

  public static getViewRealizers(): IterableIterator<ViewRealizer> {
    return ViewRealizerManager.getInstance().viewRealizers.values();
  }

  public static getOrCreate(realizeCounter: number, structure: CSViewRealizerStructure): ViewRealizer {
    let vr: ViewRealizer = ViewRealizerManager.getViewRealizer(structure.RealizerUID);
    if (!vr) {
      if (structure.Structure != null) {
        vr = ViewRealizerManager.createViewRealizer(structure, realizeCounter);
      } else {
        console.error("Couldn't create realizer: " + stringify(structure));
      }
    }

    return vr;
  }

  /**
   * Odregistruje realizer ze seznamu realizeru.
   * @param realizer
   */
  private static unRegisterViewRealizer(realizerUID: string) {
    let realizers = ViewRealizerManager.getInstance().viewRealizers;
    if (realizers.has(realizerUID)) {
      realizers.delete(realizerUID);
    } else {
      Log.debug("Closing realizer: " + realizerUID + " not exist in cache");
    }
  }
}
