import * as React from "react";
import { useRef, useState } from "react";
import { CSNclTabControlMetadata, UpdateTabControl, UpdateVRTabControl } from "../../common/communication.base";
import { NclBaseTabControl } from "../../common/components.ncl";
import { AcquireControl, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2VRTabControl from "./K2VRTabControl";
import K2TabControlBase from "./K2TabControlBase";
import K2ListDetailTabControl from "./K2ListDetailTabControl";
import K2ResponsiveTabControl from "./K2ResponsiveTabControl";
import { useAnimationEnd } from "../hooks";
import { writeToCSS } from "../VCX/VCXHelper";

export interface TabControlProps {
  control: NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>;
  currentPage: string;
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const K2TabControl = (props: WithContextPlacementProps & { isVRTab: boolean }) => {
  const control = useRef(
    AcquireControl(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclBaseTabControl) as NclBaseTabControl<CSNclTabControlMetadata, UpdateTabControl>
  );
  const [data, setData] = useState<UpdateTabControl>(control.current.init({ updateState: updateState, updateVCX: updateVCX }) as UpdateTabControl);
  const [VCXVersion, setVCXVersion] = useState(-1);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [renderMobileMenu, handleAnimationEnd] = useAnimationEnd(showMobileMenu);
  const tc = useRef(null);

  function updateState(state: UpdateTabControl) {
    setData(state);

    if (tc.current && control.current.VCX !== control.current.Parent.VCX) {
      writeToCSS(control.current.VCX, tc.current);
    }
  }

  function updateVCX(vcxVersion: number) {
    setVCXVersion(vcxVersion);

    if (tc.current) {
      writeToCSS(control.current.VCX, tc.current);
    }
  }

  if (!data) return null;

  return (
    <div ref={tc} style={StyleHelper(control.current, props.style)}>
      {props.isVRTab && <K2VRTabControl control={control.current} currentPage={data.CurrentPage} setShowMobileMenu={setShowMobileMenu} />}
      {!props.isVRTab && !control.current.Ncl.ListDetailTabControl && (
        <K2TabControlBase control={control.current} currentPage={data.CurrentPage} setShowMobileMenu={setShowMobileMenu} />
      )}
      {control.current.Ncl.ListDetailTabControl && (
        <K2ListDetailTabControl control={control.current} currentPage={data.CurrentPage} setShowMobileMenu={setShowMobileMenu} />
      )}
      {renderMobileMenu && (
        <K2ResponsiveTabControl
          control={control.current}
          currentPage={data.CurrentPage}
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
          handleAnimationEnd={handleAnimationEnd}
        />
      )}
    </div>
  );
};

export default K2TabControl;
