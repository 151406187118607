import * as React from "react";
import { UpdateControl, UpdateHeadered, CSNclViewMetadata, TBehaviorTypeByDevice } from "../../common/communication.base";
import { K2ComponentState, StyleHelper, AcquireControl } from "../k2hoc";
import {
  NclFloaterView,
  NclViewBase,
  NclFloaterAccessor,
  NclMenuView,
  NclMenu,
  NclInplaceView,
  NclVirtualKeyboardDialog,
  NclCodeReaderDialog,
  NclOpenDialog,
  NclSilentOpenDialogContent,
} from "../../common/components.ncl";
import { K2FlowPanel } from "../FlowPanel/K2FlowPanel";
import { ViewRealizer, ViewRealizerManager } from "../../viewrealizer";
import { Context } from "../../appcontext";
import K2OpenDialogContent from "../SilentOpenDialog/K2SilentOpenDialogContnet";
import K2VirtualKeyboard from "../VirtualKeyboard/K2VirtualKeyboard";
import K2CodeReader from "../CodeReader/K2CodeReader";
import { withVCXInCSS, WithVCXinCSSProps } from "../VCX/VCXHelper";
import K2ModalWindow from "../Modal/K2ModalWindow";
import K2SimpleModalWindow from "../Modal/K2SimpleModalWindow";
import { List } from "immutable";
import { RealizerQueueItem } from "../View/ViewRealizerReact";
import K2ModalMenuWindow from "../Modal/K2ModalMenuWindow";
import K2Menu from "../Menu/K2Menu";
import K2OpenDialog from "../OpenDialog/K2OpenDialog";

interface ViewProps extends WithVCXinCSSProps {
  overlayBck: boolean;
  isOpen?: boolean;
  realizersQueue?: List<RealizerQueueItem>;
  updateModalList?: () => void;
}

class _View extends React.Component<ViewProps, K2ComponentState<UpdateHeadered>> {
  static displayName = `K2View`;
  private control: NclViewBase<CSNclViewMetadata, UpdateHeadered>;
  private element: HTMLElement;

  constructor(props: ViewProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclViewBase;
    }) as NclViewBase<CSNclViewMetadata, UpdateHeadered>;
    this.state = { data: this.control.init(this) as UpdateHeadered, vcxVersion: -1 };
  }

  getOverRect(): DOMRect {
    let result = undefined;

    if (this.state.data.Visible === true) {
      if (this.element) {
        result = this.element.getBoundingClientRect();
      }
    }

    return result;
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  componentDidMount() {
    let vr: ViewRealizer = this.acquireParentViewRealizer();
    if (this.element && this.props.onVCXChanged && vr && vr.VCX.getVersion() != this.control.VCX.getVersion()) {
      this.props.onVCXChanged(this.control.VCX, this.element);
    }
  }

  componentDidUpdate(prevProps: Readonly<ViewProps>, prevState: Readonly<K2ComponentState<UpdateHeadered>>, snapshot?: any): void {
    if (prevState.vcxVersion !== this.state.vcxVersion) {
      if (this.props.onVCXChanged && this.element) {
        this.props.onVCXChanged(this.control.VCX, this.element);
      }
    }
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateHeadered>) => {
      return { data: state as UpdateHeadered };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  private acquireParentViewRealizer(): ViewRealizer {
    let vr = ViewRealizerManager.getViewRealizer(this.control.getRealizerUID());
    if (vr) {
      let dock = vr.getDockControl();
      if (dock) {
        return ViewRealizerManager.getViewRealizer(dock.getRealizerUID());
      }
      let nvr = ViewRealizer.getNearestListener(vr);
      if (nvr) {
        return nvr.getViewRealizer();
      }
    }

    return Context.getApplication()?.appViewRealizer;
  }

  private handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape" && this.control instanceof NclFloaterView && this.control.Parent instanceof NclFloaterAccessor) {
      this.control.Parent.hide();
      e.stopPropagation();
    }
  };

  render() {
    let isMenu: boolean = false;
    let content: JSX.Element = null;
    if (this.control.Content) {
      if (this.control.Content instanceof NclMenu) {
        isMenu = true;
        content = (
          <K2Menu
            controlUID={this.control.Content.MetaData.ControlUID}
            vrUID={this.control.Content.getRealizerUID()}
            depth={0}
            subMenuDisplayInside={Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdMobile}
          />
        );
      } else if (this.control instanceof NclOpenDialog) {
        content = <K2OpenDialog controlUID={this.control.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />;
      } else if (this.control.Content instanceof NclSilentOpenDialogContent) {
        content = <K2OpenDialogContent controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.control.Content.getRealizerUID()} />;
      } else if (this.control instanceof NclVirtualKeyboardDialog) {
        content = (
          <K2VirtualKeyboard controlUID={this.control.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} mode={this.control.getAlphabetMode()} />
        );
      } else if (this.control instanceof NclCodeReaderDialog) {
        content = <K2CodeReader controlUID={this.control.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />;
      } else {
        content = <K2FlowPanel controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.control.Content.getRealizerUID()} />;
      }
    }
    let vr = ViewRealizerManager.getViewRealizer(this.control.getRealizerUID());

    let view = (
      <div
        ref={(ref) => (this.element = ref)}
        data-k2-edit-mode={this.control.InEditMode}
        data-k2-test-id={this.control.getName()}
        style={StyleHelper(
          this.control,
          Object.assign(
            {
              flexDirection: "column",
              flex: "1 1 auto",
              position: "relative",
              alignSelf: "stretch",
              background: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentColorBck1),
            },
            this.props.style
          )
        )}
        onKeyDown={this.handleKeyDown}
      >
        {Context.getApplication().UserInfo.IsTestVersion && vr === Context.getApplication().appViewRealizer && (
          <div className="testPanel">Test Test Test Test</div>
        )}
        {content}
        {this.props.children}
      </div>
    );

    if (
      !vr.showAsLocalModal() &&
      !vr.showAsModal() &&
      !(this.control instanceof NclFloaterView) &&
      !(this.control instanceof NclMenuView) &&
      !(this.control instanceof NclOpenDialog)
    )
      return view; // View dock in main docked layout

    let window = null;
    let showType = Context.DeviceInfo.StyleOfModalWindowShow;
    if (this.control instanceof NclInplaceView) {
      showType = Context.DeviceInfo.InplaceEditBehavior;
    }

    switch (showType) {
      case TBehaviorTypeByDevice.btbdMobile: {
        if (this.control instanceof NclMenuView && this.control.isOnlyActionsInFirstLevel(3)) {
          window = (
            <K2ModalMenuWindow
              isOverlayBck={this.props.overlayBck}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
              updateModalList={this.props.updateModalList}
              realizersQueue={this.props.realizersQueue}
            >
              {view}
            </K2ModalMenuWindow>
          );
        } else {
          if (!this.state.data.Visible) return null; // nevykreslovat okno, at se neprovadi animace se skrytym obsahem okna

          window = (
            <K2SimpleModalWindow
              isOverlayBck={this.props.overlayBck}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
              updateModalList={this.props.updateModalList}
              realizersQueue={this.props.realizersQueue}
              headerTitle={this.state.data.Title}
            >
              {view}
            </K2SimpleModalWindow>
          );
        }
        break;
      }
      case TBehaviorTypeByDevice.btbdNormal: {
        if (isMenu) {
          window = (
            <K2ModalMenuWindow
              isOverlayBck={this.props.overlayBck}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
              updateModalList={this.props.updateModalList}
              realizersQueue={this.props.realizersQueue}
            >
              {view}
            </K2ModalMenuWindow>
          );
        } else {
          window = (
            <K2ModalWindow
              isOverlayBck={this.props.overlayBck}
              vcx={this.control.VCX}
              realizerUID={vr.getRealizerUID()}
              controlUID={this.control.MetaData.ControlUID}
              headerTitle={this.state.data.Title}
              updateModalList={this.props.updateModalList}
              realizersQueue={this.props.realizersQueue}
            >
              {view}
            </K2ModalWindow>
          );
        }
        break;
      }
    }

    return window;
  }
}

export const K2View = withVCXInCSS(_View);
