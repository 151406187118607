import React from "react";
import { getAttributes } from "../../common/common";
import { IconPosition, UpdateCommandItem } from "../../common/communication.base";
import { ClientNclCommandItem, NclCommandItem, NclInput, NclTabToolBar, NclToolBar } from "../../common/components.ncl";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./Action.scss";
import SubMenuIndicator from "../SubMenuIndicator/K2SubMenuIndicator";
import { useServerState } from "../hooks";

const K2Action = (
  props: WithContextPlacementProps & {
    color?: string;
    preventClick?: boolean;
    onClientAction?: (ctrl: NclCommandItem) => void;
  }
) => {
  const [control, data, element] = useServerState<NclCommandItem, UpdateCommandItem, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclCommandItem
  );

  const getStyle = (): React.CSSProperties => {
    let flexDirection: React.CSSProperties;

    if (control.Parent instanceof NclToolBar && control.Parent.Parent instanceof NclTabToolBar) {
      flexDirection = { flexDirection: "column", padding: `0 ${control.VCX.sizeMap(10)}px` };

      return flexDirection;
    }

    switch (control.Ncl.FrgtData.IconPosition) {
      case IconPosition.ipBottom:
        flexDirection = { flexDirection: "column-reverse", padding: `0 ${control.VCX.sizeMap(10)}px` };
        break;
      case IconPosition.ipTop:
        flexDirection = { flexDirection: "column", padding: `0 ${control.VCX.sizeMap(10)}px` };
        break;
      case IconPosition.ipLeft:
        flexDirection = { flexDirection: "row" };
        break;
      case IconPosition.ipRight:
        flexDirection = { flexDirection: "row-reverse" };
        break;
      case IconPosition.ipCenter:
        flexDirection = { flexDirection: "row" };
        break;
    }

    return flexDirection;
  };

  const getImageStyle = (): React.CSSProperties => {
    let iconStyle: React.CSSProperties = null;

    //Pro ikony na TabToolPanel nastavit zarovnání na střed
    if (control.Parent instanceof NclToolBar && control.Parent.Parent instanceof NclTabToolBar) {
      iconStyle = {
        margin: "auto",
        marginTop: "initial",
        marginBottom: "initial",
      };
      return iconStyle;
    }

    return iconStyle;
  };

  const handleClick = () => {
    if (props.preventClick === true) return;

    if (control instanceof ClientNclCommandItem) {
      props.onClientAction?.(control);

      return;
    }

    control.executeCommand();
  };

  let caption: JSX.Element = null;

  if (data.Title && data.Title !== "" && control.Ncl.FrgtData.ShowCaption) {
    caption = <K2TruncateText className={`${css.caption} ${data.Enabled? "" : css.caption_disabled}`} >{data.Title}</K2TruncateText>;
  }

  let clr: string;
  if (data.Enabled !== false) {
    clr = props.color ? props.color : null;
  } else {
    clr = "rgba(0, 0, 0, 0.2)";
  }

  let icon: JSX.Element;

  if (data.GlyphId && control.Ncl.FrgtData.ShowIcon) {
    let height: number,
      width: number = 0;

    if (control.Parent instanceof NclToolBar && control.Parent.Parent instanceof NclInput) {
      width = height = control.Parent.ComputedMinHeight;
    } else if (control.Parent instanceof NclToolBar && control.Parent.Parent instanceof NclTabToolBar) {
      width = height = control.VCX.InputControl.getInputHeight(control.Parent.Parent.Size * 0.7, false, true);
    } else {
      width = height = control.VCX.LabelControl.getHeight(1.4);
    }

    icon = (
      <K2Img
        height={data.Checked ? height - control.VCX.sizeMap(2) * 2 : height}
        width={data.Checked ? width - control.VCX.sizeMap(2) * 2 : width}
        glyphId={data.GlyphId}
        vcx={control.VCX}
        strokeColor={clr}
        style={getImageStyle()}
      />
    );
  }

  return (
    <div style={StyleHelper(control, props.style)} ref={element}>
      <button
        data-k2-test-id={control.MetaData.Name}
        className={`${css.ac}${data.Checked ? ` ${css.ac_checked}` : ""}`}
        onClick={handleClick}
        {...getAttributes(data)}
      >
        <div style={{ ...getStyle(), display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
          {icon}
          {caption}
        </div>
        {data.SubMenuIndicatorVisible && <SubMenuIndicator strokeColor={clr} />}
      </button>
    </div>
  );
};

export default K2Action;
