import React from "react";

interface K2TruncateTextProps {
  className?: string;
  children: string;
  style?: React.CSSProperties;
  line?: number;
  keyboardAccelerator?: boolean;
  textColor?: string;
  textBackColor?: string;
  onClick?: () => void;
  setAccessKey?: (key: string) => void;
}

const UnderLinePattern = "&\\w";

const K2TruncateText = (props: K2TruncateTextProps) => {
  let accessKey: string;

  const convertText = () => {
    let input = props.children;

    if (!input || props.keyboardAccelerator === false) return input;

    let matches = input.match(UnderLinePattern);

    if (matches && matches.length === 1) {
      let elements: JSX.Element[] = new Array<JSX.Element>();

      matches.forEach((value: string, index: number, array: string[]) => {
        if (!accessKey) {
          accessKey = value.charAt(1);
        }

        let i = input.indexOf(value);

        if (i === 0) {
          elements.push(<u key={accessKey + "_u"}>{accessKey}</u>);
          elements.push(<span key={accessKey + "_s"}>{input.substring(i + 2)}</span>);
        } else if (i > 0) {
          elements.push(<span key={accessKey + "_s0"}>{input.substring(0, i)}</span>);
          elements.push(<u key={accessKey + "_u"}>{accessKey}</u>);
          elements.push(<span key={accessKey + "_s"}>{input.substring(i + 2)}</span>);
        } else {
          return input;
        }
      });

      return <span>{elements}</span>;
    }

    return input;
  };

  const handleClick = (e: React.MouseEvent<HTMLParagraphElement>) => {
    if (props.onClick) {
      props.onClick();
    }
  };

  const setAccessKey = () => {
    props.setAccessKey?.(accessKey);
  };

  let multilineStyle: React.CSSProperties = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical" as "vertical",
    WebkitLineClamp: props.line,
    whiteSpace: "break-spaces",
  };

  let style: React.CSSProperties = props.style;

  if (props.line && props.line > 1) {
    style = Object.assign({}, multilineStyle, style);
  }

  if (props.onClick) {
    let linkStyle: React.CSSProperties = {};
    style = Object.assign({}, style, linkStyle);
  }

  let clsName: string = "truncate-text";

  if (props.className && props.className != undefined) {
    clsName += " " + props.className;
  }

  let st: React.CSSProperties;

  if (props.textBackColor) {
    st = {};
    st.backgroundColor = props.textBackColor;
  }

  if (props.textColor) {
    if (!st) st = {};
    st.color = props.textColor;
  }

  let content: string | JSX.Element;

  if (st) {
    content = <span style={st}>{convertText()}</span>;
  } else {
    content = convertText();
  }

  return (
    <p className={clsName} style={style} onClick={handleClick} ref={setAccessKey}>
      {content}
    </p>
  );
};

export default K2TruncateText;
