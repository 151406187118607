import React, { useContext } from "react";
import { VCXContext } from "../../context";
import K2Img from "../Image/K2Img";

const SubMenuIndicator = (props: { strokeColor: string }) => {
  const vcxContext = useContext(VCXContext);

  return (
    <div style={{ position: "absolute", bottom: `${vcxContext.vcx.sizeMap(-5)}px`, right: `${vcxContext.vcx.sizeMap(-4)}px` }}>
      <K2Img glyphId="wui*arrow.down" vcx={vcxContext.vcx} height={15} width={15} strokeColor={props.strokeColor} />
    </div>
  );
};

export default SubMenuIndicator;
