import React, { useRef, useState } from "react";
import { getAttributes } from "../../common/common";
import { UFUpdateControl } from "../../common/communication.base";
import { NclFloaterAccessor } from "../../common/components.ncl";
import { AcquireControl, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import K2TruncateText from "../Text/K2TruncateText";
import { writeToCSS } from "../VCX/VCXHelper";
import css from "./FloaterAccessor.scss";

const K2FloaterAccessor = (props: WithContextPlacementProps) => {
  const control = useRef(AcquireControl(props.controlUID, props.vrUID, (ctrl) => ctrl instanceof NclFloaterAccessor) as NclFloaterAccessor);
  const [data, setData] = useState(control.current.init({ updateState: updateState, updateVCX: updateVCX, getOverRect: getOverRect }) as UFUpdateControl);
  const [VCX, setVCX] = useState(-1);
  const element = useRef<HTMLButtonElement>();

  function updateState(state: UFUpdateControl) {
    setData(state);

    if (control.current.VCX !== control.current.Parent.VCX) {
      writeToCSS(control.current.VCX, element.current);
    }
  }

  function updateVCX(vcxVersion: number) {
    setVCX(vcxVersion);
    writeToCSS(control.current.VCX, element.current);
  }

  function getOverRect() {
    if (!element.current) return null;

    return element.current.getBoundingClientRect();
  }

  const getClassName = () => {
    let className = `${css.fa_button} ${css.fa_font}`;

    if (control.current.InEditMode) {
      className += ` ${css.fa_edit}`;
    }

    return className;
  };

  return (
    <button
      onClick={() => control.current.show()}
      data-k2-test-id={control.current.MetaData.Name}
      className={getClassName()}
      title={data.Title}
      style={StyleHelper(control.current, { ...props.style, minHeight: control.current.ComputedMinHeight + "px" })}
      ref={element}
      {...getAttributes(data)}
    >
      {data.GlyphId && (
        <div className={css.fa_img}>
          <K2Img glyphId={data.GlyphId} width={control.current.ComputedMinHeight} height={control.current.ComputedMinHeight} vcx={control.current.VCX} />
        </div>
      )}
      <K2TruncateText className={css.fa_label}>{data.Title}</K2TruncateText>
    </button>
  );
};

export default K2FloaterAccessor;
