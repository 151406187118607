import React, { useEffect } from "react";
import { cJSonFunctionLoadPreviewData, UpdateFilePreview } from "../../common/communication.base";
import { NclFilePreview } from "../../common/components.ncl";
import { useServerState } from "../hooks";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import K2Img from "../Image/K2Img";
import { getDenyImage } from "../Image/K2Image";
import K2TruncateText from "../Text/K2TruncateText";
import K2FileViewer from "./K2FileViewer";
import css from "./FilePreview.scss";

const K2FilePreview = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclFilePreview, UpdateFilePreview, HTMLDivElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFilePreview
  );
  let content;

  const isOthersSupportedFile = () => {
    if (data.ObjectId) {
      let ext = data.ObjectId.substring(data.ObjectId.length - 3);

      if (ext.toUpperCase() === "PDF") return true;
    }

    return false;
  };

  useEffect(() => {
    if (data.IsSupported && data.ObjectId && !data.IsLoaded) {
      control.appendFunction({ Name: cJSonFunctionLoadPreviewData, Args: [] }, true);
    }
  }, [data.ObjectId]);

  if (!data.ObjectId) return null;

  if (data.ImageDeny === true) {
    content = getDenyImage(control.VCX, data.Stretch, control.MetaData.Bounds.Align, control.ComputedMinHeight);
  } else if (data.IsSupported === false) {
    content = (
      <div className="previewNotSupported">
        <div style={{ flexDirection: "column", alignSelf: "center" }}>
          <K2TruncateText line={4} style={{ textAlign: "center" }}>{`Náhled souboru "${data.ObjectId}" není podporovaný.`}</K2TruncateText>
        </div>
      </div>
    );
  } else {
    if (data.IsLoaded) {
      if (isOthersSupportedFile()) {
        content = <K2FileViewer url={data.Alias ? data.Alias : data.ObjectId} />;
      } else {
        content = <K2Img glyphId={data.Alias ? data.Alias : data.ObjectId} vcx={control.VCX} />;
      }
    }
  }
  return (
    <div ref={element} style={StyleHelper(control, props.style)} className={css.file}>
      {content}
    </div>
  );
};

export default K2FilePreview;
