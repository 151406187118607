import { LogLevel, Log, Helper, queryVariable } from "./common/common";
import { Context } from "./appcontext";
import { K2App } from "./components/app";
import "../wwwroot/css/default.css";
import "../wwwroot/css/login.css";
import "../wwwroot/css/datepicker.css";
import "../wwwroot/css/default_mouse_device.css";
import "./components/VCX/variables.css";

window.onload = (ev: Event) => {
  Log.init(LogLevel.Error);
  CSSByUrlParams();
  Context.registerApplication(new K2App());
  Context.getApplication().run();
};

window.onunload = (ev: Event) => {
  Context.getApplication().close(false, true);
};

document.querySelector("#reloadPageBtn").addEventListener("click", async () => {
  await Helper.sendErrorMessage("cancel reconnect in busy indicator", null);
  location.reload();
});

if (process.env.NODE_ENV !== "development") {
  // Zakázat tlačítko ZPĚT v prohlížeči
  history.pushState(null, null, document.URL);
  window.addEventListener("popstate", function () {
    history.pushState(null, null, document.URL);
    Context.getApplication().getActiveRealizer().processHotKey("ESC");
  });

  // tlačítko REFRESH v prohlížeči na dotaz
  window.addEventListener("beforeunload", beforeUnloadHandler);

  // Zakázet pravé tlačítko myši pro potlačení výchozího contexMenu nad internetovým prohlížečem.
  window.addEventListener("contextmenu", function () {
    event.preventDefault();
  });
}

function addCssFile(file: string): void {
  // načte css soubor do hlavičky webu
  if (!document.getElementById(file)) {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = file;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "./css/" + file + ".css";
    link.media = "all";
    head.appendChild(link);
  }
}

export function openUrlInsideThisWeb(url: string) {
  window.removeEventListener("beforeunload", beforeUnloadHandler);
  window.location.href = url;
  window.addEventListener("beforeunload", beforeUnloadHandler);
}

function beforeUnloadHandler(event: BeforeUnloadEvent) {
  if (Context.getApplication().HideReloadAlert) return;

  if (Context.getApplication().appViewRealizer) {
    event.preventDefault();
    event.returnValue = ""; //chrome potřebuje návratovou hodnotu
  }
}

function CSSByUrlParams() {
  let css = queryVariable("custom-style", undefined);

  if (process.env.NODE_ENV === "production") {
    if (css) {
      var lnk = document.createElement("link");
      lnk.href = `css/${css}.css`;
      lnk.rel = "stylesheet";
      lnk.type = "text/css";
      (document.head || document.documentElement).appendChild(lnk);
      document.body.className = css;
    }
  }

  if (process.env.NODE_ENV === "development") {
    if (!css) return;

    (async function () {
      try {
        const config = await fetch("./themes.json");
        const data: { [key: string]: string[] } = await config.json();
        document.body.className = css;

        data[css]?.map(async (file) => {
          const fileSplitted = file.split("/");
          const fileName = fileSplitted[fileSplitted.length - 1];

          (await import(`../themes/${css}/${fileName}`)).default;
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }
}
/* KONEC - Pro dotyková zařízení */

// var DESIGN_LAYOUT = false; // vykresli pouze zakladni layout, tlacitka a taby
