import * as React from "react";
import { GanttPointOptionsObject, XAxisOptions, YAxisBreaksOptions } from "highcharts";
import { GanttOperation, GanttResource, GanttCapacity } from "../../common/communication.base";

export interface ColorProps {
  AccentBaseColorBck: string;
  GridRulerColorBck: string;
  BaseColorBck1: string;
  ContentColorBck1: string;
  ContentChangeDecorateColorFrg: string;
  BaseColorFrg1: string;
  ContentFrame1: string;
  DataBrowseColorBck: string;
  DataBrowseColorFrg: string;
  DataChangeColorBck: string;
  DataChangeColorFrg: string;
}

export const getOperations = (items: any[][]) => {
  if (items == undefined) return [];

  const operations: GanttOperation[] = items.map((item): GanttOperation => {
    return {
      operationId: item[0],
      parentId: item[1],
      name: item[2],
      serieName: item[3],
      datetimeFrom: item[4],
      datetimeTo: item[5],
      completed: item[6],
      isMilestone: item[7],
      isStage: item[8],
      resourceId: item[9],
      resourceOrder: item[10],
      isExpanded: item[11],
      isInCollision: item[12],
      dependencyIds: item[13],
      dependencyTypes: item[14],
      customColor: item[15],
      workTime: item[16],
      workTimeUnit: item[17],
      htmlTooltip: item[18],
      description: item[19],
      customPattern: item[20],
      collisionRow: item[21],
      groupById: item[22],
      selected: item[23],
      readOnly: item[24],
    };
  });

  return operations;
};

export const getResources = (items: any[][]) => {
  if (items == undefined) return [];

  const resources: GanttResource[] = items.map((item): GanttResource => {
    return {
      resourceID: item[0],
      name: item[1],
      order: item[2],
      abbr: item[3],
      hasCollision: item[4],
      collisionCount: item[5],
    };
  });

  return resources;
};

export const getCapacities = (items: any[][]) => {
  if (items == undefined) return [];

  const capacities: GanttCapacity[] = items.map((item): GanttCapacity => {
    return {
      resourceID: item[0],
      order: item[1],
      datetimeFrom: item[2],
      datetimeTo: item[3],
      collisionRow: item[4],
    };
  });

  return capacities;
};

interface GanttPoint extends Highcharts.GanttPointOptionsObject {
  borderColor?: string;
  borderWidth?: number;
  custom?: {
    workTime: number;
    workTimeUnit: string;
    htmlTooltip: string;
  };
}

export const getDataitems = (
  items: GanttOperation[],
  byResources: boolean,
  arrayOfCategories: string[],
  byOperations: boolean,
  ownColors: boolean,
  leftAxisTableColumns: string[],
  leftAxisTableData: string[][],
  zoom: number,
  draggableX: boolean,
  draggableY: boolean,
  inEditMode: boolean,
  pointSelectAllowed: boolean
): Highcharts.GanttPointOptionsObject[] => {
  const offset = 365 * 70 + 19;
  const day = 1000 * 60 * 60 * 24;

  let dataitems: Highcharts.GanttPointOptionsObject[] = items.map((item, itemindex): GanttPoint => {
    let itemdependencies: Array<Highcharts.XrangePointConnectorsOptionsObject>;

    let itemdependency_ids_string: string;
    itemdependency_ids_string = item.dependencyIds;
    let itemdependency_ids: string[] = itemdependency_ids_string.split(";");

    let itemdependency_types_string: string;
    itemdependency_types_string = item.dependencyTypes;
    let itemdependency_types: string[] = itemdependency_types_string.split(";");

    if (itemdependency_ids[0].trim().length > 0) {
      itemdependencies = [];

      for (let i = 0; i < itemdependency_ids.length; i++) {
        //if (itemdependency_ids[i].trim() == "") {
        //  continue;
        //}

        let dependentitem: Highcharts.XrangePointConnectorsOptionsObject = {};
        dependentitem.to = itemdependency_ids[i];
        // dependentitem.type = "fastAvoid"; //nejde

        switch (itemdependency_types[i]) {
          case "FS": //Task B starts after task A is finished
            //default
            break;

          case "FF": //Task B must end together with task A
            dependentitem.startMarker = {
              align: "right", // Where to start the line (horizontally)
              enabled: true, // Add the marker symbol
            };
            dependentitem.endMarker = {
              align: "right", // Where to end the line (horizontally)
            };
            break;

          case "SS": //Tasks A and B start together
            (dependentitem.startMarker = {
              align: "left", // Where to start the line (horizontally)
              enabled: true, // Add the marker symbol
            }),
              (dependentitem.endMarker = {
                align: "left", // Where to end the line (horizontally)
              });
            break;

          case "SF": //Task B must start before task A finishes
            (dependentitem.startMarker = {
              align: "right", // Where to start the line (horizontally)
              enabled: true, // Add the marker symbol
            }),
              (dependentitem.endMarker = {
                align: "left", // Where to end the line (horizontally)
              });
            break;

          default: //0
        }

        itemdependencies.push(dependentitem);
      }
    }

    let data = {
      collapsed: item.isExpanded ? false : true,
      id: item.operationId.toString(),
      name: item.name,
      description: byResources ? item.name : item.description, //arrayOfCategories[item.resourceOrder],
      start: Math.round((item.datetimeFrom - offset) * day),
      end: Math.round((item.datetimeTo - offset) * day),
      completed: Math.round(item.completed) / 100,
      milestone: item.isMilestone,
      pointWidth: (item.isStage ? 3 : 12) * (zoom / 100),
      //borderColor: item.isInCollision ? "red" : null,
      //borderWidth: item.isInCollision ? 1 : null,
      // serieName: item[3],
      dependency: itemdependencies,
      custom: {
        workTime: item.workTime,
        workTimeUnit: item.workTimeUnit,
        htmlTooltip: item.htmlTooltip,
        readOnly: item.readOnly,
      },
      groupById: item.groupById, //dat do custom? pozor pak pri pouziti v dragDrop.groupBy
      dragDrop: {
        //draggableStart: false, u jednotlivych usecek neni
        //draggableEnd: false,
        draggableX: draggableX && inEditMode && !item.readOnly,
        draggableY: draggableY && inEditMode && !item.readOnly,
        dragMinY: byResources ? 0 : 0,
        dragMaxY: byResources ? arrayOfCategories.length - 1 : 0,
      },
      selected: pointSelectAllowed ? item.selected : false,
    };

    if (ownColors && item.customColor) {
      (data as any).color = item.customColor;
    } else {
      (data as any).color = defaultColors[itemindex % defaultColors.length];
    }

    // pattern
    if (item.customPattern != 0) {
      let d: string;
      switch (item.customPattern) {
        case 1: //pada doprava
          d = "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11";
          break;
        case 2: //doleva
          d = "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11";
          break;
        case 3: //oba smery
          d = "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11";
          break;
      }

      let lcolor = (data as any).color;
      (data as any).color = {
        pattern: {
          backgroundColor: lcolor,
          path: {
            d: d,
            stroke: "black",
            strokeWidth: 1,
          },
          width: 10,
          height: 10,
        },
      };
    }

    /*
    if (leftAxisTableData?.length > 1) {
      leftAxisTableData[item.resourceOrder].map((value, index) => {
        if (index === 0) return;

        data = {
          ...data,
          [leftAxisTableColumns[item.resourceOrder]]: value[index],
        };
      });
    }
    */

    if (byResources) {
      return {
        y: item.resourceOrder + item.collisionRow * 0.02,
        ...data,
      };
    } else {
      //byOperations
      return {
        y: itemindex, //byResources ? (item.resourceOrder+(item.collisionRow*0.02)) : itemindex,
        parent: item.parentId == 0 ? null : item.parentId.toString(),
        ...data,
      };
    }
  });

  return dataitems;
};

export const getSeries = (
  operations: GanttOperation[],
  dataitems: GanttPointOptionsObject[],
  capacities: GanttCapacity[],
  zoom: number,
  pointSelectAllowed: boolean
) => {
  let seriesdataitemsarray: string[] = [];
  let found: boolean;
  let serieName: string;
  for (let i = 0; i < operations.length; i++) {
    serieName = operations[i].serieName;
    found = false;
    for (let j = 0; j < seriesdataitemsarray.length; j++) {
      if (seriesdataitemsarray[j] == serieName) {
        found = true;
      }
    }
    if (!found) {
      seriesdataitemsarray.push(serieName);
    }
  }

  //2. dataitems do serii
  let seriesdataitems: Array<Highcharts.SeriesOptionsType> = []; // Highcharts.SeriesGanttOptions[] = [];
  for (let i = 0; i < seriesdataitemsarray.length; i++) {
    serieName = seriesdataitemsarray[i];

    let dataitemsforserie = new Array<Highcharts.GanttPointOptionsObject>();

    for (let j = 0; j < operations.length; j++) {
      if (serieName == operations[j].serieName) {
        dataitemsforserie.push(dataitems[j]);
      }
    }

    let seriesGanttOptions: Highcharts.SeriesGanttOptions = {
      name: serieName,
      type: "gantt",
      zIndex: 10,
      data: dataitemsforserie,
    };

    seriesdataitems.push(seriesGanttOptions);
  }

  //kapacity
  let capacitiesArray = new Array<Highcharts.XrangePointOptionsObject>();

  /* SRAFOVANI
  //https://www.highcharts.com/docs/chart-design-and-style/pattern-fills
  //https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/css/pattern/
  let patternObject : Highcharts.PatternObject  = 
  {
    pattern: {
      aspectRatio : 1,
      backgroundColor : "#7c151c",
      color : "#7cb5ec",
      image: '',
      patternTransform : '',
      path: {
          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
          strokeWidth: 3
      },
      width: 10,
      height: 10,
      opacity: 0.4
    }
  }
  */

  for (let i = 0; i < capacities.length; i++) {
    capacitiesArray.push({
      color: "lightgray",
      //color : patternObject,
      x: capacities[i].datetimeFrom * 1000,
      x2: capacities[i].datetimeTo * 1000,
      y: capacities[i].order + capacities[i].collisionRow * 0.02,
    });
  }

  let seriesXrangeOptions: Highcharts.SeriesXrangeOptions = {
    type: "xrange",
    allowPointSelect: pointSelectAllowed,
    enableMouseTracking: pointSelectAllowed,
    showInNavigator: false,
    skipKeyboardNavigation: true,
    zIndex: 5,
    pointWidth: 15 * (zoom / 100),
    data: capacitiesArray,
    pointPadding: 0,
    events: {
      //click: handleCapacityClick,
    },
  };

  seriesdataitems.push(seriesXrangeOptions);

  return seriesdataitems;

  /*
seriesdataitems.push({
  type: "polygon",
  color: "gray",
  data: [ [1613361600000, 1],
  [1612841640000, 1],
  [1612841640000, 10],
  [1613361600000, 10]]
}); 
*/
};

export const getArrayOfCategories = (resources: GanttResource[]) => {
  if (resources == undefined) return [];

  let arrayOfCategories = resources.map((item) => item.abbr);

  return arrayOfCategories;
};

export const getYAxisOptions = (
  byOperations: boolean,
  byResources: boolean,
  arrayOfCategories: string[],
  resources: GanttResource[],
  operations: GanttOperation[],
  isDesktop: boolean,
  fontColor: string,
  leftAxisTableColumns: string[],
  zoom: number
) => {
  let yAxisOptions: Highcharts.YAxisOptions;

  if (byOperations) {
    let categories = operations.map((item) => item.name);

    yAxisOptions = {
      type: "treegrid",
      staticScale: isDesktop ? 22 * (zoom / 100) : 21 * (zoom / 100),
      // categories: categories,
      labels: {
        enabled: true,
        style: {
          color: fontColor,
        },
      },
      breaks: categories.map(() => {
        return {
          breakSize: 0,
          from: 0,
          to: 0,
        };
      }),
      //     labels: {
      //       enabled: true,
      //     },
      //     grid: {
      //       columns: [
      //         {
      //           title: {
      //             //text: "Etapy/činnosti",
      //           },
      //           labels: {
      //             format: "{point.name}",
      //           },
      //         },
      //       ],
      //     },
    };
  }

  //GRAF ZDROJU
  if (byResources) {
    //vysky radku pomoci breaks
    let lbreaks: Array<Highcharts.YAxisBreaksOptions> = [];
    for (const resource of resources) {
      if (resource.collisionCount > 0) {
        for (let i = 0; i < resource.collisionCount; i++) {
          let lbreak: Highcharts.YAxisBreaksOptions = {
            breakSize: 0.7,
            from: resource.order + 0.01 + i * 0.02,
            to: resource.order + 0.01 + i * 0.02,
          };
          lbreaks.push(lbreak);
        }
      }
    }

    yAxisOptions = {
      type: "category",
      staticScale: isDesktop ? 22 * (zoom / 100) : 21 * (zoom / 100),
      labels: {
        enabled: true,
        style: {
          color: fontColor,
        },
        formatter: (context) => {
          const value = context.value as string;

          for (const resource of resources) {
            const currentRow = resource.abbr === value;

            if (currentRow) {
              if (resource.hasCollision) {
                return `<span style="color: red">${value}</span>`;
              }
            }
          }

          return value;
        },
      },
      categories: arrayOfCategories,
      breaks: lbreaks,
      min: 0,
      max: arrayOfCategories.length - 1,
      showEmpty: true,
      startOfWeek: 1,
      /*
      grid:{
        columns: [{
          title: {
            text: ''
          },
          labels: {
            format: '{point.name}'
          }
          }, {
          title: {
            text: 'Kapacita'
          },
          labels: {
            format: '{point.CapacityUMCalc}'
          }
          }, {
          title: {
            text: 'Požadavky'
          },
          labels: {
            format: '{point.RequirementsUMCalc}'
          }
        }]
      }
*/
    };

    /*
    if (leftAxisTableColumns?.length > 1) {

      let columns: XAxisOptions[] = [];
      columns = leftAxisTableColumns.map((title) => {
        return {
          title: {
            text: title,
          },
          labels: {
            format: "{point.name}",
          },
        };
      });

      yAxisOptions.grid.columns = columns;
    }
    */
  }

  return yAxisOptions;
};

export const updateDataAfterDrop = (e: Highcharts.PointDropEventObject) => {
  let updatedData: string[][] = [];
  let newPoints: Highcharts.Dictionary<Highcharts.PointDragDropObject> = e.newPoints;

  for (let id in newPoints) {
    let changes: Highcharts.PointDragDropObject = newPoints[id];

    for (let key in changes.newValues) {
      let value = changes.newValues[key];

      if (key === "end") {
        value = new Date(value).setSeconds(new Date(value).getSeconds() - 2);
      }

      updatedData.push([id, key, value.toString()]);
    }
  }

  return updatedData;
};

export const NoData = (colors: ColorProps) => {
  if (!colors) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
        background: colors.DataBrowseColorBck,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: "1 1 auto",
          background: colors.ContentFrame1,
          color: colors.DataBrowseColorFrg,
          padding: "3px",
          justifyContent: "center",
        }}
      >
        Žádná data k zobrazení
      </div>
    </div>
  );
};

const defaultColors = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];

export const getPlotBands = (days: number[][]) => {
  return days.map((day) => {
    let color = "";

    if (day[0] === 1) {
      color = "#eee";
    }

    if (day[0] === 2) {
      color = "salmon";
    }

    return {
      from: day[1] * 1000,
      to: day[2] * 1000,
      color: color,
      zIndex: 1,
    };
  });
};
