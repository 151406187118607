import React, { useEffect } from "react";
import { useState } from "react";
import { getAttributes } from "../../common/common";
import { TDataTextFormat, UpdateFormattableInput } from "../../common/communication.base";
import { NclFormattableInput } from "../../common/components.ncl";
import { setRequestedActiveControl } from "../app";
import { useServerState } from "../hooks";
import K2LabelInput from "../Input/K2LabelInput";
import { StyleHelper, WithContextPlacementProps } from "../k2hoc";
import css from "./FormattableInput.scss";

const K2FormattableInput = (props: WithContextPlacementProps) => {
  const [control, data, element] = useServerState<NclFormattableInput, UpdateFormattableInput, HTMLTextAreaElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclFormattableInput,
    setAsActive
  );
  const [value, setValue] = useState(data.Text);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(data.Text);
  }, [data.Text]);

  function setAsActive(isActive: boolean) {
    if (element.current && isActive) {
      element.current.focus({ preventScroll: true });
    }
  }

  const getHTML = () => {
    return (
      <div className={css.fi_html}>
        <iframe
          style={{
            border: "none",
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
          srcDoc={data.Text}
          sandbox=""
        />
      </div>
    );
  };

  const getTextareaTag = () => {
    return (
      <textarea
        className={`${css.fi_textarea}`}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={element}
        {...getAttributes(data)}
        value={value}
        id={control.Ncl.Name}
        rows={control.Size}
      />
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (
      e.key === "Enter" ||
      e.key === "Delete" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowRight" ||
      e.key === "z" ||
      e.key === "y" ||
      e.key === "-" ||
      e.key === "+" ||
      e.keyCode === 110 || // numpad decimal
      e.key.match(/^[0-9]$/) ||
      e.key === "Backspace"
    ) {
      e.stopPropagation();
    }
  };

  const handleFocus = (e: React.FocusEvent<any>) => {
    e.stopPropagation();
    control.setActiveControlRequested();
    setFocused(true);
  };

  const handleBlur = (e: React.FocusEvent<any>) => {
    setFocused(false);
    setRequestedActiveControl(e, control.getRealizerUID());
    changeData(value, true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let targetValue = e.target.value;

    if (value != targetValue) {
      setValue(targetValue);
      changeData(targetValue, false);
    }
  };

  const changeData = (data: string, accept: boolean) => {
    control.change(data, accept);
  };

  let content: JSX.Element = null;

  switch (data.TextFormat) {
    case TDataTextFormat.dtfText:
      content = getTextareaTag();
      break;
    case TDataTextFormat.dtfHTML:
      content = getHTML();
      break;
  }

  return (
    <div style={StyleHelper(control, props.style)} className={css.fi}>
      <K2LabelInput
        titleVisible={control.isVisibleTitle()}
        title={data.Title}
        errorsCount={null}
        warningsCount={null}
        focused={focused}
        inEditMode={control.InEditMode}
        modified={data.Modified}
        readOnly={data.ReadOnly}
        vcx={control.VCX}
        frgtData={control.Ncl.FrgtData}
        id={control.Ncl.Name}
      >
        {content}
      </K2LabelInput>
    </div>
  );
};

export default K2FormattableInput;
