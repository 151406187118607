import * as React from "react";
import { AcquireControl, K2ComponentState, StyleHelper, WithContextPlacementProps } from "../k2hoc";
import { K2Header } from "../Expander/K2Expander";
import { FrgtGanttData, UpdateControl } from "../../common/communication.base";
import { UpdateHeadered, UpdateInnerGantt } from "../../common/communication.base";
import { NclGantt, NclGanttFooter, NclGanttContent, NclInnerGantt } from "../../common/components.ncl";
import { K2GanttCommon } from "../common/K2Gantt";
import {
  getDataitems,
  getSeries,
  getArrayOfCategories,
  getYAxisOptions,
  updateDataAfterDrop,
  getOperations,
  getResources,
  getCapacities,
  NoData,
  getPlotBands,
  ColorProps,
} from "../common/GanttFunctions";
import { SeriesGanttOptions } from "highcharts";
import K2ToolBar from "../ToolBar/K2ToolBar";
import css from "./Gantt.scss";

export class K2InnerGantt extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateInnerGantt>> {
  static displayName = `K2InnerGantt`;
  private control: NclInnerGantt;
  colors: ColorProps;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclInnerGantt;
    }) as NclInnerGantt;
    this.control.setMaxRowCount(1);

    this.state = {
      data: this.control.init(this) as UpdateInnerGantt,
      vcxVersion: -1,
    };

    this.colors = {
      AccentBaseColorBck: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.AccentBaseColorBck),
      GridRulerColorBck: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.GridRulerColorBck),
      BaseColorBck1: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorBck1),
      ContentColorBck1: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentColorBck1),
      ContentChangeDecorateColorFrg: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentChangeDecorateColorFrg),
      BaseColorFrg1: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.BaseColorFrg1),
      ContentFrame1: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.ContentFrame1),
      DataBrowseColorBck: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.DataBrowseColorBck),
      DataBrowseColorFrg: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.DataBrowseColorFrg),
      DataChangeColorBck: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.DataChangeColorBck),
      DataChangeColorFrg: this.control.VCX.getColor(this.control.VCX.Data.ColorMap.DataChangeColorFrg),
    };
  }

  private handleClick = (e: Highcharts.PointClickEventObject) => {
    if (e.point.selected) {
      this.control.defaultAccept([e.point.options.id]);
    }
  };

  private handleGanttContextMenu = (e: Highcharts.PointerEventObject) => {
    this.control.ganttContextMenu();
  };

  private handleSelect(e: Highcharts.PointInteractionEventObject) {
    const point = this as unknown as Highcharts.Point;
    this.control.selectPoint([point.options.id, e.accumulate]);
  }

  private handleUnselect(e: Highcharts.PointInteractionEventObject) {
    const point = this as unknown as Highcharts.Point;
    this.control.unselectPoint([point.options.id, e.accumulate]);
  }

  private handleRangeSelect = (e: Highcharts.AxisSetExtremesEventObject) => {
    this.control.rangeSelect([e.min.toString(), e.max.toString()]);
  };

  private handleDrop = (e: Highcharts.PointDropEventObject) => {
    this.control.change(updateDataAfterDrop(e), e.target.options.id.toString());
  };

  updateState(state: UpdateInnerGantt) {
    this.setState((prevState: K2ComponentState<UpdateInnerGantt> & { series: Array<Highcharts.SeriesOptionsType> /*SeriesOptions/*SeriesGanttOptions[]*/ }) => {
      if (state instanceof UpdateInnerGantt) {
        let operations = getOperations(state.Operations.toJS());
        let resources = getResources(state.Resources.toJS());
        let capacities = getCapacities(state.Capacities.toJS());
        let arrayOfCategories = getArrayOfCategories(resources);
        let yAxisOptions = getYAxisOptions(
          this.control.Container.byOperations(),
          this.control.Container.byResources(),
          arrayOfCategories,
          resources,
          operations,
          false,
          this.colors.DataBrowseColorFrg,
          this.control.Container.LeftAxisTableColumns,
          this.control.VCX.Zoom
        );
        let dataitems = getDataitems(
          operations,
          this.control.Container.byResources(),
          arrayOfCategories,
          this.control.Container.byOperations(),
          this.control.Container.ownColors(),
          this.control.Container.LeftAxisTableColumns,
          this.control.Container.LeftAxisTableData,
          this.control.VCX.Zoom,
          state.DraggableX,
          state.DraggableY,
          this.control.InEditMode,
          this.control.Container.pointSelectAllowed()
        );
        let seriesdataitems = getSeries(operations, dataitems, capacities, this.control.VCX.Zoom, this.control.Container.pointSelectAllowed());
        let plotBands = getPlotBands(state.DayBackgrounds);

        this.control.DataItems = dataitems;
        this.control.SeriesDataItems = seriesdataitems;
        this.control.YAxisOptions = yAxisOptions;
        this.control.Categories = yAxisOptions.categories;
        this.control.DraggableX = state.DraggableX;
        this.control.DraggableY = state.DraggableY;
        this.control.XAxisOptions = {
          minRangeDate: state.MinRangeDate,
          maxRangeDate: state.MaxRangeDate,
          rangeDateFrom: state.RangeDateFrom,
          rangeDateTo: state.RangeDateTo,
          plotBands: plotBands,
        };

        return { data: state };
      }
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    if (this.control.SeriesDataItems.length === 0 || !this.colors) return NoData(this.colors);

    return (
      <div
        style={StyleHelper(this.control, {
          ...this.props.style,
          flex: "1 1 auto",
          flexDirection: "column",
          justifyContent: "center",
        })}
        className={css.gantt}
      >
        <K2GanttCommon
          // key={}
          handleClick={this.handleClick}
          handleGanttContextMenu={this.handleGanttContextMenu}
          handleRangeSelect={this.handleRangeSelect}
          handleDrop={this.handleDrop}
          handleSelect={this.handleSelect}
          handleUnselect={this.handleUnselect}
          inEditMode={this.control.InEditMode}
          dataitems={this.control.DataItems}
          seriesdataitems={this.control.SeriesDataItems}
          categories={this.control.Categories}
          yAxisOptions={this.control.YAxisOptions}
          byOperations={this.control.Container.byOperations()}
          byResources={this.control.Container.byResources()}
          headerAtBottom={(((this.control.Parent as NclGanttContent).Parent as NclGantt).MetaData.FrgtData as FrgtGanttData).HeaderAtBottom}
          ownColors={this.control.Container.ownColors()}
          hideNavigator={this.control.Container.hideNavigator()}
          hideXAxis={this.control.Container.hideXAxis()}
          hideYAxis={this.control.Container.hideYAxis()}
          hideDateIndicator={this.control.Container.hideDateIndicator()}
          hideRangeSelectorDates={this.control.Container.hideRangeSelectorDates()}
          //showDateTime={this.control.Container.showDateTime()}
          twoRowXAxis={this.control.Container.twoRowXAxis()}
          timeAxisPrecision={this.control.Container.timeAxisPrecision()}
          draggableStartEnd={this.control.Container.draggableStartEnd()}
          pointSelectAllowed={this.control.Container.pointSelectAllowed()}
          draggableX={this.control.DraggableX}
          draggableY={this.control.DraggableY}
          colors={this.colors}
          xAxisOptions={this.control.XAxisOptions}
          zoom={this.control.VCX.Zoom}
        />
        {this.control.Container.showFooter() && (
          <K2GanttFooter controlUID={this.control.Container.Footer.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
        )}
      </div>
    );
  }
}

export class K2GanttContent extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2GanttContent`;
  private control: NclGanttContent;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGanttContent;
    }) as NclGanttContent;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, { flex: "1 1 auto", ...this.props.style })}>
        <K2InnerGantt controlUID={this.control.GanttInner.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export class K2GanttFooter extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateControl>> {
  static displayName = `K2GanttFooter`;
  private control: NclGanttFooter;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGanttFooter;
    }) as NclGanttFooter;
    this.state = { data: this.control.init(this) as UpdateControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateControl>) => {
      return { data: state as UpdateControl };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    return (
      <div style={StyleHelper(this.control, this.props.style)}>
        <K2ToolBar controlUID={this.control.LeftToolbar.MetaData.ControlUID} vrUID={this.control.getRealizerUID()} />
      </div>
    );
  }
}

export class K2Gantt extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateHeadered>> {
  private control: NclGantt;
  static displayName = `K2Gantt`;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(this.props.controlUID, this.props.vrUID, (ctrl) => {
      return ctrl instanceof NclGantt;
    }) as NclGantt;
    this.state = { data: this.control.init(this) as UpdateHeadered, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateHeadered>) => {
      return { data: state as UpdateHeadered };
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let title = this.state.data.Title ? this.state.data.Title : "";
    if (this.state.data.TitleSuffix) {
      title += " " + this.state.data.TitleSuffix;
    }

    return (
      <div style={StyleHelper(this.control, { ...this.props.style, flexDirection: "column" })}>
        {this.control.isShowHeader() && <K2Header controlUID={this.control.Header.MetaData.ControlUID} vrUID={this.props.vrUID} title={title} />}
        <K2GanttContent controlUID={this.control.Content.MetaData.ControlUID} vrUID={this.props.vrUID} />
      </div>
    );
  }
}

//DEMO: https://www.highcharts.com/gantt/demo/project-management/dark-unica
//MANUAL: https://www.highcharts.com/docs/gantt/gantt-task-dependencies
//API: https://api.highcharts.com/gantt/
//FORUM: https://www.highcharts.com/forum/
//Organizacni struktura: https://www.highcharts.com/blog/tutorials/how-to-use-an-org-chart-more-effectively/
