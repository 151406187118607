import * as React from "react";

import { Context } from "../../appcontext";
import { getVersion, Helper, sleep } from "../../common/common";
import { LoginData, SessionInfo, SessionsInfo, TGPSRequest } from "../../common/communication.base";
import * as CryptoJS from "crypto-js";
import { K2Key } from "../../app";
import K2Img from "../Image/K2Img";
import { VisualContext } from "../../common/visualContext";
import moment from "moment";

const svg_no_connect =
  '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface1"><path d="M15.508,2.094l-1.414,1.414l2.523,2.523c-0.207,-0.011 -0.406,-0.031 -0.617,-0.031c-2.391,-0 -4.582,0.867 -6.305,2.281l-5.988,-5.988l-1.414,1.414l26,26l1.414,-1.414l-5.988,-5.988c1.418,-1.723 2.281,-3.914 2.281,-6.305c0,-1.5 -0.301,-2.898 -0.902,-4.098l-1.5,1.5c0.3,0.797 0.402,1.7 0.402,2.598c0,1.84 -0.641,3.535 -1.699,4.891l-11.192,-11.196c1.356,-1.054 3.051,-1.695 4.891,-1.695c0.188,0 0.371,0.016 0.559,0.031l-2.465,2.461l1.414,1.414l4.906,-4.906l-4.906,-4.906Zm-8.348,9.254c-0.738,1.39 -1.16,2.976 -1.16,4.652c0,1.5 0.301,2.898 0.898,4.098l1.5,-1.5c-0.199,-0.797 -0.398,-1.7 -0.398,-2.598c0,-1.121 0.234,-2.188 0.656,-3.156l-1.496,-1.496Zm9.039,9.039l-4.613,4.613l4.906,4.906l1.414,-1.414l-2.527,-2.523c0.207,0.011 0.414,0.031 0.621,0.031c1.676,0 3.262,-0.422 4.652,-1.16l-1.496,-1.496c-0.968,0.422 -2.035,0.656 -3.156,0.656c-0.187,0 -0.371,-0.012 -0.559,-0.027l2.172,-2.172l-1.414,-1.414Z" style="fill:#f44;fill-rule:nonzero;"/></g></svg>';
const svg_eye = (
  <svg version="1.1" id="eye" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32">
    <path
      id="eye_1_"
      d="M16,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S14.3,13,16,13z M30.7,16.7c-0.3,0.3-6.7,6.6-13.6,7.2C16.7,24,16.4,24,16,24s-0.7,0-1.1-0.1C8,23.3,1.6,17,1.3,16.7L1,16l0.3-0.7C1.6,15,8,8.7,14.9,8.1C15.3,8,15.6,8,16,8s0.7,0,1.1,0.1c6.9,0.6,13.3,7,13.6,7.2L31,16L30.7,16.7z M16.8,10c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0C11.7,10.4,9,12.9,9,16s2.7,5.6,6.2,6c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c3.5-0.3,6.2-2.9,6.2-6C23,12.9,20.3,10.4,16.8,10z M3,16c0.9,0.8,2.7,2.2,4.9,3.4C7.3,18.4,7,17.2,7,16s0.3-2.4,0.9-3.4C5.7,13.8,3.9,15.2,3,16z M29,16c-0.9-0.8-2.7-2.2-4.9-3.4c0.5,1,0.9,2.2,0.9,3.4c0,1.2-0.3,2.4-0.9,3.4C26.3,18.2,28.1,16.8,29,16z"
    ></path>
  </svg>
);
const svg_no_eye = (
  <svg version="1.1" id="eye.no_1_" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32">
    <path
      id="eye.no"
      d="M31,16l-0.3-0.7C30.4,15,24,8.7,17.1,8.1C16.7,8,16.4,8,16,8c-0.4,0-0.7,0-1.1,0.1c-1.5,0.1-3,0.5-4.4,1.1L6.8,5.4L5.4,6.8l19.8,19.8l1.4-1.4L23.4,22c4.1-2.1,7.1-5.1,7.3-5.3L31,16z M18.7,17.3c0.2-0.4,0.3-0.8,0.3-1.3c0-1.7-1.3-3-3-3c-0.5,0-0.9,0.1-1.3,0.3l-2.4-2.4c0.9-0.5,1.9-0.8,2.9-0.9c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c3.5,0.3,6.2,2.9,6.2,6c0,1.5-0.6,2.8-1.7,3.9L18.7,17.3z M24.1,19.4c0.6-1,0.9-2.2,0.9-3.4c0-1.2-0.3-2.4-0.9-3.4c2.2,1.2,4,2.6,4.9,3.4C28.1,16.8,26.3,18.2,24.1,19.4z M16.8,22c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0C11.7,21.6,9,19.1,9,16c0-0.7,0.2-1.5,0.5-2.1l-2.8-2.8c-3.1,1.9-5.2,4-5.4,4.2L1,16l0.3,0.7C1.6,17,8,23.3,14.9,23.9c0.3,0,0.7,0.1,1.1,0.1c0.4,0,0.7,0,1.1-0.1c0.7-0.1,1.4-0.2,2.1-0.4l-1.7-1.7C17.2,21.9,17,21.9,16.8,22z M7.9,19.4c-2.2-1.2-4-2.6-4.9-3.4c0.9-0.8,2.7-2.2,4.9-3.4C7.3,13.6,7,14.8,7,16C7,17.2,7.3,18.4,7.9,19.4z"
    ></path>
  </svg>
);

interface ReconnectProps {
  reconnectFce: () => void;
}

interface LoginViewProps {
  headerImg: string;
  footerImg: string;
  ver: string;
  ToSLink: string;
  privacyPolicyLink: string;
  licenseHolder: string;
  more?: string;
  reconnect?: ReconnectProps;
}

enum LoginState {
  none,
  checkInfo,
  waitToTwoFactorConfirm,
  selectSession,
  login,
  success,
  error,
  reconnect,
  wait2FResult
}

interface LoginViewState {
  username?: string;
  password?: string;
  loginState: LoginState;
  requestState?: LoginState;
  errorMessage?: string;
  revealPassword: boolean;
  version: string;
  loginData?: LoginData;
  nextTryIn: number;
  sessions?: Array<SessionsInfo>;
  sessionsVersion?:number;
  sessionId?:string;
  wsid?:string;
}

// const MAX_RECONNECT_COUNT = 3;
const RECONNECT_DELAY = 5; // seconds

export default class LoginView extends React.PureComponent<LoginViewProps, LoginViewState> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  userField: HTMLInputElement;
  rememberLogin = false;
  errorTries = 0;
  // reconnectCountExceeded = false;
  autoLogin = false;
  countdown = 0;

  constructor(props: LoginViewProps) {
    super(props);
    this.state = {
      loginState: props.reconnect ? LoginState.reconnect : LoginState.none,
      revealPassword: false,
      version: "",
      username: "",
      password: "",
      nextTryIn: 0,
    };
  }

  async componentDidMount() {
    if (!this.props.reconnect) {
      const version = await getVersion();
      Context.getApplication().Version = version;
      this.setState({ version: version });
    }

    this.userField ? this.userField.focus() : null;

    if (Context.DeviceInfo.AutoLogin == "0") {
      document.cookie = "login=";
    }

    const loginCookie = document.cookie.split("; ").find((cookie) => cookie.includes("login"));

    if (!loginCookie) return;

    let [name, password, autoLogin] = [...loginCookie.split(",")];
    name = name.replace("login=", "");

    if (autoLogin === "true") {
      const pass = CryptoJS.AES.decrypt(password, K2Key).toString(CryptoJS.enc.Utf8);
      this.autoLogin = autoLogin === "true";
      this.rememberLogin = true;
      this.setState({ username: name, password: pass, loginState: (this.props.reconnect)?LoginState.login:LoginState.checkInfo });
    }
  }

  render() {
    let content: JSX.Element = null;
    if (this.state.loginState === LoginState.none) {
      content = this.getLoginForm();
    } else {
      content = this.getLoginStateInfo();
    }
    return (
      <div>
        <div className="login-container animation-container">
          <div className="login-form">
            {!this.props.reconnect && this.state.loginState != LoginState.selectSession && (
              <div className="logo-container">
                <img src={this.props.headerImg} alt={"ERP " + this.props.ver} className="main-logo one-row" />
              </div>
            )}
            <div className="login-content">{content}</div>
          </div>
        </div>
      </div>
    );
  }

  private getLoginStateInfo(): JSX.Element {
    let img: string = null;
    let text: string = null;
    let cls: string = null;
    switch (this.state.loginState) {
      case LoginState.reconnect:
        if(!this.autoLogin){
          if (this.props.reconnect.reconnectFce) {
            return (
              <div className="login-info reconnect-info">
                <div className="login-reconnect">
                  <div className="svg" dangerouslySetInnerHTML={{ __html: svg_no_connect }} />
                  <span>Chyba připojení, zkontrolujte prosím připojení k internetu.</span>
                </div>
                <button
                  className="loginBtn"
                  onClick={() => {
                    this.props.reconnect.reconnectFce.call(this);
                  }}
                >
                  Obnovit spojení
                </button>
              </div>
            );
          } else {
            return (
              <div className="login-info">
                <div className="login-reconnect">
                  <div className="svg" dangerouslySetInnerHTML={{ __html: svg_no_connect }} />
                  <span>Došlo k odhlášení uživatele na serveru.</span>
                </div>
                <button
                  className="loginBtn"
                  onClick={() => {
                    location.reload();
                  }}
                >
                  Přihlásit
                </button>
              </div>
            );
          }
          break;
        }
      case LoginState.checkInfo:
      case LoginState.login:
        img = "img/connecting.svg";
        text = "Připojuji...";
        cls = "loginBusyIndicatorRotate " + "loginBusyIndicatorBorder";
        break;
      case LoginState.wait2FResult:  
      case LoginState.waitToTwoFactorConfirm:
        img = "img/devices.svg";
        text = "Pokračujte ověřením na svém mobilním zařízení...";
        break;
      case LoginState.success:
        img = "img/check.svg";
        text = "Ověřeno...";
        cls = "loginBusyIndicatorFullBorder";
        break;
      case LoginState.error:
        return (
          <div className="login-info">
            <div className="login-err">
              <div className="no-shrink">
                <img src={"img/error.red.svg"} className="imgErr" />
              </div>
              <div className="error-message">
                <span>{this.state.errorMessage}</span>
                {this.autoLogin &&
                  (false /*&& this.reconnectCountExceeded*/ ? (
                    <span>Dosaženo maximálního počtu pokusů o obnovení připojení.</span>
                  ) : this.state.nextTryIn < 0 ? (
                    <span>Pokus o obnovení připojení...</span>
                  ) : (
                    <span>Pokus o obnovení připojení za {this.state.nextTryIn} s.</span>
                  ))}
              </div>
            </div>
            <button
              className="loginBtn"
              onClick={() => {
                location.reload();
              }}
            >
              Opakovat
            </button>
            <button className="loginBtn reconnectBtn" onClick={this.changeUser}>
              Změnit uživatele
            </button>
          </div>
        );
      case LoginState.selectSession:
          let content:JSX.Element = undefined;
          if(this.state.sessions && this.state.sessions.length > 0){
            content = <>
              {this.state.sessions.map((server,i)=>{
                return <div key={`session_${i}`} style={{display:"contents"}}>
                {server.List.map((item, index)=>{
                  return <div key={index} className="select-session-box">
                            <div className="session-state" style={{backgroundColor:(!item.IsActiveConnection)?"#FF6464":"#59E17D"}}/>
                            <div className="session-info-box">
                              <div className="session-info">
                                <img src={this.getOSImage(item.OS)}/>
                                <p className="session-info-txt session-info-txt-main">{this.getDeviceInfo(item)}</p>
                              </div>
                              <div className="session-info">
                                <img src={this.getBrowserImage(item.Browser)}/>
                                <div className="session-info-txt">
                                  <p placeholder="Datum a čas přihlášení">{`${moment(item.Created).format('DD.MM.YY HH:mm:ss')}`}</p>-<p placeholder="Datum a čas poslední akce">{`${(item.LastUserInteractiveTime)?moment(item.LastUserInteractiveTime).format('DD.MM.YY HH:mm:ss'):moment(item.Created).format('DD.MM.YY HH:mm:ss')}`}</p>
                                </div>
                              </div>
                            </div>
                            <div className="session-btns">
                              <div style={{flex:"1 0 auto", margin:"auto", alignItems:"center"}}>
                              <span className="button session-btn session-btn-cancel" onClick={()=>this.closeSession(item.ID)}/>
                              </div>
                              <div style={{flex:"0 1 0%", borderTop:"1px solid #CFCFCF"}}/>
                              <div style={{flex:"1 0 auto", margin:"auto", alignItems:"center"}}>
                                <span className="button session-btn session-btn-connect" onClick={()=>this.loginToSesion(item.ID)}/>
                              </div>
                            </div>
                          </div>
                })}
                </div>
              })}
            </>
          }else{
            content = <div className="session-msg-center"><p>Všechny relace byly úspěšně ukončeny.</p></div>
          }
          return (
            <div className="login-info">
              <div className="select-session-title">Dostupná připojení</div>
              <div className="select-session-container">
                {content}
              </div>
              
              <button className="loginBtn session-loginBtn" onClick={this.login}>
                <div style={{justifyContent:"center"}}>
                  <span style={{backgroundRepeat:"no-repeat", backgroundImage:"url(img/add_connect.svg)", height:"16px", width:"16px", marginRight:"8px"}}/>
                  <p>Nové přihlášení</p>
                </div>
              </button>
            </div>
          )
      default:
        break;
    }

    return (
      <div className="login-info">
        <div className={"loginBusyIndicatorIcon " + cls} />
        <img src={img} className="imgInfo" />
        <span className="text">{text}</span>
        {this.props.reconnect && (
          <button className="loginBtn reconnectBtn" onClick={this.reloadPage}>
            Zrušit
          </button>
        )}
      </div>
    );
  }

  private getDeviceInfo(info:SessionInfo): string {
    if(info){
      if(info.DeviceInfo) return info.DeviceInfo;
      if(info.OS) return info.OS;
    }

    return 'Neznámé zařízení.';
  }
  
  private getBrowserImage(browser: string) {
    if(!browser) return undefined;
    browser= browser.toUpperCase();
    if(browser.indexOf("CHROME") >= 0){
      return 'img/chrome.svg';
    }else if(browser.indexOf("FIREFOX") >= 0){
      return 'img/firefox.svg';
    }else if(browser.indexOf("SAFARI") >= 0){
      return 'img/safari.svg';
    }
  }

  private getOSImage(os: string) {
    if(!os) return undefined;
    os = os.toUpperCase();
    if(os.indexOf("WINDOWS") >= 0){
      return 'img/os_windows.svg';
    }else if(os.indexOf("LINUX") >= 0){
      return 'img/os_linux.svg';
    }else if(os.indexOf("IOS") >= 0){
      return 'img/os_apple.svg';
    }else if(os.indexOf("ANDROID") >= 0){
      return 'img/os_android.svg';
    }
  }

  reloadPage = async () => {
    await Helper.sendErrorMessage("cancel reconnect in login view", null);
    location.reload();
  };

  changeUser = () => {
    document.cookie = "login=";
    location.reload();
  };

  loginToSesion = (id:string) => {
    this.setState({sessionId:id, requestState:undefined, loginState:this.state.requestState })
  };

  closeSession = (sid:string) => {
    Context.getApplication().closeSession(this.state.username, this.state.password, sid).then(value=>{
      if(value){
        let st = this.state.sessions;
        let ndx:number = -1;
        st.map((item, i)=>{
          item.List.map((info,index)=>{
            if(info.ID === sid){
               ndx = index;
               return; 
            }
          });
          if(ndx >= 0){
            item.List.splice(ndx,1);
            if(item.List.length === 0){
              ndx = i;
            }else{
              ndx = -1;
            }
            return;
          }
        });
        if(ndx >= 0){
          st.splice(ndx, 1);
        }
        
        this.setState({sessions:st, sessionsVersion:Date.now()});
      }
    }).catch(reason =>{
      this.handleRejection(reason);
    });
  };

  login = () => {
    this.setState({requestState:undefined, loginState:this.state.requestState});
  };

  private getLoginForm(): JSX.Element {
    return (
      <form action="#" className="two-row" data-k2-test-id="k2_loginForm" onSubmit={this.onSubmit}>
        <div className="inputs">
          <div className="input-field">
            <label htmlFor="k2_user">Jméno</label>
            <input
              type="text"
              id="k2_user"
              data-k2-test-id="k2_user"
              autoComplete="username"
              onChange={(e) => {
                this.setState({ username: e.currentTarget.value });
              }}
              ref={(ref) => {
                if (ref) this.userField = ref;
              }}
            />
          </div>

          <div className="input-field">
            <label htmlFor="k2_pwd">Heslo</label>
            <div className="password-wrapper">
              <input
                type="password"
                id="k2_pwd"
                data-k2-test-id="k2_pwd"
                autoComplete="current-password"
                onChange={(e) => {
                  this.setState({ password: e.currentTarget.value });
                }}
                ref={this.inputRef}
                className="input-password"
              />
              <button type="button" className="reveal-password-btn" onClick={this.revealPassword}>
                {this.state.revealPassword ? svg_no_eye : svg_eye}
              </button>
            </div>
          </div>

          {Context.DeviceInfo.AutoLogin == "1" && (
            <div className="remember-password">
              <input type="checkbox" id="remember-password_input" onClick={this.handleRememberPassword} />
              <label htmlFor="remember-password_input" className="remember-password_label">
                <span className="checkbox"></span>
                Zapamatovat přihlášení
              </label>
            </div>
          )}
        </div>

        <div className="cleaner height30"></div>

        <div className="two-column">
          <div className="info">
            <b>{this.props.ver} - web klient</b>
            <br />
            Vlastník licence: <span data-k2-test-id="licenseHolder">{this.props.licenseHolder}</span> <br />
            <span data-k2-test-id="version">
              {this.props.ver} (verze: {this.state.version})
            </span>
          </div>
          <div className="loginBtn-container">
            <button type="submit" className="loginBtn">
              Přihlásit
            </button>
          </div>
        </div>

        <div className="cleaner height30"></div>

        <div className="two-column">
          <div className="info">
            &copy; K2 software s.r.o.
            <br />
            <a href={this.props.ToSLink}>Podmínky použití</a>
          </div>
          <div className="logo-footer">
            <a href="https://www.k2.cz" target="_blank">
              <img src={this.props.footerImg} alt="K2 software s.r.o." />
            </a>
          </div>
        </div>
      </form>
    );
  }

  revealPassword = () => {
    this.setState({ revealPassword: !this.state.revealPassword }, () => {
      this.inputRef.current.type = this.state.revealPassword ? "text" : "password";
    });
  };

  handleRememberPassword = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      this.rememberLogin = true;
    } else {
      this.rememberLogin = false;
    }
  };

  async componentDidUpdate(prevProps: LoginViewProps, prevState: LoginViewState) {
    this.resetCountdown();

    if (this.state !== prevState) {
      if (this.state.loginState !== prevState.loginState) {
        switch (this.state.loginState) {
          case LoginState.checkInfo:
            Context.getApplication()
              .getLoginInfo(this.state.username)
              .then((result) => {
                let nextState = LoginState.login;
                if (result && result.TwoFactorAuthentification == true) {
                  nextState = LoginState.waitToTwoFactorConfirm;
                }

                if(this.autoLogin){
                  delete result.Sessions;
                }

                this.setState({ 
                  loginState:(result.Sessions && result.Sessions.length > 0)?LoginState.selectSession: nextState, 
                  requestState: (result.Sessions && result.Sessions.length > 0)?nextState:undefined,
                  sessions: result.Sessions, sessionsVersion:Date.now(), wsid: result.WSID} );
              })
              .catch(async (reason) => {
                await this.handleRejection(reason);
              });
            break;
          case LoginState.waitToTwoFactorConfirm:
          case LoginState.login:
            // Pro pripad, ze se navaze pripojeni s IIS, a nedojde tak k vyjimce a zavolani metody handleRejection v predchazejicim kroku

            if (this.reconnect()) return;

            Context.getApplication()
              .login(this.state.username, this.state.password, this.state.sessionId)
                .then((result) => {
                  this.setState({ loginState: LoginState.success, loginData: result });
                })
                .catch(async (reason) => {
                  if(reason instanceof CloseEvent){
                    if(this.state.loginState === LoginState.waitToTwoFactorConfirm && this.state.wsid){
                      this.setState({loginState: LoginState.wait2FResult});
                      return;
                    }
                  }
                    
                  await this.handleRejection(reason);
                });
              
            break;
          case LoginState.wait2FResult:
            if(this.state.wsid){
              Context.getApplication()
                .wait2FResult(this.state.wsid)
                  .then((result) => {
                      this.setState({ loginState: LoginState.success, loginData: result });
                  })
                  .catch(async (reason) => {
                    await this.handleRejection(reason);
                  });
            }
            break;
          case LoginState.success:
            if (this.rememberLogin) {
              const hash = CryptoJS.AES.encrypt(this.state.password, K2Key).toString();
              document.cookie = `login=${this.state.username},${hash},true;expires=01 Jan 2100 12:00:00 UTC`;
            } else {
              document.cookie = "login=";
            }
            await sleep(300);
            Context.getApplication().realizeApp(this.state.loginData, this.state.username);
            break;
          default:
            break;
        }
      }
    }

    // Spusti se znovu timer v pripade, ze prijde dalsi pozadavek na vykresleni reconnect formulare
    if (prevProps.reconnect !== this.props.reconnect) {
      this.handleRejection(this.state.errorMessage);

      return;
    }

    if (!this.autoLogin || this.state.nextTryIn < -1 /*|| this.reconnectCountExceeded*/) return;

    // Countdown
    if (this.state.nextTryIn !== prevState.nextTryIn) {
      this.countdown = window.setTimeout(() => {
        this.setState({ nextTryIn: this.state.nextTryIn - 1 });
      }, 1000);
    }

    // Pokus o (re)connect
    if (this.state.loginState === LoginState.error && this.state.nextTryIn === -1) {
      if (this.reconnect()) return;

      this.setState({ loginState: LoginState.checkInfo });
    }
  }

  handleRejection = async (reason: any) => {
    this.errorTries += 1;
    const nextTry = /*this.errorTries **/ RECONNECT_DELAY;

    // if (this.errorTries > MAX_RECONNECT_COUNT) this.reconnectCountExceeded = true;

    if (reason instanceof Blob) {
      const text = await reason.text();
      this.setState({ loginState: LoginState.error, errorMessage: JSON.parse(text)?.json, nextTryIn: nextTry });
    } else {
      this.setState({ loginState: LoginState.error, errorMessage: reason, nextTryIn: nextTry });
    }
  };

  reconnect = () => {
    if (this.props.reconnect) {
      this.props.reconnect.reconnectFce.call(this);

      return true;
    }

    return false;
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.username && this.state.loginState === LoginState.none) {
      this.setState({ loginState: LoginState.checkInfo });
    } else {
      this.shakeOn();
    }
  };

  componentWillUnmount(): void {
    this.resetCountdown();
  }

  resetCountdown = () => {
    if (this.countdown) {
      window.clearTimeout(this.countdown);
    }
  };

  async shakeOn() {
    this.userField.focus();
    this.userField.classList.add("shakeOn");
    await sleep(1000);
    this.userField.classList.remove("shakeOn");
  }
}
