import React from "react";
import { Context } from "../../appcontext";
import { UpdateControl, UpdateDockControl } from "../../common/communication.base";
import { NclDockControl } from "../../common/components.ncl";
import { ViewRealizer, ViewRealizerManager } from "../../viewrealizer";
import { AcquireControl, K2ComponentState, WithContextPlacementProps } from "../k2hoc";
import { ViewRealizerReact } from "../View/ViewRealizerReact";

export class K2PageHolder extends React.Component<WithContextPlacementProps, K2ComponentState<UpdateDockControl>> {
  static displayName = `K2TabPlaceHolder`;
  private control: NclDockControl;

  constructor(props: WithContextPlacementProps) {
    super(props);
    this.control = AcquireControl(
      this.props.controlUID,
      this.props.vrUID,
      (ctrl) => {
        return ctrl instanceof NclDockControl;
      },
      false
    ) as NclDockControl;

    if (!this.control) {
      this.control = Context.getApplication().getMainDockControl();
    }

    if (!this.control) {
      throw new Error("Dont't exist dock control.");
    }

    this.state = { data: this.control.init(this) as UpdateDockControl, vcxVersion: -1 };
  }

  updateState(state: UpdateControl) {
    this.setState((prevState: K2ComponentState<UpdateDockControl>) => {
      return { data: state as UpdateDockControl };
    });
  }

  dock(state: UpdateDockControl): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState({ data: state }, resolve);
    });
  }

  undock(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState({ data: { DockRealizerUID: null } as UpdateDockControl }, resolve);
    });
  }

  updateVCX(vcxVersion: number) {
    this.setState({ vcxVersion: vcxVersion });
  }

  componentWillUnmount() {
    this.control.willUnMount(true);
    this.control = null;
  }

  render() {
    let vr: ViewRealizer = ViewRealizerManager.getViewRealizer(this.state.data.DockRealizerUID);
    if (vr == null) {
      return null;
    } else {
      return <ViewRealizerReact VRUID={vr.getRealizerUID()} overlayBck={false} />;
    }
  }
}
