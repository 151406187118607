import React, { useEffect } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { NclCodeReaderDialog } from "../../common/components.ncl";

const K2QRCodeReader = (props: { control: NclCodeReaderDialog }) => {
  useEffect(() => {
    const html5Qrcode = new Html5Qrcode("qr_reader");
    html5Qrcode.start({ facingMode: "environment" }, { fps: 10 }, onScanSuccess, onScanFailure);

    return () => {
      html5Qrcode.stop();
    };
  }, []);

  function onScanSuccess(decodedText: string) {
    props.control.setResult(decodedText);
  }

  function onScanFailure() {
    //
  }

  return <div id="qr_reader"></div>;
};

export default K2QRCodeReader;
