import React from "react";
import { UpdateQuickFilter, ColumnProportionEm } from "../../common/communication.base";
import { NclQuickFilter, UFNclControlBase } from "../../common/components.ncl";
import { GenerateControl } from "../K2GenerateControl";
import { WithContextPlacementProps } from "../k2hoc";
import { ColumnsProps, FixedColumnOffset, getFixedColumnStyles } from "./utils";
import css from "./DataGrid.scss";
import { useServerState } from "../hooks";

interface QuickFilterProps extends WithContextPlacementProps, ColumnsProps {
  getFixedColumnOffset: FixedColumnOffset;
}

const K2QuickFilter = (props: QuickFilterProps) => {
  const [control, data, element] = useServerState<NclQuickFilter, UpdateQuickFilter, HTMLTableRowElement>(
    props.controlUID,
    props.vrUID,
    (ctrl) => ctrl instanceof NclQuickFilter
  );

  if (!control.Filters || data.Visible !== true) return null;

  let filters = control.Filters;

  return (
    <tr ref={element} tabIndex={1} className={css.dg_header_row}>
      {filters.map((value: UFNclControlBase, index: number) => {
        if (props.columnsProportion.length > index) {
          const proportion = props.columnsProportion[index];

          return (
            <th
              key={"column_filter_" + index}
              className={css.dg_filter + `${index < props.fixedColumnsCount ? " dg_fixed_col" : ""}`}
              style={getFixedColumnStyles(proportion.Width, props.getFixedColumnOffset(props.columnsWidths, index, index < props.fixedColumnsCount))}
            >
              {GenerateControl(value, { minHeight: control.VCX.GridControl.GetRowHeight(1) + "px", padding: 0 })}
            </th>
          );
        } else {
          return null;
        }
      })}
    </tr>
  );
};

export default K2QuickFilter;
