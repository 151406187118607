import {
  CSNclControlMetadata,
  VCXFontNoColorMetaData,
  FontStyle,
  TBehaviorTypeByDevice,
  CSUFUpdateControl,
  GPSDataRequest,
  K2Attribute,
} from "./communication.base";
import { Context } from "../appcontext";
import { error } from "util";
import { UAParser } from "ua-parser-js";
import { parsePhoneNumber, isPossiblePhoneNumber, CountryCode } from "libphonenumber-js";
import { openUrlInsideThisWeb } from "../main";
import { ViewRealizerManager } from "../viewrealizer";

export const RESpecialChars = [".", "^", "$", "*", "+", "-", "?", "(", ")", "[", "]", "{", "}", "\\", "|", "—", "/"];

export interface Size {
  height: number;
  width: number;
}

export interface ElementHtmlAttributes {
  tabIndex: number;
  readOnly?: boolean;
  disabled?: boolean;
}

interface ElementAttributes {
  TabStop: boolean;
  ReadOnly?: boolean;
  Enabled?: boolean;
}

const hideRule = "hide";

export function intToRGBA(num: number, alpha: number): string {
  let r = (num & 0xff).toString(10);
  let g = ((num >> 8) & 0xff).toString(10);
  let b = ((num >> 16) & 0xff).toString(10);

  return "rgba(" + r + "," + g + "," + b + "," + alpha / 100 + ")";
}

export function getAttributes(componenetState: ElementAttributes): ElementHtmlAttributes {
  // Naplní atributy ReadOnly, Disabled a TabIndex pro elementy, následně vloženo do komponenty pomocí spread values
  let result: ElementHtmlAttributes = {
    tabIndex: undefined,
    readOnly: undefined,
    disabled: undefined,
  };

  result.tabIndex = componenetState.TabStop ? 1 : -1;
  result.readOnly = componenetState.ReadOnly;
  if (typeof componenetState.Enabled != "undefined") {
    result.disabled = !componenetState.Enabled;
  }

  return result;
}

export function setCustomAttributes(element: HTMLElement, attributes: string) {
  if (element) {
    if (attributes) {
      let attrs = JSON.parse(attributes) as Array<K2Attribute>;
      attrs.forEach((attr) => {
        element.setAttribute(`data-k2-${attr.Name}`, attr.Value);
      });
    }
  }
}

export function isKeyOrDigit(key: string): boolean {
  if (/^([\p{L}\p{N}]|[-_ +.,/*\s])$/gu.test(key)) {
    return true;
  }
  return false;
}

export class Helper {
  static lastErrorMessage: string;

  public static clone<T extends CSNclControlMetadata>(source: T): T {
    let to: Object = {};
    for (var nextKey in source) {
      if (Object.prototype.hasOwnProperty.call(source, nextKey) && nextKey !== "Controls") {
        Object(to)[nextKey] = source[nextKey];
      }
    }

    return to as T;
  }

  public static clearContent(element: HTMLElement) {
    if (element == null) return;
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  }

  public static show(element: HTMLElement) {
    if (element == null) return;
    if (!Helper.isShow(element)) {
      element.classList.remove(hideRule);
    }
  }

  public static hide(element: HTMLElement) {
    if (element == null) return;
    if (!element.classList.contains(hideRule)) {
      element.classList.add(hideRule);
    }
  }

  public static isShow(element: HTMLElement): boolean {
    return !element.classList.contains(hideRule);
  }

  public static createModalAnchor(parent: HTMLElement): HTMLElement {
    let anchor = document.createElement("div");
    anchor.classList.add("modal");
    Helper.hide(anchor);
    parent.appendChild(anchor);
    return anchor;
  }

  public static getPictureUrl(glyphId: string, requiredSize: number): string {
    if (glyphId == null || glyphId === "") return "";

    let url: string = location.href + "Picture/GetPicture?glyphId=" + glyphId;

    if (requiredSize != null && requiredSize > 0) url += "&requiredSize=" + requiredSize.toString();

    return url;
  }

  private static keyMap: Map<string, string> = new Map<string, string>([
    [" ", "Space"],
    ["Insert", "Ins"],
    ["Escape", "Esc"],
    ["ArrowDown", "Down"],
    ["ArrowUp", "Up"],
    ["ArrowLeft", "Left"],
    ["ArrowRight", "Right"],
    ["Delete", "Del"],
    ["NumpadAdd", "Num +"],
    ["NumpadSubtract", "Num -"],
    ["Numpad0", "Num0"],
    ["Numpad1", "Num1"],
    ["Numpad2", "Num2"],
    ["Numpad3", "Num3"],
    ["Numpad4", "Num4"],
    ["Numpad5", "Num5"],
    ["Numpad6", "Num6"],
    ["Numpad7", "Num7"],
    ["Numpad8", "Num8"],
    ["Numpad9", "Num9"],
    ["NumpadDecimal", "NumDecimal"],
  ]);

  private static specHotKeys: string[] = [
    "Backspace",
    "Tab",
    "Enter",
    "NumpadEnter",
    "Escape",
    "ArrowLeft",
    "ArrowUp",
    "ArrowRight",
    "ArrowDown",
    "Insert",
    "Delete",
    "NumpadAdd",
    "NumpadSubtract",
    "Numpad0",
    "Numpad1",
    "Numpad2",
    "Numpad3",
    "Numpad4",
    "Numpad5",
    "Numpad6",
    "Numpad7",
    "Numpad8",
    "Numpad9",
    "NumpadDecimal",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "F8",
    "F9",
    "F10",
    "F11",
    "F12",
  ];

  private static ignoreHotKeys: string[] = ["Ctrl+V", "Ctrl+C", "Ctrl+X"];

  private static ignoreNumAccelerator = "Alt+";

  private static convertKey(ev: KeyboardEvent): string {
    if (ev.code.startsWith("Digit")) return ev.code.replace("Digit", ""); //numeric keys

    if (Helper.keyMap.has(ev.key)) {
      return Helper.keyMap.get(ev.key);
    } else if (Helper.keyMap.has(ev.code)) {
      return Helper.keyMap.get(ev.code);
    } else {
      let key = ev.key;

      // ziskavam stisknutou klavesu pomoci ev.code a ne pres ev.key, protoze pri kombinaci ctrl+alt+klavesa se meni pismeno na znak, napr. '[' pro ctrl+alt+f
      if (ev.code.match(/Key[A-Z]$/) != null) {
        key = ev.code.replace("Key", "");
      }

      // pro CZ a dalsi klavesnice, ktere maji prehozene Y a Z
      if (ev.code === "KeyY") {
        if (ev.key === "z") {
          key = "z";
        } else if (ev.key === "Z") {
          key = "Z";
        }
      } else if (ev.code === "KeyZ") {
        if (ev.key === "y") {
          key = "y";
        } else if (ev.key === "Y") {
          key = "Y";
        }
      }

      return key.toUpperCase();
    }
  }

  public static convertToTxt(ev: KeyboardEvent): string {
    let txt: string = "";
    let modifier: string = "";
    let key = Helper.convertKey(ev);

    if (ev.key === "Shift" || ev.key === "Control" || ev.key === "Alt" || ev.key === "AltGraph") return "";
    if (ev.ctrlKey && ev.key !== "Control") modifier += "Ctrl+";
    if (ev.altKey && ev.key !== "Alt") modifier += "Alt+";
    if (ev.shiftKey && ev.key !== "Shift" && (modifier || Helper.specHotKeys.includes(ev.code))) modifier += "Shift+";

    if (modifier || (!modifier && (Helper.specHotKeys.includes(ev.code) || Helper.specHotKeys.includes(ev.key)))) {
      if (Helper.keyMap.has(key)) {
        txt = modifier + Helper.keyMap.get(key);
      } else {
        txt = modifier + key.toUpperCase();
      }
    }

    if (txt.startsWith(this.ignoreNumAccelerator) && ev.code.match(/Numpad[0-9]$/) != null) return "";

    if (Helper.ignoreHotKeys.indexOf(txt) < 0) {
      return txt;
    } else {
      return "";
    }
  }

  public static setHint(element: HTMLElement, hint: string) {
    if (hint != null && hint != "") {
      element.title = hint;
    }
  }

  public static async sendErrorMessage(message: string, stack: any, reactStack?: any) {
    if (process.env.NODE_ENV === "development") return;
    if (this.lastErrorMessage === message) return;

    this.lastErrorMessage = message;
    const app = Context.getApplication();
    const deviceInfo = Context.DeviceInfo;
    let bodyData = {};
    let vr;

    bodyData = {
      message: message,
      date: new Date(Date.now()),
      url: window.location.href,
      device: {
        OS: deviceInfo.OSInfo,
        browser: deviceInfo.BrowserInfo,
        width: window.innerWidth,
        height: window.innerHeight,
      },
      user: app.User,
      version: app.Version,
      callStack: stack,
      reactCallStack: reactStack,
    };

    if (app.ReconnectData) {
      bodyData = {
        ...bodyData,
        reconnect: {
          UID: app.ReconnectData.ReconnectUID,
          AS3Server: app.ReconnectData.AS3Server,
          AS3Pipe: app.ReconnectData.AS3Pipe,
        },
      };
    }

    if (app.LastMessage.received && app.LastMessage.sent) {
      bodyData = {
        ...bodyData,
        lastSentMessage: {
          messageType: app.LastMessage.sent.messageType,
          realizerUID: app.LastMessage.sent.realizerUID,
          realizeCounter: app.LastMessage.sent.realizeCounter,
          json: app.LastMessage.sent.json,
        },
        lastReceivedMessage: {
          messageType: app.LastMessage.received.messageType,
          realizerUID: app.LastMessage.received.realizerUID,
          realizeCounter: app.LastMessage.received.realizeCounter,
          json: app.LastMessage.received.json,
        },
        control: {
          id: app.LastMessage.sent.control?.MetaData.Name,
          name: app.LastMessage.sent.control?.MetaData.__type,
          state: app.LastMessage.sent.control?.State,
        },
      };

      vr = ViewRealizerManager.getViewRealizer(app.LastMessage.sent.control?.getRealizerUID())?.getRoot();
    }

    if (vr) {
      bodyData = {
        ...bodyData,
        view: {
          name: vr?.MetaData.Name,
          title: (vr?.State as CSUFUpdateControl)?.Title,
        },
      };
    }

    try {
      await fetch("https://statistics.k2.cz/webK2/webK2.php", {
        method: "POST",
        body: JSON.stringify({
          token: app.ErrorMessageToken,
          action: "webK2",
          JSON: {
            source: window.location.href,
            data: {
              ...bodyData,
            },
          },
        }),
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export enum LogLevel {
  Error,
  Warn,
  Debug,
  Info,
}

export class Log {
  private static instance: Log;
  private level: LogLevel;

  private constructor(level: LogLevel) {
    this.level = level;
  }

  public static init(level: LogLevel) {
    if (Log.instance == null) {
      Log.instance = new Log(level);
    } else {
      //err
    }
  }

  public static info(log: string) {
    Log.write(LogLevel.Info, log);
  }

  public static debug(log: string) {
    Log.write(LogLevel.Debug, log);
  }

  public static warn(log: string) {
    Log.write(LogLevel.Warn, log);
  }

  public static error(log: string, exception: any) {
    Log.write(LogLevel.Error, log);
  }

  private static write(level: LogLevel, log: string) {
    if (Log.instance != null) {
      Log.instance.internalWrite(level, log);
    }
  }

  private internalWrite(level: LogLevel, log: string) {
    if (level > this.level) return;
    let msg: string;
    msg = LogLevel[level] + ":" + new Date().toLocaleString() + "-" + log;
    switch (level) {
      case LogLevel.Info:
        console.info(msg);
        break;
      case LogLevel.Error:
        console.error(msg);
        break;
      case LogLevel.Debug:
        console.debug(msg);
        break;
      case LogLevel.Warn:
        console.warn(msg);
        break;
      default:
    }
  }
}

export async function delay(milliseconds: number) {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

var dummy: HTMLDivElement;

export interface Dimension {
  height: number;
  width: number;
}

/**
 * Funkce pro výpočet výšky textu se zadaným fontem
 * @param text Měřený text
 * @param font Použitý font
 */
export function computeTextDimension(text: string, font: VCXFontNoColorMetaData, newFontSize: number): Dimension {
  let body = document.getElementsByTagName("body")[0];
  if (!dummy) {
    dummy = document.createElement("div");
    dummy.style.position = "absolute";
    dummy.style.whiteSpace = "pre";
    dummy.style.top = "0";
    dummy.style.left = "0";
    body.appendChild(dummy);
  } else {
    dummy.innerHTML = "";
  }

  let dummyText = document.createTextNode(text);
  dummy.appendChild(dummyText);
  if (font.FontStyle === FontStyle.fsBold) {
    dummy.style.fontWeight = "bold";
  }
  if (font.FontStyle === FontStyle.fsItalic) {
    dummy.style.fontStyle = "italic";
  }
  if (font.FontStyle === FontStyle.fsStrikeOut) {
    dummy.style.textDecoration = "line-through";
  }
  if (font.FontStyle === FontStyle.fsUnderline) {
    dummy.style.textDecoration = dummy.style.textDecoration + " underline";
  }

  dummy.style.fontSize = newFontSize + "pt";
  dummy.style.fontFamily = font.FontName;

  dummy.style.display = "block";
  let result = { height: dummy.offsetHeight, width: dummy.offsetWidth + 1 };
  dummy.style.display = "none";

  return result;
}

const RegExPattern = "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$";

export function isUrl(value: string): boolean {
  if (value) {
    let reg: RegExp = new RegExp(RegExPattern);

    return reg.exec(value) !== null;
  }
  return false;
}

export interface CancelablePromise<T> {
  promise: Promise<T>;
  cancel(): void;
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    if (promise) {
      promise.then(
        (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
        (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
      );
    } else {
      reject(error);
    }
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

function calculateStreenWidth(): number {
  let div = document.createElement("div");
  div.style.width = "1in";
  div.style.height = "1in";
  div.style.position = "absolute";
  div.style.left = "-100%";
  div.style.top = "-100%";

  document.body.appendChild(div);
  let dpi_x: number = div.offsetWidth;
  document.body.removeChild(div);

  return (window.screen.width / dpi_x) * 25.4;
}

export function isPDF(fileName: string): boolean {
  if (fileName) {
    let file = fileName.toUpperCase();
    if (file.endsWith(".PDF")) return true;
  }

  return false;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isPhoneNumber(phone: string): boolean {
  if (phone) {
    return isPossiblePhoneNumber(phone, Context.DeviceInfo.CountryCode);
  }
  return false;
}

export function call(phone: string) {
  if (phone) {
    try {
      const phoneNumber = parsePhoneNumber(phone, Context.DeviceInfo.CountryCode);
      if (phoneNumber) {
        openUrlInsideThisWeb(phoneNumber.getURI());
      }
    } catch (err) {
      Log.error(`Call to phone: ${phone}`, err);
      throw err;
    }
  }
}

export class DeviceInfo {
  private uaParser: UAParser;
  private culture: string;
  private independentFormatMode: boolean;
  private styleOfModalWindowShow: TBehaviorTypeByDevice;
  private inplaceEditBehavior: TBehaviorTypeByDevice;
  private isPDFSupport: boolean;
  private className: string;
  private zoomFactor: number;
  private transformColumnsCount: Array<number>;
  private responsiveBreakpoint: number;
  private isAndroidDevice: boolean;
  private isTouchDevice: boolean;
  private isIOS: boolean;
  private isIPadOS: boolean;
  private isMacOS: boolean;
  private isAnimationDisabled: boolean;
  private useServerVirtualKeyboard: boolean;

  private k2PK: string;
  private rid: string;
  private frgtId: string;
  private activateDC: string;
  private editMode: string;
  private selectionID: string;
  private useMainLayout: boolean;
  private script: string;
  private scriptParams: string;
  private countryCode: string;
  private dcPort: number;
  private isTestMode: boolean = undefined;
  private explicitMaxRowCount: number;
  private showKeyboardOnFocus: string;
  private autoLogin: string;
  private hostName: string;

  private get UAParser(): UAParser {
    if (!this.uaParser) {
      this.uaParser = new UAParser(window.navigator.userAgent);
    }

    return this.uaParser;
  }

  public get IsAndroidDevice(): boolean {
    if (this.isAndroidDevice === undefined) this.isAndroidDevice = this.UAParser.getOS().name.toLocaleUpperCase().indexOf("ANDROID") >= 0;

    return this.isAndroidDevice;
  }

  public get IsIOS(): boolean {
    if (!this.isIOS) {
      this.isIOS = this.UAParser.getOS().name === "iOS";
    }

    return this.isIOS;
  }

  public get IsIPadOS(): boolean {
    if (!this.isIPadOS) {
      this.isIPadOS = this.UAParser.getOS().name === "Mac OS" && navigator.maxTouchPoints > 0;
    }

    return this.isIPadOS;
  }

  public get IsMacOS(): boolean {
    if (!this.isMacOS) {
      this.isMacOS = this.UAParser.getOS().name === "Mac OS" && navigator.maxTouchPoints === 0;
    }

    return this.isMacOS;
  }

  public get IsTouchDevice(): boolean {
    if (!this.isTouchDevice) {
      this.isTouchDevice = (this.IsIOS || this.IsIPadOS || this.IsAndroidDevice) && navigator.maxTouchPoints > 0;
    }

    return this.isTouchDevice;
  }

  public get BrowserInfo(): string {
    return this.UAParser.getBrowser().name;
  }

  public get DeviceInfo(): string {
    let result: string = undefined;
    if (this.UAParser.getDevice()) {
      if (this.UAParser.getDevice().type) {
        let type = this.UAParser.getDevice().type;
        type = type.charAt(0).toUpperCase() + type.slice(1);
        result = `${type}`;
      }

      if (this.UAParser.getDevice().model) result += ` - ${this.UAParser.getDevice().model}`;
    }
    return result;
  }

  public get OSInfo(): string {
    return `${this.UAParser.getOS().name} ${this.UAParser.getOS().version}`;
  }

  public get ScreenWidth(): number {
    let screenWidth = calculateStreenWidth();

    let w = parseFloat(this.getQueryVariable("ScreenSize", "-1"));

    if (w > 0) {
      screenWidth = w * 10; // use width in mm
    }

    return screenWidth;
  }

  public get K2PK(): string {
    if (!this.k2PK) {
      this.k2PK = this.getQueryVariable("K2PK", undefined);
    }
    return this.k2PK;
  }

  public get RID(): string {
    if (!this.rid) {
      this.rid = this.getQueryVariable("RID", undefined);
    }
    return this.rid;
  }

  public get CountryCode(): CountryCode {
    if (!this.countryCode) {
      this.countryCode = this.getQueryVariable("CountryCode", "CZ");
    }

    return this.countryCode.toUpperCase() as CountryCode;
  }

  public get DCPort(): number {
    if (!this.dcPort) {
      let port = Number.parseInt(this.getQueryVariable("DCPort", ""));
      if (Number.isNaN(port)) {
        this.dcPort = -1;
      } else {
        this.dcPort = port;
      }
    }

    if (this.dcPort < 0) return undefined;

    return this.dcPort;
  }

  public get IsTestMode(): boolean {
    if (this.isTestMode === undefined) {
      let value = this.getQueryVariable("IsTestMode", undefined);
      this.isTestMode = value !== undefined ? true : false;
    }

    return this.isTestMode;
  }

  public get ExplicitMaxRowCount(): number {
    if (!this.explicitMaxRowCount) {
      let value = Number.parseInt(this.getQueryVariable("ExplicitMaxRowCount", this.detectWinPlatform() ? "" : "200"));
      if (Number.isNaN(value)) {
        this.explicitMaxRowCount = -1;
      } else {
        this.explicitMaxRowCount = value;
      }
    }

    return this.explicitMaxRowCount;
  }

  public get FrgtId(): string {
    if (!this.frgtId) {
      this.frgtId = this.getQueryVariable("FrgtId", undefined);
    }
    return this.frgtId;
  }

  public get ActiveDC(): string {
    if (!this.activateDC) {
      this.activateDC = this.getQueryVariable("ActiveDC", undefined);
    }
    return this.activateDC;
  }

  public get EditMode(): string {
    if (!this.editMode) {
      this.editMode = this.getQueryVariable("EditMode", undefined);
    }
    return this.editMode;
  }

  public get SelectionID(): string {
    if (!this.selectionID) {
      this.selectionID = this.getQueryVariable("SelectionID", undefined);
    }
    return this.selectionID;
  }

  public get UseMainLayout() {
    if (this.useMainLayout === undefined) {
      let qresult = this.getQueryVariable("UseMainLayout", "-1");
      if (qresult && qresult != "-1") {
        if (qresult === "1") {
          this.useMainLayout = true;
        } else if (qresult === "0") {
          this.useMainLayout = false;
        }
      }
    }

    return this.useMainLayout;
  }

  public get Script(): string {
    if (!this.script) {
      this.script = this.getQueryVariable("Script", undefined);
    }
    return this.script;
  }

  public get ScriptParams(): string {
    if (!this.scriptParams) {
      this.scriptParams = this.getQueryVariable("ScriptParams", undefined);
    }
    return this.scriptParams;
  }

  public get ZoomFactor(): number {
    if (!this.zoomFactor) {
      this.zoomFactor = this.getDefaultZoom();
      let zoom = Number.parseFloat(this.getQueryVariable("zoom", this.zoomFactor.toString()));
      if (zoom !== NaN) {
        this.zoomFactor = zoom;
      }
    }
    return this.zoomFactor;
  }

  public get CurrentCulture() {
    if (!this.culture) {
      this.culture = this.getQueryVariable("lng", navigator.language);
    }
    return this.culture;
  }

  public get HostName(): string {
    return this.hostName;
  }

  public set HostName(value: string) {
    this.hostName = value;
  }

  public get IndependentFormatMode(): boolean {
    if (this.independentFormatMode === undefined) {
      this.independentFormatMode = !this.detectWinPlatform();

      let qresult = this.getQueryVariable("IndependentFormatMode", "-1");
      if (qresult && qresult !== "-1") {
        if (qresult === "0") {
          this.independentFormatMode = false;
        }
        if (qresult === "1") {
          this.independentFormatMode = true;
        }
      }
    }
    return this.independentFormatMode;
  }

  public get IsAnimationDisabled(): boolean {
    if (this.isAnimationDisabled === undefined) {
      let qresult = this.getQueryVariable("IsAnimationDisabled", "-1");
      if (qresult && qresult !== "-1") {
        if (qresult === "0") {
          this.isAnimationDisabled = false;
        }
        if (qresult === "1") {
          this.isAnimationDisabled = true;
        }
      } else {
        this.isAnimationDisabled = false;
      }
    }
    return this.isAnimationDisabled;
  }

  public get UseServerVirtualKeyboard(): boolean {
    if (this.useServerVirtualKeyboard === undefined) {
      let qresult = this.getQueryVariable("UseServerVirtualKeyboard", "-1");
      if (qresult && qresult !== "-1") {
        if (qresult === "0") {
          this.useServerVirtualKeyboard = false;
        }
        if (qresult === "1") {
          this.useServerVirtualKeyboard = true;
        }
      } else {
        this.useServerVirtualKeyboard = false;
      }
    }
    return this.useServerVirtualKeyboard;
  }

  public get StyleOfModalWindowShow(): TBehaviorTypeByDevice {
    if (!this.styleOfModalWindowShow) {
      let qresult = this.getQueryVariable("StyleOfModalWindowShow", undefined);
      if (qresult) {
        switch (qresult.toUpperCase()) {
          case "MOBILE":
            this.styleOfModalWindowShow = TBehaviorTypeByDevice.btbdMobile;
            break;
          case "NORMAL":
            this.styleOfModalWindowShow = TBehaviorTypeByDevice.btbdNormal;
            break;
          default:
        }
      } else {
        if (this.detectWinPlatform() || !this.IsTouchDevice) {
          this.styleOfModalWindowShow = TBehaviorTypeByDevice.btbdNormal;
        } else {
          this.styleOfModalWindowShow = TBehaviorTypeByDevice.btbdMobile;
        }
      }
    }

    return this.styleOfModalWindowShow;
  }

  public get InplaceEditBehavior(): TBehaviorTypeByDevice {
    if (this.detectWinPlatform() || !this.IsTouchDevice) {
      this.inplaceEditBehavior = TBehaviorTypeByDevice.btbdNormal;
    } else {
      this.inplaceEditBehavior = TBehaviorTypeByDevice.btbdMobile;
    }

    let qresult = this.getQueryVariable("InplaceEditBehavior", undefined);
    if (qresult) {
      switch (qresult) {
        case "MOBILE":
          this.inplaceEditBehavior = TBehaviorTypeByDevice.btbdMobile;
          break;
        case "NORMAL":
          this.inplaceEditBehavior = TBehaviorTypeByDevice.btbdNormal;
          break;
        default:
      }
    }

    return this.inplaceEditBehavior;
  }

  public get IsPDFSupport() {
    if (this.isPDFSupport === undefined) {
      let qresult = this.getQueryVariable("IsPdfSupport", "-1");
      if (qresult && qresult != "-1") {
        if (qresult === "1") {
          this.isPDFSupport = true;
        } else if (qresult === "0") {
          this.isPDFSupport = false;
        }
      } else {
        this.isPDFSupport = this.detectWinPlatform();
      }
    }

    return this.isPDFSupport;
  }

  public get StartClassName(): string {
    if (!this.className) {
      this.className = this.getQueryVariable("ClassName", undefined);
    }
    return this.className;
  }

  public get TransformColumnsCount(): number {
    if (!this.transformColumnsCount) {
      this.transformColumnsCount = this.getDefaultTransformColumns();
      let trs = this.getQueryVariable("TransformColumnsCount", "");
      if (/\[\d(,\d)?\]/.test(trs)) {
        trs = trs.replace(/\[|\]/g, "");
        if (trs) {
          let values = trs.split(",");
          if (values && values.length > 0) {
            let val: number;
            values.map((item, index) => {
              val = Number.parseInt(item);
              if (val != NaN) {
                this.transformColumnsCount[index] = val;
              }
            });
          }
        }
      }
    }
    if (this.getOrientation() === "portrait") {
      return this.transformColumnsCount[0];
    } else {
      return this.transformColumnsCount[1];
    }
  }

  public get ResponsiveBreakpoints(): Array<number> {
    if (!this.responsiveBreakpoint) {
      this.responsiveBreakpoint = null;
      let value = Number.parseInt(this.getQueryVariable("ResponsiveBreakpoint", "980"));
      if (value != NaN) {
        this.responsiveBreakpoint = value;
      }
    }

    if (this.responsiveBreakpoint > 0) {
      return [this.responsiveBreakpoint];
    }

    return null;
  }

  public get ShowKeyboardOnFocus() {
    if (!this.showKeyboardOnFocus) {
      this.showKeyboardOnFocus = this.getQueryVariable("ShowKeyboardOnFocus", "0");
    }

    return this.showKeyboardOnFocus;
  }

  public get AutoLogin() {
    if (!this.autoLogin) {
      this.autoLogin = this.getQueryVariable("AutoLogin", "0");
    }

    return this.autoLogin;
  }

  private getDefaultTransformColumns(): number[] {
    if (this.detectWinPlatform() || this.IsIPadOS || this.IsMacOS) {
      return [0, 0];
    }

    return [1, 4];
  }

  private getDefaultZoom(): number {
    if (this.detectWinPlatform() || this.IsMacOS) {
      return 1;
    }

    return 1.25;
  }

  private detectWinPlatform(): boolean {
    let result = navigator.platform.match(/Win32/g);
    let value = result && result.length > 0;
    if (value) {
      return true;
    }
    return false;
  }

  public getQueryVariable(paramName: string, defaultValue: string): string {
    return queryVariable(paramName, defaultValue);
  }

  public getOrientation(): "portrait" | "landscape" {
    if (window.innerHeight > window.innerWidth) {
      return "portrait";
    } else {
      return "landscape";
    }
  }
}

export function queryVariable(paramName: string, defaultValue: string): string {
  let result: string = defaultValue;
  new URL(window.location.toString()).searchParams.forEach((value, key) => {
    if (key.toUpperCase() === paramName.toUpperCase()) {
      result = value;
      return;
    }
  });

  return result;
}

export function parseData(data: string): any {
  let str: string = data;
  str = str.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
  return JSON.parse(str);
}

interface DataFile {
  version: string;
}

export enum Version {
  same,
  new,
  notAvailable,
}

export const getVersion = async () => {
  try {
    const response = await fetch("./info.json", { cache: "no-store" });
    const data: DataFile = await response.json();

    return data.version;
  } catch (error) {
    return "error";
  }
};

export const isNewVersionAvailable = async () => {
  const currentVersion = Context.getApplication().Version;
  const newVersion = await getVersion();

  if (newVersion === "error") {
    return Version.notAvailable;
  }

  if (currentVersion === newVersion) {
    return Version.same;
  }

  return Version.new;
};

export class GPSReader {
  private watchID: number;
  private start: Date;
  private lastUpdate: Date;
  private lastPosition: Position;
  private accuracy: number;
  private request: GPSDataRequest;

  private static options = {
    maximumAge: 0,
    timeout: 30000,
    enableHighAccuracy: true,
  };

  public startWatchCurrentPosition(accuracy: number) {
    if (this.watchID === undefined) {
      this.start = new Date();
      this.accuracy = accuracy;
      this.watchID = navigator.geolocation.watchPosition(this.processGeolocationResult.bind(this), this.processError.bind(this), GPSReader.options);
    }
  }

  public stopWatchCurrentPosition() {
    if (this.watchID != undefined) {
      navigator.geolocation.clearWatch(this.watchID);
    }
    this.lastPosition = undefined;
    this.watchID = undefined;
    this.lastUpdate = undefined;
    this.start = undefined;
    this.request = undefined;
  }

  public acquireActualPosition(onResult: (request: GPSDataRequest) => void, accuracy: number) {
    if (accuracy > 0 && this.accuracy != accuracy) {
      this.accuracy = accuracy;
    }

    if (!this.request) {
      this.prepareGPSRequest();
    }

    onResult(this.request);
    this.request = undefined;
  }

  public isWatching(): boolean {
    return this.watchID != undefined;
  }

  private processGeolocationResult(coords: Position) {
    let prev = this.lastUpdate ? this.lastUpdate : this.start;
    if (coords.coords.latitude > 0 && coords.coords.longitude > 0 && new Date(coords.timestamp).getTime() - prev.getTime() > 0) {
      if (!this.lastPosition || (coords.coords.accuracy <= this.accuracy && coords.coords.accuracy < this.lastPosition.coords.accuracy)) {
        this.lastPosition = coords;
        this.lastUpdate = new Date();
      }
    }
  }

  private prepareGPSRequest() {
    if (this.lastPosition) {
      if (this.lastPosition.coords.accuracy <= this.accuracy) {
        this.request = {
          GPS: `${this.lastPosition.coords.latitude},${this.lastPosition.coords.longitude}`,
          Accuracy: this.lastPosition.coords.accuracy,
        } as GPSDataRequest;
      } else {
        this.request = { GPSErrMsg: "GPS cannot be detected within the specified accuracy." } as GPSDataRequest;
      }
    } else {
      this.request = { GPSErrMsg: "GPS timeout" } as GPSDataRequest;
    }
  }

  private processError(error: PositionError) {
    this.request = { GPSErrMsg: error.message } as GPSDataRequest;
    console.log("Geolocation error: " + error.message);
  }
}

export function repairUrl(url:string):string{
  if (!url.startsWith(document.location.protocol) && url.indexOf(`${document.location.host}${document.location.pathname}`) >= 0) {
    let addr = new URL(url);
    if (addr.host === document.location.host && addr.pathname.indexOf(document.location.pathname) >= 0) {
      addr.protocol = document.location.protocol;
      return addr.href;
    }
  }
  return url;
}

export function base64ToBlob(base64String: string, type: string) {
  const string = window.atob(base64String);
  const blobPart = new Uint8Array(string.length);

  for (let i = 0; i < string.length; i++) {
    blobPart[i] = string.charCodeAt(i);
  }

  return new Blob([blobPart], { type: type });
}
