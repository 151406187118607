import { Iterable, List, Map, Record } from "immutable";
import { VCXGrid } from "./visualContext";

export const cJSonFunctionGridOperation = "GridOperation";
export const cJSonFunctionUpdateData = "UpdateData";
export const cJSonFunctionAcceptData = "AcceptData";
export const cJSonFunctionExecute = "Execute";
export const cJSonCommandExecuteLookup = "ExecuteLookup";
export const cJSonFunctionCloseView = "CloseView";
export const cJSonFunctionExecuteShortcut = "ExecuteShortcut";
export const cJSonDefaultAcceptExecute = "DefaultAccept";
export const cJSonGanttContextMenu = "ContextMenu";
export const cJSonRangeSelect = "RangeSelect";
export const cJSonSelectPoint = "SelectPoint";
export const cJSonUnselectPoint = "UnselectPoint";
export const cJsonFunctionShowFloater = "ShowFloater";
export const cJsonFunctionHideFloater = "HideFloater";
export const cJSonFunctionContextMenu = "ContextMenu";
export const cJSonFunctionGridChangeSortBy = "GridChangeSortBy";
export const cJSonFunctionNextPriorValue = "NextPriorValue";
export const cJSonChangeActiveDateRange = "ChangeActiveDateRange";
export const cJSonFunctionShowSetting = "ShowSetting";
export const cJSonFunctionShowDocumentByK2PK = "ShowDocumentByK2PK";
export const cJSonFunctionToggleNodeExecute = "ToggleNode";
export const cJSonFunctionDragAndDropAppointment = "DragAndDropAppointment";
export const cJSonFunctionEditOnlineAppointment = "EditOnlineAppointment";
export const cJSonFunctionDeleteAppointment = "DeleteAppointment";
export const cJSonFunctionDeleteOnlineAppointment = "DeleteOnlineAppointment";
export const cJSonFunctionMoveNodeExecute = "MoveNode";
export const cJSonDataGridDblClickExecute = "DataGridDblClick";
export const cJSonTreeViewDblClickExecute = "TreeViewDblClick";
export const cJSonFunctionDefaultExplicitBounds = "DefaultExplicitBounds";
export const cJSonFunctionAutoUpdate = "AutoUpdate";
export const cJSonFunctionLoadPreviewData = "LoadPreviewData";
export const cJSonFunctionPartClickExecute = "PartClickExecute";
export const cJSonMemberClickToExpand = "MemberClickToExpand";
export const cJSonTreeViewPartClick = "TreeViewPartClick";
export const cJSonDynamicFilterPartClick = "DynamicFilterPartClick";
export const cJSonPartUpdate = "PartUpdate";
export const cJSonFunctionODUploadFile = "UploadFile";
export const cJSonFunctionAutoClose = "AutoCloseView";
export const cJSonExecuteCommandByNumber = "ExecuteCommandByNumber";
export const cJSonExecuteCommandByNumber_InstantFilter = "ExecuteCommandByNumber_InstantFilter";
export const cJSonFunctionODBeginUploadFile = "BeginUploadFile";
export const cJSonFunctionODEndUploadFile = "EndUploadFile";
export const cJSonFunctionODCancelUploadFile = "CancelUploadFile";
export const cJsonFunctionMainMenuByRID = "MainMenuByRID";
export const cJSonFunctionResolveFocus = 'ResolveFocus';

///COMMANDS
export const cmdOKView = "OKViewCOMMAND";

export const cClientFileType = "TClientFile";
export const cClientUrlType = "TClientUrl";
export const cClienSoundType = "TClientSound";

export const DEFAULT_LANG_IMG = "Lang/Lang0";

export const clNone = 536870911;
//#region "enums"

export enum TUFDisplayValueAs {
  dvaText,
  dvaImage,
}

export enum GridOperations {
  next = 1,
  prior = 2,
  firstPage = 3,
  lastPage = 4,
  nextPage = 5,
  priorPage = 6,
  swipeUp = 10,
  swipeDown = 11,
}
export enum Align {
  Left = 0,
  Right = 1,
  Top = 2,
  Bottom = 3,
  Client = 4,
}

export enum FontStyle {
  fsBold = 1,
  fsItalic = 2,
  fsUnderline = 4,
  fsStrikeOut = 8,
}

export enum TCondFormattingOption {
  cfoFontStyleBold = 1,
  cfoFontStyleItalic = 2,
  cfoFontStyleUnderline = 4,
  cfoFontStyleStrike = 8,
  cfoBackColor = 16,
  cfoForegroundColor = 32,
}

export enum DisplayMode {
  ///	<summary>
  ///	  Nezobrazit
  ///	</summary>
  fpdmNone,

  ///	<summary>
  ///	  Zobrazit standardne
  ///	</summary>
  fpdmNormal,

  ///	<summary>
  ///	  Zobrazit standardne tucne
  ///	</summary>
  fpdmNormalBold,

  ///	<summary>
  ///	  Zobrazit dekoracni barvou
  ///	</summary>
  fpdmDecorated,

  ///	<summary>
  ///	  Zobrazit dekoracni barvou tucne
  ///	</summary>
  fpdmDecoratedBold,
}

export enum Orientation {
  foHorizontal,
  foVertical,
}

export enum SplitterPanelOrientation {
  spoLeft,
  spoRight,
  spoBottom,
  spoTop,
}

export enum DecorateMode {
  ufdemNone,
  ufdemDesiner,
}

export enum DataActionDecorate {
  dadeDecorate,
  dadeBase,
  dadeError,
  dateHighLight,
  dadeContent,
  dadeContentSuppress,
  dadeTransparent,
  dadeTransparentBold,
}

export enum FrgtGridLinesStyle {
  glsNone,
  glsFirst,
  glsLast,
  glsAll,
}

export enum TAlignment {
  taLeftJustify,
  taRightJustify,
  taCenter,
}

export enum TUFCharCountMultiplierKind {
  ccmkZero,
  ccmk2Em3,
  ccmkRowHeight,
  ccmkEm,
}

export enum TUFContextDisplayStyle {
  cdsDefault,
  cdsText,
  cdsImage,
  cdsTextAboveImage,
}

export enum TUFFrameKind {
  uffkunderLine,
  uffkFrame,
  uffkNone,
}

export enum TKeybInputColorStyle {
  kicsLabel,
  kicsInput,
}
export enum TFrgtAcceptKeyAction {
  fakaAcceptAndFocusNext,
  fakaAcceptOnly,
  fakaAcceptAndFocusPrior,
}

/// <summary>
///   Umisteni ikony ve vizualnim fragmentu
/// </summary>
export enum IconPosition {
  ipLeft,
  ipTop,
  ipRight,
  ipBottom,
  ipCenter,
}

export enum HorizontalAlignment {
  fhaLeft,
  fhaCenter,
  fhaRight,
}
export enum VerticalAlignment {
  fvaTop,
  fvaCenter,
  fvaBottom,
}

export enum TVerticalAlignment {
  taAlignTop,
  taAlignBottom,
  taVerticalCenter,
}

export enum RectInDockSizeMode {
  ridsmOriginal, //OriginSize
  ridsmPercent, //Procento z InRect
  ridsmAnchorWidth, //Sirka anchored jako anchor (pokud neni anchored pak jde o sirku zarovnavaneho), vyska OriginSize
  ridsmAnchorWidthMinOriginal, //Sirka anchored jako maximum z anchor a OriginSize (pokud neni anchored pak jde o sirku zarovnavaneho), vyska OriginSize
}

export enum RectInDockPositionMode {
  ridpmCenter, //Na stred v InRect
  ridpmAchorCenter, //Zarovnany na stred anchoru
  ridpmAnchorOutside, //Zarovnany X: na pravou/stred/levou hranu anchoru (podle umisteni anchoru), Y: na dolni hranu anchoru
  ridpmAnchorOverAtLeft, //Zarovnany X: anchored.left  na levou hranu anchoru , Y: top anchoru na top anchored (pokud neni anchored pak jde o zarovnavany control)
  ridpmAnchorOverAtRight, //Zarovnany X: anchored.right na pravou hranu anchoru, Y: top anchoru na top anchored (pokud neni anchored pak jde o zarovnavany control)
}

export enum FrameStyle {
  frsTitle,
  frsTitleResize,
  frsNone,
  frsFrame,
  frsFrameResize,
}

export enum TOpenOption {
  ofReadOnly = 2 ** 0,
  ofOverwritePrompt = 2 ** 1,
  ofHideReadOnly = 2 ** 2,
  ofNoChangeDir = 2 ** 3,
  ofShowHelp = 2 ** 4,
  ofNoValidate = 2 ** 5,
  ofAllowMultiSelect = 2 ** 6,
  ofExtensionDifferent = 2 ** 7,
  ofPathMustExist = 2 ** 8,
  ofFileMustExist = 2 ** 9,
  ofCreatePrompt = 2 ** 10,
  ofShareAware = 2 ** 11,
  ofNoReadOnlyReturn = 2 ** 12,
  ofNoTestFileCreate = 2 ** 13,
  ofNoNetworkButton = 2 ** 14,
  ofNoLongNames = 2 ** 15,
  ofOldStyleDialog = 2 ** 16,
  ofNoDereferenceLinks = 2 ** 17,
  ofEnableIncludeNotify = 2 ** 18,
  ofEnableSizing = 2 ** 19,
  ofDontAddToRecent = 2 ** 20,
  ofForceShowHidden = 2 ** 21,
}

export enum TFormattableNoSpecifiedFormat {
  fnsfAutoDetect,
  fnsfText,
  fnsfHtml,
}

export enum TDataTextFormat {
  dtfText,
  dtfHTML,
}

export enum TBehaviorTypeByDevice {
  btbdNormal,
  btbdMobile,
}

/// <summary>
///   Rezim viceradkoveho editu
/// </summary>
export enum TInputMultiLineMode {
  /// <summary>
  ///   Standardni, jednoradkovy
  /// </summary>
  imlmSingleLine,

  /// <summary>
  ///   Viceradkovy text, zjednoduseny = wordwrap, bez posuvniku, Enter skace na dalsi komponentu
  /// </summary>
  imlmMultiLineSimple,

  /// <summary>
  ///   Viceradkovy text plny - posuvnik, Enter dela zalomeni radku
  /// </summary>
  imlmMultiLineFull,
}

export enum TNclInputType {
  nitUndefined,
  nitString,
  nitNumeric,
  nitDateTime,
  nitDate,
  nitTime,
}

/// <summary>
///   Rezim zapisu hodnoty z komponenty do presenteru
/// </summary>
export enum TAcceptMode {
  /// <summary>
  ///   Explicitni - vynucene (ztrata focusu, enter, atd...)
  /// </summary>
  amExplicit,

  /// <summary>
  ///   Okamzite - po kazde zmene hodnoty v komponente
  /// </summary>
  amImmediate,

  /// <summary>
  ///   Opozdene - automaticky zapis po zmene hodnoty, ale se zpozdenim
  /// </summary>
  amDelayed,
}

export enum TClientFileActionType {
  catUnknown,
  catOpen,
  catPrint,
  catDownload,
  catDeviceConnector,
}

export enum TUFActionDisplayStyle {
  ufadsButtonTile,
  ufadsButtonLine,
  ufadsButtonSmall,
}

export enum TTreeDataHasChildNodes {
  chnYes,
  chnNo,
  chnOnDemand,
}

export enum TDataItemModifyType {
  tdimModify = 0,
  tdimNew = 1,
  tdimDelete = 2,
}

/// <summary>
///   typ presunu uzlu stromu
/// </summary>
export enum TTreeDataMoveNodeMode {
  tdmnOnNode,
  tdmnBeforeNode,
  tdmnAfterNode,
}

export enum TColorType {
  ctNone,
  ctHColor1,
  ctHColor1Inv,
  ctHColor2,
  ctHColor2Inv,
  ctHColor3,
  ctHColor3Inv,
  ctHColor4,
  ctHColor4Inv,
  ctHColor5,
  ctHColor5Inv,
  ctHColor6,
  ctHColor6Inv,
  ctHColor7,
  ctHColor7Inv,
  ctHColor8,
  ctHColor8Inv,
  ctError,
  ctErrorInv,
  ctWarning,
  ctWarningInv,
  ctHighlight,
  ctHighlightInv,
  ctCustom,
}

export enum TColorShiftLevel {
  cslMin,
  csl1,
  csl2,
  csl3,
  cslMax,
}

export enum TARContainerOrientation {
  coHorizontal,
  coVertical,
}

export enum TARMemberState {
  msClear,
  msChecked,
  msGray,
}

export enum TARGraphSerieType {
  gstBarSerie,
  gstLineSerie,
  gstStackedSerie,
}

export enum TLinkType {
  ltInteractiveSelection,
  ltContextMenu,
  ltDrillThrough,
  ltContextMenuNavigationToUrl,
}

export enum TTypeOfValue {
  tovUniqueName,
  tovLabel,
}

export enum TNavigationType {
  ntInsteadActualDashboard,
  ntNewChildWindow,
  ntNewTab,
}

export enum TARPresentationType {
  ptChartBySeries,
  ptGrid,
  ptBarChart,
  ptLineChart,
  ptStackedChart,
  ptPieChart,
  ptAreaChart,
  ptDrillThroughGrid,
}

export enum TARLegendPosition {
  glpRight,
  glpLeft,
  glpBottom,
  glpTop,
  glpNone,
}

export enum TARPageSetup {
  psFiltersAboveContainersBelow,
  psContainersHorizontally,
  psContainersVertically,
}

export enum TARHorizontalAlignment {
  haLeft,
  haCenter,
  haRight,
  haStretch,
}

export enum TARVerticalAlignment {
  vaTop,
  vaCenter,
  vaBottom,
  vaStretch,
}

export enum TDeviceConnectResult {
  dcrOK,
  dcrWarning,
  dcrError,
}

export enum TPartState {
  psShowError,
  psShowProgress,
  psShowPart,
  psReloadData,
  psAfterLoadingThread,
}

export enum TFormaterWidthMode {
  fwmByContent,
  fwmAuto,
  fwmChars,
  fwmPercent,
  fwmLinesCount,
}

export enum TBlockWidthMultiplierKind {
  bwmkByRatio,
  bwmkPercent,
  bwmkTextWidth,
  bwmkRowHeight,
  bwmkDigit,
  bwmkEm,
}

export enum TMouseButton {
  mbLeft,
  mbRight,
  mbMiddle,
}

export enum TReaderCodeType {
  rctEAN = 1,
  rctEAN8 = 2,
  rctCODE39 = 4,
  rctCODE39VIN = 8,
  rctCODE128 = 16,
  rctCODABAR = 32,
  rctUPC_A = 64,
  rctUPC_E = 128,
  rctI2OF5 = 256,
  rct2OF0 = 512,
  rctCODE93 = 1024,
  rctCODE32 = 2048,
  rctQR = 4096,
}

export enum TUpdateValueState {
  uvsOK,
  uvsRefuseByPerm,
  uvsRefuseByOther,
}

export enum TExplicitBoundsEnabledOptions {
  ebeoPosition = 1,
  ebeoSize = 2,
}

export enum TMFValueCommandState {
  vcsNone,
  vcsEnabled,
  vcsDisabled,
}

export enum FormatterColorMode {
  fcmNone,
  fcmLighten,
  fcmDarken,
  fcmDiferent,
  fcmVCXColor,
  fcmColor,
}

export enum TGPSRequest {
  gpsrStart,
  gpsrStop,
  gpsrCheck,
}

export enum ClientActionNames {
  MAXIMIZE = "maximize",
  DEFAULT = "default",
  OKCOMMAND = "okcommand",
  CLOSE = "close",
}

export enum TLanguageCodeList {
  lclImplicit, // 0
  lclEnglish, // 1
  lclGerman, // 2
  lclSlovak, // 3
  lclFrench, // 4
  lclCzech, // 5
  lclHungarian, // 6
  lclPolish, // 7
  lclItalian, // 8
  lclSpanish, // 9
  lclRussian, // 10
  lclAlbanian, // 11
  lclBelorussian, // 12
  lclBosnian, // 13
  lclBulgarian, // 14
  lclCatalanian, // 15
  lclCroatian, // 16
  lclDanish, // 17
  lclDutch, // 18
  lclGreek, // 19
  lclEstonian, // 20
  lclFinnish, // 21
  lclGallic, // 22
  lclIcelandic, // 23
  lclLatvian, // 24
  lclLithuanian, // 25
  lclLuxembourgian, // 26
  lclMacedonian, // 27
  lclMaltaian, // 28
  lclMoldovian, // 29
  lclNorwegian, // 30
  lclPortugese, // 31
  lclRetoromanian, // 32
  lclRomanian, // 33
  lclSerbian, // 34
  lclSlovene, // 35
  lclSwedish, // 36
  lclTurkish, // 37
  lclUkrainian, // 38
  lclArabic, // 39
  lclFilipino, // 40
  lclHebrew, // 41
  lclIndonesian, // 42
  lclJapanese, // 43
  lclKorean, // 44
  lclMalaysian, // 45
  lclMandarin, // 46
  lclMongolian, // 47
  lclPersian, // 48
  lclSomalian, // 49
  lclSwahilli, // 50
  lclVietnamese, // 51
  lclZhonguoHua, // 52
  lclChinese, // 53
  lclFlemishDutch, // 54
  lclBelgianFrench, // 55
}

//#endregion "enums"

//#region "other types and interfaces"
export type GlyphId = string;

export type SignData = string;

export interface LoginInfo {
  Delay: number;
  TwoFactorAuthentification: boolean;
  Sessions:Array<SessionsInfo>;
  HostName: string;
  WSID?:string;
}

export interface WebSettings {
  SmartlookProjectKey: string;
  EnableSmartlookForUsers: string;
  FoxentryProjectKey: string;
}

export interface AppData {
  NotifyCount: number;
}

export interface LoginData {
  AS3Server: string;
  AS3Pipe: string;
  ReconnectUID?: string;
  Settings: WebSettings;
  ErrorMessageToken: string;
}

export interface SessionsInfo{
  Server:string;
  Pipe: string;
  List: Array<SessionInfo>;
}

export interface SessionInfo{
  ID: string;
  Created?:Date;
  LastUserInteractiveTime?:Date;
  OS?:string;
  Browser?:string;
  DeviceInfo?:string;
  IsActiveConnection?:boolean;
}

export interface CSClientData extends K2JSONObject {}

export interface CSClientUrl extends CSClientData {
  Url: string;
}

export interface CSClientFile extends CSClientData {
  FileName: string;
  Action: TClientFileActionType;
  UseCache: boolean;
  ErrorMessage: string;
  Url: string; // change data to url on IIS
  UrlPattern: string;
}

export interface CSFile extends CSClientData {
  FileName: string;
  Data: string;
}

export interface FileItem extends CSFile {
  Id: number;
  Size: number;
}

export interface CSClientSound extends CSClientFile {}

export interface CSDeviceConnectData extends CSClientData {
  ConnectionString: string;
  RequestMessage: string;
  DisconnectRequest: boolean;
}

export interface OutData {
  OutData: Array<CSClientData>;
}

export interface Function {
  Name: string;
  Args?: any;
}

export interface Bounds {
  Align: Align;
  FromBandIndex: number;
  PartIndex: number;
  BandsCount: number;
  InteriorBandsCount: number;
}

export interface RectInDock extends K2JSONObject {
  SizeMode: RectInDockSizeMode;
  PositionMode: RectInDockPositionMode;
  FrameStyle: FrameStyle;
  Width: number;
  Height: number;
  AnchorControlUID: string;
  AnchoredControlName: string;
  EffectiveMouseClickClose: boolean;
}

export interface ExplicitBounds {
  Left: number;
  Top: number;
  Width: number;
  Height: number;
  Maximized: boolean;
  BoundsEnabledOptions: TExplicitBoundsEnabledOptions;
}

export class DataRequest {
  public RealizerUID: string;
  public Data: Array<ControlDataRequest>;
  public ActiveControl: string;
  public ScreenSize: number;
  public TransformColumnsCount: number;

  constructor(realizerUID: string) {
    this.RealizerUID = realizerUID;
    this.Data = new Array<ControlDataRequest>();
  }
}

export interface MultiMessage {
  Operation: number;
  RealizerUID: string;
  RealizeCounter: number;
  JSon: string;
}

export interface UserInfo {
  Lng: number;
  InstallationLanguageNo: number;
  NoopTimeout: number;
  InactivityTimeout: number;
  IsTestVersion?: boolean;
  MainMenuData: MainMenuData;
}

interface MainMenuData {
  DataFromDM: DataFromDM[];
}

export interface DataFromDM {
  RID: number;
  IsFolder: boolean;
  ParentRID: number;
  Name: string;
  Icon: string;
  Hint: string;
  IsInvalidRecord: boolean;
  __type: string;
  Expanded: boolean;
  NormalizedName: string;
  ChildrenIds: number[];
}

export interface CSAggrData {
  Text: String;
  Align: TAlignment;
  GlyphId: GlyphId;
}

export interface CondFormattingStyle {
  Options: TCondFormattingOption;
  BackColor: number;
  ForegroundColor: number;
}

export interface MFMeasure extends K2JSONObject {
  Sections: Array<MFSectionMeasureItem>;
  //Calc
  Height?: number;
}

export interface MFSectionMeasureItem extends K2JSONObject {
  LBlocks: MFBlocksMeasureItem;
  Bands: Array<MFBlocksMeasureItem>;
  RBlocks: MFBlocksMeasureItem;
  //Calc
  Height?: number;
}

export interface MFBlocksMeasureItem extends K2JSONObject {
  Blocks: Array<MFBlockMeasureItem>;
  //Calc
  Height?: number;
  MaxLineCount?: number;
}

export interface MFBlockMeasureItem extends K2JSONObject {
  Index: number;
  Attrs: FormatterAttrs;
  BlockDeclaredLF: number;
  WidthMultiplierKind: TBlockWidthMultiplierKind;
  Width: number;
  WidthPlusBlockHeight: number;
  CommandNumber: number;
}

export interface MultiFormatterValuesPair extends K2JSONObject {
  Values: List<MFValue>;
  Measure: MFMeasure;
}

export interface InstantFilter extends K2JSONObject {
  List: List<MultiFormatterValuesPair>;
  GapX: number;
  GapY: number;
  PaddingX: number;
  PaddingY: number;
  IsLive: boolean;
}

export interface FormatterColor extends K2JSONObject {
  Color: number;
  ColorMode: FormatterColorMode;
}

export interface FormatterAttrs extends K2JSONObject {
  Enabled: boolean;
  Font: FormatterFontAttrs;
  Border: FormatterBorderAttrs;
  BackColor: FormatterColor;
  NewSection: boolean;
  LineCount: number;
  Width: number;
  WidthMode: TFormaterWidthMode;
  NewBand: boolean;
  TextHorzAlign: TAlignment;
  TextVertAlign: TVerticalAlignment;
  ImageHorzAlign: TAlignment;
  ShowImage: boolean;
}

export interface FormatterFontAttrs extends K2JSONObject {
  Color: FormatterColor;
  Enabled: boolean;
  FontStyle: FontStyle;
  Zoom: number;
}

export interface FormatterBorderAttrs extends K2JSONObject {
  Left: boolean;
  Right: boolean;
  Top: boolean;
  Bottom: boolean;
  Width: number;
}

export interface K2Attribute {
  Name: string;
  Value: string;
}

export interface ModalPosition {
  x: number;
  y: number;
}

//#endregion "other types and interfaces"

//interface objektu ktere klient posila na server jako update

//#region "Control Request"

export interface ControlDataRequest {
  ControlUID: string;
  Functions?: Array<Function>;
  Commands?: Array<Function>;
}

export interface InputDataRequest extends ControlDataRequest {
  Text: string;
}
export interface KeyboardInputDataRequest extends ControlDataRequest {
  KeyboardInput: string;
}

export interface CheckBoxDataRequest extends ControlDataRequest {
  Checked: boolean;
}
export interface InnerColumnsListDataRequest extends ControlDataRequest {
  WidthColumns?: Array<Array<number>>;
  ColumnsMove?: Array<Array<number>>;
  CurrentColumn: number;
}
export interface InnerDataGridDataRequest extends InnerColumnsListDataRequest {
  MaxRowCount: number;
  Position: number;
}

export interface DataGridDataRequest extends ControlDataRequest {
  MaxRowCount: number;
}

export interface GanttDataRequest extends ControlDataRequest {
  MaxRowCount: number;
  Position: number;
  UpdatedData: string[][]; //List<List<string>>;
  TargetID: string;
}

export interface TabControlDataRequest extends ControlDataRequest {
  CurrentPage: string;
}

export interface ExpanderDataRequest extends ControlDataRequest {
  Collapsed: boolean;
}

export interface SplitterDataRequest extends ControlDataRequest {
  Ratio: number;
  Collapsed: boolean;
}

export interface ListViewDataRequest extends ControlDataRequest {
  Position: number;
}

export interface RibbonDataRequest extends ControlDataRequest {
  CurrentToolBarIndex: number;
}

export interface SilentOpenDialogDataRequest extends ControlDataRequest {
  File: CSFile;
}

export interface OpenDialogDataRequest extends ControlDataRequest {
  Files: Array<CSFile>;
}

export interface SignInputDataRequest extends ControlDataRequest {
  SignData: SignData;
}

export interface TreeViewDataRequest extends ControlDataRequest {
  CurrentBookmark: string;
}

export interface ViewDataRequest extends ControlDataRequest {
  ExplicitBounds: ExplicitBounds;
  FormExplicitBounds: ExplicitBounds;
}

export interface DeviceConnectorDataRequest extends ControlDataRequest {
  ResultType: TDeviceConnectResult;
  ResultMessage: string;
}

export interface GPSDataRequest extends ControlDataRequest {
  GPS: string;
  GPSErrMsg: string;
  Accuracy: number;
}

export interface VirtualKeyboardRequest extends ControlDataRequest {
  InputText: string;
}

export interface CodeReaderRequest extends ControlDataRequest {
  Result: string;
}

//#endregion

//#region "Interface objektu ktere klient ziskava ze serveru pri update"

export interface CSUpdateControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
}

export const UpdateControlRecord = (defaultValues: any) =>
  class extends Record({
    ControlUID: "",
    Visible: false,
    Enabled: undefined,
    TabStop: false,
    RealizerUID: "",
    ...defaultValues,
  }) {};

export class UpdateControl extends UpdateControlRecord({}) implements CSUpdateControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;

  constructor(props: Partial<CSUpdateControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdateControl>): UpdateControl {
    return this.merge(value) as this;
  }
}

export interface CSUFUpdateControl extends CSUpdateControl {
  Title: string;
  GlyphId: GlyphId;
  RenderVersion?: number;
}

export const UFUpdateControlRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    Title: "",
    GlyphId: "",
    RenderVersion: null,
    ...defaultValues,
  }) {};

export class UFUpdateControl extends UFUpdateControlRecord({}) implements CSUFUpdateControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  RenderVersion?: number;

  constructor(props: Partial<CSUFUpdateControl>) {
    super(props);
  }

  public with(value: Partial<CSUFUpdateControl>): UFUpdateControl {
    return this.merge(value) as this;
  }
}

interface CSUpdatePrefixSufix extends CSUFUpdateControl {
  PrefixUpdateValueState: TUpdateValueState;
  PrefixGlyphId: GlyphId;
  PrefixText: string;
  SuffixUpdateValueState: TUpdateValueState;
  SuffixGlyphId: GlyphId;
  SuffixText: string;
}

const UpdatePrefixSufixRecord = (defaultValues: any) =>
  UFUpdateControlRecord({
    PrefixUpdateValueState: TUpdateValueState.uvsOK,
    PrefixGlyphId: "",
    PrefixText: "",
    SuffixUpdateValueState: TUpdateValueState.uvsOK,
    SuffixGlyphId: "",
    SuffixText: "",
    ...defaultValues,
  });

export interface CSUpdateInput extends CSUpdatePrefixSufix {
  Text: string;
  ReadOnly: boolean;
  Watermark: string;
  TextUpdateValueState: TUpdateValueState;
  Modified: boolean;
  AcceptMode: TAcceptMode;
  AutoSelect: boolean;
  Hint: string;
  IsUpperCase: boolean;
  MultiLineMode: TInputMultiLineMode;
  MaxLength: number;
  Format: string;
  ErrorsCount: number;
  WarningsCount: number;
  Formatting: CondFormattingStyle;
  PasteText: string;
  InputType: TNclInputType;
  HorizontalAlignment: HorizontalAlignment;
}

const UpdateInputRecord = UpdatePrefixSufixRecord({
  Text: "",
  ReadOnly: false,
  Watermark: "",
  TextUpdateValueState: TUpdateValueState.uvsOK,
  Modified: false,
  AcceptMode: TAcceptMode.amExplicit,
  AutoSelect: false,
  Hint: "",
  IsUpperCase: false,
  MultiLineMode: TInputMultiLineMode.imlmSingleLine,
  MaxLength: 0,
  Format: "",
  ErrorsCount: 0,
  WarningsCount: 0,
  Formatting: undefined,
  PasteText: "",
  InputType: TNclInputType.nitUndefined,
  HorizontalAlignment: undefined
});

export class UpdateInput extends UpdateInputRecord implements CSUpdateInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;
  Watermark: string;
  TextUpdateValueState: TUpdateValueState;
  PrefixUpdateValueState: TUpdateValueState;
  PrefixGlyphId: GlyphId;
  PrefixText: string;
  SuffixUpdateValueState: TUpdateValueState;
  SuffixGlyphId: GlyphId;
  SuffixText: string;
  AcceptMode: TAcceptMode;
  AutoSelect: boolean;
  Hint: string;
  IsUpperCase: boolean;
  MultiLineMode: TInputMultiLineMode;
  MaxLength: number;
  Format: string;
  ErrorsCount: number;
  WarningsCount: number;
  Formatting: CondFormattingStyle;
  PasteText: string;
  InputType: TNclInputType;
  HorizontalAlignment: HorizontalAlignment;

  get FormattingStyle(): CondFormattingStyle {
    let f = this.get("Formatting");
    if (f && f.toJS) {
      return f.toJS() as CondFormattingStyle;
    }

    return undefined;
  }

  constructor(props: Partial<CSUpdateInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInput>): UpdateInput {
    return this.merge(value) as this;
  }
}

export interface CSUpdateKeyboardInput extends CSUFUpdateControl {
  Text: string;
  ReadOnly: boolean;
  Watermark: string;
  ValueDeny: boolean;
}

const UpdateKeyboardInputRecord = UpdatePrefixSufixRecord({
  Text: "",
  ReadOnly: false,
  Watermark: "",
  ValueDeny: false,
});

export class UpdateKeyboardInput extends UpdateKeyboardInputRecord implements CSUpdateKeyboardInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;
  Watermark: string;
  ValueDeny: boolean;

  get FormattingStyle(): CondFormattingStyle {
    let f = this.get("Formatting" as any);
    if (f && f.toJS) {
      return f.toJS() as CondFormattingStyle;
    }

    return undefined;
  }

  constructor(props: Partial<CSUpdateKeyboardInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateKeyboardInput>): UpdateKeyboardInput {
    return this.merge(value) as this;
  }
}

export interface CSUpdateInputFramed extends CSUFUpdateControl {
  Text: string;
  ReadOnly: boolean;
}

const UpdateInputFramedRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    Text: "",
    ReadOnly: false,
    ...defaultValues,
  }) {};

export class UpdateInputFramed extends UpdateInputFramedRecord({}) implements CSUpdateInputFramed {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;

  constructor(props: Partial<CSUpdateInputFramed>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInputFramed>): UpdateInputFramed {
    return this.merge(value) as this;
  }
}

export interface CSUpdateFormattableInput extends CSUpdateInputFramed {
  Language: number;
  TextFormat: TDataTextFormat;
}

const UpdateFormattableInputRecord = UpdateInputFramedRecord({
  Language: 0,
  TextFormat: TDataTextFormat.dtfText,
});

export class UpdateFormattableInput extends UpdateFormattableInputRecord implements CSUpdateFormattableInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Modified: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Text: string;
  ReadOnly: boolean;
  Language: number;
  TextFormat: TDataTextFormat;

  constructor(props: Partial<CSUpdateFormattableInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateFormattableInput>): UpdateFormattableInput {
    return this.merge(value) as this;
  }
}

export interface CSUpdateCommandItem extends CSUFUpdateControl {
  Decorate: DataActionDecorate;
  Checked: boolean;
  SubMenuIndicatorVisible: boolean;
}

const UpdateCommandItemRecord = UFUpdateControlRecord({
  Decorate: DataActionDecorate.dadeBase,
  Checked: false,
  SubMenuIndicatorVisible: false,
});

export class UpdateCommandItem extends UpdateCommandItemRecord implements CSUpdateCommandItem {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  Checked: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Decorate: DataActionDecorate;
  SubMenuIndicatorVisible: boolean;

  constructor(props: Partial<CSUpdateCommandItem>) {
    super(props);
  }

  public with(value: Partial<CSUpdateCommandItem>): UpdateCommandItem {
    return this.merge(value) as this;
  }
}

export interface CSUpdateCheckBox extends CSUFUpdateControl {
  Checked: boolean;
  ReadOnly: boolean;
  ValueDeny: boolean;
  ErrorsCount: number;
  WarningsCount: number;
}

const UpdateCheckBoxRecord = UFUpdateControlRecord({
  Checked: false,
  ReadOnly: false,
  ValueDeny: false,
  ErrorsCount: 0,
  WarningsCount: 0,
});

export class UpdateCheckBox extends UpdateCheckBoxRecord implements CSUpdateCheckBox {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Checked: boolean;
  ReadOnly: boolean;
  ValueDeny: boolean;
  ErrorsCount: number;
  WarningsCount: number;

  constructor(props: Partial<CSUpdateCheckBox>) {
    super(props);
  }

  public with(value: Partial<CSUpdateCheckBox>): UpdateCheckBox {
    return this.merge(value) as this;
  }
}

export interface CSUpdateDataLabel extends CSUpdatePrefixSufix {
  Text: string;
  Hint: string;
  TextUpdateValueState: TUpdateValueState;
  Watermark: string;
  LinkDecorate: DataActionDecorate;
  LinkEnabled: boolean;
}

const UpdateDataLabelRecord = UpdatePrefixSufixRecord({
  Text: "",
  Hint: "",
  TextUpdateValueState: TUpdateValueState.uvsOK,
  Watermark: "",
  LinkDecorate: DataActionDecorate.dadeBase,
  LinkEnabled: false,
});

export class UpdateDataLabel extends UpdateDataLabelRecord implements CSUpdateDataLabel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  PrefixUpdateValueState: TUpdateValueState;
  PrefixGlyphId: GlyphId;
  PrefixText: string;
  SuffixUpdateValueState: TUpdateValueState;
  SuffixGlyphId: GlyphId;
  SuffixText: string;
  Text: string;
  Hint: string;
  TextUpdateValueState: TUpdateValueState;
  Watermark: string;
  LinkDecorate: DataActionDecorate;
  LinkEnabled: boolean;

  constructor(props: Partial<CSUpdateDataLabel>) {
    super(props);
  }

  public with(value: Partial<CSUpdateDataLabel>): UpdateDataLabel {
    return this.merge(value) as this;
  }
}

export interface ColumnProportionEm {
  FieldPath: string;
  Caption: string;
  Adaptable: boolean;
  Size: number;
  Alignment: TAlignment;
  MultiplierKind: TUFCharCountMultiplierKind;
  DisplayStyleRT: TUFContextDisplayStyle;
  Width?: number;
  MFMeasure?: MFMeasure;
}

export interface ColumnsProportion extends Array<ColumnProportionEm> {}

export interface OrderByItem {
  ColNdx: number;
  OrderByNdx: number;
}

export interface CSUpdateInnerColumnsList extends CSUpdateControl {
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  ColumnsProportion: ColumnsProportion;
  CurrentColumn: number;
  ColumnsVersion: number;
  ComputedColumnsVersion: number;
}

export const UpdateInnerColumnsListRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    AutoAdjustWidth: false,
    FixedColumnsCount: 0,
    ColumnsProportion: List<Iterable.Keyed<string, any>>(),
    CurrentColumn: undefined,
    ColumnsVersion: undefined,
    ComputedColumnsVersion: undefined,
    ...defaultValues,
  }) {
    private columnsProportion: ColumnsProportion;

    public get ColumnsProportion(): ColumnsProportion {
      //Nechci pristupovat navenek k vlastnostem pres get(name) tak z List delam plain JS objekt
      if (!this.columnsProportion) this.columnsProportion = this.get("ColumnsProportion").toJS();

      return this.columnsProportion;
    }
  };

export class UpdateInnerColumnsList extends UpdateInnerColumnsListRecord(null) implements CSUpdateInnerColumnsList {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  CurrentColumn: number;
  ColumnsVersion: number;
  ComputedColumnsVersion: number;

  constructor(props: Partial<CSUpdateInnerColumnsList>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerColumnsList>): UpdateInnerColumnsList {
    return this.merge(value) as this;
  }
}

export interface CSColumnBase {
  CondFormatting: CondFormattingStyle;
  Text: string;
  GlyphId: string;
}

export interface CSColumn extends CSColumnBase {
  IsModified: boolean;
  HasError: boolean;
  IsCancelled: boolean;
  IsValidValue: boolean;
  IsSelectedText: boolean;
  CanEdit: boolean;
  UpdateValueState: TUpdateValueState;
  MultiTexts: Array<MFValue>;
}

export interface CSDataItem {
  Key: string;
  Row: number;
  Columns: Array<CSColumn>;
}

export interface ModifyItem {
  Key: string;
  ModifyType: TDataItemModifyType;
  Changes: CSDataItem;
}

export interface CSUpdateInnerDataGrid extends CSUpdateInnerColumnsList {
  Data?: Array<ModifyItem>;
  OrderBy: List<OrderByItem>;
  CanEditItems: boolean;
  DataVersion: number;
  Position: number;
}

export const UpdateInnerDataGridRecord = class extends UpdateInnerColumnsListRecord({
  OrderBy: List<OrderByItem>(),
  CurrentColumn: -1,
  Position: -1,
  CanEditItems: false,
  DataVersion: -1,
}) {};

export class UpdateInnerDataGrid extends UpdateInnerDataGridRecord implements CSUpdateInnerDataGrid {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  OrderBy: List<OrderByItem>;
  CanEditItems: boolean;
  DataVersion: number;
  Position: number;
  CurrentColumn: number;
  ColumnsVersion: number;
  ComputedColumnsVersion: number;

  constructor(props: Partial<CSUpdateInnerDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerDataGrid>): UpdateInnerDataGrid {
    return this.merge(value) as this;
  }
}

export interface CSUpdateTabControl extends CSUFUpdateControl {
  CurrentPage: string;
}

const UpdateTabControlRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    CurrentPage: "",
    ...defaultValues,
  }) {};

export function GetColumnWidth(value: ColumnProportionEm, vcx: VCXGrid): number {
  let result: number = 0;
  switch (value.MultiplierKind) {
    case TUFCharCountMultiplierKind.ccmk2Em3:
      result = vcx.GetExteriorCellWidth(Math.round(value.Size * vcx.Font._MWidth * (2 / 3)));
      break;
    case TUFCharCountMultiplierKind.ccmkRowHeight:
      result = vcx.GetExteriorCellWidth(vcx.GetRowHeightWithoutMargin(Math.round(value.Size)));
      break;
    case TUFCharCountMultiplierKind.ccmkZero:
      result = vcx.GetExteriorCellWidth(Math.round(value.Size * vcx.Font._0Width));
      break;
    case TUFCharCountMultiplierKind.ccmkEm:
    default:
      result = vcx.GetExteriorCellWidth(Math.round(value.Size * vcx.Font._MWidth));
  }

  result = Math.max(result, vcx.Font.computeTextWidth(value.Caption));

  if (result <= 0) {
    result = Math.max(Math.floor(vcx.GetRowHeight(value.Size) / 2), 4);
  }

  return result;
}

export function GetColumnWidthEm(valuePx: number, vcx: VCXGrid): number {
  return (valuePx - vcx.GetExteriorCellWidth(0)) / vcx.Font._MWidth;
}

// export interface IGanttDataItem{
//     ID : string;
//     ParentID : string;
//     Name : string;
//     SerieName : string;
//     DateTimeFrom : string;
//     DateTimeTo : string;
//     Completed : string;
//     IsMilestone : string;
//     IsStage : string;
// }

export class GanttOperationItem {
  operationId: string;
  parentId: string;
  name: string;
  serieName: string;
  dateTimeFrom: number;
  dateTimeTo: number;
  completed: number;
  isMilestone: boolean;
  isStage: boolean;
  resourceId: string;
  resourceOrder: number;
  isExpanded: boolean;
  isInCollision: boolean;
  itemDependencies: string; //13
  itemDependenciesTypes: string; //14
  customColor: string; //15
  workTime: number; //16
  workTimeUnit: string; //17
  htmlTooltip: string; //18
  description: string; //19
  customPattern: number; //20
  collisionRow: number; //21
  groupById: string; //22
  selected: boolean;
  readOnly: boolean;

  private day = 1000 * 60 * 60 * 24; //milisekundy za den

  private K2posun = 365 * 70 + 19; //70 let ve dnech

  public map(item: List<string>) {
    item.map((value: string, valueindex: number, array: List<string>) => {
      //DEPENDENCY
      // if ((itemindex+1) == 3) {
      //   dataitem1.dependency = String(2);
      // }
      // 	// dependency: {
      // 	// 	type:'simpleConnect',
      // 	// 	dashStyle:'solid',
      // 	// 	endMarker:{enabled:true},
      // 	// 	startMarker:{enabled:true},
      // 	// 	lineWidth:10,
      // 	// 	lineColor:"#00FF00",
      // 	// 	//symbol: 'square', // Use a square symbol
      // 	// 	//to: this.state.chartOptions.series[0] as SeriesGanttOptions }
      // 	// 	to:'1'
      // 	// }

      //SUBTASKS - Etapa - Cinnost
      // console.log(dataitem1.id);
      // if (itemindex > 1) {
      // 	dataitem1.parent = String(itemindex);

      switch (valueindex) {
        case 0:
          this.operationId = value;
          break;
        case 1:
          this.parentId = value;
          break;
        case 2:
          //console.log('name value 0: '+value);
          this.name = value;
          break;
        case 3:
          this.serieName = value;
          break;
        case 4:
          //console.log('name value 1: '+value);
          //dataitem1.start = this.today.getTime();
          //dataitem1.start = new Date(value).getDate();
          this.dateTimeFrom = Math.round((Number(value) - this.K2posun) * this.day);
          break;
        case 5:
          //dataitem1.end = this.today.getTime() + (20 * this.day);
          //dataitem1.end = new Date(value).getDate();
          this.dateTimeTo = Math.round((Number(value) - this.K2posun) * this.day);
          break;
        case 6:
          this.completed = Number(value);
          break;
        case 7:
          this.isMilestone = Boolean(value);
          break;
        case 8:
          this.isStage = Boolean(value);
          break;
        case 9:
          this.resourceId = value;
          break;
        case 10:
          this.resourceOrder = Number(value);
          break;
        case 11:
          this.isExpanded = Boolean(value);
          break;
        case 12:
          this.isInCollision = Boolean(value);
          break;
        case 13:
          this.itemDependencies = value;
          break;
        case 14:
          this.itemDependenciesTypes = value;
          break;
        case 15:
          this.customColor = value;
          break;
        case 16:
          this.workTime = Number(value);
          break;
        case 17:
          this.workTimeUnit = value;
          break;
        case 18:
          this.htmlTooltip = value;
          break;
        case 19:
          this.description = value;
          break;
        case 20:
          this.customPattern = Number(value);
          break;
        case 21:
          this.collisionRow = Number(value);
          break;
        case 22:
          this.groupById = value;
          break;
        case 23:
          this.selected = Boolean(value);
          break;
        case 24:
          this.readOnly = Boolean(value);
          break;
        default:
        // code block
      }
    });
  }
}

export class GanttResourceItem {
  resourceId: string;
  name: string;
  //operationId: string;
  order: string; //number;
  abbr: string;
  hasCollision: boolean;
  collisionCount: number;

  public map(item: List<string>) {
    item.map((value: string, valueindex: number, array: List<string>) => {
      switch (valueindex) {
        case 0:
          this.resourceId = value;
          break;
        case 1:
          this.name = value;
          break;
        // case 2:
        // 	this.operationId = value;
        // 	break;
        case 2:
          this.order = value; //Number(value);
          break;
        case 3:
          this.abbr = value;
          break;
        case 4:
          this.hasCollision = Boolean(value);
          break;
        case 5:
          this.collisionCount = Number(value);
          break;
        default:
        // code block
      }
    });
  }
}

export interface CSUpdateInnerGantt extends CSUpdateControl {
  //interface odpovida TNclInnerGantt.TUpdatable
  //AutoAdjustWidth:boolean;
  MarkedCount: number;
  Position: number;
  Operations: List<List<string>>; //List<GanttDataItem>;
  Resources: any;
  Capacities: any;
  DayBackgrounds: any;
  //OrderBy: List<OrderByItem>;
  //ColumnsProportion: ColumnsProportion;
  RowCount: number;
  RecordsCount: number;
  CanEditItems: boolean;
  MinRangeDate: number;
  MaxRangeDate: number;
  GanttUniqueIdentifier: string;
  DraggableX: boolean;
  DraggableY: boolean;
}

export const UpdateInnerGanttRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    //
    //AutoAdjustWidth:false,
    MarkedCount: 0,
    Position: 0,
    Operations: List<List<string>>(), //  List<GanttDataItem>(),
    Resources: List<List<any>>(),
    Capacities: List<List<any>>(),
    DayBackgrounds: List<List<any>>(),
    //OrderBy: List<OrderByItem>(),
    //ColumnsProportion: List<Iterable.Keyed<string, any>>(),
    RowCount: 0,
    RecordsCount: 0,
    CanEditItems: false,
    MinRangeDate: 0,
    MaxRangeDate: 0,
    GanttUniqueIdentifier: "",
    DraggableX: false,
    DraggableY: false,
    ...defaultValues,
  }) {};

export interface GanttOperation {
  operationId: number;
  parentId: number;
  name: string;
  serieName: string;
  datetimeFrom: number;
  datetimeTo: number;
  completed: number;
  isMilestone: boolean;
  isStage: boolean;
  resourceId: number;
  resourceOrder: number;
  isExpanded: boolean;
  isInCollision: boolean;
  dependencyIds: string;
  dependencyTypes: string;
  customColor: string;
  workTime: number;
  workTimeUnit: string;
  htmlTooltip: string;
  description: string;
  customPattern: number;
  collisionRow: number;
  groupById: string;
  selected: boolean;
  readOnly: boolean;
}

export interface GanttResource {
  resourceID: number;
  name: string;
  order: number;
  abbr: string;
  hasCollision: boolean;
  collisionCount: number;
}

export interface GanttCapacity {
  resourceID: number;
  order: number;
  datetimeFrom: number;
  datetimeTo: number;
  collisionRow: number;
}

export class UpdateInnerGantt extends UpdateInnerGanttRecord(null) implements CSUpdateInnerGantt {
  //
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  //AutoAdjustWidth:boolean;
  Position: number;
  Operations: List<List<string>>; //List<GanttDataItem>;
  Resources: any;
  Capacities: any;
  DayBackgrounds: any;
  //OrderBy: List<OrderByItem>;
  RowCount: number;
  RecordsCount: number;
  MarkedCount: number;
  CanEditItems: boolean;
  MinRangeDate: number;
  MaxRangeDate: number;
  RangeDateFrom: number;
  RangeDateTo: number;
  DraggableX: boolean;
  DraggableY: boolean;
  GanttUniqueIdentifier: string;

  constructor(props: Partial<CSUpdateInnerGantt>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerGantt>): UpdateInnerGantt {
    return this.merge(value) as this;
  }
}

export class UpdateTabControl extends UpdateTabControlRecord({}) implements CSUpdateTabControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CurrentPage: string;

  constructor(props: Partial<CSUpdateTabControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdateTabControl>): UpdateTabControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdateVRTabControl extends CSUpdateTabControl {
  AddedTabs: List<List<string>>;
  RemovedTabs: List<string>;
  DataVersion: number;
}

const UpdateVRTabControlRecord = UpdateTabControlRecord({
  AddedTabs: List<List<string>>(),
  RemovedTabs: List<string>(),
  DataVersion: null,
});

export class UpdateVRTabControl extends UpdateVRTabControlRecord implements CSUpdateVRTabControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CurrentPage: string;
  AddedTabs: List<List<string>>;
  RemovedTabs: List<string>;
  DataVersion: number;

  constructor(props: Partial<CSUpdateVRTabControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdateVRTabControl>): UpdateVRTabControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdatePageControl extends CSUFUpdateControl {
  Hide: boolean;
  IsPinned: boolean;
}

const UpdatePageControlRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    Hide: false,
    IsPinned: false,
    ...defaultValues,
  }) {};

export class UpdatePageControl extends UpdatePageControlRecord({}) implements CSUpdatePageControl {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Hide: boolean;
  IsPinned: boolean;
  constructor(props: Partial<CSUpdateTabControl>) {
    super(props);
  }

  public with(value: Partial<CSUpdatePageControl>): UpdatePageControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdateHeadered extends CSUFUpdateControl {
  CollapsedTitle: string;
  TitleSuffix: string;
  TitleSuffixCommandEnabled: boolean;
}

const UpdateHeaderedRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    CollapsedTitle: "",
    TitleSuffix: "",
    TitleSuffixCommandEnabled: false,
    ...defaultValues,
  }) {};

export class UpdateHeadered extends UpdateHeaderedRecord({}) implements CSUpdateHeadered {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  TitleSuffix: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CollapsedTitle: string;
  TitleSuffixCommandEnabled: boolean;

  constructor(props: Partial<CSUpdateHeadered>) {
    super(props);
  }

  public with(value: Partial<CSUpdateHeadered>): UpdateHeadered {
    return this.merge(value) as this;
  }
}

export enum AlphabetMode {
  unknown,
  numeric,
  upperCase,
}

export interface CSUpdateVirtualKeyboard extends CSUpdateHeadered {
  Mode: AlphabetMode;
  OldValue: string;
}

const UpdateVirtualKeyboardRecord = (defaultValues: any) =>
  class extends UpdateHeaderedRecord({
    Mode: AlphabetMode.unknown,
    OldValue: "",
    ...defaultValues,
  }) {};

export class UpdateVirtualKeyboard extends UpdateVirtualKeyboardRecord({}) implements CSUpdateVirtualKeyboard {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  TitleSuffix: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CollapsedTitle: string;
  TitleSuffixCommandEnabled: boolean;
  Mode: AlphabetMode;
  OldValue: string;

  constructor(props: Partial<CSUpdateVirtualKeyboard>) {
    super(props);
  }

  public with(value: Partial<CSUpdateVirtualKeyboard>): UpdateVirtualKeyboard {
    return this.merge(value) as this;
  }
}

export interface CSUpdateDataGrid extends CSUpdateHeadered {
  MarkedCount: number;
  RecordsCount: number;
  InLineEditActive: boolean;
}

export const UpdateDataGridRecord = class extends UpdateHeaderedRecord({
  MarkedCount: 0,
  RecordsCount: 0,
  InLineEditActive: false,
}) {};

export class UpdateDataGrid extends UpdateDataGridRecord implements CSUpdateDataGrid {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  RecordsCount: number;
  MarkedCount: number;
  CollapsedTitle: string;
  TitleSuffix: string;
  InLineEditActive: boolean;
  TitleSuffixCommandEnabled: boolean;

  constructor(props: Partial<CSUpdateDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateDataGrid>): UpdateDataGrid {
    return this.merge(value) as this;
  }
}

export interface CSUpdateExpander extends CSUpdateHeadered {
  Collapsed: boolean;
}

const UpdateExpanderRecord = UpdateHeaderedRecord({
  Collapsed: false,
});

export class UpdateExpander extends UpdateExpanderRecord implements CSUpdateExpander {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  TitleSuffix: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Collapsed: boolean;
  CollapsedTitle: string;
  TitleSuffixCommandEnabled: boolean;

  constructor(props: Partial<CSUpdateExpander>) {
    super(props);
  }

  public with(value: Partial<CSUpdateExpander>): UpdateExpander {
    return this.merge(value) as this;
  }
}

export interface CSUpdateImage extends CSUFUpdateControl {
  Stretch: boolean;
  ObjectId: string;
  ImageDeny: boolean;
}

const UpdateImageRecord = (defaultValues: any) =>
  class extends UFUpdateControlRecord({
    Stretch: false,
    ObjectId: "",
    ImageDeny: false,
    ...defaultValues,
  }) {};

export class UpdateImage extends UpdateImageRecord({}) implements CSUpdateImage {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Stretch: boolean;
  ObjectId: string;
  ImageDeny: boolean;

  constructor(props: Partial<CSUpdateImage>) {
    super(props);
  }

  public with(value: Partial<CSUpdateImage>): UpdateImage {
    return this.merge(value) as this;
  }
}

export interface CSUpdateFilePreview extends CSUpdateImage {
  IsSupported: boolean;
  IsLoaded: boolean;
  Alias: string;
}

const UpdateFilePreviewRecord = UpdateImageRecord({
  IsSupported: false,
  IsLoaded: false,
  Alias: undefined,
});

export class UpdateFilePreview extends UpdateFilePreviewRecord implements CSUpdateFilePreview {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Stretch: boolean;
  ObjectId: string;
  ImageDeny: boolean;
  IsSupported: boolean;
  IsLoaded: boolean;
  Alias: string;

  constructor(props: Partial<CSUpdateFilePreview>) {
    super(props);
  }

  public with(value: Partial<CSUpdateFilePreview>): UpdateFilePreview {
    return this.merge(value) as this;
  }
}

export interface CSUpdateSplitterPanel extends CSUFUpdateControl {
  Ratio: number;
  Collapsed: boolean;
  FirstVisible: boolean;
  SecondVisible: boolean;
}

const UpdateSplitPanelRecord = UFUpdateControlRecord({
  Ratio: 0,
  Collapsed: false,
  FirstVisible: false,
  SecondVisible: false,
});

export class UpdateSplitterPanel extends UpdateSplitPanelRecord implements CSUpdateSplitterPanel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Ratio: number;
  Collapsed: boolean;
  FirstVisible: boolean;
  SecondVisible: boolean;

  constructor(props: Partial<CSUpdateSplitterPanel>) {
    super(props);
  }

  public with(value: Partial<CSUpdateSplitterPanel>): UpdateSplitterPanel {
    return this.merge(value) as this;
  }
}

export interface CSUpdatePageHolder extends CSUFUpdateControl {
  DockRealizerUID: string;
}

const UpdatePageHolderRecord = UFUpdateControlRecord({
  DockRealizerUID: "",
});

export class UpdateDockControl extends UpdatePageHolderRecord implements CSUpdatePageHolder {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  DockRealizerUID: string;

  constructor(props: Partial<UpdateDockControl>) {
    super(props);
  }

  public with(value: Partial<UpdateDockControl>): UpdateDockControl {
    return this.merge(value) as this;
  }
}

export interface CSUpdateDynamicContent extends CSUFUpdateControl {
  Structure: CSUFNclControlMetadata;
  DynamicContentId: string;
}

const UpdateDynamicContentRecord = UFUpdateControlRecord({
  Structure: null,
  DynamicContentId: null,
});

export class UpdateDynamicContent extends UpdateDynamicContentRecord implements CSUpdateDynamicContent {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Structure: CSUFNclControlMetadata;
  DynamicContentId: string;

  constructor(props: Partial<CSUpdateDynamicContent>) {
    super(props);
  }

  public with(value: Partial<CSUpdateDynamicContent>): UpdateDynamicContent {
    return this.merge(value) as this;
  }
}

export interface CSUpdateMenuContainer extends CSUFUpdateControl {
  isOpen: boolean;
}

const UpdateMenuContainerRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    isOpen: false,
    ...defaultValues,
  }) {};

export class UpdateMenuContainer extends UpdateMenuContainerRecord({}) implements CSUpdateMenuContainer {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  isOpen: boolean;

  constructor(props: Partial<CSUpdateMenuContainer>) {
    super(props);
  }

  public with(value: Partial<CSUpdateMenuContainer>): UpdateMenuContainer {
    return this.merge(value) as this;
  }
}

export interface CSUpdateSignInput extends CSUFUpdateControl {
  SignData: SignData;
}

const UpdateSignInputRecord = (defaultValues: any) =>
  class extends UpdateControlRecord({
    SignData: "",
    ...defaultValues,
  }) {};

export class UpdateSignInput extends UpdateSignInputRecord({}) implements CSUpdateSignInput {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  SignData: "";

  constructor(props: Partial<CSUpdateSignInput>) {
    super(props);
  }

  public with(value: Partial<CSUpdateSignInput>): UpdateSignInput {
    return this.merge(value) as this;
  }
}

export interface Updatable {
  collectData(collector: Array<ControlDataRequest>): void;
  updateState(update: CSUFUpdateControl): void;
  resetToState(): void;
}

export interface UpdateBase {
  RealizerUID: string;
}

export interface CSUpdateData extends UpdateBase {
  Data: Array<CSUFUpdateControl>;
  ActiveControl: string;
  VCX: VisualContextMetadata;
  VCXSetToDef: boolean;
  HotKeys: string[];
  IsVCXAsInActive: boolean;
  CompanyColor: number;
  CompanyID: number;
}

export interface CSUpdateListView extends CSUpdateHeadered {
  Position: number;
  GroupView: boolean;
  ViewStyleAsInt: number;
  ListViewGroups: Array<CSUFNclListViewGroupMetadata>;
}

const UpdateListViewRecord = UpdateHeaderedRecord({
  Position: 0,
  GroupView: true,
  ViewStyleAsInt: 0,
  ListViewGroups: List<CSUFNclListViewGroupMetadata>(),
});

export class UpdateListView extends UpdateListViewRecord implements CSUpdateListView {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  CollapsedTitle: string;
  TitleSuffix: string;
  TitleSuffixCommandEnabled: boolean;

  Position: number;
  GroupView: boolean;
  ViewStyleAsInt: number;
  ListViewGroups: Array<CSUFNclListViewGroupMetadata>;

  constructor(props: Partial<CSUpdateListView>) {
    super(props);
  }

  public with(value: Partial<CSUpdateListView>): UpdateListView {
    return this.merge(value) as this;
  }
}

export interface ModifyTreeItem {
  Key: string;
  ModifyType: TDataItemModifyType;
  Data: CSTreeDataItem;
}

export interface CSTreeColumn extends CSColumnBase {
  StateGlyphId?: string;
}
export interface CSTreeDataItem {
  ParentKey: string;
  Columns: Array<CSTreeColumn>;
  HasChildren: TTreeDataHasChildNodes;
  Expanded: boolean;
  Index: number;
}

export interface ClientData {
  key?: string;
  children?: ClientData[];
}

export interface CSUpdateInnerTreeView extends CSUpdateInnerColumnsList {
  Data: Array<ModifyTreeItem>;
  CurrentBookmark: string;
  IsDraggable: boolean;
  DeniedText: string;
  DataVersion: number;
  MainColumnWidthEmW: number;
}

const UpdateInnerTreeViewRecord = class extends UpdateInnerColumnsListRecord({
  Data: List<ModifyTreeItem>(),
  CurrentBookmark: undefined,
  IsDraggable: false,
  DeniedText: undefined,
  DataVersion: -1,
  MainColumnWidthEmW: undefined,
  DataSource: undefined,
}) {};

export class UpdateInnerTreeView extends UpdateInnerTreeViewRecord implements CSUpdateInnerColumnsList {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  AutoAdjustWidth: boolean;
  FixedColumnsCount: number;
  Data: List<ModifyTreeItem>;
  CurrentBookmark: string;
  IsDraggable: boolean;
  DeniedText: string;
  DataVersion: number;
  MainColumnWidthEmW: number;
  CurrentColumn: number;
  ColumnsVersion: number;
  ComputedColumnsVersion: number;
  DataSource: List<ClientData>;

  constructor(props: Partial<CSUpdateInnerDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerTreeView>): UpdateInnerTreeView {
    return this.merge(value) as this;
  }
}

interface TreeViewColumn {
  Caption: string;
  Width: number;
}

export interface CSUpdateCalendar extends CSUFUpdateControl {
  Position: number;
  ActiveStartDate: string;
  ActiveEndDate: string;
  ProvidersList: Array<CSUFTNclProvider>;
  Fake_VCXColorMap: string;
  InitialView: string;
  InitialStartDate: string;
}

const UpdateCalendarRecord = UFUpdateControlRecord({
  Position: 0,
  ActiveStartDate: "",
  ActiveEndDate: "",
  ProvidersList: List<CSUFTNclProvider>(),
  ProvidersListHash: "",
  Fake_VCXColorMap: "",
  InitialView: "",
  InitialStartDate: "",
});

export class UpdateCalendar extends UpdateCalendarRecord implements CSUpdateCalendar {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  Position: number;
  ActiveStartDate: string;
  ActiveEndDate: string;
  ProvidersList: Array<CSUFTNclProvider>;
  ProvidersListHash: string;
  Fake_VCXColorMap: string;
  InitialView: string;
  InitialStartDate: string;

  constructor(props: Partial<CSUpdateCalendar>) {
    super(props);
  }

  public with(value: Partial<CSUpdateCalendar>): UpdateCalendar {
    return this.merge(value) as this;
  }
}

// export interface CSUpdateGantt extends CSUFUpdateControl {
// }

// const UpdateGanttRecord = UFUpdateControlRecord({
// });

// export class UpdateGantt extends UpdateGanttRecord implements CSUpdateGantt{
// 	ControlUID: string;
// 	Visible: boolean;
// 	Enabled: boolean;
// 	TabStop: boolean;
// 	Title: string;
// 	GlyphId: GlyphId;
// 	RealizerUID: string;

// 	constructor(props: Partial<CSUpdateGantt>){
// 		super(props);
// 	}

// 	public with(value: Partial<CSUpdateGantt>):  UpdateGantt{
// 		return this.merge(value) as this;
// 	}
// }

// export interface UpdateGantt extends CSUpdateHeadered {
// 	//data
// }

// export class UpdateGantt extends UpdateHeadered implements CSUpdateGantt{
// 		constructor(props: Partial<CSUpdateGantt>){
// 		super(props);
// 	}

// 	public with(value: Partial<CSUpdateGantt>):  UpdateGantt{
// 		return this.merge(value) as this;
// 	}
// }

export interface CSUpdateRibbon extends CSUFUpdateControl {
  CurrentToolBarIndex: number;
  HideToolBars: boolean;
  ToolBars: List<CSNclInnerToolBarMetadata>;
}

const UpdateRibbonRecord = UFUpdateControlRecord({
  CurrentToolBarIndex: 0,
  HideToolBars: false,
  ToolBars: undefined,
});

export class UpdateRibbon extends UpdateRibbonRecord implements CSUpdateRibbon {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  CurrentToolBarIndex: number;
  HideToolBars: boolean;
  ToolBars: List<CSNclInnerToolBarMetadata>;

  constructor(props: Partial<CSUpdateRibbon>) {
    super(props);
  }

  public with(value: Partial<CSUpdateRibbon>): UpdateRibbon {
    return this.merge(value) as this;
  }
}

export interface CSUpdateInnerToolbar extends CSUpdateControl {
  VisibleAccessor: boolean;
}

const UpdateInnerToolbarRecord = UpdateControlRecord({
  VisibleAccessor: false,
});

export class UpdateInnerToolbar extends UpdateInnerToolbarRecord implements CSUpdateInnerToolbar {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;

  VisibleAccessor: boolean;

  constructor(props: Partial<CSUpdateInnerToolbar>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInnerToolbar>): UpdateInnerToolbar {
    return this.merge(value) as this;
  }
}

export interface CSUpdateQuickFilter extends CSUpdateControl {
  Controls: List<CSUFNclControlMetadata>;
}

const UpdateQuickFilterRecord = UpdateControlRecord({
  Controls: null,
});

export class UpdateQuickFilter extends UpdateQuickFilterRecord implements CSUpdateQuickFilter {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Controls: List<CSUFNclControlMetadata>;

  constructor(props: Partial<CSUpdateQuickFilter>) {
    super(props);
  }

  public with(value: Partial<CSUpdateQuickFilter>): UpdateQuickFilter {
    return this.merge(value) as this;
  }
}

export interface CSUpdateAggregationPanel extends CSUpdateControl {
  Data: List<CSAggrData>;
  InvalidData: Boolean;
}

const UpdateAggregationPanelRecord = UpdateControlRecord({
  Data: null,
  InvalidData: false,
});

export class UpdateAggregationPanel extends UpdateAggregationPanelRecord implements CSUpdateAggregationPanel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  Title: string;
  GlyphId: GlyphId;
  RealizerUID: string;
  Data: List<CSAggrData>;
  InvalidData: Boolean;

  constructor(props: Partial<CSUpdateQuickFilter>) {
    super(props);
  }

  public with(value: Partial<CSUpdateAggregationPanel>): UpdateAggregationPanel {
    return this.merge(value) as this;
  }
}

export interface PartState {
  Id: string;
  State: TPartState;
  Error?: string;
  Data?: DashboardData | ReportPart | TilePart | FilterPart;
}

interface MemberState {
  MemberData: MemberData;
}

interface MemberData {
  ExpandedMembers: List<VisibleMember>;
  MemberID: string;
  PartIdentifier: string;
}

export interface CSUpdateDashboard extends CSUpdateHeadered {
  PartStates: List<PartState>;
  MemberStates: List<MemberState>;
  DataVersion?: number;
  Error: string;
}

const UpdateDashboardRecord = UpdateHeaderedRecord({
  PartStates: null,
  MemberStates: null,
  DataVersion: 0,
  Error: "",
});

export class UpdateDashboard extends UpdateDashboardRecord implements CSUpdateDashboard {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
  Title: string;
  GlyphId: string;
  PartStates: List<PartState>;
  MemberStates: List<MemberState>;
  DataVersion?: number;
  CollapsedTitle: string;
  TitleSuffix: string;
  TitleSuffixCommandEnabled: boolean;
  Error: string;

  public with(value: Partial<CSUFUpdateControl>): UpdateDashboard {
    return this.merge(value) as this;
  }
}

export interface CSUpdateOpenDialogContentRecord extends CSUFUpdateControl {
  ExpectedFilesCount: number;
}

const UpdateOpenDialogContentRecord = UFUpdateControlRecord({
  ExpectedFilesCount: undefined,
});

export class UpdateOpenDialogContent extends UpdateOpenDialogContentRecord implements CSUpdateOpenDialogContentRecord {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
  Title: string;
  GlyphId: string;
  ExpectedFilesCount: number;

  public with(value: Partial<CSUFUpdateControl>): UpdateOpenDialogContent {
    return this.merge(value) as this;
  }
}

export interface MFValue {
  Text: string;
  GlyphId: string;
  CommandState: TMFValueCommandState;
  CommandChecked: boolean;
}

export interface CSUpdateMultiFormatText extends CSUFUpdateControl {
  Values: List<MFValue>;
  Measure: MFMeasure;
}

const UpdateMultiFormatTextRecord = UpdateControlRecord({
  Values: undefined,
  Measure: undefined,
});

export class UpdateMultiFormatText extends UpdateMultiFormatTextRecord implements CSUpdateMultiFormatText {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
  Title: string;
  GlyphId: string;
  Values: List<MFValue>;
  Measure: MFMeasure;

  public with(value: Partial<CSUFUpdateControl>): UpdateMultiFormatText {
    return this.merge(value) as this;
  }
}

export interface CSUpdateMenuItem extends CSUpdateControl {
  Checked: boolean;
}

const UpdateMenuItemRecord = UpdateControlRecord({
  Checked: undefined,
});

export class UpdateMenuItem extends UpdateMenuItemRecord implements CSUpdateMenuItem {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
  Title: string;
  GlyphId: string;
  Checked: boolean;

  public with(value: Partial<CSUpdateControl>): UpdateMenuItem {
    return this.merge(value) as this;
  }
}

export interface CSUpdateInstantFilterPanel extends CSUFUpdateControl {
  InstantFilterMFs: InstantFilter;
  InstantFilterPanelVisible: boolean;
}

export const UpdateInstantFilterPanelRecord = class extends UFUpdateControlRecord({
  InstantFilterMFs: undefined,
  InstantFilterPanelVisible: false,
}) {};

export class UpdateInstantFilterPanel extends UpdateInstantFilterPanelRecord implements CSUpdateInstantFilterPanel {
  ControlUID: string;
  Visible: boolean;
  Enabled: boolean;
  TabStop: boolean;
  RealizerUID: string;
  Title: string;
  GlyphId: GlyphId;
  InstantFilterMFs: InstantFilter;
  InstantFilterPanelVisible: boolean;

  constructor(props: Partial<CSUpdateDataGrid>) {
    super(props);
  }

  public with(value: Partial<CSUpdateInstantFilterPanel>): UpdateInstantFilterPanel {
    return this.merge(value) as this;
  }
}

//#endregion

//#region "Interface objektu TFrgtData"

export interface BackgroundColorMode extends K2JSONObject {
  AsString: string;
  ColorType: number;
  CustomColor: number;
}

export interface FrgtBaseData extends K2JSONObject {
  Icon: GlyphId;
  Size: number;
  HorizontalAlignment: HorizontalAlignment;
  VerticalAlignment: VerticalAlignment;
  ZoomFactor: number;
  BandsCount: number;
}

export interface FrgtPanelBaseData extends FrgtBaseData {
  VerticalLines: FrgtGridLinesStyle;
  HorizontalLines: FrgtGridLinesStyle;
  Scroll: boolean;
}

export interface FrgtListViewData extends FrgtBaseData {
  LiteHeader: boolean;
  //HideHeader     : boolean;
  //TabStop        : boolean;
}

export interface FrgtTreeViewData extends FrgtBaseData {}

export interface FrgtCalendarData extends FrgtBaseData {}

export interface FrgtDashboardData extends FrgtBaseData {}

export interface FrgtExpanderData extends FrgtPanelBaseData {
  LiteHeader: boolean;
}

export interface FrgtFormBaseData extends FrgtPanelBaseData {
  WHRatio: number;
}

export interface FrgtFormData extends FrgtFormBaseData {
  AutoUpdateInterval: number;
}

export interface FrgtBaseGridData extends FrgtBaseData {
  RowHeightMultiplier: number;
  HideHeading: boolean;
}

export interface FrgtGridData extends FrgtBaseGridData {
  LiteHeader: boolean;
  HideHeader: boolean;
  HideFooter: boolean;
  DefaultCellLink: boolean;
  CellLinkCommand: string;
}

export interface FrgtGanttData extends FrgtBaseGridData {
  //FrgtBaseData{
  //LiteHeader		: boolean;
  HideHeader: boolean;
  HideFooter: boolean;
  //DefaultCellLink : boolean;
  //CellLinkCommand : string;

  //ProjectManagement  : boolean;
  //ResourceManagement : boolean;
  ByOperations: boolean;
  ByResources: boolean;
  HeaderAtBottom: boolean;
  OwnColors: boolean;
  HideNavigator: boolean;
  HideXAxis: boolean;
  HideYAxis: boolean;
  HideDateIndicator: boolean;
  HideRangeSelectorDates: boolean;
  //ShowDateTime: boolean;
  TwoRowXAxis: boolean;
  TimeAxisPrecision: number;
  DraggableStartEnd: boolean;
  PointSelectAllowed: boolean;
}

export interface FrgtSimpleGridData extends FrgtBaseGridData {}

export interface FrgtInputData extends FrgtBaseData {
  Watermark: String;
  ShowLabel: boolean;
  ShowFrame: boolean;
  DisableLabelHighlight: boolean;
  TabStop: boolean;
  IsPassword: boolean;
  BackgroundColorEditMode: BackgroundColorMode;
  BackgroundColorReadMode: BackgroundColorMode;
  BackgroundColorDisabledMode: BackgroundColorMode;
}

export interface FrgtKeyboardInputData extends FrgtBaseData {
  TabStop: Boolean;
  FrontGlyphId: GlyphId;
  RearGlyphId: GlyphId;
  BackGlyphId: GlyphId;
  FrameKind: TUFFrameKind;
  IsPassword: boolean;
  ColorStyle: TKeybInputColorStyle;
  AcceptKeyAction: TFrgtAcceptKeyAction;
  IgnoreFieldValue: boolean;
  InputAsEnglishUSLocale: boolean;
  ShowFrame: boolean;
}

export interface FrgtFormattableInputData extends FrgtInputData {
  ShowToolBar: boolean;
  NoSpecifiedFormat: TFormattableNoSpecifiedFormat;
}

export interface FrgtLabelData extends FrgtBaseData {
  TitleDisplayMode: DisplayMode;
  ValueDisplayMode: DisplayMode;
  Orientation: Orientation;
  BottomLine: boolean;
  ShowColon: boolean;
  StretchImage: boolean;
}

export interface FrgtTabControlData extends FrgtBaseData {
  TabIconPosition: IconPosition;
  AccesorsStretch: boolean;
}

export interface FrgtSplitterPanelData extends FrgtBaseData {
  Orientation: SplitterPanelOrientation;
  InitialRatio: number;
}

export interface FrgtCommandItemData extends FrgtBaseData {
  ShowIcon: boolean;
  ShowCaption: boolean;
  IconPosition: IconPosition;
  TabStop: boolean;
  DisplayStyle: TUFActionDisplayStyle;
}

export interface FrgtButtonData extends FrgtCommandItemData {
  FillWidth: boolean;
  Decorate: DataActionDecorate;
}

export interface FrgtCheckBoxData extends FrgtBaseData {
  TabStop: boolean;
}

export interface FrgtOpenDialogData extends FrgtFormData {
  DefaultExt: string;
  Filter: string;
  InitialDir: string;
  Options: TOpenOption;
}

export interface FrgtRibbonData extends FrgtBaseData {
  TileCaptionLineCount: number;
  ShowMenuButton: boolean;
  ShowF9Button: boolean;
  ShowSectionCaption: boolean;
}

export interface FrgtVirtualKeyboardData extends FrgtFormData {
  IsFloat: boolean;
  IsNumeric: boolean;
  MainMessage: string;
  GlyphId: GlyphId;
}

export interface FrgtGPSDialogData extends FrgtFormData {
  Accuracy: number;
  Request: TGPSRequest;
}

export interface FrgtCodeReaderData extends FrgtFormData {
  CodeTypes: TReaderCodeType;
  ImmediatelyAccept: Boolean;
}

//#endregion

//#region "Interface objektu ktere klient ziskava ze serveru pri get structure"

export interface K2JSONObject {
  readonly __type: string;
}

export interface CSNclControlMetadata extends K2JSONObject {
  ControlUID: string;
  Name: string;
  Attributes: string;
}

export interface CSUFNclControlMetadata extends CSNclControlMetadata {
  Bounds: Bounds;
  FrgtData: FrgtBaseData;
}

export interface CSNclContainerMetadata extends CSUFNclControlMetadata {
  Controls: Array<CSUFNclControlMetadata>;
}

export interface CSNclPanelMetadata extends CSNclContainerMetadata {
  FrgtData: FrgtPanelBaseData;
}

export interface CSUFNclListViewItemMetadata extends CSNclControlMetadata {
  Caption: string;
  GlyphId: string;
  Position: number;
  OverLayIcon: string;
}

export interface CSUFNclListViewGroupMetadata extends CSNclControlMetadata {
  Caption: string;
  ListViewItems: Array<CSUFNclListViewItemMetadata>;
}

export interface CSNclListViewMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtListViewData;
}

export interface CSNCLTreeViewMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtTreeViewData;
  Content: CSNclInnerDataGridMetadata;
  NoDataText: string;
}

export interface CSNclInnerTreeViewMetadata extends CSNclControlMetadata {
  //
}

/*  Calendar  */
export interface CSNclCalendarMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtCalendarData;
}

export interface CSUFNclAppointment extends CSNclControlMetadata {
  StartDate: string;
  EndDate: string;
  Subject: string;
  K2Pk: string;
  SerieUI: string;
  Categories: String;
  AppointmentItemType: number;
  Importance: number;
  AppointmentId: string;
  LegacyFreeBusyStatus: number;
  IsAllDayEvent: boolean;
  Hint: string;
  CustomColor: string;
  StateGlyphId: string;
  Priority: number;
  /*Id    : Integer ;*/
}

export interface CSUFTNclProvider extends CSNclControlMetadata {
  Caption: string;
  ProviderID: string;
  Description: string;
  Color: string;
  Icon: string;

  ProviderIDField: string;
  FormFilterSourceField: string;
  FormFilterCalendarField: string;
  ProviderDMClassname: string;

  AppointmentsList: Array<CSUFNclAppointment>;

  AllowDelete: boolean;
  AllowDragAndDrop: boolean;
  AllowInsert: boolean;
}
/* End of Calendar  */

/*  Gantt  */

export interface CSNclGanttMetadata extends CSNclHeaderedMetadata {
  //CSUFNclControlMetadata{
  FrgtData: FrgtGanttData;
  Content: CSNclGanttContent;
  Footer: CSNclGanttFooter;
}

/* End of Gantt  */

export interface Dashboard {
  ProvidersListHash: string;
  DashboardData: DashboardData;
}

export interface DashboardData {
  ControlUID: string;
  DashboardRid: number;
  Containers: Container[];
  Title: string;
  HorizontalAlignment: number;
  VerticalAlignment: number;
  PageSetup: TARPageSetup;
}

export interface Container {
  Parts: (ReportPart | TilePart)[];
  Orientation: TARContainerOrientation;
  Description: string;
  ContainerWidth: number;
}

export interface Part {
  ID: number;
  PartIdentifier: string;
  PartWidth: number;
}

export interface TilePart extends Part {
  BackgroundColor: string;
  Description: string;
  IconPath: string;
  KPIStateIcon: string;
  KPIText: string;
  LinkToDashboard: string;
  NavigationType: TNavigationType;
  TileTitle: string;
}

export interface DynamicFilterChoice {
  DynamicFilterChoiceItemID: number;
  DynamicFilterChoiceItemDescription: string;
}

export interface DynamicFilterPart extends Part {
  DynamicFilterChoices: DynamicFilterChoice[];
  DynamicFilterPartType: string;
  DynamicFilterSelectedChoiceIndex: number;
  DynamicFilterTitle: string;
}

export interface FilterPart extends Part {
  FilterPartType: string;
  PartTitle: string;
  VisibleMembers: VisibleMember[];
  UpdatedMembers: VisibleMember[];
}

export interface ReportPart extends Part {
  AxesDefinition: AxesDefinition[];
  BackgroundFilterInfo: string;
  DefaultPresentation: TARPresentationType;
  DrillthroughColumnsGroupName: string;
  LegendPosition: TARLegendPosition;
  LegendTitle: string;
  LinkDefinition: TLinkType[];
  MarksAngle: number;
  MarksStyle: number;
  MarksVisible: boolean;
  PartTitle: string;
  Series: Series[];
  ShowBackgroundFilterInfo: boolean;
  SupportsAsyncLoading: boolean;
  SeriesRowSum: DataPoint[];
  SeriesColumnSum: DataPoint[];
}

export interface AxesDefinition {
  Axis0: Axis[];
  Axis1: Axis[];
}

export interface Axis {
  Tuple: SelectedMember[];
}

export interface SelectedMember {
  Caption: string;
  DisplayInfo: number;
  Hierarchy: string;
  Identifier: string;
  LNum: number;
}

export interface Series {
  DataPoints: DataPoint[];
  GraphSerieType: TARGraphSerieType;
  Title: string;
  Color: string;
}

export interface DataPoint {
  Independent: string;
  BACK_COLOR: number;
  Dependent: number;
  FomattedValue: string;
  FORE_COLOR: number;
}

export interface VisibleMember extends SelectedMember {
  MemberState: TARMemberState;
  Selected: boolean;
  Children: VisibleMember[];
  Parent: VisibleMember;
}

export interface CSNCLDashboardMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtDashboardData;
}

export interface CSNclDynamicContentMetadata extends CSNclContainerMetadata {}

export interface CSNclHeaderedMetadata extends CSUFNclControlMetadata {
  Header: CSNclHeaderMetaData;
}

export interface CSNclViewMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtFormData;
  Content: CSNclPanelMetadata;
  ExplicitBounds: ExplicitBounds;
  FormExplicitBounds: ExplicitBounds;
}

export interface CSNclFloaterViewMetadata extends CSNclViewMetadata {
  RectInDock: RectInDock;
}

export interface CSNclMenuViewMetadata extends CSNclViewMetadata {
  Menu: CSNclMenuMetadata;
}

export interface CSNclMenuItemBaseMetadata extends CSNclControlMetadata {
  Caption: string;
  GlyphId: GlyphId;
}

export interface CSNclMenuContainerMetadata extends CSNclMenuItemBaseMetadata {
  Actions: Array<CSNclMenuItemBaseMetadata>;
}

export interface CSNclMenuMetadata extends CSNclMenuContainerMetadata {
  SourceControlUID: string;
}

export interface CSNclMenuGroupMetadata extends CSNclMenuContainerMetadata {}

export interface CSNclMenuItemMetadata extends CSNclMenuItemBaseMetadata {
  HotKey: string;
}

export interface CSNclClientMenuItemMetadata extends CSNclMenuItemMetadata {
  action: () => void;
}

export interface CSNclMenuDividerMetadata extends CSNclMenuItemBaseMetadata {}

export interface CSNclLibraryReferenceMetadata extends CSNclContainerMetadata {}

export interface CSNclHeaderMetaData extends CSNclControlMetadata {
  ToolBar: CSNclToolBarMetadata;
  RQuickButtons: Array<CSNclCommandItemMetadata>;
  Separator: CSUFNclControlMetadata;
  LocatorPanel: CSNclLocatorPanelMetadata;
  LQuickButton: CSNclCommandItemMetadata;
  LQuickButton1: CSNclCommandItemMetadata;
}

export interface CSNclExpanderMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtExpanderData;
  Content: CSNclPanelMetadata;
}

export interface CSNclInnerColumnsListMetadata extends CSNclControlMetadata {
  CanColumnDrag: boolean;
}

export interface CSNclInnerDataGridMetadata extends CSNclInnerColumnsListMetadata {}

export interface CSNclInnerGanttMetadata extends CSNclInnerColumnsListMetadata {
  //
}

export interface CSNclDataGridContent extends CSUFNclControlMetadata {
  DataGrid: CSNclInnerDataGridMetadata;
}

export interface CSNclGanttContent extends CSUFNclControlMetadata {
  GanttInner: CSNclInnerGanttMetadata;
}

export interface CSNclDataGridFooter extends CSNclControlMetadata {
  LeftToolbar: CSNclToolBarMetadata;
  RightToolbar: CSNclToolBarMetadata;
}

export interface CSNclGanttFooter extends CSNclControlMetadata {
  LeftToolbar: CSNclToolBarMetadata;
}

export interface CSNclDataGridBaseMetadata extends CSNclHeaderedMetadata {
  FrgtData: FrgtBaseGridData;
  Content: CSNclDataGridContent;
  Footer: CSNclDataGridFooter;
  QuickFilter: CSNclQuickFilterMetadata;
  AggregationPanel: CSNclAggregationPanel;
}

export interface CSNclDataGridMetadata extends CSNclDataGridBaseMetadata {
  FrgtData: FrgtGridData;
  InstantFilterPanel: CSNclInstantFilterPanelMetadata;
}

export interface CSNclSimpleDataGridMetadata extends CSNclDataGridBaseMetadata {
  FrgtData: FrgtSimpleGridData;
}

export interface CSNclInputMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtInputData;
  ToolBar: CSNclToolBarMetadata;
  PrefixDisplayAs: TUFDisplayValueAs;
  SuffixDisplayAs: TUFDisplayValueAs;
}

export interface CSNclKeyboardInputMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtKeyboardInputData;
  ToolBar: CSNclToolBarMetadata;
  PrefixDisplayAs: TUFDisplayValueAs;
  SuffixDisplayAs: TUFDisplayValueAs;
}

export interface CSNclInputFramedMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtInputData;
}

export interface CSNclFormattableInputMetadata extends CSNclInputFramedMetadata {
  FrgtData: FrgtFormattableInputData;
  ToolBar: CSNclToolBarMetadata;
}

export interface CSNclDataLabelMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtLabelData;
  DisplayAs: TUFDisplayValueAs;
  HasAction: boolean;
}

export interface CSNclTabControlMetadata extends CSUFNclControlMetadata {
  ListDetailTabControl: boolean;
  FrgtData: FrgtTabControlData;
  Pages: Array<CSNclPageMetadata>;
  Btns: Array<CSNclCommandItemMetadata>;
}

export interface CSNclPageMetadata extends CSUFNclControlMetadata {
  PageUID: string;
  IsDynamic: boolean;
  Content: CSUFNclControlMetadata;
}

export interface CSNclVRTabControlMetadata extends CSNclTabControlMetadata {}

export interface CSNclSplitterPanelMetadata extends CSNclContainerMetadata {
  FrgtData: FrgtSplitterPanelData;
}

export interface CSNclButtonMetadata extends CSNclCommandItemMetadata {
  FrgtData: FrgtButtonData;
  BackgroundColorCalc: number;
  ForegroundColorCalc: number;
}

export interface CSNclCommandItemMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtCommandItemData;
}

export interface CSNclCheckBoxMetaData extends CSUFNclControlMetadata {
  FrgtData: FrgtCheckBoxData;
}

export interface CSNclTabToolBarMetadata extends CSUFNclControlMetadata {
  ToolBars: Array<CSNclToolBarMetadata>;
}

export interface CSNclToolBarMetadata extends CSNclControlMetadata {
  Actions: Array<CSUFNclControlMetadata>;
  MenuBtn: CSNclCommandItemMetadata;
}

export interface CSNclGroupBoxMetaData extends CSNclContainerMetadata {}

export interface CSNclPreviewPanelMetadata extends CSNclContainerMetadata {
  FrgtData: FrgtPanelBaseData;
}

export interface CSNclFloaterAccessorMetadata extends CSNclContainerMetadata {}

export interface CSNclImageMetadata extends CSUFNclControlMetadata {}

export interface CSNclOpenDialogMetadata extends CSNclViewMetadata {
  FrgtData: FrgtOpenDialogData;
  OKBtn: CSNclButtonMetadata;
  CancelBtn: CSNclButtonMetadata;
}

export interface CSNclDeviceConnectorDialogMetadata extends CSNclViewMetadata {
  DeviceData: CSDeviceConnectData;
}

export interface CSNclGPSDialogMetadata extends CSNclViewMetadata {
  FrgtData: FrgtGPSDialogData;
}

export interface CSNclVirtualKeyboardDialogMetadata extends CSNclViewMetadata {
  FrgtData: FrgtVirtualKeyboardData;
}

export interface CSNclCodeReaderDialogMetadata extends CSNclViewMetadata {
  FrgtData: FrgtCodeReaderData;
}

export interface CSNclLocatorPanelMetadata extends CSNclContainerMetadata {
  SwitchBtn: CSNclCommandItemMetadata;
}

export interface CSNclWebViewMetadata extends CSNclImageMetadata {}

export interface CSNclSignInputMetadata extends CSUFNclControlMetadata {}

export interface CSViewRealizerStructure extends UpdateBase {
  Modal: boolean;
  ShowAsForm: boolean;
  DialogForm: boolean;
  Structure: CSUFNclControlMetadata;
  PriorRealizerUID: string;
  Dock: string;
  Caption: string;
  HotKeys: string[];
  VCX: VisualContextMetadata;
  RectInDock: RectInDock;
  InEditMode: boolean;
  AutoCloseTime: number;
}

export interface CSNclInnerSectionMetaData extends CSNclControlMetadata {
  ContentItems: Array<CSUFNclControlMetadata>;
  Caption: string;
  Hint: string;
  GlyphId: GlyphId;
}

export interface CSNclInnerToolBarMetadata extends CSNclControlMetadata {
  Sections: Array<CSNclInnerSectionMetaData>;
  Caption: string;
  Hint: string;
  GlyphId: GlyphId;
}

export interface CSNclRibbonMetadata extends CSUFNclControlMetadata {
  FrgtData: FrgtRibbonData;
  OthersBtn: CSNclCommandItemMetadata;
  TabHideBtn: CSNclCommandItemMetadata;
  ContextToolBarsCount: number;
}

export interface CSNclBreakerMetadata extends CSUFNclControlMetadata {}

export interface CSNclQuickFilterMetadata extends CSNclControlMetadata {}

export interface CSNclAggregationPanel extends CSNclControlMetadata {}

export interface CSNclMultiFormatTextMetadata extends CSUFNclControlMetadata {}

export interface CSNclInstantFilterPanelMetadata extends CSUFNclControlMetadata {}

//#endregion

//#region "VCX"

export interface VCXColorMapMetaData extends VCXMetaData {
  Alpha: number;
  InactiveAlphaValue: number;
  InactiveAlphaColor: number;
  AlphaColor: number;
  LumaShift: number;
  Gray: number;

  BaseColorBck1: number;
  BaseColorFrg1: number;

  AccentBaseColorBck: number;
  AccentBaseColorFrg: number;

  ContentColorBck1: number;
  ContentChangeColorBck: number;
  ContentNormalColorFrg: number;
  ContentDecorateColorFrg: number;
  ContentChangeDecorateColorFrg: number;

  DataBrowseColorBck: number;
  DataBrowseColorFrg: number;

  DataChangeColorBck: number;
  DataChangeColorFrg: number;

  DataChangeROColorBck: number;
  DataChangeROColorFrg: number;

  ErrorColorBck: number;
  ErrorColorFrg: number;

  WarningColorBck: number;
  WarningColorFrg: number;

  HighLightColorBck: number;
  HighLightColorFrg: number;
  HighlightColorSet: HighlightColorSetColors;

  GridRulerColorBck: number;

  ContentFrameMin: number;
  ContentFrame1: number;
  ContentFrame2: number;
  ContentFrame3: number;

  ContentFrameMinForChange: number;
  ContentFrame1ForChange: number;
  ContentFrame2ForChange: number;
  ContentFrame3ForChange: number;
}

export interface HighlightColorSetColors {
  [key: string]: number | string;
  Color1: number;
  Color2: number;
  Color3: number;
  Color4: number;
  Color5: number;
  Color6: number;
  Color7: number;
  Color8: number;
}

export interface VisualContextMetadata extends K2JSONObject {
  UI: string;
  Caption: string;
  Description: string;

  MarginX: number;
  MarginY: number;
  Zoom: number;

  DecorateMode: DecorateMode;

  SimpleChartControl: VCXSimpleChartControlMetaData;
  ColorMap: VCXColorMapMetaData;

  ExpanderControl: VCXExpanderControlMetaData;
  SplitterControl: VCXSplitterControlMetaData;

  BookTabControl: VCXTabControlMetaData;
  GridControl: VCXGridControlMetaData;
  Placement: VCXPlacementMetaData;
  TreeViewControl: VCXTreeViewControlMetaData;
  DashboardControl: VCXDashboardControlMetaData;
  ViewControl: VCXViewControlMetaData;

  DataInputControl: VCXDataInputControlMetaData;
  ListViewControl: VCXListViewControlMetaData;

  LabelControl: VCXLabelBaseMetaData;
  TitleControl: VCXLabelBaseMetaData;
}
export interface VCXMetaData extends K2JSONObject {}

export interface VCXListViewControlMetaData extends VCXMetaData {
  SmallIconSize: number;
  LargeIconSize: number;
  Font: VCXFontNoColorMetaData;
}

export interface VCXLabelBaseMetaData extends VCXMetaData {
  Font: VCXFontNoColorMetaData;
}

export interface VCXInputEditBaseMetaData extends VCXMetaData {
  InteriorBorder: number;
  Font: VCXFontNoColorMetaData;
}

export interface VCXDataInputControlMetaData extends VCXMetaData {
  FrameWidth: number;
  InputLabel: VCXLabelBaseMetaData;
  InputEdit: VCXInputEditBaseMetaData;
}

export interface VCXViewControlMetaData extends VCXMetaData {
  OutLineWidth: number;
  FrameWidth: number;
  Margin: number;
}

export interface VCXDashboardControlMetaData extends VCXMetaData {
  FilterInnerFont: VCXFontNoColorMetaData;
  GraphFont: VCXFontNoColorMetaData;
  PivotTableFixedFont: VCXFontNoColorMetaData;
  PivotTablePropertiesFont: VCXFontNoColorMetaData;
  PivotTableValueFont: VCXFontNoColorMetaData;
  SimpleTableFont: VCXFontNoColorMetaData;
  TileFont: VCXFontNoColorMetaData;
  TileSmallFont: VCXFontNoColorMetaData;
}

export interface VCXTreeViewControlMetaData extends VCXMetaData {
  HeadingFont: VCXFontNoColorMetaData;
  Font: VCXFontNoColorMetaData;
}

export interface VCXPlacementMetaData extends K2JSONObject {
  MaximizeAll: number;
}

export interface VCXGridControlMetaData extends VCXMetaData {
  HeadingFont: VCXFontNoColorMetaData;
  Font: VCXFontNoColorMetaData;
}

export interface VCXTabControlMetaData extends VCXMetaData {
  CurrentAccessorFont: VCXFontNoColorMetaData;
  AccessorFont: VCXFontNoColorMetaData;
}

export interface VCXSplitterControlMetaData extends VCXMetaData {
  Size: number;
}

export interface VCXExpanderControlMetaData extends VCXMetaData {
  HeaderFont: VCXFontNoColorMetaData;
  LiteSeparationWidth: number;
}

export interface VCXSimpleChartControlMetaData extends VCXMetaData {
  ChartCaptionFont: VCXFontNoColorMetaData;
  Font: VCXFontNoColorMetaData;
}

export interface VCXTitleControlMetaData extends VCXMetaData {
  Font: VCXFontNoColorMetaData;
}

export interface VCXFontNoColorMetaData extends VCXMetaData {
  FontStyle: FontStyle;
  FontSize: number;
  FontName: string;
}

//#endregion "VCX"

export function getNotifySvg(id: string, countStr: string) {
  return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg id="${id}" width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <g id="icon">
          <path id="notification" d="M4,5L4,23L8,23L8,28.078L14.352,23L28,23L28,5L4,5ZM6,7L26,7L26,21L13.648,21L10,23.918L10,21L6,21L6,7ZM18,14L8,14L8,16L18,16L18,14ZM24,10L8,10L8,12L24,12L24,10Z" style="fill-rule:nonzero;"/>
          <g id="${id}_indicator" visibility=${countStr.length == 1 ? "visibile" : "hidden"}>
            <g id="elipse" transform="matrix(1,0,0,1,-2,2)">
              <circle cx="24" cy="20" r="6" style="fill:rgb(244,86,86);"></circle>
            </g>
          </g>
         
      </g>
  </svg>`;
}
