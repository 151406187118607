import React from "react";
import { BackgroundColorMode, FrgtInputData, FrgtKeyboardInputData, TColorShiftLevel, TColorType } from "../../common/communication.base";
import { VCXColorMap, VisualContext } from "../../common/visualContext";
import K2TruncateText from "../Text/K2TruncateText";
import css from "./LabelInput.scss";

interface LabelInputProps {
  titleVisible: boolean;
  title: string;
  inEditMode: boolean;
  readOnly: boolean;
  vcx: VisualContext;
  focused: boolean;
  errorsCount: number;
  warningsCount: number;
  modified: boolean;
  frgtData: FrgtInputData | FrgtKeyboardInputData;
  id: string;
  children: React.ReactNode;
  className?: string;
}

const K2LabelInput = (props: LabelInputProps) => {
  const getHColor = (inv: boolean, colorType: number) => {
    let result: string;
    let i = Math.floor(colorType / 2);

    if (!inv) {
      i += 1;
    }

    result = props.vcx.getColor(props.vcx.Data.ColorMap.HighlightColorSet[`Color${i}`] as number);

    return result;
  };

  const getColor = (bgColorMode: BackgroundColorMode) => {
    let bgColor: string;
    let {
      ctHColor1,
      ctHColor2,
      ctHColor3,
      ctHColor4,
      ctHColor5,
      ctHColor6,
      ctHColor7,
      ctHColor8,
      ctHColor1Inv,
      ctHColor2Inv,
      ctHColor3Inv,
      ctHColor4Inv,
      ctHColor5Inv,
      ctHColor6Inv,
      ctHColor7Inv,
      ctHColor8Inv,
      ctError,
      ctErrorInv,
      ctWarning,
      ctWarningInv,
      ctHighlight,
      ctHighlightInv,
    } = TColorType;

    switch (bgColorMode.ColorType) {
      case ctHColor1:
      case ctHColor2:
      case ctHColor3:
      case ctHColor4:
      case ctHColor5:
      case ctHColor6:
      case ctHColor7:
      case ctHColor8:
        bgColor = getHColor(false, bgColorMode.ColorType);
        break;
      case ctHColor1Inv:
      case ctHColor2Inv:
      case ctHColor3Inv:
      case ctHColor4Inv:
      case ctHColor5Inv:
      case ctHColor6Inv:
      case ctHColor7Inv:
      case ctHColor8Inv:
        bgColor = props.vcx.getColor(VCXColorMap.getDifferentColor(parseInt(getHColor(true, bgColorMode.ColorType)), TColorShiftLevel.csl3));
        break;
      case ctError:
        bgColor = props.vcx.getColor(props.vcx.Data.ColorMap.ErrorColorBck);
        break;
      case ctErrorInv:
        bgColor = props.vcx.getColor(props.vcx.Data.ColorMap.ErrorColorFrg);
        break;
      case ctWarning:
        bgColor = props.vcx.getColor(props.vcx.Data.ColorMap.WarningColorBck);
        break;
      case ctWarningInv:
        bgColor = props.vcx.getColor(props.vcx.Data.ColorMap.WarningColorFrg);
        break;
      case ctHighlight:
        bgColor = props.vcx.getColor(props.vcx.Data.ColorMap.HighLightColorBck);
        break;
      case ctHighlightInv:
        bgColor = props.vcx.getColor(props.vcx.Data.ColorMap.HighLightColorFrg);
        break;
      default:
        bgColor = props.vcx.getColor(bgColorMode.CustomColor);
        break;
    }

    return bgColor;
  };

  const bgColor = (colorMode: BackgroundColorMode) => {
    if (colorMode.ColorType !== 0) {
      return getColor(colorMode);
    }
  };

  const resolveColors = () => {
    let labelClassName = "";
    let inputClassName = "";
    let borderClassName = "";
    const style: React.CSSProperties = {};

    if (props.inEditMode || !props.readOnly) {
      if (props.readOnly) {
        if ("BackgroundColorDisabledMode" in props.frgtData) style.backgroundColor = bgColor(props.frgtData.BackgroundColorDisabledMode);
        labelClassName = css.in_label_readonly;
        borderClassName = css.in_border_readonly;
        inputClassName = css.in_input_readonly;
      } else {
        if (props.focused) {
          borderClassName = css.in_border_focused;
        } else {
          borderClassName = css.in_border_edit;
        }

        if (props.errorsCount > 0) {
          if ("BackgroundColorEditMode" in props.frgtData) style.backgroundColor = style.backgroundColor = bgColor(props.frgtData.BackgroundColorEditMode);
          labelClassName = css.in_label_error;
          inputClassName = css.in_input_error;
        } else if (props.warningsCount > 0) {
          if ("BackgroundColorEditMode" in props.frgtData) style.backgroundColor = bgColor(props.frgtData.BackgroundColorEditMode);
          labelClassName = css.in_label_warning;
          inputClassName = css.in_input_warning;
        } else if (props.modified) {
          if ("BackgroundColorEditMode" in props.frgtData) style.backgroundColor = bgColor(props.frgtData.BackgroundColorEditMode);
          labelClassName = css.in_label_modified;
          inputClassName = css.in_input_modified;
        } else {
          if ("BackgroundColorEditMode" in props.frgtData) style.backgroundColor = bgColor(props.frgtData.BackgroundColorEditMode);
          labelClassName = css.in_label_edit;
          inputClassName = css.in_input_edit;
        }
      }
    } else {
      if ("BackgroundColorReadMode" in props.frgtData) style.backgroundColor = bgColor(props.frgtData.BackgroundColorReadMode);
      labelClassName = css.in_label_browse;
      inputClassName = css.in_input_browse;
      borderClassName = css.in_border_browse;
    }
    if ("DisableLabelHighlight" in props.frgtData) {
    if (props.frgtData.DisableLabelHighlight) {
      labelClassName = css.in_label_no_highlight;
    }
    }
    if (props.className) inputClassName += ` ${props.className}`;

    return { labelClassName, inputClassName, borderClassName, style };
  };

  const { labelClassName, inputClassName, borderClassName, style } = resolveColors();

  return (
    <>
      {props.titleVisible && (
        <label className={css.in_label} htmlFor={props.id}>
          <K2TruncateText className={`${css.in_label_text} ${labelClassName}`}>{props.title === "" ? " " : props.title}</K2TruncateText>
        </label>
      )}
      <div
        style={{ backgroundColor: style.backgroundColor }}
        className={`${css.in_input_label} ${inputClassName}${props.frgtData.ShowFrame ? ` ${borderClassName}` : ""}`}
      >
        {props.children}
      </div>
    </>
  );
};

export default K2LabelInput;
