import { List } from "immutable";
import React, { useEffect } from "react";
import { useState } from "react";
import { Context } from "../../appcontext";
import { Size } from "../../common/common";
import { ModalPosition, TBehaviorTypeByDevice } from "../../common/communication.base";
import { NclMenu, NclMenuView } from "../../common/components.ncl";
import { ViewRealizerManager } from "../../viewrealizer";
import { AcquireControl, WithVCXProps } from "../k2hoc";
import { RealizerQueueItem } from "../View/ViewRealizerReact";
import css from "./MenuModal.scss";

interface SimpleModalWindowProps extends WithVCXProps {
  realizerUID: string; // realizer for show
  controlUID: string;
  isOverlayBck?: boolean;
  realizersQueue: List<RealizerQueueItem>;
  updateModalList: () => void;
}

const K2ModalMenuWindow = (props: SimpleModalWindowProps) => {
  const [control] = useState(() => AcquireControl(props.controlUID, props.realizerUID, (ctrl) => ctrl instanceof NclMenuView) as NclMenuView);
  const [vr] = useState(() => ViewRealizerManager.getViewRealizer(props.realizerUID));

  const getPosition = (size: Size): ModalPosition => {
    let result = Context.getApplication().getMenuPositon();
    if (!result || (result.y == 0 && Context.DeviceInfo.StyleOfModalWindowShow === TBehaviorTypeByDevice.btbdNormal)) {
      //Do prvního pohybu myši, aby nebyla na PC výška 98% pro mobily
      result = { x: 1, y: 1 };
    }
    let wSize: Size = document.body.getBoundingClientRect();

    if (result.y + size.height > wSize.height) {
      result.y = Math.max(0, result.y - (result.y + size.height - wSize.height));
    }

    if (result.x + size.width > wSize.width) {
      result.x = Math.max(0, result.x - (result.x + size.width - wSize.width));
    }

    return result;
  };

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    control.closeRequest();

    if (e.type === "contextmenu") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    props.realizersQueue.map((r) => {
      if (!r.isOpen) {
        props.updateModalList();
      }
    });
  }, [props.realizersQueue]);

  let size: Size = { height: control.ComputedMinHeightWithMargin, width: control.VCX.sizeMap((control.Content as NclMenu).getMenuWidth()) };
  let position = getPosition(size);
  let style: React.CSSProperties = {
    position: "absolute",
    top: position.y,
    left: position.x,
    width: size.width,
  };

  if (position.y === 0) {
    style.height = "98%";
  }

  const isClosed = props.realizersQueue.some((r) => !r.isOpen);

  if (isClosed) return null;

  return (
    <div onClick={handleOutsideClick} onContextMenu={handleOutsideClick} style={{ zIndex: vr.getDepth() * 5 }} className={css.modal_menu_backdrop}>
      <div style={style} className={css.modal_menu}>
        {props.children}
      </div>
    </div>
  );
};

export default K2ModalMenuWindow;
